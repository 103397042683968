/*===================================================================
1. General
====================================================================*/
html {
  font-size: 14px;
}

.tab-content > .tab-pane,
[class~="custom-radio"] li input {
  display: none;
}

[class~="h3"],
[class~="h4"],
[class~="h2"],
[class~="h1"] {
  margin-bottom: 0.5rem;
}

[class~="h2"],
[class~="h4"],
[class~="h3"],
[class~="h1"] {
  font-family: Cormorant Garamond, serif;
}

.animation-horizontal:hover {
  animation-name: animation-horizontal;
}

[class~="btn-default"]:hover:before {
  height: 100%;
}

body {
  font-family: Lato, sans-serif;
}
.product-button:hover {
  color: white;
}

.theColectionImg {
  border: 1px solid rgba(128, 128, 128, 0.35);
  padding: 10px;
  border-radius: 7px;
}
.modal {
  z-index: 999999999999999999999 !important;
}
/* PRODUCTS DETAILS */
.modal-content {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.changPasswordCard{
  padding: 20px;
}
.changPasswordCol{
  padding:13px;
}
.whatsappIcon{
  width: 48px;
  height: 48px;
}
.mainDivWharsappImg{
  position: fixed;
  z-index: 99999999;
  bottom: 74px;
  left: 94%;
}
.title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  font-family: "Cormorant Garamond";
  font-weight: 600;
  color: #210832;
  text-align: center;
}
/* .mySlides {
  flex: 0 0 auto;
  max-width: 100%;
  display: block;
  transition: transform 0.3s ease;
} */
.menu-item-text {
  text-align: start;
}
.webCamTrayOn{
  width: 100%;
  /* height: 100%; */
}
.mySlides {
  flex: 0 0 auto;
  max-width: 100%;
  display: flex; /* Add this line */
  justify-content: space-between; /* Add this line to separate the number text and image */
  align-items: center; /* Optional: align items vertically in the center */
  transition: transform 0.3s ease;
  align-self: center;
}

.numbertext {
  flex: 0 0 auto;
  margin-right: 600px; /* Add some spacing between number text and image */
}

img {
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  /* Set the image to fit within the container while maintaining aspect ratio */
}

.gallery-scroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  cursor: pointer;
}
.text-danger {
  font-size: 10px;
}
.column {
  flex: 0 0 auto;
  width: 300px; /* Set the desired width for each image */
  margin-right: 10px; /* Add some margin between images */
  scroll-snap-align: start;
  transition: opacity 0.2s;
}

/* Optional: Add a hover effect */
.column:hover {
  opacity: 0.7;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
}

.custom-button {
  /* background-color: #F8C6C0; */
  background-color: #3c2147;
  border: none;
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
}

.custom-button:hover {
  background-color: #e39d92;
}

[class~="h3"],
[class~="h2"],
[class~="h1"],
[class~="h4"] {
  font-weight: 500;
}

[class~="h1"],
[class~="h3"],
[class~="h4"],
[class~="h2"] {
  line-height: 1.2;
}

body.home .section .block-title .sub-title {
  margin-top: 0.104166667in;
}

a:hover,
a:focus {
  color: #cb8161;
}

.animation-horizontal:hover {
  animation-duration: 1s;
}

[class~="h3"],
[class~="h1"],
[class~="h4"],
[class~="h2"] {
  color: #000;
}

.btn-underline.center:hover:before {
  left: 25%;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"] {
  font-size: 15px;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"] {
  position: relative;
}

body {
  font-size: 1rem;
}

.animation-horizontal:hover {
  animation-timing-function: ease-in-out;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"] {
  bottom: -10.5pt;
}

.btn-underline.center:hover:before {
  right: 25%;
}

.animation-horizontal:hover {
  animation-iteration-count: 1;
}
.productImgDivModal {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  left: 95%;
}
.productImgModal {
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 18px;
  background-color: #fff;
  /* box-shadow: 3px 3px 15px #d29638; */
    /* box-shadow: 3px 3px 4px #d29638; */

}
.whatsappLink{
  font-size: 25px;
  color: #3c2147;
  font-weight: 700;
}
a:focus,
a:hover {
  text-decoration: unset;
}

[class~="section"][class~="content-outside"]
  [class~="block-widget-wrap"]
  > div {
  margin-bottom: -21.25pc;
}

[class~="btn-default"]:hover:before {
  opacity: 1;
}

a:focus,
a:hover {
  outline: unset;
}

body {
  font-weight: 400;
}

[class~="tab-content"] > [class~="tab-pane"][class~="active"] {
  display: block;
}

[class~="btn"]:not(:disabled):not([class~="disabled"]),
[class~="clear-all"]:not(:disabled):not([class~="disabled"]) {
  cursor: pointer;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  margin-left: 0;
}

[class~="rating"][class~="small"] [class~="star"]:after {
  font-size: 11px;
}

[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-left: 0;
}

[class~="hover-opacity"]:hover {
  opacity: 0.8;
}

[class~="btn-primary"]:hover {
  color: #fff;
}

[class~="button-outline"][class~="button-white"][class~="thick-border"] {
  border-left-width: 0.020833333in;
}

body i[class~="slick-arrow"][class~="fa-angle-left"]:before {
  content: "";
}

body {
  line-height: 1.75;
}

.custom-radio li label:before {
  position: absolute;
}

body {
  color: #868686;
}

[class~="btn-primary"]:hover {
  background-color: #2b929f;
}

[class~="button-outline"][class~="button-white"][class~="thick-border"] {
  border-bottom-width: 0.020833333in;
}

[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-bottom: 0;
}

[class~="btn-primary"]:hover {
  border-left-color: #288995;
}

body {
  text-align: left;
}

.custom-radio li label:before {
  left: 0;
}

[class~="btn-default"]:hover:before {
  visibility: visible;
}

input[type="email"] {
  border-left-width: 0.75pt;
}
input[type="tel"] {
  background: white !important;
  border: 1px solid black !important;
  padding: 8.5pt 20px !important;
}
[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-right: 0;
}

input[type="email"] {
  border-bottom-width: 0.75pt;
}

[class~="btn-primary"]:hover {
  border-bottom-color: #288995;
}

[class~="h6"],
h1,
h2,
[class~="h5"] {
  margin-bottom: 0.5rem;
}

.custom-radio li label:before {
  border-radius: 50%;
}

h2,
[class~="h5"],
[class~="h6"],
h1 {
  font-family: Cormorant Garamond, serif;
}

input[type="email"] {
  border-right-width: 0.75pt;
}

[class~="btn-primary"]:hover {
  border-right-color: #288995;
}

[class~="btn-primary"]:hover {
  border-top-color: #288995;
}

[class~="custom-radio"] li label {
  margin-left: 0;
}

[class~="btn-primary"]:hover:before {
  height: 100%;
}

[class~="button-outline"][class~="button-white"][class~="thick-border"] {
  border-right-width: 0.020833333in;
}

input[type="email"] {
  border-top-width: 0.75pt;
}

input[type="email"] {
  border-left-style: solid;
}

input[type="email"] {
  border-bottom-style: solid;
}

input[type="email"] {
  border-right-style: solid;
}

input[type="email"] {
  border-top-style: solid;
}

input[type="email"] {
  border-left-color: #e5e5e5;
}

[class~="h6"],
[class~="h5"],
h2,
h1 {
  font-weight: 500;
}

.custom-radio li label:before {
  border-radius: 50%;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"] {
  font-size: 0.520833333in;
}

input[type="email"] {
  border-bottom-color: #e5e5e5;
}

input[type="email"] {
  border-right-color: #e5e5e5;
}

h1,
[class~="h6"],
[class~="h5"] {
  line-height: 1.2;
}

.btn-default:hover,
[class~="btn-primary"]:hover {
  color: #fff !important;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"]
  svg {
  fill: #cb8161;
}

body i[class~="slick-arrow"][class~="fa-angle-right"]:before {
  content: "";
}

input[type="email"] {
  border-top-color: #e5e5e5;
}

.custom-radio li label:before {
  border-radius: 50%;
}

input[type="email"] {
  border-image: none;
}

.btn-default:hover,
[class~="btn-primary"]:hover {
  border-left-color: #000 !important;
}

h1,
[class~="h6"],
[class~="h5"] {
  color: #000;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  display: flex;
}

.btn-default:hover,
[class~="btn-primary"]:hover {
  border-bottom-color: #000 !important;
}

.custom-radio li label:before {
  border-radius: 50%;
}

.custom-radio li input:checked + label:before {
  background: #000;
}

input[type="email"] {
  padding-left: 11.25pt;
}

[class~="btn-primary"]:hover,
.btn-default:hover {
  border-right-color: #000 !important;
}

input[type="email"] {
  padding-bottom: 0.4375pc;
}

input[type="email"] {
  padding-right: 11.25pt;
}

.btn-default:hover,
[class~="btn-primary"]:hover {
  border-top-color: #000 !important;
}

input[type="email"] {
  padding-top: 0.4375pc;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"] {
  color: #cb8161;
}

.products-loadmore .loadmore.loading .lds-ellipsis {
  display: inline-block;
}

input[type="email"] {
  background: transparent;
}

[class~="button-outline"][class~="button-white"][class~="thick-border"] {
  border-top-width: 0.020833333in;
}

.button-outline.border-white:hover,
[class~="btn-underline"]:hover:before {
  background: #cb8161;
}

[class~="form-control"],
input[type="email"] {
  box-shadow: none;
}

[class~="btn-primary"]:hover:before {
  opacity: 1;
}

.button-outline.border-white:hover,
body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"] {
  border-left-color: #cb8161;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"],
.button-outline.border-white:hover {
  border-bottom-color: #cb8161;
}

.button-outline.border-white:hover {
  border-right-color: #cb8161;
}

[class~="rating"][class~="small"] [class~="star"]:after,
[class~="rating"][class~="small"] [class~="star"]:before {
  letter-spacing: 0.020833333in;
}

[class~="button"][class~="padding-large"] {
  padding-left: 26.25pt;
}

.custom-radio li input:checked + label:before {
  border-left-color: #000;
}

[class~="custom-radio"] li label {
  margin-bottom: 0;
}

.products-loadmore .loadmore.loading .lds-ellipsis {
  position: relative;
}

.custom-radio li label:before {
  border-radius: 50%;
}

.custom-radio li label:before {
  top: calc(50% - 6px);
}

[class~="button"][class~="padding-large"] {
  padding-bottom: 0;
}

input[type="email"] {
  border-radius: 0;
}

input[type="email"] {
  border-radius: 0;
}

input[type="email"] {
  border-radius: 0;
}

[class~="button"][class~="padding-large"] {
  padding-right: 26.25pt;
}

.products-loadmore .loadmore.loading .lds-ellipsis,
[class~="table"] {
  width: 100%;
}

[class~="table"] {
  max-width: 100%;
}

[class~="button"][class~="padding-large"] {
  padding-top: 0;
}

[class~="button-black"]:focus,
[class~="button-white"]:hover,
[class~="button-primary"]:hover,
[class~="button-white"]:focus,
.button-black:hover,
[class~="button-outline"]:focus,
[class~="button-outline"]:hover {
  color: #fff;
}

input[type="email"] {
  border-radius: 0;
}

input[type="email"] {
  border-radius: 0;
}

.custom-radio li label:before {
  width: 12px;
}

.custom-radio li label:before {
  height: 12px;
}

[class~="button-white"]:hover,
[class~="button-white"]:focus {
  background-color: #cb8161;
}

[class~="btn-primary"]:hover:before {
  visibility: visible;
}

.button-outline.border-white:hover {
  border-top-color: #cb8161;
}

input[type="email"] {
  appearance: none;
}

[class~="btn-underline"]:hover:before {
  width: 50%;
}

input[type="number"],
.custom-radio li label:before {
  border-left-width: 0.0625pc;
}

.custom-radio li input:checked + label:before {
  border-bottom-color: #000;
}

[class~="table"] {
  margin-bottom: 1rem;
}

.custom-radio li label:before {
  border-bottom-width: 0.0625pc;
}

[class~="rating"][class~="small"] [class~="star"]:before {
  font-size: 11px;
}

[class~="button-outline"]:focus,
[class~="button-outline"]:hover,
[class~="button-outline"][class~="border-white"]:focus,
.button-black:hover,
[class~="button-black"]:focus {
  background: #3c2147 !important;
}

ul.slick-dots li.slick-active button {
  background: #fff;
}

.custom-radio li label:before {
  border-right-width: 0.0625pc;
}

.custom-radio li input:checked + label:before {
  border-right-color: #fff;
}

[class~="table"] {
  background-color: transparent;
}

[class~="button"] {
  border-left-width: 0;
}

h2,
h3 {
  line-height: 1.2;
}

[class~="button"] {
  border-bottom-width: 0;
}

[class~="button-outline"][class~="border-white"]:focus {
  border-left-color: #cb8161;
}

.custom-radio li label:before,
input[type="number"] {
  border-top-width: 0.0625pc;
}

h2 {
  color: #000;
}

[class~="button"] {
  border-right-width: 0;
}

[class~="rating"] [class~="star"][class~="star-1"]:before {
  content: "";
}

[class~="button"] {
  border-top-width: 0;
}

[class~="button"] {
  border-left-style: none;
}

[class~="custom-radio"] li label {
  margin-right: 0;
}

input[type="number"],
.custom-radio li label:before {
  border-left-style: solid;
}

h3 {
  margin-bottom: 0.5rem;
}

input[type="email"] {
  appearance: none;
}

[class~="button"] {
  border-bottom-style: none;
}

[class~="no-space"] [class~="row"] > div {
  padding-left: 0;
}

[class~="button"] {
  border-right-style: none;
}

[class~="rating"] [class~="star"][class~="star-2"]:before {
  content: "";
}

[class~="custom-radio"] li label {
  margin-top: 0;
}

[class~="button"] {
  border-top-style: none;
}

[class~="button"] {
  border-left-color: currentColor;
}

[class~="no-space"] [class~="row"] > div {
  padding-bottom: 0;
}

[class~="button"] {
  border-bottom-color: currentColor;
}

input[type="email"] {
  appearance: none;
}

h3 {
  font-family: Cormorant Garamond, serif;
}

[class~="button"] {
  border-right-color: currentColor;
}

[class~="rating"] [class~="star"][class~="star-3"]:before {
  content: "";
}

[class~="button"] {
  border-top-color: currentColor;
}

h3 {
  font-weight: 500;
}

[class~="button"] {
  border-image: none;
}

[class~="button-outline"][class~="thick-border"] {
  border-left-width: 0.020833333in;
}

.custom-radio li input:checked + label:before {
  border-top-color: #000;
}

input[type="number"] {
  border-bottom-width: 0.75pt;
}

[class~="btn"] {
  border-radius: 0;
}

[class~="align-right"] {
  text-align: right;
}

[class~="product-table"] tbody tr td:first-child {
  background: #f5f5f5 !important;
}

input[type="number"],
.custom-radio li label:before {
  border-bottom-style: solid;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before,
[class~="position-v-top"],
[class~="position-h-center"] {
  position: absolute;
}

input[type="number"] {
  border-right-width: 0.75pt;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  justify-content: space-between;
}

[class~="button-outline"][class~="border-white"]:focus {
  border-bottom-color: #cb8161;
}

[class~="position-v-top"] {
  top: 0;
}

[class~="position-v-center"] {
  align-content: center;
}

[class~="button-outline"][class~="thick-border"] {
  border-bottom-width: 0.020833333in;
}

[class~="position-v-center"] {
  align-items: center;
}

[class~="no-space"] [class~="row"] > div {
  padding-right: 0;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  margin-bottom: 0;
}

[class~="custom-radio"] li label {
  font-weight: 400;
}

input[type="number"],
.custom-radio li label:before {
  border-right-style: solid;
}

[class~="button-outline"][class~="thick-border"] {
  border-right-width: 0.020833333in;
}

[class~="custom-radio"] li label {
  position: relative;
}

[class~="position-v-center"] {
  display: box;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"],
[class~="button-outline"][class~="border-white"]:focus {
  border-right-color: #cb8161;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  margin-right: 0;
}

input[type="number"],
.custom-radio li label:before {
  border-top-style: solid;
}

[class~="position-v-center"] {
  display: flexbox;
}

[class~="position-v-center"] {
  display: flex;
}

.custom-radio li label:before {
  border-left-color: #868686;
}

[class~="position-v-center"],
.products-loadmore .loadmore.loading .lds-ellipsis {
  height: 100%;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  padding-bottom: 0.15625in;
}

h3 {
  color: #000;
}

[class~="small-space"] [class~="row"] > div,
[class~="no-space"] [class~="row"] > div,
[class~="no-space"]
  > [class~="slick-wrap"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-top: 0;
}

[class~="border"],
input[type="number"] {
  border-left-color: #e5e5e5;
}

[class~="button-outline"][class~="thick-border"] {
  border-top-width: 0.020833333in;
}

[class~="small-space"] [class~="row"] > div {
  padding-left: 0.46875pc;
}

[class~="small-space"] [class~="row"] > div {
  padding-bottom: 0;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  margin-top: 0;
}

[class~="position-h-center"] {
  left: 50%;
}

[class~="position-h-center"] {
  transform: translateX(-50%);
}

[class~="custom-radio"] li label {
  padding-left: 0.1875in;
}

[class~="border"],
input[type="number"] {
  border-bottom-color: #e5e5e5;
}

.custom-radio li label:before {
  border-bottom-color: #868686;
}

[class~="position-h-center"] {
  transform: translateX(-50%);
}

[class~="border"] {
  border-left-width: 0.75pt;
}

[class~="button-outline"][class~="white"] {
  background: #fff;
}

[class~="button-outline"][class~="border-white"] {
  border-left-color: #fff;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:hover:before {
  width: 50%;
}

[class~="button-outline"][class~="button-white"],
[class~="border"] {
  border-bottom-width: 0.75pt;
}

h4 {
  margin-bottom: 0.5rem;
}

[class~="button-outline"][class~="border-white"] {
  border-bottom-color: #fff;
}

[class~="button-outline"][class~="border-white"]:focus {
  border-top-color: #cb8161;
}

.custom-radio li label:before {
  border-right-color: #868686;
}

[class~="button-outline"][class~="button-white"],
[class~="border"] {
  border-right-width: 0.75pt;
}

input[type="number"],
[class~="border"] {
  border-right-color: #e5e5e5;
}

[class~="small-space"] [class~="row"] > div {
  padding-right: 0.46875pc;
}

.products-loadmore .loadmore.loading .lds-ellipsis {
  opacity: 1;
}

input[type="number"],
[class~="border"] {
  border-top-color: #e5e5e5;
}

h4 {
  font-family: Cormorant Garamond, serif;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"]
  svg {
  width: 1em;
}

[class~="btn-primary"],
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a,
[class~="button-outline"][class~="border-white"] {
  color: #fff;
}

[class~="border"],
[class~="button-outline"][class~="button-white"] {
  border-top-width: 0.75pt;
}

[class~="button-outline"][class~="button-white"],
[class~="border"] {
  border-left-style: solid;
}

[class~="button-outline"][class~="border-white"] {
  border-right-color: #fff;
}

[class~="product-table"] tbody tr td:first-child {
  font-size: 12pt;
}

[class~="button-outline"][class~="border-white"] {
  border-top-color: #fff;
}

h4 {
  font-weight: 500;
}

input[type="number"] {
  border-image: none;
}

[class~="border"] {
  border-bottom-style: solid;
}

.button-outline.border-black {
  border-left-color: #000;
}

input[type="number"] {
  padding-left: 11.25pt;
}

.button-outline.border-black {
  border-bottom-color: #000;
}

.button-outline.border-black {
  border-right-color: #000;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  display: inline-block;
}

[class~="rating"] [class~="star"][class~="star-4"]:before {
  content: "";
}

[class~="border"] {
  border-right-style: solid;
}

[class~="border"] {
  border-top-style: solid;
}

input[type="number"] {
  padding-bottom: 0.4375pc;
}

input[type="number"] {
  padding-right: 11.25pt;
}

.button-outline.border-black {
  border-top-color: #000;
}

[class~="rating"] [class~="star"][class~="star-5"]:before,
.rating .star.star-0:after {
  content: "";
}

[class~="border"] {
  border-image: none;
}

.button-outline.border-black,
h4 {
  color: #000;
}

h4 {
  line-height: 1.2;
}

input[type="number"] {
  padding-top: 0.4375pc;
}

[class~="btn-primary"] {
  background-color: #33aebd;
}

[class~="btn-primary"] {
  border-left-color: #33aebd;
}

input[type="number"] {
  background: transparent;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  font-size: 15pt;
}

[class~="button-outline"][class~="button-white"] {
  border-left-width: 0.0625pc;
}

input[type="number"] {
  border-radius: 0;
}

[class~="btn-primary"] {
  border-bottom-color: #33aebd;
}

[class~="btn-primary"] {
  border-right-color: #33aebd;
}

[class~="btn-primary"] {
  border-top-color: #33aebd;
}

.products-loadmore .loadmore.loading .lds-ellipsis {
  visibility: visible;
}

.custom-radio li label:before {
  border-top-color: #868686;
}

[class~="btn-primary"] {
  box-shadow: inset 0 0.010416667in 0 hsla(0, 0%, 100%, 0.15),
    0 0.010416667in 0.010416667in rgba(0, 0, 0, 0.075);
}

[class~="btn-primary"],
[class~="btn-underline"],
[class~="btn-default"] {
  position: relative;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"] {
  width: 100%;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  text-transform: uppercase;
}

[class~="btn-primary"],
[class~="btn-default"] {
  z-index: 0;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"] {
  border-top-color: #cb8161;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  font-size: 35px;
}

.custom-radio li label:before {
  border-image: none;
}

h5 {
  margin-bottom: 0.5rem;
}

[class~="button-outline"][class~="border-black"]:hover {
  background: #cb8161;
}

input[type="number"] {
  border-radius: 0;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"] {
  max-width: 285pt;
}

input[type="number"] {
  border-radius: 0;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  margin-bottom: 0.416666667in;
}

input[type="number"] {
  border-radius: 0;
}

[class~="button-outline"][class~="button-white"] {
  border-bottom-style: solid;
}

h5 {
  font-family: Cormorant Garamond, serif;
}

[class~="button-outline"][class~="button-white"] {
  border-right-style: solid;
}

h5 {
  font-weight: 500;
}

[class~="x-small-space"] [class~="row"] > div {
  padding-left: 3.75pt;
}

[class~="x-small-space"] [class~="row"] > div,
.large-space .row > div {
  padding-bottom: 0;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  line-height: 0.416666667in;
}

[class~="btn-underline"] {
  padding-bottom: 0.020833333in;
}

[class~="rating"] [class~="star"][class~="star-1"]:after {
  content: "";
}

[class~="button-outline"][class~="button-white"] {
  border-top-style: solid;
}

[class~="btn-underline"],
body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"],
[class~="rating"] [class~="star"]:after,
[class~="button"] {
  display: inline-block;
}

h5 {
  line-height: 1.2;
}

[class~="button"],
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  h2 {
  letter-spacing: 2.25pt;
}

[class~="x-small-space"] [class~="row"] > div {
  padding-right: 3.75pt;
}

[class~="button"] {
  font-size: 0.114583333in;
}

[class~="button"] {
  font-weight: 400;
}

[class~="button"] {
  line-height: 0.395833333in;
}

[class~="x-small-space"] [class~="row"] > div,
.large-space .row > div {
  padding-top: 0;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  margin-left: auto;
}

[class~="button-outline"][class~="button-white"] {
  border-left-color: #fff;
}

.large-space .row > div {
  padding-left: 1.875pc;
}

[class~="button-outline"][class~="border-black"]:hover {
  border-left-color: #cb8161;
}

[class~="custom-radio"] li label {
  cursor: pointer;
}

.rating .star.star-2:after {
  content: "";
}

[class~="rating"] [class~="star"][class~="star-3"]:after {
  content: "";
}

[class~="button"] {
  padding-left: 18.75pt;
}

input[type="number"] {
  border-radius: 0;
}

[class~="button-outline"][class~="button-white"] {
  border-bottom-color: #fff;
}

[class~="button"] {
  padding-bottom: 0px;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before,
.custom-radio li label:before {
  content: "";
}

.rating .star.star-4:after {
  content: "";
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"],
h5 {
  color: #000;
}

[class~="button-outline"][class~="button-white"] {
  border-right-color: #fff;
}

[class~="button-outline"][class~="button-white"] {
  border-top-color: #fff;
}

input[type="number"] {
  box-shadow: none;
}

[class~="button"] {
  padding-right: 18.75pt;
}

[class~="button-outline"][class~="button-white"] {
  border-image: none;
}

[class~="button"] {
  padding-top: 0px;
}

input[type="number"] {
  appearance: none;
}

[class~="button-outline"][class~="button-white"],
.button-primary,
[class~="pagination"] ul li a[class~="prev"]:hover {
  color: #fff;
}

input[type="number"] {
  appearance: none;
}

h6 {
  margin-bottom: 0.5rem;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  margin-bottom: 0.3125pc;
}

.large-space .row > div {
  padding-right: 1.875pc;
}

input[type="password"],
[class~="button-outline"],
[class~="section"][class~="top-border"] {
  border-top-width: 0.75pt;
}

h6 {
  font-family: Cormorant Garamond, serif;
}

input[type="number"] {
  appearance: none;
}

h6 {
  font-weight: 500;
}

[class~="button-outline"],
input[type="password"] {
  border-left-width: 0.75pt;
}

input[type="password"],
[class~="button-outline"] {
  border-bottom-width: 0.75pt;
}

[class~="button"] {
  text-transform: uppercase;
}

[class~="section"][class~="top-border"],
[class~="button-outline"] {
  border-top-style: solid;
}

.button-primary {
  background-color: #3c2147;
}

ul.slick-dots li button {
  font-size: 0;
}

[class~="button-white"] {
  background-color: #fff;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"] {
  margin-left: 0;
}

[class~="section"][class~="top-border"] {
  border-top-color: #e7e7e7;
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"] {
  font-size: 12px;
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"] {
  letter-spacing: 2.25pt;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  margin-right: auto;
}

[class~="button-white"],
h6 {
  color: #000;
}

[class~="button-outline"],
input[type="password"] {
  border-right-width: 0.75pt;
}

[class~="section"][class~="top-border"] {
  border-image: none;
}

[class~="section"][class~="p-t-10"] {
  padding-top: 0.104166667in;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  margin-top: auto;
}

[class~="button-black"] {
  background-color: #000;
}

.section.p-t-20 {
  padding-top: 1.25pc;
}

[class~="section"][class~="p-t-50"] {
  padding-top: 37.5pt;
}

[class~="product-table"] tbody tr td:first-child {
  color: #000 !important;
}

[class~="button-black"] {
  color: #fff;
}

body[class~="home"] [class~="section"] [class~="block-title"] {
  text-align: center;
}

body[class~="home"] [class~="section"] [class~="block-title"] {
  margin-bottom: 0.416666667in;
}

h6 {
  line-height: 1.2;
}

[class~="section"][class~="p-t-60"] {
  padding-top: 60px;
}

img {
  border-left-width: 0;
}

img {
  border-bottom-width: 0;
}

.section.p-t-70 {
  padding-top: 70px;
}

[class~="page-preloader"] [class~="loader"] div:nth-child(2) {
  animation-delay: -1s;
}

img {
  border-right-width: 0;
}

[class~="section"][class~="p-t-80"] {
  padding-top: 80px;
}

[class~="button-outline"],
input[type="password"] {
  border-left-style: solid;
}

img {
  border-top-width: 0;
}

input[type="password"],
[class~="button-outline"],
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-bottom-style: solid;
}

[class~="button-outline"],
[class~="button-outline"][class~="border-black"]:hover {
  border-bottom-color: #cb8161;
}

[class~="section"][class~="p-t-100"] {
  padding-top: 75pt;
}

[class~="button-outline"][class~="border-black"]:hover,
[class~="button-outline"] {
  border-color: #3c2147 !important;
}

img {
  border-left-style: none;
}

[class~="button-outline"],
input[type="password"] {
  border-right-style: solid;
}

ul.slick-dots li button {
  width: 0.09375in;
}

[class~="button-outline"] {
  border-left-color: #cb8161;
}

.product-table .tr-price del .amount {
  font-size: 16px;
}

[class~="price"] del span {
  text-decoration: line-through;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  position: relative;
}

.section.p-t-120 {
  padding-top: 1.25in;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  line-height: 1;
}

[class~="section"][class~="p-t-130"] {
  padding-top: 8.125pc;
}

[class~="section"][class~="p-t-140"] {
  padding-top: 1.458333333in;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-bottom-width: 0.010416667in;
}

img {
  border-bottom-style: none;
}

[class~="section"][class~="p-t-150"] {
  padding-top: 9.375pc;
}

[class~="section"][class~="p-b-10"] {
  padding-bottom: 7.5pt;
}

[class~="button-outline"],
[class~="button-outline"][class~="border-black"]:hover {
  border-top-color: #cb8161;
}

[class~="button-outline"] {
  border-image: none;
}

body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2,
[class~="button-outline"] {
  color: #3c2147;
  font-weight: 600;
}

ul.slick-dots li button {
  height: 9px;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"] {
  margin-bottom: 0;
}

[class~="section"][class~="p-b-20"] {
  padding-bottom: 20px;
}

[class~="button-outline"] {
  background: transparent;
}

img {
  border-right-style: none;
}

input[type="password"] {
  border-top-style: solid;
}

[class~="section"][class~="p-b-50"] {
  padding-bottom: 50px;
}

[class~="rating"] [class~="star"]:after {
  color: #fcad02;
}

[class~="rating"] [class~="star"]:after {
  font-family: FontAwesome;
}

.select2-container {
  width: 100% !important;
}

[class~="section"][class~="p-b-70"] {
  padding-bottom: 0.729166667in;
}

ul.slick-dots li button {
  background: #ffb374;
}

input[type="password"] {
  border-left-color: #e5e5e5;
}

[class~="section"][class~="p-b-80"] {
  padding-bottom: 5pc;
}

img {
  border-top-style: none;
}

input[type="password"] {
  border-bottom-color: #e5e5e5;
}

[class~="section"] {
  margin-bottom: 3.125pc;
}

img {
  border-left-color: currentColor;
}

[class~="section-padding"] {
  padding-left: 1.40625pc;
}

[class~="section-small-padding"],
ul.slick-dots li button,
[class~="section-padding"] {
  padding-bottom: 0;
}

[class~="section"][class~="p-b-100"] {
  padding-bottom: 100px;
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"] {
  margin-top: 0;
}

input[type="password"] {
  border-right-color: #e5e5e5;
}

input[type="password"] {
  border-top-color: #e5e5e5;
}

input[type="password"] {
  border-image: none;
}

[class~="section-padding"] {
  padding-right: 1.40625pc;
}

ul.slick-dots li button,
[class~="section-small-padding"],
[class~="section-padding"] {
  padding-top: 0;
}

ul.slick-dots li button {
  padding-left: 0;
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"] {
  margin-bottom: 5px;
}

[class~="product-table"] tbody tr td:first-child {
  font-weight: 500 !important;
}

.product-table .tr-price del .amount {
  color: #868686;
}

[class~="section"][class~="p-b-120"] {
  padding-bottom: 90pt;
}

input[type="password"] {
  padding-left: 11.25pt;
}

[class~="section"][class~="content-outside"] {
  margin-bottom: 307.5pt;
}

[class~="button-outline"][class~="border-black"]:hover {
  color: #fff;
}

[class~="section"][class~="content-outside"] {
  padding-top: 75pt;
}

[class~="button-outline"][class~="border-black"]:focus {
  background: #cb8161;
}

img {
  border-bottom-color: currentColor;
}

input[type="password"] {
  padding-bottom: 0.4375pc;
}

ul.slick-dots li button {
  padding-right: 0;
}

[class~="section"][class~="background-1"] {
  background-color: #e0e8f1;
}

body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 24px;
}

[class~="section"][class~="background-2"] {
  background-color: #dfe5dd;
}

.product-table .tr-price del .amount {
  margin-right: 0.020833333in;
}

[class~="section-small-padding"] {
  padding-left: 0.104166667in;
}

[class~="rating"] [class~="star"]:after {
  font-size: 0.135416667in;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  color: #000;
}

[class~="section"][class~="background-3"] {
  background-color: #f2eee7;
}
.wishListCard {
  padding: 10px;

  box-shadow: 2px 2px 11px #d4d1d6;
}
.wishListDiv {
  display: flex;
  gap: 10px;
  margin: "20px";
}
.tryItDivMain {
  display: flex;
  align-items: center;
  gap: 10px;
}
.deleteIcon {
  font-size: 25px;
  color: #3c2147;
}
ul.slick-dots li button {
  border-radius: 50%;
}
.lableEmail {
  display: flex;
  gap: 10px;
  color: #000;
  /* margin-top: 10px; */
}
[class~="section-small-padding"] {
  padding-right: 0.104166667in;
}

input[type="password"] {
  padding-right: 11.25pt;
}

img {
  border-right-color: currentColor;
}

ul.slick-dots li button {
  border-radius: 50%;
}

img {
  border-top-color: currentColor;
}

img {
  border-image: none;
}

[class~="products-loadmore"] [class~="loadmore"][class~="loading"]:before {
  content: none;
}

[class~="section-container"] {
  max-width: 1425px;
}

[class~="section-container"] {
  margin-right: auto;
}

[class~="rating"] [class~="star"]:after {
  line-height: 1;
}

input[type="password"] {
  padding-top: 0.4375pc;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  background: #000;
}

[class~="rating"] [class~="star"]:after {
  letter-spacing: 0.052083333in;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-left: 15pt;
}

img {
  vertical-align: top;
}

input[type="password"] {
  background: transparent;
}

[class~="section-container"] {
  margin-left: auto;
}

[class~="section-row"] {
  width: 100%;
}

[class~="section"][class~="background-4"] {
  background-color: #eeecf2;
}

input[type="password"] {
  border-radius: 0;
}

body i[class~="slick-arrow"] {
  line-height: 2.25pc;
}

ul.slick-dots li button {
  border-radius: 50%;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"]
  svg {
  height: 1em;
}

.section.background-5 {
  background-color: #cdc2bb;
}

input[type="password"] {
  border-radius: 0;
}

[class~="section-row"] {
  display: box;
}

input[type="password"] {
  border-radius: 0;
}

img {
  max-width: 100%;
}

input[type="password"] {
  border-radius: 0;
}

body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 600;
}

[class~="section"][class~="background-6"] {
  background-color: #ece5e0;
}

body i[class~="slick-arrow"] {
  text-align: center;
}

[class~="section-row"] {
  display: flexbox;
}

[class~="section"][class~="background-7"] {
  background-color: #e1ddd9;
}

[class~="section"][class~="background-8"] {
  background-color: #f5f5f5;
}

.section.background-9 {
  background-color: #ced3cd;
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  [class~="sub-title"] {
  text-transform: uppercase;
}

[class~="section"][class~="background-10"] {
  background-color: #d5d8e4;
}

img {
  height: auto;
}

[class~="section-row"] {
  display: flex;
}

[class~="button-outline"][class~="border-black"]:focus {
  border-left-color: #cb8161;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"]
  svg,
[class~="section-column-wrap"],
[class~="section-column"] {
  position: relative;
}

.section.background-11 {
  background-color: #e7ecef;
}

input[type="password"] {
  border-radius: 0;
}

[class~="section-column"] {
  display: box;
}

[class~="products-loadmore"] [class~="loadmore"][class~="loading"] span {
  opacity: 0;
}

a {
  color: #000;
}

[class~="rating"] [class~="star"]:before {
  display: inline-block;
}

[class~="section-column"] {
  display: flexbox;
}

[class~="rating"] [class~="star"]:before {
  color: #fcad02;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:first-child {
  left: 0.75pc;
}

[class~="section-column"] {
  display: flex;
}

body i[class~="slick-arrow"] {
  font-size: 1.25pc;
}

[class~="section"][class~="background-12"] {
  background-color: #f1eee8;
}

input[type="password"] {
  box-shadow: none;
}

input[type="password"] {
  appearance: none;
}

.section.background-13 {
  background-color: #fdf6f2;
}

[class~="section"][class~="background-img"] {
  background-position: center center;
}

a {
  text-decoration: none;
}

[class~="section"][class~="background-img"] {
  background-repeat: no-repeat;
}

body i[class~="slick-arrow"] {
  top: 50%;
}

ul.slick-dots li button {
  border-radius: 50%;
}

[class~="rating"] [class~="star"]:before {
  font-family: FontAwesome;
}

[class~="button-outline"][class~="border-black"]:focus {
  border-bottom-color: #cb8161;
}

[class~="section"][class~="background-img"] {
  background-size: cover;
}

[class~="section"][class~="bg-img-1"] {
  background-image: url("../../media/banner/bg-img-1.jpg");
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"],
.no-space .row {
  margin-right: 0;
}

input[type="password"] {
  appearance: none;
}

.section.bg-img-2 {
  background-image: url("../../media/banner/bg-img-2.jpg");
}

[class~="pagination"] ul li a:hover,
[class~="button-outline"][class~="border-black"]:focus {
  color: #fff;
}

[class~="section-column-wrap"] {
  display: box;
}

[class~="section"][class~="bg-img-3"] {
  background-image: url("../../media/banner/bg-img-3.jpg");
}

[class~="section"][class~="bg-img-4"] {
  background-image: url("../../media/banner/bg-img-4.jpg");
}

[class~="section"][class~="bg-img-5"] {
  background-image: url("../../media/banner/bg-img-5.jpg");
}

[class~="section-column-wrap"] {
  display: flexbox;
}

[class~="products-loadmore"] [class~="loadmore"][class~="loading"] span {
  visibility: hidden;
}

[class~="section"][class~="bg-img-6"] {
  background-image: url("../../media/banner/bg-img-6.jpg");
}

[class~="section-column-wrap"] {
  display: flex;
}

[class~="section"][class~="bg-img-7"] {
  background-image: url("../../media/banner/bg-img-7.jpg");
}

[class~="button-outline"][class~="border-black"]:focus {
  border-right-color: #cb8161;
}

[class~="section-container"][class~="large"] {
  max-width: 18.229166667in;
}

[class~="section-container"][class~="small"] {
  max-width: 900pt;
}

[class~="button-outline"][class~="border-black"]:focus {
  border-top-color: #cb8161;
}

.no-space .row {
  margin-left: 0;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:first-child {
  animation: i 0.6s infinite;
}

[class~="large-space"] [class~="row"],
[class~="small-space"] [class~="row"],
[class~="x-small-space"] [class~="row"],
.no-space .row {
  margin-bottom: 0;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-bottom-color: #e4e4e4;
}

[class~="section-column-wrap"] {
  width: 100%;
}

input[type="password"] {
  appearance: none;
}

select,
input[type="tel"] {
  border-left-width: 0.75pt;
}

[class~="small-space"] [class~="row"],
.no-space .row {
  margin-top: 0;
}

.section-mb-l {
  margin-bottom: 170px;
}

[class~="rating"] [class~="star"]:before {
  font-size: 0.135416667in;
}

[class~="position-center"] {
  align-content: center;
}

[class~="small-space"] [class~="row"] {
  margin-left: -0.46875pc;
}

[class~="position-center"] {
  align-items: center;
}

[class~="position-center"] {
  justify-content: center;
}

ul.slick-dots li button {
  border-radius: 50%;
}

[class~="position-center"] {
  display: box;
}

.products-loadmore .loadmore.loading i {
  animation: 2s linear 0s normal none infinite running o;
}

[class~="position-center"] {
  display: flexbox;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-bottom: 0;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(2) {
  left: 0.75pc;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(3),
[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(2) {
  animation: k 0.6s infinite;
}

[class~="rating"] [class~="star"]:before {
  line-height: 1;
}

[class~="position-center"] {
  display: flex;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(3) {
  left: 2.25pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  line-height: 30pt;
}

.products-loadmore .loadmore.loading i {
  opacity: 1;
}

ul.slick-dots li button {
  transition: all 0.2s ease;
}

[class~="small-space"] [class~="row"] {
  margin-right: -0.46875pc;
}

select,
input[type="tel"] {
  border-bottom-width: 0.75pt;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-right: 15pt;
}

select,
input[type="tel"] {
  border-right-width: 0.75pt;
}

[class~="button-outline"][class~="white"]:focus,
[class~="button-outline"][class~="white"]:hover,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  background: #000;
}

[class~="x-small-space"] [class~="row"] {
  margin-left: -0.3125pc;
}

ul.slick-dots li button {
  transition: all 0.2s ease;
}

[class~="rating"] [class~="star"]:before {
  letter-spacing: 0.052083333in;
}

.products-loadmore .loadmore.loading i {
  visibility: visible;
}

ul.slick-dots li button {
  position: relative;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(4) {
  left: 0.625in;
}

[class~="rating"] [class~="star"]:after {
  color: #e1e1e1;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="title"] {
  font-family: Cormorant Garamond, serif;
}

body i[class~="slick-arrow"] {
  transform: translateY(-50%);
}

[class~="position-center"] {
  height: 100%;
}

[class~="x-small-space"] [class~="row"] {
  margin-right: -0.3125pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"]:hover {
  background: #cb8161;
}

[class~="products-loadmore"]
  [class~="loadmore"][class~="loading"]
  [class~="lds-ellipsis"]
  div:nth-child(4) {
  animation: j 0.6s infinite;
}

[class~="page-preloader"] [class~="loader"] div {
  position: absolute;
}

.column-background-wrap {
  padding-left: 90px;
}

.column-background-wrap {
  padding-bottom: 5.625pc;
}

.column-background-wrap {
  padding-right: 11.25pt;
}

select,
input[type="tel"] {
  border-top-width: 0.75pt;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"],
[class~="large-space"] [class~="row"],
[class~="x-small-space"] [class~="row"] {
  margin-top: 0;
}

input[type="tel"],
select {
  border-left-style: solid;
}

.column-background-wrap {
  padding-top: 5.625pc;
}

.column-background-wrap {
  background: #d7e0e3;
}

body i[class~="slick-arrow"] {
  transform: translateY(-50%);
}

.p-t-5 {
  padding-top: 5px;
}

[class~="p-t-10"] {
  padding-top: 0.104166667in;
}

[class~="large-space"] [class~="row"] {
  margin-left: -0.3125in;
}

.p-t-20 {
  padding-top: 0.208333333in;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  width: 100%;
}

.p-t-30 {
  padding-top: 0.3125in;
}

input[type="tel"],
select {
  border-bottom-style: solid;
}

[class~="page-preloader"] [class~="loader"] div {
  border-left-width: 0.25pc;
}

[class~="p-t-50"] {
  padding-top: 37.5pt;
}

[class~="p-t-60"] {
  padding-top: 0.625in;
}

[class~="p-t-70"] {
  padding-top: 70px;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"] {
  border-image: none;
}

input[type="tel"],
select {
  border-right-style: solid;
}

[class~="p-t-80"] {
  padding-top: 0.833333333in;
}

[class~="large-space"] [class~="row"] {
  margin-right: -0.3125in;
}

[class~="p-t-100"] {
  padding-top: 6.25pc;
}

input[type="tel"] {
  border-top-style: solid;
}

[class~="slick-dots"] li button:before {
  content: none;
}

[class~="product-table"] tbody tr td {
  border-left-width: 0.0625pc !important;
}

[class~="p-t-120"] {
  padding-top: 120px;
}

[class~="p-t-130"] {
  padding-top: 97.5pt;
}

[class~="form-control"]:focus {
  border-left-width: medium;
}

[class~="product-table"] tbody tr td {
  border-left-style: solid !important;
}

[class~="p-t-140"] {
  padding-top: 8.75pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"] {
  display: flex;
}

select,
input[type="tel"] {
  border-left-color: #e5e5e5;
}

[class~="form-control"]:focus {
  border-bottom-width: medium;
}

select,
input[type="tel"] {
  border-bottom-color: #e5e5e5;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"] {
  background: #fff;
}

[class~="form-control"]:focus {
  border-right-width: medium;
}

[class~="page-preloader"] [class~="loader"] div {
  border-bottom-width: 0.25pc;
}

[class~="p-t-150"] {
  padding-top: 150px;
}

input[type="tel"] {
  border-right-color: #e5e5e5;
}

[class~="product-table"] tbody tr td {
  border-left-color: #e5e5e5 !important;
}

input[type="tel"] {
  border-top-color: #e5e5e5;
}

[class~="p-b-50"] {
  padding-bottom: 37.5pt;
}

[class~="page-preloader"] [class~="loader"] div {
  border-right-width: 0.25pc;
}

input[type="tel"] {
  border-image: none;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="sub-title"] {
  font-size: 0.166666667in;
}

input[type="tel"],
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  padding-left: 0.9375pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  cursor: pointer;
}

[class~="p-b-70"] {
  padding-bottom: 52.5pt;
}

[class~="p-b-80"] {
  padding-bottom: 80px;
}

[class~="form-control"]:focus {
  border-top-width: medium;
}

[class~="form-control"]:focus {
  border-left-style: none;
}

[class~="p-b-100"] {
  padding-bottom: 6.25pc;
}

[class~="button-outline"][class~="button-white"]:hover {
  background: #cb8161;
}

[class~="p-b-120"] {
  padding-bottom: 120px;
}

input[type="tel"] {
  padding-bottom: 0.4375pc;
}

body[class~="home"] [class~="section"] [class~="block-title"] h2,
[class~="m-t-0"] {
  margin-top: 0;
}

[class~="button-outline"][class~="button-white"]:hover {
  border-left-color: #cb8161;
}

[class~="m-t-5"] {
  margin-top: 5px;
}

body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  text-transform: uppercase;
}

body[class~="home"][class~="title-2"]
  [class~="section"]
  [class~="block-contact-form"]
  [class~="block-title"]
  h2 {
  font-size: 40px !important;
}

[class~="button-outline"][class~="button-white"]:hover {
  border-bottom-color: #cb8161;
}

[class~="form-control"]:focus {
  border-bottom-style: none;
}

body i[class~="slick-arrow"] {
  margin-top: -0.15625in;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
input[type="tel"] {
  padding-right: 11.25pt;
}

[class~="m-t-10"] {
  margin-top: 7.5pt;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  padding-bottom: 0;
}

[class~="form-control"]:focus {
  border-right-style: none;
}

[class~="product-table"] tbody tr td {
  border-image: none !important;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  color: #cb8161;
}

[class~="form-control"]:focus {
  border-top-style: none;
}

[class~="product-table"] tbody tr td {
  border-bottom-width: 0.0625pc !important;
}

[class~="form-control"]:focus {
  border-left-color: currentColor;
}

body i[class~="slick-arrow"] {
  position: absolute;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  font-size: 1.125pc;
}

select {
  border-top-style: solid;
}

input[type="tel"] {
  padding-top: 0.4375pc;
}

[class~="m-t-20"] {
  margin-top: 15pt;
}

[class~="m-b-0"] {
  margin-bottom: 0;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  font-weight: 600;
}

.m-b-10 {
  margin-bottom: 0.104166667in;
}

[class~="m-b-15"] {
  margin-bottom: 0.9375pc;
}

input[type="tel"] {
  background: transparent;
}

[class~="product-table"] tbody tr td {
  border-bottom-style: solid !important;
}

[class~="form-control"]:focus {
  border-bottom-color: currentColor;
}

input[type="tel"] {
  border-radius: 0;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="sub-title"] {
  color: #868686;
}

.m-b-20 {
  margin-bottom: 20px;
}

input[type="tel"] {
  border-radius: 0;
}

[class~="form-control"]:focus {
  border-right-color: currentColor;
}

[class~="form-control"]:focus {
  border-top-color: currentColor;
}

[class~="page-preloader"] [class~="loader"] div {
  border-top-width: 0.25pc;
}

[class~="product-table"] tbody tr td {
  border-bottom-color: #e5e5e5 !important;
}

[class~="m-b-30"] {
  margin-bottom: 30px;
}

[class~="page-preloader"] [class~="loader"] div,
input[type="text"] {
  border-left-style: solid;
}

select {
  border-right-color: #e5e5e5;
}

input[type="tel"] {
  border-radius: 0;
}

[class~="p-0"],
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="fullwidth"] [class~="block-widget-wrap"],
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  padding-top: 0;
}

input[type="tel"] {
  border-radius: 0;
}

select {
  border-top-color: #e5e5e5;
}

input[type="tel"] {
  border-radius: 0;
}

select,
[class~="form-control"]:focus {
  border-image: none;
}

[class~="m-b-60"] {
  margin-bottom: 3.75pc;
}

.m-b-70 {
  margin-bottom: 0.729166667in;
}

input[type="tel"],
[class~="form-control"]:focus {
  box-shadow: none;
}

[class~="m-b-80"] {
  margin-bottom: 80px;
}

input[type="tel"] {
  appearance: none;
}

input[type="tel"] {
  appearance: none;
}

[class~="product-table"] tbody tr td {
  padding-left: 0.208333333in !important;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  height: 37.5pt;
}

input[type="tel"] {
  appearance: none;
}

[class~="m-b-90"] {
  margin-bottom: 0.9375in;
}

input[type="text"],
[class~="page-preloader"] [class~="loader"] div {
  border-bottom-style: solid;
}

input[type="text"] {
  border-left-width: 0.75pt;
}

input[type="text"] {
  border-bottom-width: 0.75pt;
}

input[type="text"] {
  border-right-width: 0.75pt;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  line-height: 15pt;
}

[class~="m-t-n-60"] {
  margin-top: -0.625in;
}

[class~="pagination"] ul li a:hover {
  background: #000;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  height: 0.75pt;
}

[class~="p-0"],
[class~="fullwidth"] [class~="block-widget-wrap"] {
  padding-left: 0;
}

[class~="p-0"],
[class~="fullwidth"] [class~="block-widget-wrap"] {
  padding-bottom: 0;
}

.m-t-n-100 {
  margin-top: -100px;
}

input[type="text"] {
  border-top-width: 0.75pt;
}

[class~="m-t-n-130"] {
  margin-top: -97.5pt;
}

[class~="p-0"],
[class~="fullwidth"] [class~="block-widget-wrap"] {
  padding-right: 0;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  font-weight: 600;
}

[class~="product-table"] tbody tr td {
  padding-bottom: 0.208333333in !important;
}

[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  letter-spacing: 0.020833333in;
}

select {
  padding-left: 11.25pt;
}

.price,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="sub-title"] {
  font-weight: 400;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"],
[class~="rating"] [class~="review-count"],
[class~="page-preloader"] [class~="loader"],
[class~="content-product-list"] [class~="products-thumb"],
.rating .star {
  display: inline-block;
}

[class~="page-preloader"] [class~="loader"] div,
input[type="text"] {
  border-right-style: solid;
}

body i[class~="slick-arrow"] {
  z-index: 1;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  font-size: 0.125in;
}

body i[class~="slick-arrow"] {
  color: #000;
}

input[type="text"] {
  border-top-style: solid;
}

[class~="content-product-list"] [class~="products-thumb"] {
  vertical-align: top;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  margin-left: 0;
}

[class~="p-l-r"] {
  padding-left: 7.5px;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  color: #868686;
}

[class~="product-table"] tbody tr td {
  padding-right: 0.208333333in !important;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"],
.price {
  color: #cb8161;
}

[class~="p-l-r"] {
  padding-right: 7.5px;
}

input[type="text"] {
  border-left-color: #e5e5e5;
}

[class~="hide"] {
  display: none;
}

.price {
  line-height: 18pt;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  bottom: -10px;
}

.price {
  font-size: 0.15625in;
}

[class~="button-outline"][class~="button-white"]:hover,
[class~="button-outline"][class~="button-white"]:focus {
  border-right-color: #cb8161;
}

select,
input[type="text"] {
  padding-bottom: 0.4375pc;
}

select {
  padding-right: 11.25pt;
}

input[type="text"] {
  border-bottom-color: #e5e5e5;
}

[class~="block-section"],
[class~="page-preloader"] [class~="loader"] {
  position: relative;
}

[class~="product-table"] tbody tr td {
  padding-top: 0.208333333in !important;
}

[class~="pagination"] ul li a:hover {
  border-left-color: #000;
}

[class~="block-section"] {
  width: 100%;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  margin-bottom: 0.208333333in;
}

[class~="rating"] [class~="review-count"] {
  margin-left: 7.5pt;
}

input[type="text"] {
  border-right-color: #e5e5e5;
}

[class~="product-table"] tbody tr td {
  background: #fff !important;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  line-height: 37.5pt;
}

input[type="text"] {
  border-top-color: #e5e5e5;
}

[class~="page-preloader"] [class~="loader"] {
  width: 80px;
}

[class~="column-50"] {
  width: 50%;
}

[class~="column-25"] {
  width: 25%;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  font-size: 10.5pt;
}

[class~="page-preloader"] [class~="loader"] {
  height: 60pt;
}

[class~="button-outline"][class~="button-white"]:hover,
[class~="button-outline"][class~="button-white"]:focus {
  border-top-color: #cb8161;
}

[class~="block-widget-wrap"] {
  padding-left: 7.5px;
}

.block-widget-wrap-2,
[class~="block-widget-wrap"] {
  padding-bottom: 0in;
}

[class~="button-outline"][class~="button-white"]:focus {
  background: #cb8161;
}

[class~="block-widget-wrap"] {
  padding-right: 7.5px;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  font-weight: 600;
}

[class~="slick-dotted"][class~="slick-slider"] {
  margin-bottom: 0;
}

[class~="button-outline"][class~="button-white"]:focus {
  border-left-color: #cb8161;
}

select,
input[type="text"] {
  padding-top: 0.4375pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  border-bottom-width: 0.125pc;
}

[class~="block-widget-wrap"],
.block-widget-wrap-2 {
  padding-top: 0pt;
}

input[type="text"] {
  border-image: none;
}

[class~="button-outline"][class~="button-white"]:focus {
  border-bottom-color: #cb8161;
}

[class~="slick-sliders"] [class~="slick-list"] {
  margin-bottom: 0;
}

.block-widget-wrap-2,
[class~="block-widget-wrap"] {
  width: 100%;
}

.block-widget-wrap-2,
input[type="text"] {
  padding-left: 0.9375pc;
}

[class~="slick-sliders"] [class~="slick-list"] {
  margin-top: 0;
}

[class~="slick-sliders"] [class~="slick-list"] {
  transform: translateZ(0);
}

input[type="text"],
.block-widget-wrap-2 {
  padding-right: 15px;
}

[class~="slick-sliders"] [class~="slick-list"] {
  transform: translateZ(0);
}

input[type="text"],
select,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  background: transparent;
}

.slick-wrap:hover .slick-arrow {
  opacity: 1;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  left: 50%;
}

input[type="text"],
select {
  border-radius: 0;
}

body i[class~="slick-arrow"][class~="fa-angle-left"] {
  left: -11.25pt;
}

select,
input[type="text"] {
  border-radius: 0;
}

.slick-wrap:hover .slick-arrow {
  visibility: visible;
}

[class~="quantity"] [class~="minus"] {
  background: #fff;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  margin-right: 0;
}

[class~="quantity"] [class~="minus"] {
  float: left;
}

.full-height {
  height: 100%;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  [class~="title-icon"]
  svg,
[class~="quantity"] [class~="minus"],
[class~="hot"],
[class~="clearfix"]:after {
  display: block;
}

[class~="pagination"] ul li span {
  display: table-cell;
}

[class~="hot"] {
  text-align: center !important;
}

[class~="quantity"] [class~="minus"],
[class~="pagination"] ul li span {
  padding-left: 0;
}

[class~="pagination"] ul li span,
[class~="hot"],
[class~="quantity"] [class~="minus"] {
  padding-bottom: 0;
}

[class~="hot"] {
  text-align: center;
}

[class~="hot"] {
  color: #fff;
}

[class~="slick-wrap"] [class~="slick-arrow"]:hover {
  color: #cb8161;
}

input[type="text"],
select {
  border-radius: 0;
}

[class~="onsale"],
[class~="hot"] {
  font-size: 0.875pc;
}

[class~="onsale"],
[class~="hot"] {
  padding-left: 5.25pt;
}

[class~="pagination"] ul li span,
[class~="quantity"] [class~="minus"] {
  padding-right: 0;
}

[class~="hot"] {
  padding-right: 5.25pt;
}

[class~="page-preloader"] [class~="loader"] div {
  border-top-style: solid;
}

.products-loadmore .loadmore.loading .lds-ellipsis div {
  position: absolute;
}

select,
input[type="text"] {
  border-radius: 0;
}

[class~="quantity"] [class~="minus"],
[class~="hot"],
[class~="onsale"],
[class~="pagination"] ul li span {
  padding-top: 0;
}

[class~="hot"] {
  text-transform: capitalize;
}

[class~="hot"] {
  font-weight: 500;
}

[class~="quantity"] [class~="minus"],
[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"] {
  margin-left: 0;
}

[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"],
[class~="quantity"] [class~="minus"] {
  margin-bottom: 0;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  border-bottom-style: solid;
}

[class~="page-preloader"] [class~="loader"] div {
  border-left-color: #000;
}

[class~="hot"] {
  position: relative;
}

[class~="btn-default"]:before,
[class~="hot"] {
  position: absolute;
}

input[type="text"],
select {
  border-radius: 0;
}

.products-loadmore .loadmore.loading .lds-ellipsis div {
  top: calc(50% - 5px);
}

[class~="hot"] {
  line-height: 1.5pc;
}

select,
input[type="text"] {
  box-shadow: none;
}

[class~="hot"] {
  top: 0.625pc;
}

[class~="hot"] {
  min-width: 2.9375pc;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2,
[class~="quantity"] [class~="minus"] {
  margin-top: 0;
}

select,
input[type="text"] {
  appearance: none;
}

[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"],
[class~="quantity"] [class~="minus"] {
  margin-right: 0;
}

[class~="hot"] {
  z-index: 1;
}

input[type="text"] {
  appearance: none;
}

input[type="text"] {
  appearance: none;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  border-left-width: 0.0625pc;
}

[class~="onsale"] {
  text-align: center !important;
}

[class~="pagination"] ul li a:hover,
[class~="page-preloader"] [class~="loader"] div {
  border-bottom-color: #000;
}

[class~="onsale"] {
  text-align: center;
}

[class~="onsale"] {
  color: #fff;
}

input[type="number"] {
  appearance: textfield;
}

[class~="btn-default"]:before,
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a:hover {
  background: #cb8161;
}

[class~="pagination"] ul li span {
  float: none;
}

[class~="pagination"] ul li span {
  width: 0.416666667in;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  border-bottom-width: 0.0625pc;
}

[class~="onsale"] {
  padding-bottom: 0;
}

[class~="clearfix"]:after {
  clear: both;
}

[class~="btn-default"]:before,
[class~="clearfix"]:after {
  content: "";
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  border-bottom-color: #cb8161;
}

[class~="page-preloader"] [class~="loader"] div,
[class~="pagination"] ul li a:hover {
  border-right-color: #000;
}

[class~="onsale"] {
  padding-right: 5.25pt;
}

.products-loadmore .loadmore.loading .lds-ellipsis div {
  width: 10px;
}

[class~="quantity"] [class~="minus"],
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  border-right-width: 0.0625pc;
}

[class~="quantity"] [class~="minus"] {
  vertical-align: text-top;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="quantity"] [class~="minus"] {
  border-top-width: 0.0625pc;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  text-transform: uppercase;
}

[class~="quantity"] [class~="minus"] {
  overflow: visible;
}

[class~="pagination"] ul li a:hover,
[class~="page-preloader"] [class~="loader"] div {
  border-top-color: #000;
}

[class~="quantity"] [class~="minus"] {
  text-decoration: none;
}

[class~="quantity"] [class~="minus"] {
  cursor: pointer;
}

[class~="page-preloader"] [class~="loader"] div {
  border-image: none;
}

[class~="onsale"],
body i[class~="slick-arrow"] {
  font-weight: 500;
}

[class~="btn-default"]:before {
  top: 0;
}

[class~="quantity"] [class~="minus"] {
  line-height: 0.8125pc;
}

[class~="sidebar"] [class~="block"] [class~="block-title"] h2 {
  letter-spacing: 0.1em;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  transform: translateX(-50%);
}

body i[class~="slick-arrow"],
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  transition: all 0.3s ease;
}

[class~="quantity"] [class~="minus"] {
  font-size: 13.5pt;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-title"]
  [class~="sub-title"] {
  margin-bottom: 20px;
}

[class~="page-preloader"] [class~="loader"] div {
  opacity: 1;
}

[class~="pagination"] ul li span {
  height: 30pt;
}

select {
  appearance: none;
}

body i[class~="slick-arrow"],
[class~="product-table"] tbody tr[class~="tr-add-to-cart"] td a {
  transition: all 0.3s ease;
}

[class~="onsale"] {
  text-transform: capitalize;
}

[class~="onsale"] {
  display: block;
}

[class~="btn-default"]:before {
  left: 0;
}

[class~="quantity"] [class~="minus"] {
  color: #868686;
}

select {
  appearance: none;
}

[class~="pagination"] ul li span {
  line-height: normal;
}

[class~="onsale"] {
  position: relative;
}

[class~="quantity"] [class~="minus"],
textarea {
  border-left-width: 0.75pt;
}

[class~="btn-default"]:before {
  width: 100%;
}

[class~="btn-primary"]:before,
[class~="onsale"] {
  position: absolute;
}

[class~="quantity"] [class~="minus"],
textarea {
  border-bottom-width: 0.75pt;
}

[class~="btn-default"]:before {
  height: 0;
}

[class~="onsale"] {
  line-height: 1.5pc;
}

[class~="onsale"] {
  top: 0.625pc;
}

[class~="onsale"] {
  min-width: 2.9375pc;
}

[class~="btn-default"]:before {
  opacity: 0;
}

[class~="pagination"] ul li span {
  text-indent: 0.75pt;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 40px;
}

[class~="btn-default"]:before {
  visibility: hidden;
}

[class~="btn-default"]:before {
  transition: all 0.2s ease-in-out;
}

[class~="slick-sliders"][class~="fullwidth"] [class~="slick-list"] {
  margin-top: 0;
}

[class~="page-preloader"] [class~="loader"] div {
  border-radius: 50%;
}

[class~="page-preloader"] [class~="loader"] div {
  animation: loader-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="quantity"] [class~="minus"],
textarea,
[class~="pagination"] ul li span {
  border-left-style: solid;
}

textarea,
[class~="quantity"] [class~="minus"] {
  border-bottom-style: solid;
}

[class~="btn-default"]:before {
  transition: all 0.2s ease-in-out;
}

[class~="quantity"] [class~="minus"] {
  border-right-style: solid;
}

[class~="pagination"] ul li span,
textarea {
  border-right-width: 0.75pt;
}

[class~="onsale"] {
  z-index: 1;
}

[class~="btn-default"]:before {
  z-index: -1;
}

[class~="quantity"] [class~="minus"] {
  border-top-style: solid;
}

[class~="hot"] {
  right: 0.625pc;
}

[class~="quantity"] [class~="minus"] {
  border-left-color: #e5e5e5;
}

[class~="quantity"] input[class~="qty"],
[class~="quantity"] [class~="plus"] {
  float: left;
}

[class~="pagination"] ul li span,
textarea {
  border-top-width: 0.75pt;
}

[class~="btn-primary"]:before {
  content: "";
}

[class~="hot"] {
  background: #b12a2a;
}

[class~="btn-primary"]:before {
  top: 0;
}

.pagination ul li span.current,
[class~="hot"],
[class~="onsale"] {
  color: #fff;
}

[class~="slick-sliders"] [class~="slick-list"] [class~="slick-slide"] {
  padding-left: 15px;
}

.pagination ul li span.current {
  background: #000;
}

[class~="quantity"] [class~="minus"],
[class~="quantity"] input[class~="qty"] {
  width: 45px;
}

[class~="quantity"] [class~="minus"] {
  border-bottom-color: #e5e5e5;
}

[class~="btn-primary"]:before {
  background: #cb8161;
}

[class~="slick-sliders"] [class~="slick-list"] [class~="slick-slide"],
[class~="quantity"] [class~="plus"] {
  padding-bottom: 0;
}

[class~="quantity"] [class~="minus"] {
  border-right-color: #e5e5e5;
}

[class~="btn-primary"]:before {
  left: 0;
}

[class~="quantity"] input[class~="qty"],
[class~="pagination"] ul li span,
[class~="quantity"] [class~="minus"] {
  text-align: center;
}

[class~="quantity"] [class~="minus"] {
  border-top-color: #e5e5e5;
}

[class~="pagination"] ul li span {
  vertical-align: middle;
}

[class~="onsale"] {
  left: 7.5pt;
}

[class~="btn-primary"]:before {
  width: 100%;
}

[class~="quantity"] [class~="minus"] {
  border-image: none;
}

[class~="pagination"] ul li span {
  color: #868686;
}

[class~="pagination"] ul li span {
  font-size: 0.9375pc;
}

.pagination ul li span.current {
  border-left-color: #000;
}

[class~="quantity"] [class~="minus"],
[class~="quantity"] input[class~="qty"] {
  height: 2.375pc;
}

[class~="btn-primary"]:before {
  height: 0;
}

[class~="pagination"] ul li span {
  border-left-width: 0.75pt;
}

[class~="quantity"] [class~="plus"] {
  background: #fff;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  transform: translateX(-50%);
}

[class~="pagination"] ul li span {
  border-bottom-width: 0.75pt;
}

.pagination ul li span.current {
  border-bottom-color: #000;
}

[class~="onsale"] {
  background: #c0a483;
}

[class~="btn-primary"]:before {
  opacity: 0;
}

body i[class~="slick-arrow"] {
  font-family: wpbingofont;
}

[class~="quantity"] [class~="plus"] {
  display: block;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="pagination"] ul li span {
  border-bottom-style: solid;
}

[class~="quantity"] [class~="plus"],
[class~="quantity"] input[class~="qty"] {
  padding-left: 0;
}

textarea,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="pagination"] ul li span {
  border-right-style: solid;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:hover:before,
[class~="newsletter-popup"] [class~="newsletter-close"]:hover:after {
  transform: rotate(0deg);
}

[class~="btn-primary"]:before {
  visibility: hidden;
}

ul[class~="slick-dots"] li {
  display: inline-block;
}

ul[class~="slick-dots"] li {
  vertical-align: bottom;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:hover:after {
  transform: rotate(0deg);
}

[class~="btn-primary"]:before {
  transition: all 0.2s ease-in-out;
}

textarea,
[class~="pagination"] ul li span,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  border-top-style: solid;
}

.page-preloader {
  position: fixed;
}

.page-preloader {
  top: 0;
}

[class~="btn-primary"]:before {
  transition: all 0.2s ease-in-out;
}

[class~="slick-sliders"] [class~="slick-list"] [class~="slick-slide"] {
  padding-right: 15px;
}

[class~="btn-primary"]:before {
  z-index: -1;
}

textarea,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="pagination"] ul li span {
  border-left-color: #e5e5e5;
}

[class~="btn-underline"]:before {
  position: absolute;
}

[class~="quantity"] input[class~="qty"],
[class~="quantity"] [class~="plus"] {
  padding-right: 0;
}

[class~="quantity"] [class~="plus"],
[class~="slick-sliders"] [class~="slick-list"] [class~="slick-slide"] {
  padding-top: 0;
}

[class~="btn-underline"]:before {
  content: "";
}

[class~="btn-underline"]:before {
  width: 100%;
}

[class~="quantity"] [class~="plus"] {
  margin-left: 0;
}

.page-preloader {
  left: 0;
}

ul[class~="slick-dots"] li,
[class~="quantity"] [class~="plus"] {
  margin-bottom: 0;
}

[class~="quantity"] [class~="plus"] {
  margin-right: 0;
}

.page-preloader {
  right: 0;
}

[class~="quantity"] [class~="plus"] {
  margin-top: 0;
}

[class~="btn-underline"]:before,
.page-preloader {
  bottom: 0;
}

[class~="btn-underline"]:before {
  height: 0.010416667in;
}

[class~="quantity"] [class~="plus"] {
  vertical-align: text-top;
}

ul[class~="slick-dots"] li {
  margin-left: 0.0625in;
}

textarea,
[class~="pagination"] ul li span,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  border-bottom-color: #e5e5e5;
}

.page-preloader {
  background-color: #fff;
}

.page-preloader {
  z-index: 9999999;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
[class~="pagination"] ul li span,
textarea {
  border-right-color: #e5e5e5;
}

.pagination ul li span.current {
  border-right-color: #000;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  transition: all 0.3s ease;
}

.pagination ul li span.current {
  border-top-color: #000;
}

[class~="pagination"] ul li span,
textarea,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"] {
  border-top-color: #e5e5e5;
}

.product-table .tr-price .amount {
  font-size: 1.0625pc;
}

[class~="pagination"] ul li span,
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"],
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="email"],
textarea {
  border-image: none;
}

[class~="quantity"] input[class~="qty"] {
  padding-bottom: 0;
}

.product-table .tr-price .amount {
  color: #000;
}

.page-preloader {
  align-content: center;
}

[class~="product-table"] [class~="tr-price"] [class~="amount"] {
  color: #cb8161;
}

[class~="woosc_table"] [class~="tr-price"] [class~="amount"] {
  font-size: 15pt;
}

textarea {
  padding-left: 11.25pt;
}

.page-preloader {
  align-items: center;
}

[class~="quantity"] [class~="plus"] {
  overflow: visible;
}

.sidebar .block:last-child,
body[class~="home"] [class~="section"] [class~="block-title"] h2 {
  margin-bottom: 0;
}

.page-preloader {
  justify-content: center;
}

.page-preloader {
  display: box;
}

.page-preloader {
  display: flexbox;
}

body[class~="home"] [class~="section"] [class~="block-title"] h2 {
  font-size: 0.3125in;
}

.page-preloader {
  display: flex;
}

[class~="woosc_table"] [class~="tr-price"] [class~="amount"] {
  color: #000;
}

[class~="quantity"] input[class~="qty"] {
  padding-top: 0;
}

[class~="quantity"] [class~="plus"],
.product-table .tr-price ins {
  text-decoration: none;
}

textarea {
  padding-bottom: 0.4375pc;
}

.page-preloader {
  height: 100%;
}

[class~="star-rating"],
.slick-slider {
  overflow: hidden;
}

[class~="product-table"] [class~="tr-price"] del,
[class~="pagination"] ul li a[class~="prev"] {
  font-size: 0;
}

[class~="button-arrow"]:after,
[class~="scroll-image"],
[class~="star-rating"],
[class~="slick-wrap"] {
  position: relative;
}

[class~="quantity"] input[class~="qty"] {
  appearance: textfield;
}

[class~="quantity"] [class~="plus"] {
  cursor: pointer;
}

[class~="quantity"] [class~="plus"] {
  line-height: 0.8125pc;
}

[class~="slick-slide"],
.slick-track {
  transform: translateZ(0);
}

[class~="quantity"] [class~="plus"] {
  font-size: 13.5pt;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 600;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:hover {
  background: #e5e5e5;
}

textarea {
  padding-right: 11.25pt;
}

[class~="quantity"] [class~="plus"] {
  color: #868686;
}

textarea {
  padding-top: 0.4375pc;
}

.slick-track,
[class~="slick-slide"] {
  transform: translateZ(0);
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-no"] {
  margin-top: 20px;
}

[class~="btn-underline"]:before {
  left: 0;
}

textarea {
  background: transparent;
}

[class~="btn-underline"]:before {
  transition: all 0.3s ease;
}

[class~="quantity"] [class~="plus"] {
  border-left-width: 0.75pt;
}

[class~="pagination"] ul li span {
  transition: none;
}

[class~="btn-underline"]:before {
  transition: all 0.3s ease;
}

[class~="star-rating"] {
  width: 5.3125pc;
}

[class~="btn-underline"]:before {
  background: #000;
}

[class~="pagination"] ul li span {
  transition: none;
}

[class~="star-rating"] {
  height: 1.2em;
}

[class~="quantity"] [class~="plus"] {
  border-bottom-width: 0.75pt;
}

[class~="quantity"] [class~="plus"] {
  border-right-width: 0.75pt;
}

textarea {
  border-radius: 0;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:hover:before {
  transform: rotate(0deg);
}

textarea {
  border-radius: 0;
}

[class~="star-rating"] {
  line-height: 1.2em;
}

[class~="button-arrow"]:after {
  content: "";
}

[class~="pagination"] ul li span {
  text-transform: uppercase;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  text-align: center;
}

[class~="star-rating"],
[class~="button-arrow"]:after {
  font-family: icomoon;
}

.pagination ul li a.prev:before {
  font-size: 0.208333333in;
}

[class~="star-rating"] {
  display: inline-block;
}

[class~="products-loadmore"] [class~="loadmore"] span {
  animation: none;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-left-width: 0;
}

[class~="quantity"] [class~="plus"] {
  border-top-width: 0.75pt;
}

[class~="quantity"] [class~="plus"] {
  border-left-style: solid;
}

[class~="star-rating"],
[class~="button-arrow"]:after {
  font-size: 0.125in;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-bottom-width: 0;
}

[class~="quantity"] [class~="plus"] {
  border-bottom-style: solid;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-right-width: 0;
}

[class~="pagination"] ul li a {
  display: table-cell;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  padding-left: 1.5625pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  padding-bottom: 10px;
}

[class~="button-arrow"]:after {
  margin-left: 0.104166667in;
}

.newsletter-popup .newsletter-container .newsletter-img {
  width: 48.5%;
}

[class~="quantity"] {
  width: auto;
}

[class~="dropdown-toggle"]:after {
  display: none;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  width: 50.5%;
}

[class~="quantity"] {
  position: relative;
}

textarea {
  border-radius: 0;
}

[class~="quantity"] {
  margin-left: auto;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  margin-left: 0;
}

[class~="price"] ins {
  text-decoration: none;
}

[class~="quantity"],
[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  margin-bottom: 0;
}

ul[class~="slick-dots"] li {
  margin-right: 0.0625in;
}

[class~="quantity"] [class~="plus"] {
  border-right-style: solid;
}

textarea {
  border-radius: 0;
}

[class~="quantity"] [class~="plus"] {
  border-top-style: solid;
}

[class~="quantity"] [class~="plus"] {
  border-left-color: #e5e5e5;
}

[class~="pagination"] ul li a[class~="prev"] {
  color: #868686;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-top-width: 0;
}

[class~="quantity"] {
  margin-right: auto;
}

[class~="price"] ins {
  color: #cb8161;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"],
[class~="quantity"],
ul[class~="slick-dots"] li {
  margin-top: 0;
}

[class~="loading"]:before {
  content: "";
}

[class~="pagination"] ul li a {
  padding-left: 0;
}

[class~="quantity"] {
  overflow: hidden;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:before {
  transition: all 0.3s ease;
}

textarea {
  border-radius: 0;
}

[class~="quantity"] [class~="plus"] {
  border-bottom-color: #e5e5e5;
}

textarea {
  box-shadow: none;
}

[class~="loading"]:before {
  position: fixed;
}

[class~="products-loadmore"] [class~="loadmore"] span {
  font-size: 14px;
}

textarea {
  appearance: none;
}

[class~="quantity"] {
  zoom: 1;
}

[class~="loading"]:before {
  top: 0;
}

[class~="quantity"] [class~="plus"] {
  border-right-color: #e5e5e5;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  margin-right: 0;
}

[class~="loading"]:before {
  left: 0;
}

[class~="quantity"] [class~="plus"] {
  border-top-color: #e5e5e5;
}

[class~="pagination"] ul li a {
  padding-bottom: 0;
}

[class~="pagination"] ul li a {
  padding-right: 0;
}

[class~="loading"]:before {
  width: 100%;
}

[class~="pagination"] ul li a {
  padding-top: 0;
}

body[class~="home"][class~="title-3"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  text-transform: none;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-left-style: none;
}

[class~="quantity"] [class~="plus"] {
  border-image: none;
}

[class~="quantity"] [class~="plus"] {
  text-align: center;
}

[class~="quantity"] [class~="plus"] {
  width: 33.75pt;
}

[class~="loading"]:before {
  height: 100%;
}

[class~="back-top"]:hover,
[class~="cart-product-added"] {
  background: #000;
}

[class~="cart-product-added"] {
  line-height: 1.5;
}

[class~="products-loadmore"] [class~="loadmore"] span {
  display: inline-block;
}

.pagination ul li a.prev:before {
  font-family: eleganticons;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  content: "";
}

[class~="cart-product-added"] {
  box-shadow: unset;
}

[class~="quantity"] [class~="plus"] {
  height: 2.375pc;
}

[class~="loading"]:before {
  z-index: 9999;
}

[class~="products-loadmore"] [class~="loadmore"] {
  min-width: 140px;
}

[class~="cart-product-added"],
[class~="back-top"]:hover {
  color: #fff;
}

[class~="loading"]:before {
  background-color: rgba(0, 0, 0, 0.9);
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 0.416666667in;
}

[class~="cart-product-added"] {
  top: 0.208333333in;
}

[class~="products-loadmore"] [class~="loadmore"] {
  letter-spacing: normal;
}

[class~="slick-slide"] img {
  transform: translateZ(0);
}

[class~="products-loadmore"] [class~="loadmore"] span {
  text-transform: inherit;
}

textarea {
  appearance: none;
}

ul[class~="slick-dots"] li {
  width: 0.5625pc;
}

[class~="slick-slide"] img {
  transform: translateZ(0);
}

textarea {
  appearance: none;
}

ul.slick-dots {
  bottom: 1.875pc;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  font-family: FontAwesome;
}

[class~="cart-product-added"] {
  left: auto;
}

[class~="pagination"] ul li a {
  float: none;
}

[class~="cart-product-added"] {
  right: 1.25pc;
}

[class~="cart-product-added"] {
  padding-left: 1.5625pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-bottom-style: none;
}

.pagination ul li a.next {
  font-size: 0;
}

[class~="back-top"],
[class~="pagination"] ul li a {
  width: 0.416666667in;
}

[class~="products-loadmore"] [class~="loadmore"],
[class~="back-top"],
[class~="pagination"] ul li a {
  height: 40px;
}

ul.slick-dots {
  list-style-type: none;
}

[class~="cart-product-added"] {
  padding-bottom: 0.15625in;
}

.pagination ul li a.next {
  color: #868686;
}

[class~="cart-product-added"] {
  padding-right: 1.5625pc;
}

ul.slick-dots {
  position: unset;
}

[class~="cart-product-added"] {
  padding-top: 0.15625in;
}

[class~="cart-product-added"] {
  font-weight: 400;
}

[class~="back-top"]:hover,
[class~="slick-dots"] li {
  cursor: pointer;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-right-style: none;
}

[class~="slick-dots"] li {
  height: 15pt;
}

input::outer-spin-button {
  appearance: none;
}

ul[class~="slick-dots"] li {
  height: 9px;
}

[class~="pagination"] ul li a[class~="next"]:before {
  font-size: 0.208333333in;
}

[class~="back-top"]:hover {
  border-left-color: #000;
}

[class~="cart-product-added"],
[class~="back-top"] {
  position: fixed;
}

[class~="slick-dots"] li {
  width: 15pt;
}

[class~="products-loadmore"] [class~="loadmore"] span,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  position: relative;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-top-style: none;
}

[class~="back-top"],
[class~="cart-product-added"] {
  z-index: 999999;
}

[class~="back-top"]:hover {
  border-bottom-color: #000;
}

[class~="back-top"]:hover {
  border-right-color: #000;
}

input::outer-spin-button {
  margin-left: 0;
}

[class~="back-top"],
[class~="products-loadmore"] {
  text-align: center;
}

[class~="pagination"] {
  margin-top: 15pt;
}

[class~="products-loadmore"] [class~="loadmore"] span {
  top: -2px;
}

input::outer-spin-button {
  margin-bottom: 0;
}

[class~="pagination"] ul li a[class~="next"]:before {
  font-family: eleganticons;
}

[class~="back-top"]:hover {
  border-top-color: #000;
}

[class~="star-rating"]:before {
  content: "";
}

body[class~="home"] [class~="section"] [class~="block-title"] h2 {
  text-transform: uppercase;
}

[class~="back-top"] {
  line-height: 36.75pt;
}

input::outer-spin-button {
  margin-right: 0;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  top: -0.010416667in;
}

input::outer-spin-button {
  margin-top: 0;
}

input::inner-spin-button {
  appearance: none;
}

[class~="back-top"][class~="button-show"] {
  transform: translateY(0);
}

[class~="star-rating"]:before {
  font-family: FontAwesome;
}

[class~="star-rating"]:before {
  color: #e1e1e1;
}

[class~="back-top"][class~="button-show"] {
  transform: translateY(0);
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  background: #fff;
}

.products-loadmore .loadmore i {
  font-size: 12pt;
}

[class~="pagination"] ul li a {
  line-height: normal;
}

[class~="star-rating"] span,
[class~="star-rating"]:before {
  float: left;
}

[class~="back-top"][class~="button-show"] {
  visibility: visible;
}

[class~="star-rating"] span,
[class~="star-rating"]:before {
  top: 0;
}

[class~="back-top"] {
  bottom: 22.5pt;
}

[class~="back-top"][class~="button-show"] {
  opacity: 1;
}

[class~="back-top"] {
  right: 1.875pc;
}

[class~="sidebar"] [class~="block"] {
  margin-bottom: 0.520833333in;
}

.products-loadmore .loadmore i {
  margin-left: 5px;
}

[class~="star-rating"] span,
[class~="star-rating"]:before {
  left: 0;
}

.newsletter-popup .newsletter-close,
[class~="star-rating"] span,
[class~="star-rating"]:before,
[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  position: absolute;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  display: flex;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-left-color: currentColor;
}

input::inner-spin-button {
  margin-left: 0;
}

[class~="newsletter-popup"][class~="active"] {
  display: block;
}

input::inner-spin-button {
  margin-bottom: 0;
}

[class~="back-top"] {
  color: #fff;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  content: "";
}

[class~="star-rating"]:before {
  letter-spacing: 0.375pc;
}

ul[class~="slick-dots"] li,
[class~="back-top"] {
  transition: all 0.3s ease;
}

[class~="back-top"] {
  background: #3c2147;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-bottom-color: currentColor;
}

input::inner-spin-button {
  margin-right: 0;
}

[class~="back-top"] {
  visibility: hidden;
}

[class~="back-top"],
ul[class~="slick-dots"] li {
  transition: all 0.3s ease;
}

[class~="back-top"] {
  opacity: 0;
}

input::inner-spin-button {
  margin-top: 0;
}

[class~="back-top"] {
  filter: alpha(opacity=0);
}

[class~="back-top"] {
  transform: translateY(20px);
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 600;
}

.newsletter-popup .newsletter-close,
[class~="pagination"] ul li a {
  text-align: center;
}

[class~="star-rating"] span {
  overflow: hidden;
}

label {
  display: inline-block;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  height: 0.125pc;
}

[class~="back-top"] {
  transform: translateY(20px);
}

body[class~="home"][class~="title-4"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  text-transform: none;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  padding-right: 1.5625pc;
}

.newsletter-popup .newsletter-close {
  cursor: pointer;
}

[class~="pagination"] ul li a {
  text-indent: 0.75pt;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  padding-top: 10px;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  align-items: center;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-right-color: currentColor;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  margin-left: auto;
}

.newsletter-popup .newsletter-close {
  top: 10px;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-top-color: currentColor;
}

table,
[class~="newsletter-popup"] [class~="newsletter-close"]:after,
[class~="popup-shadow"] {
  width: 100%;
}

.newsletter-popup .newsletter-close {
  right: 10px;
}

.left-sidebar {
  padding-right: 0.3125in;
}

ul[class~="slick-dots"] li {
  border-radius: 50%;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  top: 50%;
}

body[class~="home"][class~="title-6"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  color: #cb8161;
}

.newsletter-popup .newsletter-close {
  color: #000;
}

[class~="right-sidebar"] {
  padding-left: 0.3125in;
}

[class~="popup-shadow"] {
  background-color: rgba(32, 32, 32, 0.6);
}

[class~="popup-shadow"] {
  height: 100%;
}

[class~="popup-shadow"],
[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  left: 0;
}

[class~="popup-shadow"] {
  position: fixed;
}

.newsletter-popup .newsletter-close {
  z-index: 10000;
}

.products-loadmore .loadmore.loading .lds-ellipsis div {
  height: 7.5pt;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  border-image: none;
}

[class~="popup-shadow"] {
  top: 0;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  padding-left: 13.5pt;
}

label {
  margin-bottom: 0.5rem;
}

ul[class~="slick-dots"] li {
  border-radius: 50%;
}

[class~="star-rating"] span {
  padding-top: 1.5em;
}

table {
  max-width: 100%;
}

.newsletter-popup .newsletter-close {
  width: 27pt;
}

.newsletter-popup .newsletter-close {
  height: 27pt;
}

.newsletter-popup .newsletter-close {
  border-left-width: 0.5625pc;
}

.newsletter-popup .newsletter-close {
  border-bottom-width: 0.5625pc;
}

table {
  margin-bottom: 1rem;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  margin-top: -0.010416667in;
}

[class~="popup-shadow"] {
  z-index: 999999;
}

.newsletter-popup .newsletter-close {
  border-right-width: 0.5625pc;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"] {
  justify-content: center;
}

[class~="pagination"] ul li a {
  vertical-align: middle;
}

ul[class~="slick-dots"] li {
  border-radius: 50%;
}

.newsletter-popup .newsletter-close {
  border-top-width: 0.5625pc;
}

[class~="pagination"] ul li a {
  color: #868686;
}

[class~="popup-shadow"] {
  display: none;
}

ul[class~="slick-dots"] li {
  border-radius: 50%;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  padding-bottom: 0;
}

.newsletter-popup {
  box-sizing: border-box;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form,
.newsletter-popup {
  margin-bottom: auto;
}

.newsletter-popup .newsletter-close {
  border-left-style: solid;
}

.newsletter-popup .newsletter-close {
  border-bottom-style: solid;
}

[class~="cart-product-added"] > div,
body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  display: inline-block;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  padding-right: 13.5pt;
}

.newsletter-popup .newsletter-close {
  border-right-style: solid;
}

.newsletter-popup .newsletter-close {
  border-top-style: solid;
}

.newsletter-popup .newsletter-close {
  border-left-color: transparent;
}

.newsletter-popup .newsletter-close {
  border-bottom-color: transparent;
}

table {
  background-color: transparent;
}

.newsletter-popup .newsletter-close {
  border-right-color: transparent;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  background-color: rgba(0, 0, 0, 0.5);
}

ul[class~="slick-dots"] li {
  border-radius: 50%;
}

.newsletter-popup {
  height: auto;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  transition: all 0.3s ease;
}

body[class~="home"][class~="title-6"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 0.364583333in;
}

.newsletter-popup .newsletter-close {
  border-top-color: transparent;
}

[class~="pagination"] ul {
  padding-left: 0;
}

[class~="pagination"] ul li a {
  font-size: 0.9375pc;
}

.newsletter-popup .newsletter-close {
  border-image: none;
}

.newsletter-popup .newsletter-close {
  border-radius: 100%;
}

[class~="pagination"] ul {
  list-style: none;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:after {
  transition: all 0.3s ease;
}

[class~="pagination"] ul {
  text-align: center;
}

[class~="slick-dots"] li button {
  height: 15pt;
}

.newsletter-popup {
  left: 50%;
}

table {
  border-collapse: collapse;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  content: "";
}

[class~="slick-dots"] li button {
  width: 15pt;
}

.newsletter-popup {
  transform: translate(-50%, -50%);
}

.newsletter-popup .newsletter-close {
  border-radius: 100%;
}

.newsletter-popup {
  transform: translate(-50%, -50%);
}

[class~="pagination"] ul li a {
  border-left-width: 0.75pt;
}

.newsletter-popup .newsletter-close {
  border-radius: 100%;
}

.newsletter-popup {
  margin-left: auto;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before,
[class~="pagination"] ul {
  width: 100%;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form,
.newsletter-popup {
  margin-right: auto;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  position: absolute;
}

.newsletter-popup .newsletter-close {
  border-radius: 100%;
}

.newsletter-popup .newsletter-close {
  border-radius: 100%;
}

[class~="back-top"] i {
  font-size: 1.625pc;
}

[class~="newsletter-popup"] [class~="newsletter-container"] {
  display: flex;
}

[class~="pagination"] ul li a {
  border-bottom-width: 0.75pt;
}

.newsletter-popup {
  margin-top: auto;
}

[class~="pagination"] ul li a {
  border-right-width: 0.75pt;
}

.newsletter-popup {
  position: fixed;
}

[class~="slick-dots"] li button {
  cursor: pointer;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  padding-top: 0;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before,
.newsletter-popup {
  top: 50%;
}

button {
  border-left-width: 0;
}

.products-loadmore .loadmore.loading .lds-ellipsis div {
  border-radius: 50%;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  height: 0.125pc;
}

.newsletter-popup {
  width: 750px;
}

.newsletter-popup {
  z-index: 1000000;
}

.newsletter-popup {
  border-radius: 6pt;
}

.newsletter-popup {
  border-radius: 8px;
}

.newsletter-popup {
  border-radius: 0.5pc;
}

button {
  border-bottom-width: 0;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"],
.products-loadmore .loadmore.loading .lds-ellipsis div {
  background: #000;
}

[class~="pagination"] ul li a {
  border-top-width: 0.75pt;
}

[class~="pagination"] ul li a {
  border-left-style: solid;
}

.newsletter-popup {
  border-radius: 8px;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  height: 3.125pc;
}

[class~="pagination"] ul li a[class~="prev"]:before {
  content: "4";
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  color: #000;
}

button {
  border-right-width: 0;
}

.pagination ul li a.next:before {
  content: "5";
}

.newsletter-popup {
  border-radius: 0.083333333in;
}

.newsletter-popup {
  overflow: hidden;
}

.products-loadmore .loadmore.loading .lds-ellipsis div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

button {
  border-top-width: 0;
}

button {
  border-left-style: none;
}

[class~="pagination"] ul li a {
  border-bottom-style: solid;
}

button {
  border-bottom-style: none;
}

[class~="products-loadmore"]
  [class~="loadmore"]:hover
  [class~="lds-ellipsis"]
  div {
  background: #fff;
}

button {
  border-right-style: none;
}

.newsletter-popup {
  transition: all 0.8s ease-in-out;
}

.newsletter-popup {
  transition: all 0.8s ease-in-out;
}

button {
  border-top-style: none;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before,
[class~="star-rating"] span:before {
  left: 0;
}

button {
  border-left-color: currentColor;
}

body[class~="home"][class~="title-6"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  line-height: 1.1;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  line-height: 50px;
}

button {
  border-bottom-color: currentColor;
}

[class~="pagination"] ul li a {
  border-right-style: solid;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  position: relative;
}

button {
  border-right-color: currentColor;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"],
[class~="star-rating"] span:before {
  top: 0;
}

[class~="pagination"] ul li a {
  border-top-style: solid;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  font-size: 0.6875pc;
}

button {
  border-top-color: currentColor;
}

[class~="star-rating"] span:before {
  content: "";
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"],
[class~="pagination"] ul li a[class~="next"]:hover {
  color: #fff;
}

[class~="star-rating"] span:before {
  font-family: FontAwesome;
}

[class~="pagination"] ul li a {
  border-left-color: #e5e5e5;
}

[class~="pagination"] ul li a {
  border-bottom-color: #e5e5e5;
}

[class~="pagination"] ul li a {
  border-right-color: #e5e5e5;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  margin-top: -0.010416667in;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  transition: all 0.3s ease;
}

button {
  border-image: none;
}

[class~="pagination"] ul li a {
  border-top-color: #e5e5e5;
}

[class~="pagination"] ul li a {
  border-image: none;
}

input:autofill {
  box-shadow: 0 0 0 1000px white inset !important;
}

del {
  font-size: 10.5pt;
}

del {
  color: #ababab;
}

[class~="newsletter-popup"] [class~="newsletter-close"]:before {
  transition: all 0.3s ease;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  cursor: pointer;
}

[class~="pagination"] ul li a {
  transition: none;
}

body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"]
  h2,
body[class~="home"][class~="title-6"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  text-transform: none;
}

[class~="pagination"] ul li a {
  transition: none;
}

.newsletter-popup .newsletter-close:after,
.newsletter-popup .newsletter-close:before {
  transform-origin: 50% 50%;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  form {
  margin-top: auto;
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  letter-spacing: 0.1em;
}

.newsletter-popup .newsletter-close:before {
  transform: rotate(-45deg);
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"],
[class~="pagination"] ul li a {
  text-transform: uppercase;
}

[class~="star-rating"] span:before {
  position: absolute;
}

[class~="star-rating"] span:before {
  color: #fcad02;
}

.newsletter-popup .newsletter-close:before {
  transform: rotate(-45deg);
}

[class~="star-rating"] span:before {
  letter-spacing: 0.0625in;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  font-size: 10px;
}

body[class~="home"]
  [class~="section"]
  [class~="block-title"][class~="title-underline"]
  [class~="title-right"]
  a:after {
  margin-left: 7.5pt;
}

.newsletter-popup .newsletter-close:after {
  transform: rotate(45deg);
}

body[class~="home"][class~="title-7"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 30px;
}

.newsletter-popup .newsletter-close:after {
  transform: rotate(45deg);
}

[class~="newsletter-popup"]
  [class~="newsletter-container"]
  [class~="newsletter-form"]
  [class~="newsletter-input"]
  input[type="submit"] {
  width: auto;
}

[class~="cart-product-added"] > div:before,
[class~="pagination"] ul li {
  display: inline-block;
}

[class~="cart-product-added"] > div:before {
  content: "";
}

body[class~="home"][class~="title-7"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-style: italic;
}

[class~="cart-product-added"] > div:before {
  font-family: wpbingofont;
}

[class~="cart-product-added"] > div:before {
  margin-right: 0.104166667in;
}

[class~="pagination"] ul li {
  margin-left: 3px;
}

body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-size: 2.5pc;
}

[class~="pagination"] ul li {
  margin-bottom: 0;
}

[class~="pagination"] ul li {
  margin-right: 3px;
}

[class~="pagination"] ul li {
  margin-top: 0;
}

body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  font-weight: 600;
}

body[class~="home"][class~="title-8"]
  [class~="section"]
  [class~="block-title"]
  h2 {
  line-height: 1.1;
}

body i[class~="slick-arrow"][class~="fa-angle-right"] {
  right: -0.9375pc;
}

/*===================================================================
2. Animate
====================================================================*/
@keyframes e {
  0% {
    transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes i {
  0% {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes j {
  0% {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes k {
  0% {
    transform: translate(0);
  }

  to {
    transform: translate(24px);
  }
}

@keyframes l {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes m {
  0% {
    transform: translateY(-8px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes n {
  0% {
    transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes o {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes remove-spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes remove-spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes animation-horizontal {
  16.65% {
    transform: translateX(8px);
  }

  33.3% {
    transform: translateX(-6px);
  }

  49.95% {
    transform: translateX(4px);
  }

  66.6% {
    transform: translateX(-2px);
  }

  83.25% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loader-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*========================================================================
3. Header
=========================================================================*/
.header-desktop .level-0.display-setting {
  display: none !important;
}

.site-header.absolute,
#header-topbar[class~="topbar-v1"] {
  border-bottom-width: 1px;
}

#header-topbar #topbar-menu li:before,
.site-header.absolute,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  position: absolute;
}

.site-navigation ul > li.level-0.align-center .sub-menu {
  transform: translateX(-50%);
}

[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]:hover,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a,
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"] {
  color: #fff;
}

[class~="site-header"][class~="header-v2"] [class~="site-logo"] {
  margin-left: 0.729166667in;
}

[class~="site-header"]
  [class~="header-wrapper"]
  [class~="section-container"]
  > [class~="row"],
.site-header .header-mobile .section-container > .row {
  align-items: center;
}

[class~="site-header"][class~="header-v2"] [class~="site-logo"] {
  margin-bottom: 0;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]:hover,
#header-topbar a:hover {
  color: #000;
}

.site-header.absolute,
#header-topbar[class~="topbar-v1"] {
  border-bottom-style: solid;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"][class~="icon"]
  > a {
  padding-right: 0;
}

[class~="site-header"][class~="header-v2"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 18.75pt;
}

#header-topbar[class~="topbar-v1"] {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.site-header.absolute {
  width: 100%;
}

[class~="site-header"][class~="header-v3"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0;
}

[class~="site-header"][class~="header-v3"]
  [class~="header-middle"][class~="bg-white"],
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  background: #fff;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  content: "";
}

[class~="site-header"][class~="header-v2"] [class~="site-logo"] {
  margin-right: 0.729166667in;
}

[class~="site-header"][class~="header-v2"] [class~="site-logo"] {
  margin-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:before {
  transform: rotate(135deg);
}

[class~="site-header"][class~="header-v4"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 15pt;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  bottom: 0;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-left-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:before {
  transform: rotate(135deg);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:after {
  transform: rotate(-45deg);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"]:hover {
  top: -0.010416667in;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-bottom-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover:after {
  transform: rotate(-45deg);
}

[class~="site-navigation"] ul,
[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-top"] {
  padding-left: 0;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-right-width: 0;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-top-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-login"]
  input[type="submit"]:hover {
  background: #cb8161;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  content: "";
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-left-style: none;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  font-family: wpbingofont;
}

[class~="form-login-register"]
  [class~="box-content"]
  > div[class~="active"]
  [class~="login"] {
  display: block !important;
}

[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-top"] {
  padding-bottom: 0.9375pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"]
  input,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="email"]
  input {
  padding-left: 0.208333333in;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-bottom-style: none;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  font-size: 0.208333333in;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a
  > span:before {
  opacity: 1;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"]
  input,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="email"]
  input,
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input {
  padding-bottom: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="email"]
  input,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input {
  padding-right: 0.208333333in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"]
  input,
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"]
  input,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="email"]
  input {
  padding-top: 0;
}

.site-header.absolute {
  border-bottom-color: #e5e5e5;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-right-style: none;
}

.site-navigation ul > li.level-0.align-center .sub-menu {
  transform: translateX(-50%);
}

#header-topbar[class~="topbar-v1"],
.site-header.absolute {
  border-image: none;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]:hover
  i {
  top: -0.1875pc;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="navbar-toggle"]:before {
  color: #000;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-top-style: none;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-left-color: currentColor;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a
  > span:before {
  visibility: visible;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-bottom-color: currentColor;
}

#header-topbar {
  font-size: 10.5pt;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-right-color: currentColor;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-top-color: currentColor;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li:first-child
  ul[class~="sub-menu"] {
  top: -30px;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="header-mobile-fixed"]
  > div {
  flex: 1;
}

[class~="site-header"][class~="bottom-border"] {
  border-bottom-width: 0.0625pc;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"] {
  display: inline-block;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  left: 0;
}

#header-topbar [class~="address"] i {
  margin-right: 0.104166667in;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  border-image: none;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  font-size: 0;
}

[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"] {
  background: transparent;
}

[class~="site-navigation"] ul > li[class~="level-0"]:last-child,
#header-topbar [class~="topbar-right"] > :last-child {
  padding-right: 0;
}

[class~="site-header"][class~="bottom-border"] {
  border-bottom-style: solid;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a,
[class~="site-header"][class~="header-v2"] [class~="header-desktop"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  position: relative;
}

[class~="site-header"][class~="bottom-border"] {
  border-bottom-color: #e5e5e5;
}

[class~="site-header"][class~="header-v2"] [class~="header-desktop"],
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"],
[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-middle"],
[class~="site-header"][class~="header-v3"] [class~="header-desktop"] {
  padding-left: 0;
}

[class~="site-header"][class~="bottom-border"] {
  border-image: none;
}

[class~="site-navigation"] ul > li[class~="level-0"]:first-child {
  padding-left: 0;
}

[class~="site-header"][class~="header-v2"] [class~="header-desktop"],
[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-middle"],
[class~="site-header"] [class~="header-mobile"] [class~="navbar-toggle"],
[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-top"] {
  padding-right: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"]:hover {
  background: #cb8161;
}

.site-header.bg-black {
  background: #000;
}

/* [class~="site-header"] [class~="site-logo"] img {
 
} */
.lgoMain {
  max-width: 65px !important;
  max-height: auto !important;
}
.site-header {
  margin-bottom: 0;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  position: absolute;
}

[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  text-align: center;
}

[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a {
  color: #fff;
}

[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-left: 0;
}

[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-top"],
[class~="site-header"] [class~="header-mobile"] {
  padding-top: 20px;
}

[class~="site-header"] [class~="header-mobile"] {
  padding-left: 0.078125in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"]
  input {
  padding-right: 0.208333333in;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-ancestor"]
  > a
  > span:before {
  width: 100%;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a
  > span:before {
  opacity: 1;
}

[class~="site-header"] [class~="header-mobile"] {
  padding-bottom: 0.208333333in;
}

[class~="site-header"] [class~="header-mobile"] {
  padding-right: 15px;
}

[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-middle"] {
  padding-bottom: 11.25pt;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  line-height: 37.5pt;
}

[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-bottom: 0;
}

.site-header.padding-large .site-navigation ul > li.level-0 > .sub-menu {
  margin-top: 26.25pt;
}

[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-right: 0;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"][class~="mega-menu-fullwidth"]
  > [class~="sub-menu"] {
  width: 562.5pt;
}

[class~="site-header"] [class~="header-mobile"] [class~="site-logo"] {
  margin-top: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  padding-left: 0.416666667in;
}

[class~="site-header"] [class~="header-right"],
[class~="site-header"] [class~="header-mobile"] [class~="header-right"],
[class~="site-header"][class~="header-v2"] [class~="header-center"] {
  display: flex;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  text-transform: capitalize;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-right"] {
  justify-content: flex-end;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-right"],
[class~="site-header"][class~="header-v2"] [class~="header-center"] {
  align-items: center;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="site-header"][class~="header-v3"][class~="small-padding"]
  [class~="header-middle"] {
  padding-top: 0;
}

[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  opacity: 1 !important;
}

.site-navigation ul > li.level-0 {
  position: static;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  content: "";
}

[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  visibility: visible !important;
}

[class~="site-header"][class~="header-v2"] [class~="header-desktop"] {
  padding-bottom: 18.75pt;
}

.mini-cart .cart-popup .cart-list li.empty .go-shop,
#header-topbar [class~="address"] {
  display: inline-block;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"],
[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  display: block;
}

.form-login-register .box-form-login .rememberme-lost .rememberme input {
  display: none;
}

[class~="site-header"][class~="header-v2"] [class~="header-desktop"] {
  padding-top: 18.75pt;
}

[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current_page_item"]
  > a,
[class~="site-navigation"] ul > li[class~="level-0"]:hover > a,
[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  color: #000;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a
  > span:before,
.site-navigation ul > li.level-0.current_page_item > a > span:before {
  visibility: visible;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="site-header"] [class~="header-wrapper"][class~="bg-black"] {
  background: #000;
}

[class~="site-header"][class~="header-v2"] [class~="header-center"] {
  position: unset;
}

[class~="site-header"] [class~="header-right"],
.site-header {
  position: relative;
}

[class~="site-header"][class~="header-v3"] [class~="header-desktop"],
[class~="site-navigation"] ul,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  padding-bottom: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  padding-right: 0.416666667in;
}

[class~="site-header"] [class~="header-right"] {
  color: #8d8d8d;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  top: -7px;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  min-width: 225px;
}

[class~="site-header"] [class~="header-desktop"],
[class~="site-navigation"] ul,
[class~="site-header"][class~="header-v3"] [class~="header-top"],
[class~="site-header"][class~="header-v3"] [class~="header-desktop"] {
  padding-right: 0;
}

[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  transform: translate(0);
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit:hover {
  background: rgba(0, 0, 0, 0.75);
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  position: absolute;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current-menu-item"]
  > a
  > span:before {
  width: 100%;
}

[class~="site-header"][class~="header-v2"] [class~="header-center"] {
  justify-content: center;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  width: 1pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  height: 12pt;
}

.site-navigation ul > li.level-0 {
  display: table;
}

[class~="site-navigation"] ul,
[class~="site-header"][class~="header-v3"] [class~="header-desktop"] {
  padding-top: 0;
}

[class~="site-header"] [class~="site-logo"] img {
  width: auto;
}

.mini-cart .cart-popup .cart-list li.empty .go-shop {
  font-size: 10.5pt;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]:hover[class~="menu-item-has-children"]
  > a:after {
  transform: rotateX(-180deg);
}

.site-navigation ul > li.level-0.current_page_item > a > span:before {
  opacity: 1;
}

[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  transform: translate(0);
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  bottom: -0.520833333in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="menu-full"] [class~="close-menu-full"]:after {
  content: "";
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="header-mobile-fixed"]
  > div {
  text-align: center;
}

[class~="site-header"][class~="absolute"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"],
[class~="site-header"][class~="relative"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 22.5pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  display: inline-block;
}

[class~="site-header"] [class~="header-right"] {
  align-items: center;
}

[class~="site-header"] [class~="header-desktop"],
[class~="site-header"][class~="header-v3"] [class~="header-top"],
[class~="site-header"][class~="header-v3"] [class~="header-middle"] {
  padding-left: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="site-header"] [class~="header-left"] > *,
.site-navigation ul > li.level-0 {
  float: left;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="site-header"][class~="header-v3"] [class~="header-top"] {
  padding-bottom: 15pt;
}

.mini-cart .cart-popup .cart-list li.empty .go-shop,
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  text-transform: uppercase;
}

[class~="site-header"][class~="color-white"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  background: #fff;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="header-mobile-fixed"]
  i {
  font-size: 1.25pc;
}

[class~="site-navigation"] ul li {
  text-align: left;
}

[class~="site-header"] [class~="header-right"] {
  justify-content: flex-end;
}

[class~="site-navigation"]
  [class~="menu-item-has-children"]:hover
  > [class~="sub-menu"] {
  box-shadow: 0.03125in 0.03125in 0.46875in 0 rgba(0, 0, 0, 0.05);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

#header-topbar [class~="address"] i {
  font-size: 11.25pt;
}

[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"] {
  color: #fff;
}

[class~="site-header"][class~="header-v3"] [class~="header-top"] {
  padding-top: 15pt;
}

.mini-cart .cart-popup .cart-list li.empty .go-shop {
  line-height: 21pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]:hover[class~="menu-item-has-children"]
  > a:after {
  transform: rotateX(-180deg);
}

[class~="site-header"] [class~="header-wrapper"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"],
#header-topbar [class~="address"] i,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a,
[class~="site-header"][class~="header-v3"] [class~="header-middle"] {
  position: relative;
}

[class~="site-header"] [class~="header-desktop"] {
  padding-bottom: 0.3125in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-width: 0.020833333in;
}

[class~="site-header"] [class~="header-desktop"] {
  padding-top: 0.3125in;
}

[class~="site-header"][class~="header-v3"] [class~="header-middle"] {
  padding-bottom: 0.177083333in;
}

[class~="site-header"][class~="header-v3"] [class~="header-middle"] {
  padding-right: 0;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"] {
  cursor: pointer;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  border-radius: 0;
}

#header-topbar [class~="address"] i {
  top: 0.1875pc;
}

.menu-full .menu-lines .line-general.line-1 {
  top: 0;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before,
.site-navigation ul > li.level-0.current_page_item > a > span:before {
  width: 100%;
}

[class~="site-header"][class~="header-v3"] [class~="header-middle"] {
  padding-top: 0.177083333in;
}

.site-navigation ul > li.level-0 {
  padding-left: 13.5pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-width: 0.020833333in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  border-radius: 0;
}

.menu-full .close-menu-full:hover:before {
  transform: rotate(135deg);
}

[class~="site-header"][class~="header-v3"] [class~="header-middle"] {
  background: #cb8161;
}

#header-topbar #topbar-menu li {
  display: inline-block;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-width: 0.020833333in;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  top: 100%;
}

.menu-full .menu-lines .line-general.line-1 {
  width: 0.208333333in;
}

[class~="site-header"] [class~="text-center"],
.site-header .header-menu {
  position: unset;
}

.site-navigation ul > li.level-0 {
  padding-bottom: 0;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"]
  > [class~="sub-menu"] {
  width: 5.208333333in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-width: 0.020833333in;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  position: absolute;
}

[class~="site-header"][class~="header-v3"] [class~="header-bottom"] {
  padding-left: 0.9375pc;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  height: 0.020833333in;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-left-width: medium;
}

[class~="site-header"][class~="header-v3"] [class~="header-bottom"] {
  padding-bottom: 0.104166667in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  border-radius: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-style: solid;
}

[class~="site-header"][class~="header-v3"] [class~="header-bottom"] {
  padding-right: 0.15625in;
}

[class~="site-header"][class~="header-v3"] [class~="header-bottom"] {
  padding-top: 0.15625in;
}

[class~="site-header"][class~="header-v4"] [class~="header-desktop"],
.site-navigation ul > li.level-0 ul.sub-menu,
[class~="site-header"][class~="padding-large"] [class~="header-desktop"] {
  padding-left: 0;
}

[class~="site-header"][class~="header-v4"] [class~="header-desktop"] {
  padding-bottom: 1.25pc;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  border-radius: 0;
}

#header-topbar [class~="address"] i {
  color: #000;
}

#header-topbar .email i {
  margin-right: 0.104166667in;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-bottom-width: medium;
}

.menu-full .close-menu-full:hover:before {
  transform: rotate(135deg);
}

[class~="site-header"][class~="padding-large"] [class~="header-desktop"],
[class~="site-header"][class~="header-v4"] [class~="header-desktop"] {
  padding-right: 0;
}

.site-navigation ul > li.level-0 ul.sub-menu li ul.sub-menu {
  top: -1.625pc;
}

.color-white .site-navigation ul > li.level-0 > a {
  color: #fff;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-right-width: medium;
}

[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-2"] {
  top: 0.3125pc;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-top-width: medium;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-style: solid;
}

[class~="menu-full"] [class~="close-menu-full"]:hover:after {
  transform: rotate(-45deg);
}

[class~="site-header"][class~="header-v4"] [class~="header-desktop"] {
  padding-top: 1.25pc;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-left-style: none;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-bottom-style: none;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  margin-top: 0.625pc;
}

[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-2"] {
  width: 0.104166667in;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-right-style: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-style: solid;
}

[class~="menu-full"] [class~="close-menu-full"]:hover:after {
  transform: rotate(-45deg);
}

.site-header,
[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  z-index: 999999;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-style: solid;
}

.mini-cart .cart-popup .cart-list li.empty .go-shop {
  margin-top: 0.3125pc;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit {
  border-radius: 0;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  height: 50px;
}

[class~="site-navigation"],
#header-topbar [class~="email"],
[class~="menu-full"] [class~="site-navigation"] #main-navigation,
#header-topbar [class~="phone"] {
  display: inline-block;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"]:hover,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:hover:before {
  background: #cb8161;
}

.site-navigation ul > li.level-0:hover > a > span:before {
  opacity: 1;
}

#header-topbar .email i {
  font-size: 11.25pt;
}

[class~="site-header"][class~="padding-large"] [class~="header-desktop"] {
  padding-bottom: 1.875pc;
}

[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-3"] {
  top: 10px;
}

.site-navigation ul > li.level-0 {
  padding-right: 13.5pt;
}

[class~="site-header"][class~="padding-large"] [class~="header-desktop"] {
  padding-top: 1.875pc;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  width: 0.145833333in;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  top: 50%;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:before {
  left: 0;
}

#header-topbar #topbar-menu li {
  padding-right: 18px;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  position: fixed;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-top-style: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-color: #000;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-color: #000;
}

.site-navigation ul > li.level-0 {
  padding-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-color: #000;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  bottom: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-color: #000;
}

.site-navigation ul > li.level-0:hover > a > span:before {
  visibility: visible;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-image: none;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"],
#header-topbar [class~="email"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"],
.mini-cart .cart-popup .cart-list li.empty .go-shop,
#header-topbar .email i,
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="menu-full"] [class~="menu-lines"] {
  position: relative;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  margin-right: 5px;
}

#header-topbar #topbar-menu li:before {
  content: "";
}

[class~="site-menu-wrapper"] [class~="navbar-default"],
.site-header {
  background: transparent;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"]:hover {
  color: #fff;
}

[class~="menu-full"]
  [class~="menu-lines"]
  [class~="line-general"][class~="line-3"] {
  width: 1.25pc;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"] {
  font-size: 20px;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  top: 0;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"] {
  transition: all 0.3s ease;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  left: calc(50% - 7px);
}

#header-topbar .email i,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  top: 2.25pt;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-left-color: currentColor;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  content: "";
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  font-family: feather;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"]:hover {
  border-left-color: #cb8161;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  width: 100%;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"],
[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  background: #fff;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-bottom-color: currentColor;
}

.site-navigation ul > li.level-0 ul.sub-menu {
  padding-bottom: 15pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  z-index: 2;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"],
[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  transition: all 0.3s ease;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"]
  > [class~="sub-menu"] {
  padding-left: 30pt;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  [class~="icon-user"] {
  top: 0.010416667in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  width: 16px;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  opacity: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:hover {
  background: #e5e5e5;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  display: flex;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"]:hover {
  border-bottom-color: #cb8161;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  right: -0.177083333in;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-right-color: currentColor;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  text-transform: uppercase;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  width: 1.0625pc;
}

[class~="site-navigation"] {
  vertical-align: top;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  visibility: hidden;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-left: 0.15625in;
}

.site-navigation ul > li.level-0 ul.sub-menu {
  padding-right: 0;
}

[class~="site-navigation"] {
  float: none;
  display: flex;
  gap: 25px;
}
.loginDiv {
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.dropbtn {
  font-size: 20px;
  color: #fff;
  background-color: #3c2147;
}

.dropdown {
  position: relative;
  display: inline-block;
  text-align: start;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999999999;
}

.dropdown-content a {
  color: black;
  padding: 3px 10px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-family: Lato, sans-serif;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.userIcon {
  font-size: 20px;
  color: #fff;
}
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before,
.menu-full .menu-lines .line-general,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  position: absolute;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="email"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="username"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="content"]
  [class~="password"] {
  margin-bottom: 0.9375pc;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
#header-topbar [class~="ship"] {
  display: inline-block;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-top-color: currentColor;
}

[class~="site-menu-wrapper"] [class~="navbar-default"] {
  border-image: none;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-bottom: 7.5pt;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-right: 0.15625in;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  padding-top: 0.9375pc;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"]:hover {
  border-right-color: #cb8161;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  transition: opacity 0.1s linear, transform 0.1s linear;
}

[class~="menu-full"] [class~="menu-lines"] {
  display: block;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  transition: opacity 0.1s linear, transform 0.1s linear;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  font-size: 0.145833333in;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"]
  > [class~="sub-menu"] {
  padding-bottom: 22.5pt;
}

#header-topbar .email i {
  color: #000;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  height: 0.166666667in;
}

#header-topbar {
  height: 0.46875in;
}

[class~="site-header"] [class~="header-mobile"] [class~="header-mobile-fixed"] {
  align-items: center;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  transform: translateY(20px);
}

.menu-full .site-navigation,
.menu-full .menu-lines .line-general {
  left: 0;
}

[class~="menu-full"] [class~="menu-lines"] {
  width: 0.208333333in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  content: "";
}

[class~="menu-full"] [class~="menu-lines"] {
  height: 0.75pc;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"] {
  margin-right: 0.9375pc;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  margin-top: -0.75pt;
}

.site-navigation ul > li.level-0 ul.sub-menu {
  padding-top: 15pt;
}

.menu-full .menu-lines .line-general {
  height: 2px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  padding-right: 30pt;
}

.menu-full .menu-lines .line-general,
.menu-full .site-navigation {
  width: 100%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  height: 12.75pt;
}

#header-topbar .phone i,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"] {
  margin-right: 0.104166667in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"]:hover {
  border-top-color: #cb8161;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"]
  > [class~="sub-menu"] {
  padding-right: 30pt;
}

.menu-full .menu-lines .line-general {
  background: #000;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"] {
  position: relative;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:hover:before {
  width: 50%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit
  i {
  font-size: 0.1875in;
}

.form-login-register .box-form-login .rememberme-lost .inline,
[class~="menu-full"] [class~="menu-lines"] {
  cursor: pointer;
}

.menu-full .site-navigation {
  position: fixed;
}

.site-navigation ul > li.level-0 {
  line-height: 1.5pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"]
  input:checked
  + label:after {
  transform: scale(1);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  line-height: 0.177083333in;
}

[class~="mojuri-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  opacity: 1;
}

.menu-full .site-navigation {
  background: #fff;
}

.menu-full .menu-lines .line-general {
  transition: width 0.3s ease, top 0.3s ease;
}

[class~="mojuri-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  visibility: visible;
}

.menu-full .menu-lines .line-general {
  transition: width 0.3s ease, top 0.3s ease;
}

.menu-full .site-navigation.active {
  transform: translateX(0);
}

.menu-full .site-navigation.active {
  transform: translateX(0);
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  bottom: -1px;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  vertical-align: middle;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] > a {
  letter-spacing: 2px;
}

[class~="menu-full"] [class~="close-menu-full"]:after {
  background-color: #000;
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] {
  font-size: 10.5pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  content: "";
}

.menu-full .site-navigation {
  top: 0;
}

.form-login-register .box-form-login .rememberme-lost .inline {
  color: #000;
}

[class~="site-navigation"] ul {
  list-style: none;
}

.menu-full .site-navigation {
  padding-left: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  font-family: feather;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  top: 4px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"],
.form-login-register .box-form-login .rememberme-lost .inline {
  font-weight: 500;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-width: 3pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-width: 3pt;
}

.form-login-register .box-form-login .active-login:after {
  content: "";
}

[class~="site-header"] [class~="header-page-link"] [class~="login-header"] {
  margin-left: 0.208333333in;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  display: inline-block;
}

.menu-full .site-navigation {
  padding-bottom: 0.677083333in;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-family: Lato, sans-serif;
}

[class~="mojuri-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  transform: translateY(0) !important;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-width: 3pt;
}

[class~="site-header"] [class~="header-page-link"] > :first-child {
  margin-left: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before,
.form-login-register .box-form-login .active-login:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  position: absolute;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-width: 3pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  text-align: right;
}

.menu-full .site-navigation {
  padding-right: 0;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"]
  > [class~="sub-menu"] {
  padding-top: 22.5pt;
}

.menu-full .site-navigation {
  padding-top: 2.083333333in;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  margin-left: 0.03125in;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  top: 0.5pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"]:hover,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"]:hover {
  background: #cb8161;
}

[class~="site-navigation"] ul div[class~="sub-menu"] {
  padding-left: 1.875pc;
}

.menu-full .site-navigation {
  height: 100vh;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-weight: 400;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-size: 0.125in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  z-index: 9;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-style: solid;
}

[class~="site-navigation"] ul div[class~="sub-menu"] {
  padding-bottom: 1.375pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-style: solid;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="mega-menu"]
  > [class~="sub-menu"] {
  min-width: auto;
}

.menu-full .site-navigation {
  overflow: auto;
}

#header-topbar #topbar-menu li {
  margin-right: 0.135416667in;
}

.form-login-register .box-form-login .active-login:after {
  height: 0.125pc;
}

.menu-full .site-navigation {
  transform: translateX(100%);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  right: 0;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"],
[class~="menu-full"] [class~="close-menu-wrap"],
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  position: relative;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  right: 0.166666667in;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li:first-child {
  padding-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  top: 15px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  cursor: pointer;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="menu-section"]:last-child {
  margin-bottom: 0;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  width: 40px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  width: 21pt;
}

.menu-full .site-navigation {
  transform: translateX(100%);
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  box-shadow: none;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  top: -6.75pt;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  background: transparent;
}

[class~="site-navigation"] ul > li[class~="level-0"] [class~="sub-menu"] {
  transform: translateY(20px);
}

[class~="menu-full"] [class~="close-menu-full"]:after,
.menu-full .site-navigation {
  transition: all 0.3s ease;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  background-color: transparent;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  height: 1.75pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-style: solid;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-radius: 50%;
}

[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"] {
  opacity: 1;
}

.form-login-register .box-form-login .active-login:after {
  width: 0.875pc;
}

[class~="site-navigation"] ul {
  float: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-radius: 50%;
}

[class~="site-navigation"] ul div[class~="sub-menu"] [class~="menu-section"] {
  margin-bottom: 22.5pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  display: inline-flex;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-radius: 50%;
}

.menu-full .site-navigation {
  transition: all 0.3s ease;
}

.menu-full .site-navigation {
  z-index: 10;
}

[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"] {
  visibility: visible;
}

.menu-full .site-navigation {
  text-align: center;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  top: -0.010416667in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-radius: 50%;
}

[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"] {
  transform: translateX(0);
}

.form-login-register .box-form-login .active-login:after {
  top: 50%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-style: solid;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  font-weight: 400;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-color: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-color: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-radius: 50%;
}

[class~="form-login-register"] {
  display: none;
}

[class~="form-login-register"]
  [class~="box-content"]
  [class~="form-register"][class~="active"] {
  transform: translateX(0);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-left-width: 0.75pt;
}

[class~="mojuri-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="cart-popup"] {
  transform: translateY(0) !important;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-color: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-bottom-width: 0.75pt;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="site-navigation"] ul > li[class~="level-0"] > a {
  color: #000;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
.menu-full .close-menu-full {
  position: absolute;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-right-width: 0.75pt;
}

[class~="site-navigation"] ul div[class~="sub-menu"] {
  padding-right: 1.875pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-color: #fff;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li:last-child {
  padding-bottom: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-top-width: 0.75pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-image: none;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-left-style: solid;
}

.form-login-register .box-form-login .active-login:after {
  left: calc(50% - 7px);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  left: 0;
}

[class~="mojuri-topcart"][class~="dropdown"]
  [class~="mini-cart"][class~="show"]
  [class~="remove-cart-shadow"],
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  display: block;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  background: #000;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  display: table;
}

.form-login-register .box-form-login .active-login:after {
  margin-top: -0.0625pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-bottom-style: solid;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"] {
  top: -2px;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  height: 0.0625pc;
}

.form-login-register .box-form-login .active-login:after {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-right-style: solid;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  min-width: 3.125in;
}

.menu-full .close-menu-full {
  z-index: 9;
}

.menu-full .close-menu-full {
  top: -100px;
}

.form-login-register .box-form-login .active-login:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  transition: all 0.3s ease;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  text-transform: capitalize;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  align-items: center;
}

.form-login-register .box-form-login .active-login:after,
[class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
[class~="menu-full"] [class~="close-menu-full"]:after {
  transition: all 0.3s ease;
}

[class~="menu-full"] [class~="close-menu-full"]:before {
  content: "";
}

[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  font-size: 1.125pc;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"][class~="icon"]
  > a:after {
  content: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-top-style: solid;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-left-color: #e5e5e5;
}

#header-topbar .phone i,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-login"],
#header-topbar #topbar-menu li {
  position: relative;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  top: -0.4375pc;
}

.menu-full .close-menu-full {
  left: 50%;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  right: -12.75pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-bottom-color: transparent;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  z-index: 1;
}

.menu-full .close-menu-full {
  cursor: pointer;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  border-radius: 3.75pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  transform: scale(0);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-right-color: #e5e5e5;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  font-size: 0.166666667in;
}

.site-navigation ul > li.level-0:hover > a > span:before {
  width: 100%;
}

#header-topbar .phone i {
  font-size: 11.25pt;
}

.menu-full .close-menu-full {
  width: 28px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-top-color: #e5e5e5;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  width: 0.1875in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  border-radius: 0.3125pc;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  height: 18px;
}

[class~="menu-full"] [class~="close-menu-full"]:before {
  position: absolute;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  transform: scale(0);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a,
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
[class~="site-header"] [class~="header-page-link"] > * {
  display: inline-block;
}

.menu-full .close-menu-full {
  height: 1.75pc;
}

[class~="menu-full"] [class~="close-menu-full"]:before {
  height: 0.020833333in;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a[class~="black"]:after,
.form-login-register .box-form-login .button-login input[type="submit"] {
  background: #000;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-left-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"],
.menu-full .close-menu-full {
  transform: rotate(45deg);
}

.menu-full .close-menu-full,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  transform: rotate(45deg);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  bottom: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  font-weight: 700;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  justify-content: center;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  border-image: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-bottom-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-right-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-top-width: 0;
}

#header-topbar .phone i {
  top: 0.1875pc;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  text-align: center;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  line-height: 1.125pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"] {
  background: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-left-style: none;
}

[class~="site-navigation"] ul div[class~="sub-menu"] {
  padding-top: 1.375pc;
}

[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  margin-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  display: flex;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  justify-content: space-between;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  flex-wrap: wrap;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-bottom-style: none;
}

[class~="site-header"] [class~="header-page-link"] {
  text-align: right;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"] {
  margin-top: 11.25pt;
}

.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-right-style: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  left: 50%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"],
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  margin-bottom: 0.15625in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
.color-white .site-navigation ul > li.level-0.current-menu-item > a,
.form-login-register .box-form-login .button-login input[type="submit"] {
  color: #fff;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  content: "";
}

[class~="site-header"] [class~="header-page-link"] > * {
  margin-left: 13.5pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-top-style: none;
}

[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  text-transform: capitalize;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  white-space: nowrap;
}

[class~="site-header"] [class~="header-page-link"] > * {
  vertical-align: middle;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-left-color: currentColor;
}

[class~="site-navigation"] ul div[class~="sub-menu"] {
  box-shadow: 0 0 0.020833333in rgba(0, 0, 0, 0.1);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  background: #b9b9b9;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-left-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-bottom-color: currentColor;
}

[class~="form-login-register"] {
  position: fixed;
}

[class~="form-login-register"][class~="active"] {
  display: block;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  background: #cb8161;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before,
#header-topbar .phone i {
  color: #000;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-right-color: currentColor;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  position: absolute;
}

.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  border-top-color: currentColor;
}

[class~="form-login-register"],
[class~="form-login-register"] [class~="box-form-login"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  width: 100%;
}

[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"] {
  padding-bottom: 7px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  border-image: none;
}

[class~="site-navigation"] ul ul[class~="sub-menu"] {
  padding-left: 1.875pc;
}

[class~="site-navigation"] ul {
  transition: all 0.35s;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li:first-child {
  padding-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  font-size: 0.6875pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  text-align: center;
}

[class~="site-navigation"] ul {
  transition: all 0.35s;
}

[class~="form-login-register"] [class~="box-form-login"] {
  max-width: 510px;
}

#header-topbar [class~="ship"] i {
  margin-right: 0.104166667in;
}

[class~="form-login-register"] [class~="box-form-login"] {
  background: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-bottom-width: 0;
}

#header-topbar [class~="ship"] i,
[class~="site-navigation"] ul div[class~="sub-menu"] [class~="sub-menu-title"],
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"] {
  position: relative;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  background: #000;
}

#header-topbar [class~="ship"] i {
  font-size: 11.25pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  text-transform: uppercase;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  font-size: 1.25pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  cursor: pointer;
}

.form-login-register .box-form-login .button-login input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  font-weight: 500;
}

#header-topbar [class~="email"] {
  margin-left: 15px;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-right-width: 0;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"],
.form-login-register .box-form-login .button-login input[type="submit"] {
  letter-spacing: 0.1em;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  display: inline-block;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-top-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  height: 2.75pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  line-height: 0.458333333in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-left-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-login"] {
  margin-top: 0.15625in;
}

[class~="form-login-register"] {
  height: 100%;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-left-style: none;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="form-login-register"] [class~="box-form-login"] {
  margin-left: auto;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  height: 0.125pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-bottom-style: none;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  color: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-right-style: none;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-count"] {
  font-size: 9pt;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  letter-spacing: 1.5pt;
}

[class~="form-login-register"] [class~="box-form-login"] {
  margin-bottom: auto;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-left: 2.5pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-style: none;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before,
[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-bottom: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-left-color: currentColor;
}

[class~="form-login-register"] [class~="box-form-login"] {
  margin-right: auto;
}

[class~="site-header"][class~="header-v2"]
  [class~="mini-cart"]
  [class~="cart-popup"] {
  margin-top: 3.75pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before,
[class~="menu-full"] [class~="close-menu-full"]:before {
  width: 0.145833333in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  background: #b9b9b9;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-bottom-width: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-bottom-color: currentColor;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  width: 0;
}

[class~="form-login-register"] [class~="box-form-login"] {
  margin-top: auto;
}

[class~="form-login-register"] [class~="box-form-login"] {
  position: absolute;
}

[class~="form-login-register"] [class~="box-form-login"] {
  left: calc(50% - 255px);
}

#header-topbar #topbar-menu,
#header-topbar #topbar_menu {
  list-style: none;
}

[class~="form-login-register"] [class~="box-form-login"],
[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  top: 50%;
}

#header-topbar [class~="ship"] i {
  top: 0.1875pc;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  text-align: center;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  background: #cb8161;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-left: 0.041666667in;
}

#header-topbar {
  line-height: 2.8125pc;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  transform: translateX(-50%);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-right-color: currentColor;
}

[class~="site-header"][class~="padding-large"]
  [class~="mini-cart"]
  [class~="cart-popup"] {
  margin-top: 7.5pt;
}

[class~="form-login-register"] [class~="box-form-login"] {
  padding-left: 0.3125in;
}

[class~="form-login-register"] [class~="box-form-login"] {
  padding-bottom: 0.3125in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-top-color: currentColor;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="total-cart"]
  [class~="total-price"] {
  font-size: 12.75pt;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  transition: all 0.2s ease 0s;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  border-image: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  left: calc(50% - 7px);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  font-size: 0.6875pc;
}

#header-topbar [class~="ship"] i {
  color: #000;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  color: #fff;
}

[class~="form-login-register"] {
  top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  text-transform: uppercase;
}

[class~="form-login-register"] [class~="box-form-login"] {
  padding-right: 0.3125in;
}

[class~="form-login-register"] [class~="box-form-login"] {
  padding-top: 0.3125in;
}

[class~="form-login-register"] [class~="box-form-login"] {
  transform: translateY(-50%);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
.mini-cart .cart-popup .free-ship .total-percent {
  border-radius: 3.75pt;
}

[class~="form-login-register"] [class~="box-form-login"] {
  transform: translateY(-50%);
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  cursor: pointer;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  padding-right: 2.5pc;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a {
  transition: all 0.2s ease 0s;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  transform: translateX(-50%);
}

[class~="form-login-register"] [class~="box-form-login"] {
  overflow: hidden;
}

.mini-cart .cart-popup .free-ship .total-percent {
  border-radius: 3.75pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  display: inline-block;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"] {
  font-weight: 500;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-register"]
  input[type="submit"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  letter-spacing: 0.1em;
}

#header-topbar [class~="email"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  content: "";
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  font-size: 15pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  height: 2.75pc;
}

[class~="form-login-register"] [class~="box-content"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"],
[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  position: relative;
}

.mini-cart .cart-popup .free-ship .total-percent {
  border-radius: 0.3125pc;
}

[class~="form-login-register"] [class~="box-content"] {
  border-left-width: 0.75pt;
}

[class~="site-header"]
  [class~="header-mobile"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  top: 0.25pc;
}

[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a
  > span:before {
  background: #fff;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  line-height: 0.458333333in;
}

#header-topbar [class~="email"] {
  padding-left: 1.25pc;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-bottom-style: none;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  height: 0.010416667in;
}

[class~="form-login-register"] [class~="box-content"] {
  border-bottom-width: 0.75pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
.mini-cart .cart-popup .free-ship .total-percent {
  border-radius: 0.3125pc;
}

[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  margin-top: -0.0625pc;
}

.site-navigation ul > li.level-0 ul.sub-menu li {
  padding-left: 1.875pc;
}

.mini-cart .cart-popup .free-ship .total-percent {
  border-radius: 3.75pt;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"],
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  width: 100%;
}

[class~="form-login-register"] [class~="box-content"] {
  border-right-width: 0.75pt;
}

[class~="form-login-register"] [class~="box-content"] {
  border-top-width: 0.75pt;
}

[class~="menu-full"] [class~="close-menu-full"]:before {
  left: calc(50% - 7px);
}

[class~="form-login-register"] [class~="box-content"] {
  border-left-style: solid;
}

[class~="form-login-register"] [class~="box-content"] {
  border-bottom-style: solid;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"] {
  text-align: center;
}

[class~="form-login-register"] [class~="box-content"] {
  border-right-style: solid;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="button-next-reregister"] {
  margin-top: 0.15625in;
}

[class~="form-login-register"] [class~="box-content"] {
  border-top-style: solid;
}

[class~="site-navigation"] ul ul[class~="sub-menu"] {
  padding-bottom: 1.375pc;
}

[class~="form-login-register"] [class~="box-content"] {
  border-left-color: #e5e5e5;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
#header-topbar [class~="email"]:before,
[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  position: absolute;
}

[class~="form-login-register"] [class~="box-content"] {
  border-bottom-color: #e5e5e5;
}

[class~="site-navigation"] ul ul[class~="sub-menu"] {
  padding-right: 1.875pc;
}

[class~="form-login-register"] [class~="box-content"] {
  border-right-color: #e5e5e5;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  background: #000;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  font-size: 14px;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  background: #e9e9e9;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  height: 0.3125pc;
}

[class~="menu-full"] [class~="close-menu-full"]:before {
  background-color: #000;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"] {
  padding-top: 0.572916667in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"] {
  font-size: 0.1875in;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before,
[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before,
[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  transition: all 0.3s ease;
}

[class~="form-login-register"],
[class~="form-login-register"] [class~="box-content"] [class~="form-register"],
#header-topbar [class~="email"]:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  left: 0;
}

[class~="form-login-register"] [class~="box-content"] {
  border-top-color: #e5e5e5;
}

#header-topbar,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"] {
  color: #000;
}

[class~="form-login-register"] [class~="box-content"] {
  border-image: none;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  transition: all 0.2s ease;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  top: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  width: 18px;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-right-style: none;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="form-login-register"] {
  background: rgba(0, 0, 0, 0.5);
}

[class~="form-login-register"] [class~="box-content"] {
  overflow-x: hidden;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-left: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  height: 0.1875in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-radius: 50%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  border-radius: 3.75pt;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-top: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-bottom: 0.3125in;
}

[class~="mini-cart"] [class~="remove-cart-shadow"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
[class~="form-login-register"] [class~="box-content"] {
  height: 100%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  padding-right: 0.041666667in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-right: 0;
}

[class~="form-login-register"] [class~="box-content"] {
  overflow-y: auto;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  z-index: 2;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  background: #fff;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"] {
  margin-top: 12px;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  opacity: 0;
}

#header-topbar [class~="topbar-right"] {
  text-align: right;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  visibility: hidden;
}

[class~="mini-cart"] [class~="remove-cart-shadow"] {
  position: fixed;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  transition: all 0.5s ease;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"],
.site-navigation ul > li.level-0 ul.sub-menu li {
  position: relative;
}

.site-navigation ul > li.level-0 ul.sub-menu li {
  padding-bottom: 0.25pc;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  transition: all 0.5s ease;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  transform: translateX(100%);
}

.site-navigation ul > li.level-0 ul.sub-menu li ul.sub-menu {
  left: 100%;
}

.site-navigation ul > li.level-0 ul.sub-menu li {
  padding-right: 1.875pc;
}

[class~="mini-cart"] [class~="remove-cart-shadow"],
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before {
  content: "";
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-radius: 50%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-radius: 50%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-radius: 50%;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-top-style: none;
}

[class~="site-navigation"] ul ul[class~="sub-menu"] {
  padding-top: 1.375pc;
}

.site-navigation ul > li.level-0 ul.sub-menu li {
  padding-top: 0.25pc;
}

[class~="form-login-register"] [class~="box-content"] [class~="form-register"] {
  transform: translateX(100%);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  width: calc(50% - 4px);
}

[class~="site-header"] [class~="header-mobile"] [class~="mojuri-topcart"] {
  margin-left: 7.5pt;
}

[class~="mini-cart"] [class~="remove-cart-shadow"] {
  width: 100%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-radius: 50%;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="site-header"] [class~="header-mobile"] [class~="mojuri-topcart"] {
  margin-bottom: 0;
}

#header-topbar #topbar-menu li:last-child {
  margin-right: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  transition: all 0.3s ease;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] {
  line-height: 1.5pc;
}

[class~="site-header"] [class~="header-mobile"] [class~="mojuri-topcart"] {
  margin-right: 7.5pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-left-width: 0.75pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-bottom-width: 0.75pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  margin-left: 0.052083333in;
}

[class~="form-login-register"] {
  z-index: 999999;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  transition: all 0.2s ease;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-right-width: 0.75pt;
}

[class~="mini-cart"] [class~="remove-cart-shadow"] {
  top: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-top-width: 0.75pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-left-style: solid;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  margin-right: 0.052083333in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-bottom-style: solid;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  [class~="sub-menu-title"]:before,
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  left: 0;
}

[class~="site-header"] [class~="header-mobile"] [class~="mojuri-topcart"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  margin-top: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  transform-origin: 50% 50%;
}

[class~="mini-cart"] [class~="remove-cart-shadow"] {
  z-index: 9999;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-right-style: solid;
}

#header-topbar [class~="email"]:before {
  background: rgba(0, 0, 0, 0.15);
}

[class~="search-box"] {
  cursor: pointer;
}

.site-header .header-mobile .mini-cart {
  text-align: right;
  display: none;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-top-style: solid;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-left-color: #000;
}

.site-header .header-page-link > .mojuri-topcart {
  margin-left: 15pt;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li:last-child {
  padding-bottom: 0;
}

[class~="mojuri-topcart"][class~="dropdown"] [class~="mini-cart"],
[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"] {
  position: relative;
}

[class~="mojuri-topcart"][class~="dropdown"] [class~="remove-cart"],
[class~="mojuri-topcart"][class~="dropdown"] [class~="top-total-cart"],
[class~="mini-cart"] [class~="remove-cart-shadow"] {
  display: none;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  list-style: none;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  display: block;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-bottom-color: #000;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  display: inline-block;
}

#header-topbar [class~="email"]:before {
  width: 0.75pt;
}

[class~="site-navigation"] ul,
[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  margin-left: 0;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  margin-right: 0;
}

#header-topbar [class~="email"]:before {
  height: 0.1875in;
}

[class~="search-box"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  text-align: center;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-left-color: currentColor;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-left: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-bottom-color: currentColor;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-right-color: #000;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  width: 100%;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-right-color: currentColor;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-bottom: 5px;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  transform: rotate(95deg);
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-right: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="active-login"]:before {
  transform: rotate(95deg);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-top-color: #000;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  background: #fff;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  padding-top: 0;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  max-height: 251.25pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  border-image: none;
}

[class~="mini-cart"] [class~="remove-cart-shadow"] {
  background: rgba(0, 0, 0, 0.4);
}

[class~="mini-cart"] [class~="cart-popup"] [class~="cart-list"] {
  overflow-y: auto;
}

[class~="search-overlay"] {
  position: fixed;
}

[class~="menu-full"] [class~="close-menu-full"]:before,
[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  transition: all 0.3s ease;
}

#header-topbar [class~="email"]:before {
  top: calc(50% - 9px);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"],
#header-topbar .topbar-right > * a {
  color: #000;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
.mini-cart .cart-popup .total-cart,
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  font-weight: 500;
}

[class~="site-navigation"] ul ul[class~="sub-menu"] {
  box-shadow: 0 0 0.020833333in rgba(0, 0, 0, 0.1);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after,
.mini-cart .cart-popup .remove {
  position: absolute;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-top-color: currentColor;
}

[class~="mini-cart"] [class~="cart-popup"] {
  text-align: left;
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  padding-top: 5px;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  letter-spacing: 0.1em;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  background: #000;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"] {
  margin-bottom: 20px;
}

[class~="mini-cart"] [class~="cart-popup"] {
  width: 285pt;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  overflow: hidden;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  line-height: 17px;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  width: 4.875pc;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="mini-cart-item"]
  a[class~="remove"] {
  text-indent: -1px;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  float: left;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  transform: translateX(-50%);
}

[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] {
  margin-right: 12px;
}

[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li a,
[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  color: #868686;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-left: 0;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
.mini-cart .cart-popup .total-cart,
[class~="menu-full"] [class~="site-navigation"] #main-navigation,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  position: relative;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"]:hover {
  color: #cb8161 !important;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  border-radius: 0;
}

.form-login-register .box-content > div > form {
  padding-left: 0.3125in;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a:hover {
  color: #cb8161;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  border-image: none;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-bottom: 0;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-right: 0;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="quantity"] {
  margin-top: 0;
}

.mini-cart .cart-popup .remove {
  right: 0.3125pc;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  font-size: 0.114583333in;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="menu-item-has-children"]
  > a:after {
  content: none;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  border-radius: 0;
}

.mini-cart .cart-popup .remove {
  top: 0.020833333in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  text-transform: uppercase;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  color: #fff;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-radius: 0;
}

.mini-cart .cart-popup .total-cart {
  font-size: 0.166666667in;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  border-radius: 0;
}

[class~="mini-cart"] [class~="cart-popup"] {
  padding-left: 15pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  background: #a0a0a0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"]:before {
  line-height: 1;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  transition: unset;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  padding-left: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  transition: unset;
}

.mini-cart .cart-popup .total-cart {
  font-weight: 700;
}

.mini-cart .cart-popup .total-cart {
  color: #000;
}

.mini-cart .cart-popup .total-cart {
  line-height: 26px;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"]:before {
  background: #cb8161;
}

.form-login-register .box-form-login .active-login:after,
.menu-full .close-menu-full:before {
  transform-origin: 50% 50%;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"]:after,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  position: absolute;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-radius: 0;
}

.mini-cart .cart-popup .total-cart {
  display: flex;
}

[class~="mini-cart"] [class~="cart-popup"] {
  padding-bottom: 0.25in;
}

#header-topbar [class~="topbar-right"] > * {
  display: inline-block;
}

.mini-cart .cart-popup .total-cart {
  justify-content: space-between;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  bottom: 0;
}

.mini-cart .cart-popup .total-cart {
  border-top-width: 0.010416667in;
}

.mini-cart .cart-popup .total-cart {
  border-top-style: solid;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-left-width: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:before {
  top: auto;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"]:after,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  content: "";
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  opacity: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  padding-bottom: 0;
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  padding-bottom: 0.052083333in;
}

.mini-cart .cart-popup .total-cart {
  border-top-color: #000;
}

.mini-cart .cart-popup .total-cart {
  border-image: none;
}

[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  opacity: 1;
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li
  a {
  display: block;
}

[class~="mini-cart"] [class~="cart-popup"] {
  padding-right: 15pt;
}

.mini-cart .cart-popup .total-cart {
  padding-top: 0.75pc;
}

[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  visibility: visible;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  font-size: 1.125pc;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-top-width: 0.0625pc;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-top-style: solid;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-top-color: #000;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  height: 0.75pt;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i,
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  transition: all 0.3s ease;
}

[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  transform: translateX(0);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  top: 0;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-width: 0;
}

.menu-full .close-menu-full:before {
  transform: rotate(95deg);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  i {
  transition: all 0.3s ease;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  padding-right: 0;
}

[class~="search-overlay"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"],
.form-login-register .box-form-login .content input,
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  width: 100%;
}

.form-login-register .box-content > div > form {
  padding-bottom: 1.875pc;
}

[class~="mini-cart"] [class~="cart-popup"] {
  padding-top: 0.25in;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  border-image: none;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] {
  margin-top: 0.75pc;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  left: 50%;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  padding-top: 0;
}

[class~="form-login-register"] [class~="box-content"] > div[class~="active"] {
  transform: translateX(0);
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  padding-top: 20px;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] {
  font-size: 11.25pt;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] {
  display: flex;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit {
  left: 0;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-left-width: 0.010416667in;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  background: #cbcbcb;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="buttons"] [class~="button"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-width: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-top-width: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-left-style: none;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  background: #e5e5e5;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-bottom-style: none;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  top: calc(50% - 17px);
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] {
  border-bottom-width: 0.010416667in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-right-style: none;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  display: inline-block;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-top-style: none;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  height: 50px;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-right-width: 0.010416667in;
}

[class~="site-navigation"] ul {
  margin-bottom: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  content: "";
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] {
  border-top-width: 0.010416667in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  width: auto;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  height: auto;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  color: #000;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  margin-left: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-left-color: currentColor;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  font-family: FontAwesome;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  position: relative;
}

.form-login-register .box-content > div > form {
  padding-right: 0.3125in;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-bottom-color: currentColor;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-left-style: solid;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"]:after,
[class~="search-overlay"] {
  right: 0;
}

.menu-full .close-menu-full:before {
  transform: rotate(95deg);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-right-color: currentColor;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  width: 0.1875in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  background-color: transparent !important;
}

[class~="search-overlay"] {
  height: 100%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-top-color: currentColor;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"],
.site-header .header-page-link .search-box .search-toggle,
[class~="search-overlay"] {
  top: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  background: none;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-bottom-style: solid;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  font-size: 20px;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"] {
  border-image: none;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  animation: 2s linear 0s normal none infinite running o;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"]:before {
  animation: 2s linear 0s normal none infinite running o;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:hover:before,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:hover:after {
  transform: rotate(0deg);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:hover:before,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:hover:after {
  transform: rotate(0deg);
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] {
  border-right-style: solid;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"],
[class~="mini-cart"] [class~="cart-popup"] {
  border-top-style: solid;
}

.form-login-register .box-form-login .content input {
  height: 44px;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-width: 0;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-left-color: #f3f3f3;
}

.site-navigation ul div.sub-menu ul.menu-list li a:hover {
  color: #cb8161;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-bottom-color: #f3f3f3;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-right-color: #f3f3f3;
}

.form-login-register .box-form-login .content input {
  line-height: 44px;
}

.form-login-register .box-form-login .content input,
[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-left: 0;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  top: -0.3125pc;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-top-color: #f3f3f3;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-width: 2px;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="search-overlay"] {
  color: #000;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-image: none;
}

.site-header .header-page-link .search-box .search-toggle {
  font-size: 1.125pc;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-left-color: #000;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  height: 0.0625pc;
}

[class~="site-navigation"] ul > li[class~="level-0"] ul[class~="sub-menu"] li a,
.site-header .header-page-link .search-box .search-toggle,
[class~="form-login-register"] [class~="box-content"] > div,
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  position: relative;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-width: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  transform: translateX(-50%);
}

.form-login-register .box-form-login .content input {
  padding-bottom: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-width: 0;
}

[class~="mini-cart"] [class~="cart-popup"],
.site-header .header-page-link .search-box .search-toggle {
  transition: all 0.3s ease;
}

.menu-full .close-menu-full:after {
  transform-origin: 50% 50%;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  right: 0;
}

.site-header .header-page-link .search-box .search-toggle {
  transition: all 0.3s ease;
}

[class~="mini-cart"] [class~="cart-popup"],
.site-header .header-search-form .result-search-products-content {
  background: #fff;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-style: none;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-bottom-color: #000;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-right-color: #000;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-top-color: #000;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  bottom: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-style: solid;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  border-image: none;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  max-width: 660px;
}

[class~="site-header"] [class~="header-search-form"] [class~="search-from"] {
  margin-left: auto;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-style: none;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  transition: all 0.5s ease;
}

.site-header .header-search-form .result-search-products-content,
[class~="mini-cart"] [class~="cart-popup"] {
  position: absolute;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"] {
  text-align: left;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-style: none;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-style: none;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-bottom: 0.25pc;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"] {
  flex: 1;
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  display: box;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"],
.form-login-register .box-form-login .content input {
  padding-right: 0;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  top: 50%;
}

.site-header .header-search-form .result-search-products-content,
[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] img {
  width: 100%;
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  display: flexbox;
}

.site-header .header-search-form .result-search-products-content {
  left: 0;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="search-overlay"] {
  opacity: 1;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  width: 0.75pt;
}

.site-header .header-search-form .result-search-products-content {
  border-radius: 0.041666667in;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-left-color: currentColor;
}

.site-header .header-search-form .result-search-products-content {
  border-radius: 4px;
}

.form-login-register .box-form-login .content input,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  padding-top: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="free-ship"]
  [class~="total-percent"]
  [class~="percent"] {
  transition: all 0.5s ease;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  list-style: none;
}

.site-header .header-search-form .result-search-products-content {
  border-radius: 0.25pc;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="search-overlay"] {
  visibility: visible;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  padding-left: 0;
}

.site-header .header-search-form .result-search-products-content {
  border-radius: 0.041666667in;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="mini-cart-item"] a {
  color: #868686;
}

.site-header .header-search-form .result-search-products-content {
  border-radius: 0.25pc;
}

[class~="search-overlay"] {
  z-index: 999999;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  background: #000;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  padding-bottom: 0;
}

[class~="site-navigation"] ul {
  margin-right: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-color: #e5e5e5;
}

.site-header .header-search-form .result-search-products-content {
  box-shadow: 0 0.75pt 12px 0.125pc hsla(0, 0%, 56%, 0.3);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-color: currentColor;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"],
.search-overlay .search-from .search-close,
.site-header .header-search-form .result-search-products-content {
  display: none;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="mini-cart"]
  [class~="cart-icon"]
  [class~="icons-cart"]
  [class~="cart-count"] {
  font-size: 0.75pc;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-color: currentColor;
}

.site-header .header-search-form .result-search-products-content,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  z-index: 10;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-bottom-color: currentColor;
}

[class~="mini-cart"] [class~="cart-popup"],
[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="lost_password"]
  a:before {
  transition: all 0.3s ease;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="items-search"] {
  margin-bottom: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-image: none;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  position: relative;
}

[class~="mini-cart"] [class~="cart-popup"] {
  transform: translateY(20px) !important;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  transform: translateY(-50%);
}

[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] img {
  height: auto;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  max-height: 18.75pc;
}

[class~="mini-cart"] [class~="cart-popup"] {
  transform: translateY(20px) !important;
}

[class~="mini-cart"] [class~="cart-popup"] {
  z-index: 10001;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  transition: all 0.2s ease;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="product-image"] img {
  display: block;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  overflow-y: auto;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-radius: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-left: 15pt;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-left: 0.291666667in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-bottom: 15pt;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-radius: 0;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-radius: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-right: 15pt;
}

.form-login-register .box-content > div > form,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  padding-top: 1.25pc;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:before {
  left: 50%;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-radius: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"] {
  overflow-x: hidden;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  transition: all 0.2s ease;
}

[class~="mini-cart"] [class~="cart-popup"] {
  border-radius: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  content: "";
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  display: flex;
}

.search-overlay.search-visible .wrapper-search {
  transform: translateX(0);
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  box-align: center;
}

.search-overlay.search-visible .wrapper-search {
  transform: translateX(0);
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  color: #000 !important;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  padding-bottom: 0.15625in;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  padding-right: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  padding-top: 0.15625in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  display: table;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  padding-right: 0.208333333in;
}

.search-overlay .search-from .search-close {
  text-align: center;
}

.search-overlay .search-from .search-close,
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  position: absolute;
}

[class~="mini-cart"] [class~="cart-popup"] {
  left: auto !important;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="login-header"]
  > a:after {
  transform: translateY(-50%);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-top: 0;
}

[class~="search-overlay"] {
  transition: all 0.3s ease-in-out;
}

#header-topbar [class~="topbar-right"] > * {
  padding-left: 0;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  color: #868686;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  width: 100%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-bottom-width: 0.0625pc;
}

.search-overlay .search-from .search-close {
  cursor: pointer;
}

.search-overlay .search-from .search-close {
  top: -0.3125in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-bottom-style: solid;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  font-family: wpbingofont;
}

.search-overlay .search-from .search-close {
  right: -1.875pc;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  line-height: 0.416666667in;
}

.search-overlay .search-from .search-close,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  color: #000;
}

[class~="form-login-register"] [class~="box-content"] > div {
  z-index: 3;
}

.search-overlay .search-from .search-close {
  z-index: 10000;
}

#header-topbar #topbar-menu li:before {
  right: 0;
}

[class~="mini-cart"] [class~="cart-popup"] {
  right: 0 !important;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-bottom-color: #f5f5f5;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  top: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit
  > [class~="icon-search"] {
  transform: rotateY(180deg);
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"] {
  border-image: none;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown),
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  border-left-color: #000;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span:before {
  visibility: hidden;
}

[class~="mini-cart"] [class~="cart-popup"] {
  top: 50px !important;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  width: calc(100% - 20px);
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="price"] {
  font-size: 14px;
}

[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"][class~="current_page_item"]
  > a,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"]:hover {
  color: #fff;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-left: 22.5pt;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-bottom: 22.5pt;
}

[class~="form-login-register"] [class~="box-content"] > div {
  background: #fff;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-right: 22.5pt;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  display: block;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  font-size: 16px;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  margin-bottom: 0.520833333in;
}

[class~="form-login-register"] [class~="box-content"] > div {
  opacity: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  font-weight: 500;
}

[class~="mini-cart"] [class~="cart-popup"] {
  margin-top: 0;
}

[class~="mini-cart"] [class~="cart-popup"] {
  will-change: unset !important;
}

.search-overlay .search-from .search-close {
  width: 36px;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"][class~="loading"] {
  padding-top: 22.5pt;
}

.search-overlay .search-from .search-close {
  height: 36px;
}

.search-overlay .search-from .search-close {
  border-left-width: 6.75pt;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:hover {
  background: #e5e5e5;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  transition: max-width 1s ease;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown),
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  border-bottom-color: #000;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-right-color: currentColor;
}

[class~="mini-cart"] [class~="cart-popup"] {
  box-shadow: 0 3.75pt 0.3125pc 0 rgba(50, 50, 50, 0.2);
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  height: 3.375pc;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  transition: max-width 1s ease;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  display: box;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-top-color: currentColor;
}

.search-overlay .search-from .search-close {
  border-bottom-width: 6.75pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  font-size: 1.125pc;
}

.search-overlay .search-from .search-close {
  border-right-width: 6.75pt;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  border-image: none;
}

.search-overlay .search-from .search-close {
  border-top-width: 6.75pt;
}

.search-overlay .search-from .search-close {
  border-left-style: solid;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]:hover {
  color: #000;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  transition-delay: 0.3s;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  max-width: 0;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  display: flexbox;
}

[class~="site-header"] [class~="header-search-form"] {
  flex: 1;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  font-size: 50px;
}

[class~="site-header"] [class~="header-search-form"] {
  margin-right: 50px;
}

.search-overlay .search-from .search-close {
  border-bottom-style: solid;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  line-height: 0.520833333in;
}

.search-overlay .search-from .search-close {
  border-right-style: solid;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  line-height: 1.3;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown),
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"] {
  border-right-color: #000;
}

.search-overlay.search-visible {
  opacity: 1;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"][class~="view-cart"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:not(:placeholder-shown) {
  border-top-color: #000;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  color: #acacaa;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]:hover {
  top: -3px;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  position: absolute;
}

.search-overlay .search-from .search-close {
  border-top-style: solid;
}

.search-overlay .search-from .search-close {
  border-left-color: transparent;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  margin-bottom: 0;
}

.search-overlay.search-visible {
  visibility: visible;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label,
[class~="site-navigation"] ul {
  margin-top: 0;
}

.search-overlay .search-from .search-close {
  border-bottom-color: transparent;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
.search-overlay > .close-search {
  width: 100%;
}

.search-overlay .search-from .search-close {
  border-right-color: transparent;
}

.search-overlay .search-from .search-close {
  border-top-color: transparent;
}

[class~="search-overlay"] {
  transition: all 0.3s ease-in-out;
}

.search-overlay > .close-search {
  height: 100%;
}

.search-overlay > .close-search {
  background: rgba(0, 0, 0, 0.25);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-left: 0;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-right: 0;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  line-height: 1;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"],
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  font-weight: 400;
}

.search-overlay > .close-search {
  cursor: unset !important;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  top: -5px;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  max-width: 4.375in;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  margin-left: auto;
}

.search-overlay .search-from .search-close {
  border-image: none;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="buttons"]
  [class~="button"]:first-child {
  margin-left: 0;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  transform: translateX(100%);
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  display: flex;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-bottom: 11.25pt;
}

[class~="mini-cart"] [class~="cart-popup"] a[class~="product-name"] {
  font-size: 11.25pt;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  margin-right: 0;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  right: -12.75pt;
}

.search-overlay .search-from .search-close {
  border-radius: 100%;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  text-transform: uppercase;
}

[class~="mini-cart"] [class~="cart-popup"] [class~="free-ship"] strong {
  text-decoration: underline;
}

.site-header .header-page-link .wishlist-box,
[class~="search-overlay"] [class~="search-from"] [class~="search-box"],
[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i,
.form-login-register .box-content > div > form {
  position: relative;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  transform: translateX(100%);
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  transform: rotate(45deg);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  label {
  letter-spacing: 0.015625in;
}

.search-overlay .search-from .search-close {
  border-radius: 100%;
}

[class~="form-login-register"] [class~="box-content"] > div {
  visibility: hidden;
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  flex-align: center;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  transition: transform 0.3s ease-in-out;
}

.search-overlay .search-from .search-close {
  border-radius: 100%;
}

.search-overlay .search-from .search-close {
  border-radius: 100%;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  box-align: center;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  width: 0.177083333in;
}

.search-overlay .search-from .search-close {
  border-radius: 100%;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  transition: transform 0.3s ease-in-out;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  padding-left: 37.5pt;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  content: "";
}

[class~="search-overlay"] [class~="wrapper-search"] {
  padding-bottom: 2.5pc;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  position: absolute;
}

.search-overlay .search-from .search-box .content-menu_search ul {
  padding-left: 0;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-box"] {
  text-align: left;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  padding-right: 37.5pt;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before {
  left: calc(50% - 25px);
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-left: 1.5625pc;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-box"] {
  flex: 1;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i {
  margin-left: 5px;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  height: 1.5pt;
}

.mini-cart .cart-popup .buttons .button:last-child {
  margin-right: 0;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  height: 12.75pt;
}

.search-overlay .search-from .search-box .content-menu_search ul,
[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
#header-topbar [class~="topbar-right"] > * {
  padding-bottom: 0;
}

.search-overlay .search-from .search-box .content-menu_search ul {
  padding-right: 0;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-box"] {
  cursor: unset;
}

.search-overlay .search-from .search-box .content-menu_search ul {
  padding-top: 0;
}

.site-header .header-page-link .wishlist-box {
  font-size: 0.208333333in;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  padding-top: 2.5pc;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  line-height: 1.0625pc;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after,
[class~="search-overlay"] [class~="wrapper-search"] {
  width: 100%;
}

.site-header .header-page-link .wishlist-box,
[class~="search-overlay"] [class~="wrapper-search"] {
  top: 0;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  background: #fff;
}

[class~="form-login-register"] [class~="box-content"] > div {
  transition: all 0.5s ease;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"],
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  > a,
[class~="site-header"][class~="color-white"]
  [class~="header-page-link"]
  [class~="search-box"]
  [class~="search-toggle"] {
  color: #fff;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  top: 50%;
}

.site-header .header-page-link .wishlist-box,
[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  transition: all 0.3s ease;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  left: 0;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  display: inline-block;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  #search-submit
  i {
  line-height: 34.5pt;
}

.search-overlay .search-from .search-box .content-menu_search ul {
  list-style: none;
}

.site-header .header-page-link .wishlist-box,
[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  transition: all 0.3s ease;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  position: fixed;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  margin-top: -0.0625pc;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]:before,
.site-header .header-page-link .search-box .search-toggle:hover {
  color: #000;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  flex-align: center;
}

[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li,
[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a {
  align-items: center;
}

[class~="site-navigation"]
  ul
  div[class~="sub-menu"]
  ul[class~="menu-list"]
  li
  a,
[class~="site-navigation"] ul div[class~="sub-menu"] ul[class~="menu-list"] li {
  font-size: inherit;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  right: 0;
}

[class~="search-overlay"] [class~="wrapper-search"] {
  height: 100%;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a
  > span {
  display: inline;
}

.site-header .header-page-link .search-box .search-toggle:hover {
  top: -0.03125in;
}

[class~="search-overlay"] [class~="search-from"] {
  margin-left: auto;
}

[class~="search-overlay"] [class~="search-from"] {
  margin-bottom: auto;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:first-child {
  padding-top: 0;
}

[class~="search-overlay"] [class~="search-from"] {
  margin-right: auto;
}

[class~="form-login-register"] [class~="box-content"] > div {
  transition: all 0.5s ease;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > a
  > span {
  padding-bottom: 7.5pt;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:after {
  transform: rotate(45deg);
}

[class~="search-overlay"] [class~="search-from"] {
  margin-top: auto;
}

[class~="form-login-register"] [class~="box-form-login"] h2,
[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  text-align: center;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  background: #000;
}

[class~="search-overlay"] [class~="search-from"] {
  display: flex;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  padding-bottom: 0;
}

[class~="form-login-register"] [class~="box-content"] > div {
  transform: translateX(-100%);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  border-radius: 50%;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-size: 22.5pt;
}

[class~="search-overlay"] [class~="search-from"],
[class~="form-login-register"] [class~="box-form-login"] h2 {
  position: relative;
}

#header-topbar [class~="topbar-right"] > * {
  padding-right: 11.25pt;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  border-radius: 50%;
}

[class~="form-login-register"] [class~="box-content"] > div {
  transform: translateX(-100%);
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  border-radius: 50%;
}

[class~="site-header"]
  [class~="header-page-link"]
  [class~="wishlist-box"]
  [class~="count-wishlist"] {
  font-size: 0.114583333in;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  padding-right: 1.5625pc;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"],
#header-topbar [class~="topbar-right"] > * {
  padding-top: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-bottom-width: 0;
}

[class~="site-navigation"] ul > li[class~="level-0"] > a > span {
  display: inline-block;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]
  i {
  top: 3px;
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li {
  padding-top: 0.25pc;
}

.mini-cart .cart-popup .cart-list li.empty span {
  display: block;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-bottom-style: none;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-bottom-color: currentColor;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  content: "";
}

#header-topbar #topbar-menu li:before {
  background: rgba(0, 0, 0, 0.15);
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]:last-child {
  border-image: none;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"],
[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-image"] {
  display: table-cell;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] > a {
  text-transform: none;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:before,
[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  position: absolute;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-image"] {
  width: 70px;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  font-size: 1.375pc;
}

[class~="menu-full"] [class~="site-navigation"] ul > li[class~="level-0"] > a {
  font-weight: 400;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"] {
  font-size: 15px;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-left: 0;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"] {
  vertical-align: top;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  height: 1.5pt;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"]
  a {
  font-weight: 500;
}

[class~="mini-cart"]
  [class~="cart-popup"]
  [class~="cart-list"]
  li[class~="empty"]
  [class~="go-shop"]:hover:before,
[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  width: 100%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"] {
  padding-left: 0.208333333in;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-bottom: 0.208333333in;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  top: 50%;
}

[class~="site-header"]
  [class~="header-search-form"]
  [class~="result-search-products"]
  [class~="item-search"]
  [class~="item-content"]
  a {
  text-transform: capitalize;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-right: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  left: 0;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  margin-top: -0.0625pc;
}

.search-overlay.search-visible .search-from .search-box input[type="text"] {
  max-width: 100%;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  margin-top: 0;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  text-transform: uppercase;
}

#header-topbar [class~="topbar-right"] > * a:hover i,
#header-topbar a,
[class~="search-overlay"][class~="search-visible"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]::placeholder,
.site-header.color-white .header-page-link .wishlist-box .count-wishlist {
  color: #000;
}

.site-header.color-white .header-page-link .wishlist-box .count-wishlist {
  background: #fff;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  transition: all 0.3s ease;
}

[class~="form-login-register"] [class~="box-form-login"] h2 {
  font-weight: 600;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-close"]:before {
  transition: all 0.3s ease;
}

.search-overlay .search-from .search-close:before,
[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  transform-origin: 50% 50%;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus {
  border-left-color: #000;
}

.search-overlay .search-from .search-close:before {
  transform: rotate(-45deg);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus {
  border-bottom-color: #000;
}

.search-overlay .search-from .search-close:before {
  transform: rotate(-45deg);
}

#header-topbar #topbar-menu li:before {
  width: 0.75pt;
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  transform: rotate(45deg);
}

[class~="search-overlay"] [class~="search-from"] [class~="search-close"]:after {
  transform: rotate(45deg);
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus {
  border-right-color: #000;
}

#header-topbar #topbar-menu li:before {
  height: 1.125pc;
}

#header-topbar #topbar-menu li:last-child {
  padding-right: 0;
}

#header-topbar #topbar-menu li:before {
  top: calc(50% - 9px);
}

[class~="menu-full"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:last-child:after,
#header-topbar #topbar-menu li:last-child:before {
  content: none;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  input[type="text"]:focus {
  border-top-color: #000;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  #searchsubmit
  > [class~="icon-search"] {
  transform: rotateY(180deg);
}

[class~="site-navigation"]
  ul
  > li[class~="level-0"]
  ul[class~="sub-menu"]
  li[class~="menu-item-has-children"]
  > a:after {
  right: 0;
}

[class~="form-login-register"]
  [class~="box-form-login"]
  [class~="rememberme-lost"]
  [class~="rememberme"]
  input:checked
  + label:after {
  transform: scale(1);
}

[class~="color-white"]
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]:hover
  > a {
  color: #fff;
}

body:not([class~="home"])
  [class~="site-navigation"]
  ul
  > li[class~="level-0"]
  > [class~="sub-menu"] {
  margin-top: 0.3125in;
}

[class~="search-overlay"]
  [class~="search-from"]
  [class~="search-box"]
  [class~="content-menu_search"]
  ul
  li
  a:hover {
  text-decoration: underline;
}

#header-topbar #topbar-menu {
  margin-bottom: 0;
}

/*========================================================================
4. Breadcrumbs
=========================================================================*/
.site-main .title-who-we-are {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/who-we-are.jpg");
  background-size: cover;
}
.site-main .title-collection {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/collection.jpg");
  background-size: cover;
}
.site-main .title-experience {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/ziya-experience.jpg");
  background-size: cover;
}
.site-main .title-find-ziya {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/find-ziya.jpg");
  background-size: cover;
}
.site-main .title-contact {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/inquiry.jpg");

  background-size: cover;
}
.site-main .title-product {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/product.jpg");

  background-size: cover;
}
.site-main .title-product-details {
  /* background-image: url("../../media/site-header.jpg"); */
  background-image: url("/src/assets/media/banner/product-details.jpg");

  background-size: cover;
}
.site-main .page-title-dark h1 {
  color: #fc8b57 !important;
}
.site-main .page-title-dark .breadcrumbs {
  color: #eee !important;
}
.site-main .page-title,
[class~="site-main"] [class~="page-title"] [class~="content-title-heading"],
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before,
[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] a {
  position: relative;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  text-transform: capitalize;
}

[class~="site-main"] [class~="page-title"] [class~="content-title-heading"] {
  display: inline-flex;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  content: "";
}

[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  margin-top: 0;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  display: inline-block;
}

[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] a,
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  color: #868686;
}

.site-main .page-title {
  margin-left: 0;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  font-size: 3.125pc;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  height: 0.75pc;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  width: 1px;
}

.site-main .page-title {
  margin-bottom: 60px;
}

.site-main .page-title {
  margin-right: 0;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="content-title-heading"]
  [class~="text-title-heading"] {
  text-align: center;
}

.site-main .page-title {
  padding-left: 100px;
}

.site-main .page-title,
[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-top: 0;
}

.site-main .page-title {
  font-size: 15pt;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-left: 10px;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-bottom: 0;
}

.site-main .page-title {
  padding-top: 63.75pt;
}

[class~="site-main"] [class~="page-title"] [class~="content-title-heading"] {
  align-items: center;
}

.site-main .page-title {
  padding-bottom: 90.5pt;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"] {
  margin-right: 10px;
}

.site-main .page-title {
  background-position: 50%;
}

.site-main .page-title {
  border-top-width: 0.0625pc;
}

.site-main .page-title {
  border-top-style: solid;
}

[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] {
  font-size: 10.5pt;
}

.site-main .page-title {
  border-top-color: #e5e5e5;
}

.site-main .page-title {
  border-image: none;
}

.site-main .page-title {
  border-bottom-width: 1px;
}

.site-main .page-title {
  border-bottom-style: solid;
}

[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] {
  color: #000;
}

.site-main .page-title {
  border-bottom-color: #e5e5e5;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  top: 1.5pt;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  background: #868686;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  transform: rotate(25deg);
}

[class~="site-main"] [class~="page-title"] [class~="breadcrumbs"] {
  font-weight: 500;
}

[class~="site-main"]
  [class~="page-title"]
  [class~="breadcrumbs"]
  [class~="delimiter"]:before {
  transform: rotate(25deg);
}

/*========================================================================
5. Block
=========================================================================*/
[class~="block-sliders"][class~="color-primary"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"]:hover {
  background: #000;
}

[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"],
[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-left: 0;
}

[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-left: 0;
}

.block-product-cats ul {
  padding-left: 0;
}

.block-products .nav-tabs .nav-item:hover .nav-link:before,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"][class~="active"]:before,
[class~="block-sliders"] [class~="item-content"] [class~="item-info"],
[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  width: 100%;
}

[class~="block-sliders"] i[class~="slick-arrow"],
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-left-width: 0.75pt;
}

[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-bottom: 0;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="content"] {
  max-width: 4.322916667in;
}

[class~="block-sliders"],
[class~="block-sliders"] [class~="item-content"] {
  position: relative;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  width: 2.8125pc;
}

[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  height: 6.25pc;
}

.block-banners.layout-3 .block-widget-banner .banners .banner-image a {
  display: inline-block;
}

[class~="block-sliders"][class~="color-white"] [class~="slick-dots"] li button {
  background: hsla(0, 0%, 100%, 0.3);
}

[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-right: 0;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  height: 33.75pt;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-image"]
  img:hover {
  opacity: 0.8;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  color: #cb8161;
}

[class~="block-sliders"] [class~="slick-sliders"] [class~="slick-list"] {
  margin-top: 0;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  line-height: 2.375pc;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 52.5pt;
}

.block-sliders.auto-height .item-content .content-image img {
  height: auto;
}

[class~="block-product-cats"] ul li a {
  color: #868686;
}

[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  min-height: 100vh;
}

.block-sliders.auto-height .item-content .content-image img {
  min-height: auto;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"]:hover {
  color: #fff;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"]:hover,
[class~="block-sliders"][class~="color-primary"] i[class~="slick-arrow"]:hover {
  background: #cb8161;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"][class~="right"]
  [class~="section-column-inner"]
  [class~="block-widget-banner"]:first-child {
  margin-bottom: 176.25pt;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  opacity: 1;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"],
[class~="block-sliders"] [class~="slider-bg-image"] {
  position: absolute;
}

.block-product-cats ul {
  list-style-type: none;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"]:hover {
  border-left-color: #cb8161;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  transition: unset;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"]:hover {
  border-bottom-color: #cb8161;
}

[class~="block-sliders"] [class~="slider-bg-image"] {
  top: 17%;
}

[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"],
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-bottom: 0;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  line-height: 1.2;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"][class~="right"]
  [class~="section-column-inner"]
  [class~="block-widget-banner"]:first-child {
  text-align: right;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-right: 0;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"]:hover {
  border-right-color: #cb8161;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"]
  [class~="slick-sliders"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-top: 0;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  opacity: 0;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  height: 0.125pc;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  visibility: hidden;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  transition: all 0.3s ease;
}

[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  object-fit: cover;
}

[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  transform: translateZ(0);
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  visibility: visible;
}

[class~="block-products"] ul[class~="products-list"] li,
.block-product-filter ul.filter-items li:hover span,
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a,
[class~="block-sliders"] i[class~="slick-arrow"] {
  border-bottom-width: 0.010416667in;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-right-width: 0.010416667in;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 0;
}

[class~="block-sliders"] [class~="slider-bg-image"] {
  left: 50%;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"]:hover {
  border-top-color: #cb8161;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  transition: all 0.3s ease;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  margin-top: -100px;
}

[class~="block-product-cats"] ul li {
  display: block;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  padding-left: 2.65625in;
}

[class~="block-sliders"] [class~="item-content"] [class~="content-image"] img {
  transform: translateZ(0);
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"],
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-bottom: 0px;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"],
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 0pt;
}

[class~="block-products"] ul[class~="products-list"] li,
[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  display: flex;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 60px;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  padding-top: 0pc;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li {
  margin-left: 0;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li {
  margin-bottom: 4.5pt;
}

[class~="block-product-cats"] ul li[class~="current"] a {
  color: #000;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li {
  margin-right: 0;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] li {
  margin-top: 4.5pt;
}

.block-product-filter ul.filter-items li:hover span,
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-left-width: 0.75pt;
}

[class~="block-products"] ul[class~="products-list"] li {
  padding-bottom: 0.1875in;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"],
[class~="block-sliders"][class~="color-primary"] i[class~="slick-arrow"]:hover {
  color: #fff;
}

[class~="block-map"] iframe {
  height: 220px;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-top-width: 0.010416667in;
}

[class~="block-sliders"] [class~="item-content"] [class~="item-info"] {
  transition-delay: 0.6s;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 18.75pc;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-radius: 50%;
}

[class~="block-sliders"] [class~="slider-bg-image"] {
  transform: translateX(-50%);
}

[class~="block-sliders"] [class~="slick-dots"] {
  padding-left: 0pt;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-bottom: 5px;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"] {
  border-left-color: #fff;
}

[class~="block-banners"][class~="banners-effect"]
  [class~="banner-image"]:hover
  img {
  filter: alpha(opacity=80);
}

[class~="block-product-cats"] ul li {
  margin-bottom: 7.5pt;
}

[class~="block-sliders"][class~="color-primary"]
  [class~="slick-dots"]
  li
  button {
  background: rgba(203, 129, 97, 0.3);
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-left"]:before {
  font-family: icomoon;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"] {
  border-bottom-color: #fff;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"] {
  border-right-color: #fff;
}

[class~="block-sliders"][class~="color-white"] i[class~="slick-arrow"] {
  border-top-color: #fff;
}

[class~="block-products"][class~="layout-2"][class~="slider"]
  [class~="content-product-list"]
  i[class~="slick-arrow"],
.block-sliders .slider-bg-image .image-center {
  top: 50%;
}

[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"],
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-left: 0;
}

.block-sliders .slider-bg-image .image-center {
  position: absolute;
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-left"]:before {
  font-size: 0.15625in;
}

[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  padding-bottom: 0;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 600;
}

.block-sliders .slider-bg-image .image-center {
  left: 50%;
}

[class~="block-products"] ul[class~="products-list"] li {
  margin-bottom: 1.125pc;
}

[class~="block-banners"][class~="banners-effect"]
  [class~="banner-image"]:hover
  img {
  transform: scale3d(1.1, 1.1, 1);
}

[class~="block-map"] iframe {
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

.block-sliders .slider-bg-image .image-center {
  transform: translate(-50%, -50%);
}

[class~="block-products"] ul[class~="products-list"] li {
  border-bottom-style: solid;
}

[class~="block-banners"][class~="banners-effect"]
  [class~="banner-image"]:hover
  img {
  opacity: 0.8;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-left-color: #fff;
}

.block-intro.layout-4 .nav-tabs .nav-item a.active:hover:before,
[class~="block-banners"]
  [class~="block-widget-banner"]
  [class~="banners"]
  [class~="banner-image"]
  img {
  width: 100%;
}

[class~="block-banners"]
  [class~="block-widget-banner"]
  [class~="banners"]
  [class~="banner-image"]
  a {
  display: block;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"],
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-right: 0;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-right: 0;
}

.block-sliders .slider-bg-image .image-center {
  z-index: 1;
}

[class~="block-sliders"] [class~="slick-dots"] {
  padding-bottom: 50px;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"],
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"],
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"] {
  padding-top: 0;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-bottom-color: #fff;
}

[class~="block-sliders"] [class~="slick-dots"] {
  padding-right: 67.5pt;
}

[class~="block-sliders"] i[class~="slick-arrow"]:hover,
[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:hover {
  color: #fff;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  display: inline-block;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  background: #ddd;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-top: 18.75pt;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-right-color: #fff;
}

[class~="block-products"][class~="slider"]
  [class~="content-product-list"]
  i[class~="slick-arrow"] {
  font-size: 15pt;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  text-transform: uppercase;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  justify-content: flex-start;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  height: 3px;
}

[class~="block-sliders"][class~="color-white"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button {
  background: #fff;
}

[class~="block-products"][class~="slider"]
  [class~="content-product-list"]
  i[class~="slick-arrow"] {
  top: 40%;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-top-color: #fff;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  min-width: 1.4375in;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]:before,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]:last-child:before {
  content: none;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 0.625pc;
}

[class~="block-sliders"][class~="color-primary"]
  [class~="slick-dots"]
  li[class~="slick-active"]
  button,
[class~="block-sliders"] i[class~="slick-arrow"]:hover {
  background: #cb8161;
}

.block-product-filter ul.filter-items li:hover span {
  border-right-width: 0.75pt;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  display: flex;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-left-style: solid;
}

.block-sliders .slick-current .item-content .item-info {
  opacity: 1;
}

.block-sliders .slick-current .item-content .item-info {
  visibility: visible;
}

[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
.block-sliders .slick-current .item-content .item-info,
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-top: 0;
}

[class~="block-sliders"] [class~="slick-dots"] {
  padding-top: 0pc;
}

[class~="block-sliders"] [class~="slick-dots"] {
  text-align: right;
}

.block-sliders.color-primary i.slick-arrow,
[class~="block-sliders"] i[class~="slick-arrow"]:hover {
  border-left-color: #cb8161;
}

[class~="block-sliders"] [class~="slick-dots"],
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  position: absolute;
}

[class~="block-sliders"] i[class~="slick-arrow"]:hover,
.block-sliders.color-primary i.slick-arrow {
  border-bottom-color: #cb8161;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  content: "";
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-left"]:before {
  transition: all 0.3s ease;
}

[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-size: 3.125pc;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  font-size: 18pt;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  top: calc(50% - 65px);
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="banner-image-description"] {
  margin-bottom: 20px;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before,
[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:hover {
  background: #000;
}

[class~="block-sliders"] i[class~="slick-arrow"]:hover,
.block-sliders.color-primary i.slick-arrow {
  border-right-color: #cb8161;
}

[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"],
[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  font-weight: 600;
}

.block-sliders .item-content .item-info.vertical-middle {
  top: 50%;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-bottom-style: solid;
}

.block-sliders .item-content .item-info.vertical-middle {
  transform: translateY(-50%);
}

.block-sliders .item-content .item-info.vertical-middle {
  transform: translateY(-50%);
}

[class~="block-products"] ul[class~="products-list"] li {
  border-bottom-color: #e1e1e1;
}

[class~="block-sliders"] i[class~="slick-arrow"]:hover,
.block-sliders.color-primary i.slick-arrow {
  border-top-color: #cb8161;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="vertical-bottom"],
[class~="block-sliders"] [class~="slick-dots"],
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  bottom: 0;
}

[class~="block-product-cats"] ul li a {
  font-weight: 400;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  flex-flow: column;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  width: 100% !important;
}

[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 45px;
}

.block-product-filter ul.filter-items li:hover span {
  border-top-width: 0.75pt;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  display: inline-flex;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  right: 5.3125pc;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  left: auto;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-right-style: solid;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  border-top-style: solid;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="horizontal-start"] {
  justify-content: flex-start;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="product-content"]
  [class~="product-title"] {
  font-size: 13.5pt;
}

.block-sliders .item-content .item-info.horizontal-center {
  justify-content: center;
}

[class~="block-product-cats"] ul li a {
  display: flex;
}

[class~="block-sliders"][class~="nav-vertical"] [class~="slick-dots"] {
  width: auto;
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-right"]:before {
  font-family: icomoon;
}

.block-sliders.color-primary i.slick-arrow,
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"],
[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a:hover {
  color: #cb8161;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"][class~="horizontal-end"] {
  justify-content: flex-end;
}

[class~="block-sliders"] [class~="slick-dots"] {
  z-index: 99;
}

[class~="block-banners"] [class~="block-widget-banner"] {
  position: relative;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  background: rgba(0, 0, 0, 0);
}

[class~="block-sliders"] i[class~="slick-arrow"],
[class~="block-product-cats"] ul li a:hover {
  color: #fff;
}

[class~="block-products"] ul[class~="products-list"] li {
  border-image: none;
}

[class~="block-map"] iframe {
  border-left-width: medium;
}

[class~="block-map"] iframe {
  border-bottom-width: medium;
}

[class~="block-product-cats"] ul li [class~="count"] {
  width: 1.5pc;
}

[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  margin-bottom: 1.5625pc;
}

.block-sliders.nav-center .slick-dots,
[class~="block-products"] [class~="btn-all"] {
  text-align: center;
}

.block-sliders.nav-center .slick-dots {
  padding-left: 0pt;
}

[class~="block-sliders"] i[class~="slick-arrow"][class~="fa-angle-left"] {
  left: 40px;
}

.block-sliders.nav-center .slick-dots {
  padding-bottom: 40px;
}

.block-sliders.nav-center .slick-dots,
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-right: 0px;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  margin-left: 0;
}

.block-sliders.nav-center .slick-dots,
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-top: 0in;
}

[class~="block-sliders"][class~="color-white"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  color: #fff;
}

[class~="block-product-cats"] ul li [class~="count"] {
  height: 1.5pc;
}

[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-left: 125px;
}

[class~="block-sliders"] i[class~="slick-arrow"][class~="fa-angle-right"] {
  right: 40px;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  left: 0;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  margin-bottom: 0;
}

[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  padding-bottom: 52.5pt;
}

[class~="block-post-cats"] ul li,
[class~="block-banners"][class~="layout-1"] [class~="section-column"] img {
  display: block;
}

[class~="block-product-cats"] ul li a {
  justify-content: space-between;
}

[class~="block-product-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  font-size: 0.208333333in;
}

.block-sliders .item-content .item-info .title-slider {
  font-size: 60pt;
}

.block-sliders .item-content .item-info .title-slider {
  font-weight: 600;
}

.block-sliders .item-content .item-info .title-slider {
  margin-bottom: 15px;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  margin-right: 0;
}

[class~="block-banners"][class~="layout-1"] [class~="section-column"] img {
  width: 100%;
}

.block-sliders .item-content .item-info .title-slider {
  margin-top: 0;
}

[class~="block-banners"][class~="layout-1"] [class~="section-column"] img {
  max-width: 100%;
}

[class~="block-sliders"] i[class~="slick-arrow"] {
  margin-top: 0;
}

.block-product-filter ul.filter-items li:hover span {
  border-left-style: solid;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  position: absolute;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  content: "";
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  background: #fff;
}

.block-sliders .item-content .item-info .title-slider {
  line-height: 1.1;
}

[class~="block-post-cats"] ul li {
  margin-bottom: 7.5pt;
}

[class~="block-products"] [class~="nav-tabs"] {
  margin-bottom: 30pt;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  right: 0;
}

[class~="block-products"] [class~="nav-tabs"][class~="align-left"],
[class~="block-sliders"][class~="nav-left"] [class~="slick-dots"] {
  text-align: left;
}

[class~="block-banners"][class~="layout-1"] [class~="section-column"] {
  max-width: 36.75%;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-right-width: 0.75pt;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  top: 0;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  background: #e1e1e1;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"],
[class~="block-post-archives"] ul li {
  margin-bottom: 3.75pt;
}

[class~="block-product-cats"] ul li [class~="count"],
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-weight: 400;
}

.block-sliders .item-content .item-info .description-slider,
[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  color: #000;
}

[class~="block-banners"][class~="layout-1"] [class~="section-column"] {
  flex: 0 0 36.75%;
}

.block-products.slider .content-product-list {
  position: relative;
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-right"]:before {
  font-size: 0.15625in;
}

[class~="block-products"] [class~="btn-all"] a {
  padding-left: 30pt;
}

[class~="block-sliders"][class~="color-primary"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="title-slider"] {
  color: #cb8161;
}

.block-products .nav-tabs .nav-item {
  display: inline-block;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  left: 50%;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-size: 0.1875in;
}

[class~="block-products"] [class~="nav-tabs"] {
  border-bottom-width: medium;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-size: 1rem;
}

[class~="block-products"] [class~="nav-tabs"] {
  border-bottom-style: none;
}

.block-product-filter ul.filter-items li:hover span {
  border-bottom-style: solid;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  width: 0.0625pc;
}

.block-products .nav-tabs .nav-item {
  padding-left: 1.5625pc;
}

[class~="block-map"] iframe {
  border-right-width: medium;
}

[class~="block-products"] [class~="nav-tabs"] {
  border-bottom-color: currentColor;
}

.block-products .nav-tabs .nav-item,
[class~="block-products"] [class~="btn-all"] a {
  padding-bottom: 0;
}

[class~="block-products"] [class~="nav-tabs"] {
  border-image: none;
}

.block-products .nav-tabs .nav-item {
  padding-right: 1.5625pc;
}

.block-products .nav-tabs .nav-item,
[class~="block-products"] [class~="btn-all"] a {
  padding-top: 0;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  height: 100%;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-weight: 500;
}

[class~="block-products"] [class~="btn-all"] a {
  padding-right: 30pt;
}

.block-product-filter ul.filter-items li:hover span {
  border-right-style: solid;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="rating"]
  [class~="star"]:after {
  font-size: 7.5pt;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  letter-spacing: 3px;
}

.block-products .nav-tabs .nav-item {
  margin-left: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-top-width: 0.75pt;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  transform: translateX(-50%);
}

[class~="block-products"] [class~="btn-all"] a,
[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-right"]:before {
  transition: all 0.3s ease;
}

[class~="block-products"] [class~="nav-tabs"] {
  display: block;
}

.block-products .nav-tabs .nav-item {
  margin-bottom: 0;
}

[class~="block-products"] [class~="btn-all"] a {
  transition: all 0.3s ease;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  transform: translateX(-50%);
}

[class~="block-product-cats"] [class~="item-product-cat-content"],
[class~="block-products"] [class~="nav-tabs"] {
  text-align: center;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  transform: rotate(45deg);
}

.block-sliders .item-content .item-info .description-slider {
  font-size: 20px;
}

.block-products .nav-tabs .nav-item {
  margin-right: 0;
}

.block-sliders .item-content .item-info .description-slider {
  margin-bottom: 22.5pt;
}

.block-products .nav-tabs .nav-item {
  margin-top: 0;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:before {
  transform: rotate(45deg);
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  margin-bottom: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  border-radius: 15pt;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  margin-bottom: 15px;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"],
[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="product-content"]
  [class~="product-title"] {
  margin-top: 0;
}

[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"],
.block-sliders.layout-5 .item-content .item-info .subtitle-slider {
  font-family: "Cormorant Garamond", Sans-serif;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  bottom: 0;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  content: none;
}

.block-products ul.products-list {
  padding-left: 0;
}

[class~="block-product-cats"] ul li a {
  align-items: center;
}

[class~="block-product-cats"] i[class~="slick-arrow"]:hover {
  background: #cb8161;
}

.block-products ul.products-list {
  list-style-type: none;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"],
.block-product-cats.slider .product-cats-list,
.block-products .nav-tabs .nav-item,
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"] {
  position: relative;
}

[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"],
.block-sliders.layout-5 .item-content .item-info .subtitle-slider {
  font-size: 60pt;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  font-size: 11px;
}

[class~="block-product-cats"] i[class~="slick-arrow"]:hover {
  border-left-color: #cb8161;
}

.block-sliders.layout-5 .item-content .item-info .subtitle-slider,
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  font-weight: 600;
}

[class~="block-product-cats"] i[class~="slick-arrow"]:hover {
  border-bottom-color: #cb8161;
}

[class~="block-video"] [class~="video-container"] {
  transition: opacity 1s;
}

[class~="block-map"] iframe {
  border-top-width: medium;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  text-transform: uppercase;
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  line-height: 0.5in;
}

.block-sliders.layout-5 .item-content .item-info .subtitle-slider,
[class~="block-sliders"][class~="layout-4"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"] {
  line-height: 0.833333333in;
}

[class~="block-video"] [class~="video-container"] {
  transition: opacity 1s;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-bottom: 7px;
}

.block-product-cats .item-product-cat-content .item-image img,
[class~="block-post-tags"] ul li,
.block-feature .box .box-icon span {
  display: inline-block;
}

[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-left: 0.364583333in;
}

[class~="block-product-cats"] i[class~="slick-arrow"]:hover {
  border-right-color: #cb8161;
}

[class~="block-video"] [class~="video-container"] {
  transition: opacity 1s;
}

[class~="block-product-cats"] i[class~="slick-arrow"]:hover {
  border-top-color: #cb8161;
}

[class~="block-video"] [class~="video-container"] {
  pointer-events: none;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  transform: translateY(-50%);
}

[class~="block-sliders"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  letter-spacing: 3px;
}

[class~="block-product-cats"][class~="layout-3"] [class~="item-product-cat"],
[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-bottom: 0;
}

[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"] {
  padding-right: 0.364583333in;
}

[class~="block-product-cats"][class~="round-border"]
  [class~="item-product-cat"],
[class~="block-product-cats"][class~="layout-3"] [class~="item-product-cat"] {
  padding-top: 0;
}

.block-banners.layout-1 .section-column.center {
  max-width: 26.5%;
}

.block-banners.layout-1 .section-column.center {
  flex: 0 0 26.5%;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  justify-content: flex-start;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}

[class~="block-product-cats"][class~="layout-3"] [class~="item-product-cat"] {
  padding-left: 0.3125pc;
}

[class~="block-product-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-product-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-sliders"][class~="color-white"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="subtitle-slider"],
[class~="block-product-cats"] i[class~="slick-arrow"]:hover {
  color: #fff;
}

[class~="block-video"] [class~="video-container"] {
  height: 100%;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  width: 0;
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-left"]:before {
  content: "";
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  width: 63.75pt;
}

.block-products ul.products-list {
  padding-top: 0.052083333in;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="product-content"]
  [class~="product-title"] {
  margin-bottom: 0;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-video"] [class~="video-container"],
.block-product-cats .item-product-cat-content .item-image img {
  width: 100%;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  bottom: 0;
}

.block-sliders.layout-5 .item-content .item-info .subtitle-slider {
  margin-bottom: 15px;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}

.block-feature .box .box-icon span {
  font-size: 30px;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  top: auto;
}

[class~="block-product-cats"][class~="layout-3"] [class~="item-product-cat"] {
  padding-right: 0.3125pc;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-video"] [class~="video-container"],
.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  position: absolute;
}

.block-product-filter ul.filter-items li:hover span {
  border-top-style: solid;
}

[class~="block-product-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  transform: translateY(-50%);
}

[class~="block-product-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-video"] [class~="video-container"] {
  left: 0;
}

.block-sliders.layout-3 .item-content .item-info .description-slider {
  color: #cb8161;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-width: medium;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-width: medium;
}

[class~="block-map"] iframe {
  border-left-style: none;
}

[class~="block-product-cats"][class~="layout-4"] [class~="slick-list"] {
  margin-left: -0.104166667in;
}

[class~="block-video"] [class~="video-container"] {
  top: 0;
}

.block-video {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-left: 45px;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  content: "";
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 50px;
}

[class~="block-product-cats"][class~="layout-4"] [class~="slick-list"] {
  margin-bottom: 0;
}

[class~="block-map"] iframe {
  border-bottom-style: none;
}

.block-sliders.layout-3 .item-content .item-info .description-slider,
[class~="block-post-tags"] ul li {
  font-size: 14px;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-left: 0;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-right: 11.25pt;
}

.block-feature .box .box-icon span {
  line-height: 1;
}

.block-feature .box .box-icon span {
  transition: all 0.3s;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  height: 0.0625pc;
}

.block-sliders.layout-3 .item-content .item-info .description-slider {
  margin-bottom: 2.1875pc;
}

.block-feature .box .box-icon span {
  transition: all 0.3s;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0px;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-bottom: 0.125pc;
}

.block-banners.layout-1 .block-widget-banner .button {
  letter-spacing: 0.125pc;
}

.block-product-filter ul.filter-items li:hover span {
  border-left-color: #000;
}

.block-product-filter ul.filter-items li:hover span {
  border-bottom-color: #000;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-right-width: medium;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  transition: all 0.3s ease;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  width: 0.364583333in;
}

[class~="block-sliders"][class~="color-white"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="description-slider"] {
  color: #fff;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="banner-image-description"] {
  margin-bottom: 9pt;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  height: 35px;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"],
.block-banners.layout-1 .block-widget-banner .button {
  line-height: normal;
}

.block-feature .box .box-icon span {
  transition: all 0.3s;
}

.block-feature .box .box-icon span {
  color: #818a91;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-right: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a,
.block-banners.layout-1 .block-widget-banner .button {
  border-left-style: solid;
}

.block-video {
  padding-left: 11.25pt;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-left-width: 0.75pt;
}

.block-banners.layout-1 .block-widget-banner .button {
  border-bottom-style: solid;
}

.block-banners.layout-1 .block-widget-banner .button {
  border-right-style: solid;
}

[class~="block-product-cats"] i[class~="slick-arrow"],
.block-banners.layout-1 .block-widget-banner .button {
  border-bottom-width: 0.75pt;
}

[class~="block-product-cats"] ul li [class~="count"],
.block-feature .box .box-icon span,
[class~="block-video"] [class~="video-caption"] {
  text-align: center;
}

[class~="block-product-cats"][class~="layout-4"] [class~="slick-list"] {
  margin-right: -0.104166667in;
}

[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"]:before {
  transition: all 0.3s ease;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-width: medium;
}

.block-posts .post-item .post-image img {
  max-width: 90px;
}

[class~="block-posts"][class~="slider"] [class~="post-image"] img {
  backface-visibility: hidden;
}

[class~="block-product-cats"][class~="layout-4"] [class~="slick-list"],
[class~="block-product-cats"][class~="layout-5"] [class~="row"] {
  margin-top: 0;
}

[class~="block-video"] [class~="video-container"] {
  overflow: hidden;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  width: 0;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-product-cats"][class~="layout-5"] [class~="section-column"] {
  padding-top: 0;
}

[class~="block-product-cats"] ul li [class~="count"] {
  background: #f1f1f1;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-right-width: 0.75pt;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  line-height: 33pt;
}

.block-product-cats.layout-4 .item-product-cat {
  margin-bottom: 15pt;
}

.block-banners.layout-1 .block-widget-banner .button {
  border-top-style: solid;
}

[class~="block-video"] [class~="video-container"] {
  z-index: 0;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-top-width: 0.75pt;
}

[class~="block-posts"][class~="slider"] [class~="post-image"] img {
  filter: alpha(opacity=100);
}

.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a {
  bottom: 0.260416667in;
}

[class~="block-product-cats"][class~="layout-5"] [class~="row"] {
  margin-left: -0.46875pc;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-left: 1.875pc;
}

.block-banners.layout-1 .block-widget-banner .button {
  border-left-width: 0in;
}

[class~="block-video"] [class~="video-container"] {
  direction: ltr;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  top: auto;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  height: 1px;
}

[class~="block-product-cats"] ul li [class~="count"] {
  line-height: 1.5pc;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  letter-spacing: 0.1875pc;
}

[class~="block-posts"][class~="slider"] [class~="post-image"] img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}

[class~="block-product-cats"][class~="layout-5"] [class~="row"] {
  margin-bottom: 0;
}

.block-product-cats.round-border .item-product-cat-content .item-image img {
  border-radius: 50% 50% 50% 50%;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a:hover {
  border-left-color: #cb8161;
}

.block-banners.layout-1 .block-widget-banner .button {
  border-right-width: 0pc;
}

[class~="block-product-cats"][class~="layout-5"] [class~="row"] {
  margin-right: -0.46875pc;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a,
[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"],
[class~="block-video"] [class~="video-caption"] {
  position: relative;
}

.block-video {
  padding-bottom: 191.25pt;
}

.block-banners.layout-1 .block-widget-banner .button {
  border-top-width: 0in;
}

.block-banners.layout-1 .block-widget-banner .button {
  padding-left: 0pt;
}

[class~="block-product-cats"] i[class~="slick-arrow"],
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-left-style: solid;
}

[class~="block-product-cats"][class~="layout-5"] [class~="section-column"] {
  padding-left: 0.078125in;
}

[class~="block-product-cats"][class~="layout-5"] [class~="section-column"],
[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-bottom: 0;
}

.block-banners.layout-1 .block-widget-banner .button {
  padding-bottom: 3.75pt;
}

[class~="block-product-cats"][class~="layout-5"] [class~="section-column"] {
  padding-right: 0.078125in;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  background: #cb8161;
}

[class~="block-post-tags"] ul li {
  border-left-width: 0.010416667in;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  bottom: 0;
}

[class~="block-posts"][class~="slider"] [class~="post-image"] img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}

[class~="block-product-cats"] ul li [class~="count"] {
  font-size: 0.875pc;
}

[class~="block-video"] [class~="video-caption"] [class~="caption-title"] {
  color: #fff;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-right: 1.875pc;
}

[class~="block-product-cats"] i[class~="slick-arrow"],
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"],
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-bottom-style: solid;
}

.block-banners.layout-1 .block-widget-banner .button {
  padding-right: 0pt;
}

.block-banners.layout-1 .block-widget-banner .button {
  padding-top: 0in;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a {
  padding-bottom: 3.75pt;
}

.block-video {
  padding-right: 0.9375pc;
}

[class~="block-video"] [class~="video-caption"] [class~="caption-title"] {
  font-size: 52.5pt;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  justify-content: center;
}

[class~="block-sliders"]
  i[class~="slick-arrow"][class~="fa-angle-right"]:before {
  content: "";
}

[class~="block-feature"] [class~="box"] {
  display: box;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-right-style: solid;
}

[class~="block-post-tags"] ul li {
  border-bottom-width: 0.010416667in;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}

.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a,
[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
.block-lookbook .lookbook-content .item > img {
  width: 100%;
}

[class~="block-video"] [class~="video-caption"] [class~="caption-title"],
.block-video .video-caption .button {
  font-weight: 600;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span,
[class~="block-product-cats"] ul li [class~="count"] {
  display: block;
}

[class~="block-video"] [class~="video-caption"] [class~="caption-title"] {
  margin-top: 0;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  top: 50%;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-icon"][class~="icon-2"]
  span {
  font-size: 1.25pc;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  content: "";
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-top-style: solid;
}

[class~="block-posts"] ul[class~="posts-list"] li {
  display: flex;
}

[class~="block-video"] [class~="video-caption"] [class~="caption-title"] {
  margin-bottom: 25px;
}

[class~="block-feature"] [class~="box"] {
  display: flexbox;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-icon"][class~="icon-3"]
  span {
  font-size: 1.5625pc;
}

[class~="block-feature"] [class~="box"] {
  display: flex;
}

[class~="block-post-tags"] ul li {
  border-right-width: 0.010416667in;
}

.block-video .video-caption .button {
  line-height: 31.5pt;
}

.block-product-filter ul.filter-items li:hover span {
  border-right-color: #000;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(-50%);
}

.block-feature .box .box-icon {
  display: inline-box;
}

.block-feature.layout-2 .box .box-icon span {
  font-size: 3.125pc;
}

[class~="block-sliders"][class~="layout-2"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-top: 0;
}

.block-feature .box .box-icon {
  display: inline-flexbox;
}

.block-feature .box .box-icon {
  display: inline-flex;
}

.block-feature .box .box-icon {
  box-flex: 0;
}

[class~="block-posts"] ul[class~="posts-list"] li {
  padding-bottom: 1.125pc;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  top: 0;
}

.block-feature .box .box-icon {
  flex: 0 0 auto;
}

[class~="block-map"] iframe {
  border-right-style: none;
}

[class~="block-post-cats"] ul li a {
  color: #868686;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-left-color: #cecece;
}

.block-feature .box .box-icon {
  flex: 0 0 auto;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span {
  font-weight: 700;
}

.block-feature .box .box-icon {
  margin-right: 10px;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-left-width: 0.010416667in;
}

[class~="block-feature"][class~="layout-2"] [class~="box"] {
  display: block;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  bottom: auto;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  left: 50%;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-bottom-color: #cecece;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  position: absolute;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span {
  font-size: 0.6875pc;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  line-height: 33pt;
}

[class~="block-feature"] [class~="box"] {
  box-align: center;
}

[class~="block-feature"] [class~="box"] {
  flex-align: center;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}

.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a,
[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-left: 15px;
}

[class~="block-map"] iframe {
  border-top-style: none;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span,
[class~="block-feature"][class~="layout-2"] [class~="box"] {
  text-align: center;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"],
[class~="block-feature"] [class~="box"] {
  align-items: center;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-bottom: 0px;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-right-color: #cecece;
}

[class~="block-posts"] ul[class~="posts-list"] li {
  margin-bottom: 0.1875in;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  transform: translateX(-50%);
}

[class~="block-feature"][class~="layout-2"] [class~="box"] {
  max-width: 15.3125pc;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  transform: translateX(-50%);
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"][class~="text-center"],
[class~="block-feature"] [class~="box"] {
  justify-content: center;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div a,
[class~="block-posts"] [class~="post-item"] [class~="post-image"] {
  display: inline-block;
}

[class~="block-posts"] ul[class~="posts-list"] li,
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-bottom-width: 0.010416667in;
}

[class~="block-feature"][class~="layout-2"] [class~="box"] {
  margin-left: auto;
}

[class~="block-map"] iframe {
  border-left-color: currentColor;
}

[class~="block-feature"][class~="layout-2"] [class~="box"] {
  margin-bottom: 0;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-right-width: 0.010416667in;
}

.block-intro.layout-4 .nav-tabs .nav-item a.active:before {
  opacity: 1;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-left: 1.875pc;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  font-family: icomoon;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"],
.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a {
  padding-right: 11.25pt;
}

[class~="block-feature"][class~="layout-2"] [class~="box"] {
  margin-right: auto;
}

[class~="block-posts"] [class~="posts-list"],
.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a,
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"],
[class~="block-lookbook"][class~="layout-2"] [class~="row"] > div {
  padding-bottom: 0;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0in;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-top-color: #cecece;
}

[class~="block-feature"][class~="layout-2"] [class~="box"] {
  margin-top: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"] {
  width: 50%;
}

[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  padding-right: 1.875pc;
}

.block-posts .post-item:last-child {
  margin-bottom: 0;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  font-size: 0.125in;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-right-style: solid;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"] {
  align-content: center;
}

[class~="block-map"] iframe {
  border-bottom-color: currentColor;
}

[class~="block-posts"] [class~="post-item"] [class~="post-image"] {
  max-width: 5.625pc;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  font-weight: 700;
}

[class~="block-lookbook"][class~="layout-2"] [class~="row"] > div,
[class~="block-posts"] [class~="posts-list"],
[class~="block-sliders"][class~="layout-3"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"],
.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a {
  padding-top: 0;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div a {
  position: relative;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  color: #cb8161;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-top-width: 0.010416667in;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:before {
  margin-left: 0.125pc;
}

.block-intro.layout-4 .nav-tabs .nav-item a.active:before {
  visibility: visible;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-image: none;
}

[class~="block-posts"] [class~="post-item"] [class~="post-image"] {
  flex: 0 0 0.9375in;
}

[class~="block-posts"] [class~="post-item"] [class~="post-content"] {
  padding-left: 0.15625in;
}

[class~="block-sliders"][class~="layout-5"]
  [class~="item-content"]
  [class~="item-info"]
  [class~="button-slider"] {
  line-height: 44px;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div a {
  overflow: hidden;
}

[class~="block-lookbook"][class~="layout-2"] [class~="row"] > div,
[class~="block-posts"] [class~="posts-list"] {
  padding-left: 0;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-top-style: solid;
}

[class~="block-post-cats"] ul li a {
  font-weight: 400;
}

.block-banners.layout-1 .block-widget-banner .banner-wrapper-infor .link-title,
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  input:checked
  ~ [class~="checkmark"]:after {
  display: block;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span {
  text-transform: uppercase;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-left-width: 0pc;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"] {
  padding-left: 0.9375pc;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"] {
  padding-bottom: 0px;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-radius: 50%;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span {
  color: #000;
}

[class~="block-posts"][class~="slider"] [class~="post-image"]:hover img {
  filter: alpha(opacity=80);
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-radius: 50%;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-radius: 50%;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-left-style: none;
}

[class~="block-posts"][class~="slider"] [class~="post-image"]:hover img {
  transform: scale3d(1.1, 1.1, 1);
}

[class~="block-lookbook"][class~="layout-2"] [class~="row"] > div,
[class~="block-posts"] [class~="posts-list"] {
  padding-right: 0;
}

.block-product-filter ul.filter-items li:hover span {
  border-top-color: #000;
}

[class~="block-posts"] [class~="post-item"] [class~="post-content"] {
  margin-top: -2px;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"] {
  padding-top: 0px;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="read-more"]:before {
  background: #868686;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:hover:before {
  color: #fff;
}

[class~="block-posts"] ul[class~="posts-list"] li {
  border-bottom-style: solid;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  justify-content: flex-end;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-radius: 50%;
}

.block-posts.slider .post-image,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="testimonial-icon"]
  span {
  margin-bottom: 11.25pt;
}

[class~="block-posts"] ul[class~="posts-list"] li {
  border-bottom-color: #e1e1e1;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="testimonial-icon"]
  span {
  font-size: 3.125pc;
}

[class~="block-map"] iframe {
  border-right-color: currentColor;
}

.block-product-filter ul.filter-items li:hover span,
[class~="block-posts"] ul[class~="posts-list"] li {
  border-image: none;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="testimonial-icon"]
  span {
  color: #ad8546;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  border-radius: 50%;
}

[class~="block-posts"] [class~="posts-list"] {
  list-style: none;
}

[class~="block-posts"] [class~="post-item"],
[class~="block-posts"][class~="slider"] [class~="post-date-wrap"],
[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  line-height: 2.25pc;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 0.3125in;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="testimonial-icon"]
  span {
  display: inline-block;
}

.block-testimonial i.slick-arrow.fa-angle-left {
  left: 0;
}

.block-posts.slider .post-image {
  overflow: hidden;
}

[class~="block-testimonial"] i[class~="slick-arrow"][class~="fa-angle-right"] {
  right: 0;
}

[class~="block-product-cats"] i[class~="slick-arrow"],
.block-banners.layout-2 .block-widget-banner .banner-wrapper-infor .content {
  text-align: center;
}

.block-posts.slider .post-image,
.block-intro.layout-5 .intro-icon svg {
  position: relative;
}

[class~="block-instagram"] a[class~="instagram"]:after,
[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-posts"][class~="slider"] [class~="post-date-wrap"],
.block-video .video-container iframe {
  position: absolute;
}

[class~="block-posts"] [class~="post-item"] {
  margin-bottom: 1.25pc;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  top: 0.104166667in;
}

.block-intro.layout-5 .intro-icon svg {
  transform: rotate(0deg);
}

.block-intro.layout-5 .intro-icon svg {
  width: 1em;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  left: 10px;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  color: #adadad;
}

.block-video {
  padding-top: 14.375pc;
}

[class~="block-product-cats"] i[class~="slick-arrow"] {
  font-size: 7.5pt;
}

[class~="block-product-cats"] i[class~="slick-arrow"],
[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  background: #fff;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  text-transform: uppercase;
}

[class~="block-lookbook"] [class~="background-overlay"] {
  background-color: #e8dad0;
}

[class~="block-lookbook"] [class~="background-overlay"] {
  height: 100%;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(0);
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  border-radius: 20px;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  width: 0.59375in;
}

.block-intro.layout-5 .intro-icon svg {
  height: 1em;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  height: 42.75pt;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
.block-newsletter .newsletter-content input[type="email"],
[class~="block-lookbook"] [class~="background-overlay"] {
  width: 100%;
}

[class~="block-map"] iframe {
  border-top-color: currentColor;
}

.block-intro.layout-5 .intro-icon svg {
  display: block;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  letter-spacing: 2px;
}

[class~="block-lookbook"] [class~="background-overlay"] {
  top: 0;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div a {
  vertical-align: top;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-bottom-width: 0.75pt;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0.083333333in;
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="icon"]
  [class~="wrap"]
  svg {
  width: 0.3125in;
}

[class~="block-banners"][class~="layout-1"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  top: auto;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  bottom: 0;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  border-radius: 50%;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  left: auto;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  border-radius: 50%;
}

.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a,
.block-video .video-container iframe,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  left: 50%;
}

.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a {
  transform: translateX(-50%);
}

.block-newsletter .newsletter-content input[type="email"] {
  line-height: 48px;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  /* right: -8%; */
  right: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-left: 0pc;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  border-radius: 50%;
}
[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  background-color: #ffffff85;
  padding: 20px;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  /* padding-bottom: 2.260416667in; */
  padding-bottom: 60px;
}

.block-newsletter .newsletter-content input[type="email"] {
  height: 0.5in;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  border-radius: 50%;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-right: 0pt;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"],
[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  color: #fff;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 27pt;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-right-width: 0.75pt;
}

[class~="block-post-tags"] ul li,
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-left-style: solid;
}

.block-video .video-container iframe {
  top: 50%;
}
.post-tags-list ul li {
  padding: 0px !important;
}
.error {
  color: red;
  margin-bottom: 0px;
  font-size: 12px;
}
[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  border-radius: 50%;
}

[class~="block-instagram"] a[class~="instagram"]:after,
[class~="block-lookbook"] [class~="background-overlay"] {
  left: 0;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 1.5pt;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-left-width: 0;
}

[class~="block-map"] iframe {
  border-image: none;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-bottom-width: 0;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  align-items: center;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-right-width: 0;
}

.block-product-cats.layout-4
  .item-product-cat-content
  .product-cat-content-info
  .item-title
  a {
  transform: translateX(-50%);
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  content: "|";
}

.block-video .video-container iframe {
  transform: translate(-50%, -50%);
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  justify-content: center;
}

[class~="block-posts"][class~="slider"] [class~="post-date-wrap"] {
  z-index: 2;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  padding-top: 0pt;
}

[class~="block-lookbook"] [class~="background-overlay"][class~="background-2"] {
  background-color: #85786b;
}

[class~="block-lookbook"] [class~="lookbook-item"]:last-child {
  margin-bottom: 0;
}

[class~="block-lookbook"] [class~="background-overlay"] {
  position: absolute;
}

[class~="block-lookbook"] [class~="lookbook-item"] {
  margin-bottom: 0.520833333in;
}

[class~="block-post-tags"] ul li {
  border-top-width: 0.010416667in;
}

[class~="block-lookbook"] [class~="lookbook-content"] [class~="item"],
.block-video,
[class~="block-intro"]
  [class~="intro-item"]
  [class~="icon"]
  [class~="wrap"]
  svg,
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  position: relative;
}

.block-lookbook .item-lookbook .number-lookbook {
  font-size: 0;
}

.block-lookbook .item-lookbook .number-lookbook {
  width: 2.125pc;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  display: inline-block;
}

[class~="block-post-cats"] ul li a,
[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  display: flex;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-top-width: 0;
}

.block-lookbook .item-lookbook .number-lookbook {
  height: 2.125pc;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-left: 3.75pt;
}

[class~="block-lookbook"] [class~="lookbook-content"] {
  vertical-align: top;
}

.block-newsletter .newsletter-content input[type="email"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-bottom-style: none;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-left-style: none;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-bottom: 0;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-newsletter .newsletter-content input[type="email"] {
  border-right-style: none;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  transform: translateX(-50%);
}

[class~="block-map"] iframe {
  max-width: 100%;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
[class~="block-lookbook"] [class~="lookbook-content"],
[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"],
[class~="block-instagram"] a[class~="instagram"]:after {
  width: 100%;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="rating"]
  [class~="star"]:after {
  letter-spacing: 2.25pt;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  margin-top: -40px;
}

.block-video .video-container iframe {
  transform: translate(-50%, -50%);
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="icon"]
  [class~="wrap"]
  svg {
  height: 22.5pt;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-right: 6pt;
}

[class~="block-lookbook"] [class~="item-lookbook"],
.block-lookbook .item-lookbook .number-lookbook {
  line-height: 34px;
}

.block-video .video-container iframe {
  width: 100vw;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  width: 34px !important;
}

.block-lookbook .item-lookbook .number-lookbook,
[class~="block-lookbook"] [class~="item-lookbook"] {
  border-radius: 50%;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  backface-visibility: hidden;
}

.block-lookbook .item-lookbook .number-lookbook {
  border-radius: 50%;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-bottom-width: 0.0625pc;
}

.block-video .video-container iframe {
  height: 56.25vw;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  margin-top: 0;
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  font-size: 12pt;
}

.block-lookbook .item-lookbook .number-lookbook {
  border-radius: 50%;
}

.block-video .video-container iframe {
  min-height: 100vh;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  filter: alpha(opacity=100);
}

.block-lookbook .item-lookbook .number-lookbook {
  border-radius: 50%;
}

.block-newsletter .newsletter-content input[type="email"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-style: none;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-newsletter .newsletter-content input[type="email"] {
  border-left-color: currentColor;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-bottom-color: currentColor;
}

.block-lookbook .item-lookbook .number-lookbook {
  border-radius: 50%;
}

.block-lookbook .item-lookbook .number-lookbook {
  background: #fff;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  height: 25.5pt !important;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a:hover {
  border-bottom-color: #cb8161;
}

.block-lookbook .item-lookbook .number-lookbook {
  display: inline-block;
}

[class~="block-lookbook"] [class~="item-lookbook"],
.block-lookbook .item-lookbook .number-lookbook,
[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  position: absolute;
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="icon"]
  [class~="wrap"]
  svg {
  display: block;
}

.block-lookbook .item-lookbook .number-lookbook {
  top: 0;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a {
  font-family: "Cormorant Garamond", Sans-serif;
}

.block-product-filter ul.filter-items li:hover span {
  color: #000;
}

.block-lookbook .item-lookbook .number-lookbook,
[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  left: 0;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  transform: translateX(-50%);
}

.block-newsletter .newsletter-content input[type="email"] {
  border-right-color: currentColor;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  top: 50%;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  transform: translateY(-50%);
}

[class~="block-banners"][class~="layout-2"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 0.177083333in;
}

.block-video .video-container iframe {
  min-width: 177.77vh;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"] {
  bottom: auto;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a {
  font-size: 1.25pc;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-top-color: currentColor;
}

[class~="block-post-tags"] ul li {
  border-bottom-style: solid;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a {
  font-weight: 600;
}

.block-lookbook .item-lookbook .number-lookbook,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  transition: all 0.3s ease;
}

.block-lookbook .item-lookbook .number-lookbook,
[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]
  a:before {
  transition: all 0.3s ease;
}

.block-lookbook .item-lookbook .content-lookbook {
  z-index: 9;
}

.block-newsletter .newsletter-content input[type="email"] {
  border-image: none;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"],
.block-lookbook .item-lookbook .content-lookbook {
  text-align: center;
}

.block-banners.layout-7 .block-widget-banner .banner-image {
  margin-bottom: 25px;
}

.block-newsletter .newsletter-content input[type="email"] {
  font-size: 0.9375pc;
}

[class~="block-banners"][class~="layout-3"]
  [class~="row"]
  [class~="section-column"]
  [class~="section-column-inner"] {
  width: 100%;
}

.block-newsletter .newsletter-content input[type="email"] {
  padding-left: 15pt;
}

.block-posts ul.posts-list,
[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:first-child {
  padding-left: 0;
}

[class~="block-post-tags"] ul li {
  border-right-style: solid;
}

.block-newsletter .newsletter-content input[type="email"] {
  padding-bottom: 0;
}

.block-newsletter .newsletter-content input[type="email"] {
  padding-right: 15pt;
}

.block-newsletter .newsletter-content input[type="email"] {
  padding-top: 0;
}

.block-lookbook .item-lookbook .content-lookbook {
  padding-left: 0.625pc;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-top-width: 0.75pt;
}

[class~="block-products"] [class~="nav-tabs"] [class~="nav-item"]:last-child {
  padding-right: 0;
}

[class~="block-instagram"] a[class~="instagram"]:after {
  opacity: 0;
}

[class~="block-post-cats"] ul li a {
  justify-content: space-between;
}

.block-newsletter .newsletter-content input[type="email"] {
  background: #fff;
}

[class~="block-instagram"] a[class~="instagram"]:after {
  visibility: hidden;
}

.block-products .nav-tabs .nav-item .nav-link {
  font-size: 1.375pc;
}

.block-lookbook .item-lookbook .content-lookbook {
  padding-bottom: 0.625pc;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"]
  [class~="post-comment"]:before {
  color: #e1e1e1;
}

.block-lookbook .item-lookbook .content-lookbook {
  padding-right: 0.625pc;
}

.block-products .nav-tabs .nav-item .nav-link,
[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a {
  color: #868686;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  color: #3c2147;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-right-width: 0pc;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-left-width: 0;
}

.block-products .nav-tabs .nav-item .nav-link {
  display: inline-block;
}

[class~="block-banners"][class~="banners-effect"] [class~="banners"] > div img {
  transition: opacity 1s ease 0s, transform 1s ease 0s;
}

[class~="block-products"] ul[class~="products-list"] li:last-child {
  padding-bottom: 0;
}

.block-lookbook .item-lookbook .content-lookbook {
  padding-top: 0.625pc;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-bottom-width: 0;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li a {
  font-size: 0.135416667in;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  border-radius: 50%;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-right-width: 0;
}

[class~="block-instagram"] a[class~="instagram"]:after {
  transition: opacity 0.3s ease;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-top-width: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 30pt;
}

.block-posts ul.posts-list {
  list-style-type: none;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  left: 0;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-left-style: none;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  border-radius: 50%;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child,
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-bottom-style: solid;
}

.block-posts ul.posts-list {
  padding-top: 0.3125pc;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child {
  font-size: 0.145833333in;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a {
  text-transform: uppercase;
}

.block-lookbook .item-lookbook:after {
  width: 2.5pc;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child {
  margin-bottom: 2px;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-bottom-style: none;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="text"]
  li
  span,
.block-lookbook .item-lookbook:after {
  height: 2.5pc;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-right-style: none;
}

.block-lookbook .item-lookbook:after,
.block-lookbook .item-lookbook .content-lookbook {
  position: absolute;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  border-radius: 0.208333333in;
}

.block-lookbook .item-lookbook .content-lookbook {
  width: 2.34375in;
}

.block-lookbook .item-lookbook .content-lookbook {
  box-shadow: -0.010416667in 0.3125pc 5px 0 rgba(182, 41, 41, 0.1);
}

.block-lookbook .item-lookbook:after {
  content: "";
}

.block-lookbook .item-lookbook:after {
  left: calc(50% - 20px);
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a,
[class~="block-lookbook"],
.block-products .nav-tabs .nav-item .nav-link,
.block-testimonial {
  position: relative;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child {
  line-height: 1.5pc;
}

[class~="block-posts"]
  ul[class~="posts-list"]
  li
  [class~="post-content"]
  [class~="post-title"] {
  font-size: 12.75pt;
}

.block-products .nav-tabs .nav-item .nav-link {
  padding-left: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-weight: 600;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  padding-left: 90pt;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  border-radius: 50%;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 5px;
}

[class~="block-post-tags"] ul li {
  border-top-style: solid;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-top-style: none;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  border-radius: 50%;
}

.block-banners.layout-3
  .block-widget-banner
  .banner-wrapper-infor
  .banner-image-description {
  margin-bottom: 0.1875in;
  color: #424141;
}

.block-lookbook .item-lookbook .content-lookbook {
  transition: all 0.2s ease;
}

.block-lookbook .item-lookbook .content-lookbook {
  transition: all 0.2s ease;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-left-color: currentColor;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a {
  padding-bottom: 0.625pc;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-bottom-color: currentColor;
}

.block-lookbook .item-lookbook:after {
  top: calc(50% - 20px);
}

.block-lookbook .item-lookbook:after,
[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child {
  border-bottom-width: 0.75pt;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  padding-bottom: 71.25pt;
}

[class~="block-intro"][class~="layout-4"]
  [class~="section-column"][class~="right"]
  img {
  transition: all 0.2s ease-in;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-right-color: currentColor;
}

[class~="block-instagram"] a[class~="instagram"]:after {
  transition: opacity 0.3s ease;
}

[class~="block-banners"][class~="layout-3"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="button"] {
  letter-spacing: 0.1875pc;
}

.block-lookbook .item-lookbook .content-lookbook {
  background: #fff;
}

[class~="block-map"] iframe {
  width: 100%;
}

.block-lookbook .item-lookbook .content-lookbook {
  display: none;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"],
[class~="block-lookbook"] [class~="item-lookbook"] {
  text-align: center;
}

[class~="block-intro"][class~="layout-4"]
  [class~="section-column"][class~="right"]
  img {
  transition: all 0.2s ease-in;
}

.block-products .nav-tabs .nav-item .nav-link {
  padding-bottom: 0.3125pc;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  max-width: 29.375pc;
}

.block-lookbook .item-lookbook:after {
  border-left-width: 0.010416667in;
}

.block-products .nav-tabs .nav-item .nav-link,
[class~="block-intro"][class~="layout-6"]
  [class~="section-column"]
  [class~="row"]
  > div {
  padding-right: 0;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  margin-left: auto;
}

.block-products .nav-tabs .nav-item .nav-link,
[class~="block-intro"][class~="layout-6"]
  [class~="section-column"]
  [class~="row"]
  > div {
  padding-top: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  padding-right: 22.5pt;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-top-color: currentColor;
}

[class~="block-post-cats"] ul {
  padding-left: 0;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-left-width: medium;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-bottom-width: medium;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-right-width: medium;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  padding-top: 0pt;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  transition: width 0.2s ease, height 0.2s ease;
}

.block-lookbook .lookbook-intro-wrap .title {
  color: #3c2147;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child {
  border-bottom-color: #e3e3e3;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-image: none;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-top-width: medium;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  margin-bottom: 0;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  transition: width 0.2s ease, height 0.2s ease;
}

[class~="block-instagram"] a[class~="instagram"]:before,
.block-intro.layout-4 .nav-tabs .nav-item a:before {
  position: absolute;
}

.block-lookbook .lookbook-intro-wrap .title {
  font-size: 30pt;
}

.block-lookbook .lookbook-intro-wrap .title {
  font-weight: 600;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-left-style: none;
}

[class~="block-intro"][class~="layout-6"]
  [class~="section-column"]
  [class~="row"]
  > div {
  padding-left: 0;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  margin-right: auto;
}

[class~="block-banners"][class~="layout-4"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"] {
  margin-top: 0;
}

.block-lookbook .lookbook-intro-wrap .title {
  margin-top: 0;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  display: inline-block;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li a {
  color: #868686;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-bottom-style: none;
}

.block-lookbook .lookbook-intro-wrap .title {
  margin-bottom: 8px;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-right-style: none;
}

[class~="block-parallax"] {
  background-position: center center;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-top-style: none;
}

[class~="block-intro"][class~="layout-6"]
  [class~="section-column"]
  [class~="row"]
  > div,
.block-newsletter .newsletter-content input[type="submit"] {
  padding-bottom: 0;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-right-style: solid;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="rating"]
  [class~="star"]:before {
  font-size: 7.5pt;
}

[class~="block-lookbook"]
  [class~="lookbook-intro-wrap"]
  [class~="description"] {
  margin-bottom: 1.25pc;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-products .nav-tabs .nav-item .nav-link {
  border-bottom-color: currentColor;
}

.block-newsletter .newsletter-content input[type="submit"] {
  padding-left: 1.5625pc;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  max-width: 23.625pc;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li a {
  font-weight: 400;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-left-color: currentColor;
}

.block-lookbook .item-lookbook:after {
  border-right-width: 0.010416667in;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:first-child
  [class~="banner-wrapper-infor"] {
  text-align: left;
}

[class~="block-lookbook"][class~="layout-2"] [class~="row"],
[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  margin-left: 0;
}

[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"],
[class~="block-lookbook"][class~="layout-2"] [class~="row"],
[class~="block-product-cats"][class~="round-border"]
  [class~="slick-sliders"]
  [class~="slick-list"] {
  margin-bottom: 0;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  z-index: 5;
}

[class~="block-newsletter"]
  [class~="newsletter-content"]
  input[type="submit"]:hover {
  background: rgba(0, 0, 0, 0.75);
}

[class~="block-instagram"] a[class~="instagram"]:before {
  left: 0;
}

[class~="block-lookbook"] [class~="item-lookbook"] {
  text-indent: -0.0625pc;
}

.block-newsletter .newsletter-content input[type="submit"] {
  padding-right: 1.5625pc;
}

[class~="block-post-tags"] ul li {
  border-left-color: #e5e5e5;
}

[class~="block-post-cats"] ul {
  list-style-type: none;
}

[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"],
[class~="block-lookbook"][class~="layout-2"] [class~="row"] {
  margin-right: 0;
}

[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"],
[class~="block-lookbook"][class~="layout-2"] [class~="row"],
[class~="block-product-cats"][class~="round-border"]
  [class~="slick-sliders"]
  [class~="slick-list"] {
  margin-top: 0;
}

[class~="block-testimonial"][class~="layout-2"] [class~="slick-sliders"] {
  max-width: none;
}

body[class~="home"][class~="title-2"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  color: #cb8161;
}

.block-newsletter .newsletter-content input[type="submit"] {
  padding-top: 0;
}

.block-newsletter .newsletter-content input[type="submit"],
.block-intro.layout-4 .nav-tabs .nav-item a:before {
  background: #000;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  background: #fff;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  content: "";
}

body[class~="home"][class~="title-2"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  font-size: 18pt;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
.block-newsletter .newsletter-content input[type="submit"] {
  letter-spacing: 2px;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"],
[class~="block-testimonial"] [class~="slick-sliders"] {
  margin-left: auto;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  width: 100px;
}

.block-product-filter ul.filter-items.color li .color.antique {
  background: #d4937d;
}

[class~="block-testimonial"] [class~="slick-sliders"],
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  margin-bottom: auto;
}

[class~="block-lookbook"] [class~="item-lookbook"],
.block-newsletter .newsletter-content input[type="submit"] {
  cursor: pointer;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"]
  span:first-child {
  border-image: none;
}

.block-testimonial .block-content {
  margin-top: -7.5pt;
}

[class~="block-post-archives"] ul {
  padding-left: 0;
}

[class~="block-testimonial"] [class~="slick-sliders"] {
  max-width: 890px;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  margin-right: auto;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-right-color: currentColor;
}

.block-newsletter .newsletter-content input[type="submit"] {
  position: relative;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  width: 100%;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-top-color: currentColor;
}

.block-products .nav-tabs .nav-item .nav-link {
  border-image: none;
}

.block-newsletter .newsletter-content input[type="submit"] {
  line-height: 48px;
}

[class~="block-product-cats"][class~="round-border"]
  [class~="slick-sliders"]
  [class~="slick-list"] {
  margin-left: -2.1875pc;
}

[class~="block-product-cats"][class~="round-border"]
  [class~="slick-sliders"]
  [class~="slick-list"] {
  margin-right: -2.1875pc;
}

[class~="block-products"] ul[class~="products-list"] li:last-child {
  margin-bottom: 0;
}

.block-lookbook .item-lookbook:after {
  border-top-width: 0.010416667in;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-size: 0.145833333in;
}

[class~="block-testimonial"][class~="layout-2"] [class~="testimonial-content"] {
  text-align: left;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  border-top-width: 0pc;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  margin-top: auto;
}

.block-newsletter .newsletter-content input[type="submit"] {
  height: 36pt;
}

.block-lookbook .item-lookbook:after {
  border-left-style: solid;
}

.block-testimonial .testimonial-content .item {
  padding-left: 11.25pt;
}

.block-newsletter .newsletter-content input[type="submit"] {
  top: 0;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-family: Lato, sans-serif;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"],
.block-testimonial .testimonial-content .item {
  padding-bottom: 0;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
body[class~="home"][class~="title-2"]
  [class~="block-newsletter"]
  [class~="newsletter-title"],
.block-newsletter .newsletter-content input[type="submit"],
[class~="block-banners"][class~="layout-7"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  text-transform: uppercase;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  height: 1.5pt;
}

body[class~="home"][class~="title-2"]
  [class~="block-newsletter"]
  [class~="newsletter-title"],
body[class~="home"][class~="title-3"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  font-weight: 600;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"][class~="bone"] {
  background: #e5d4c2;
}

.block-lookbook .item-lookbook:after {
  border-bottom-style: solid;
}

.block-posts.slider .post-content .read-more:hover:before {
  background: #cb8161;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-weight: 400;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  padding-left: 25px;
}

.block-newsletter .newsletter-content input[type="submit"] {
  font-size: 12px;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-top: 20px;
}

.block-testimonial .testimonial-content .item {
  padding-right: 11.25pt;
}

[class~="block-product-cats"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-bottom: 3.75pt;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  padding-right: 25px;
}

.block-testimonial .testimonial-content .item {
  padding-top: 0;
}

.block-testimonial.layout-2 .slick-arrow {
  font-size: 1.25pc;
}

[class~="block-products"] ul[class~="products-list"] li:last-child {
  border-bottom-width: 0;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  padding-top: 18.75pt;
}

[class~="block-intro"] [class~="section-column"][class~="left"] {
  width: 57.3%;
}

[class~="block-intro"] [class~="section-column"][class~="left"] {
  align-content: center;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="info"] {
  text-align: center;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a {
  color: #000;
}

body[class~="home"][class~="title-3"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  color: #cb8161;
}

body[class~="home"][class~="title-3"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  font-size: 0.416666667in;
}

[class~="block-intro"] [class~="section-column"][class~="left"] {
  align-items: center;
}

[class~="block-intro"] [class~="section-column"][class~="right"] {
  width: 100%;
}

.block-lookbook .item-lookbook:after {
  border-right-style: solid;
}

[class~="block-banners"][class~="layout-7"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-size: 26px;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  position: absolute;
}

.block-newsletter .newsletter-content input[type="submit"] {
  color: #fff;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  opacity: 0;
}

.block-newsletter .newsletter-content input[type="submit"] {
  font-weight: 500;
}

.block-newsletter .newsletter-content input[type="submit"] {
  transition: all 0.3s ease;
}

.block-lookbook .item-lookbook:after,
[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-top-style: solid;
}

.block-lookbook .item-lookbook:after {
  border-left-color: #fcfbfe;
}

.block-intro .intro-item.m-b-0 {
  margin-bottom: 0;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
.block-intro.layout-4 .nav-tabs .nav-item a:before {
  left: 50%;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  justify-content: flex-end;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  transform: translateX(-50%);
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  transform: translateX(-50%);
}

.block-newsletter .newsletter-content input[type="submit"] {
  transition: all 0.3s ease;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] {
  margin-right: 1.25pc;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-radius: 0 !important;
}

[class~="block-intro"] [class~="intro-btn"] [class~="button"] {
  line-height: 2.75pc;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"][class~="chestnut"] {
  background: #9d443e;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  bottom: 30pt;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-radius: 0 !important;
}

.block-lookbook .item-lookbook:after {
  border-bottom-color: #fcfbfe;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  font-family: Lato, sans-serif;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  bottom: 0;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-radius: 0 !important;
}

.block-lookbook .item-lookbook:after {
  border-right-color: #fcfbfe;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-radius: 0 !important;
}

body[class~="home"][class~="title-3"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  text-transform: none;
}

.block-newsletter .newsletter-content input[type="submit"] {
  border-radius: 0 !important;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  font-size: 0.8125pc;
}

[class~="block-testimonial"] [class~="slick-sliders"] {
  margin-right: auto;
}

.block-lookbook .item-lookbook:after {
  border-top-color: #fcfbfe;
}

.block-lookbook .item-lookbook:after {
  border-image: none;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-right-color: currentColor;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-left-width: 0.75pt;
}

[class~="block-banners"][class~="layout-7"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  font-style: italic;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  height: 30pt;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  line-height: 1.75;
}

[class~="block-banners"][class~="layout-7"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-top: 0;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  letter-spacing: 0.1875pc;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  color: #868686;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  visibility: hidden;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
[class~="block-intro"] [class~="row"] {
  margin-left: 0;
}

[class~="block-post-archives"] ul,
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-bottom: 0;
}

[class~="block-banners"][class~="layout-7"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="title-banner"] {
  margin-bottom: 0.4375pc;
}

[class~="block-testimonial"] [class~="slick-sliders"] {
  margin-top: auto;
}

[class~="block-post-archives"] ul,
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-right: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-bottom-width: 0.75pt;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
[class~="block-intro"] [class~="row"] {
  margin-bottom: 0;
}

.block-testimonial .testimonial-content {
  text-align: center;
}

.block-lookbook .item-lookbook:after {
  border-radius: 50%;
}

[class~="block-testimonial"] [class~="slick-arrow"] {
  font-size: 1.875pc;
}

.block-lookbook .item-lookbook:after {
  border-radius: 50%;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-right-width: 0.75pt;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  padding-left: 0px;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
[class~="block-intro"] [class~="row"] {
  margin-right: 0;
}

.block-product-cats.layout-4 .text-wrap .title,
[class~="block-intro"][class~="layout-2"] [class~="intro-text"],
body[class~="home"][class~="title-6"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  /* color: #cb8161; */
  color: #3c2147;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
[class~="block-intro"] [class~="row"] {
  margin-top: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-top-width: 0.75pt;
}

body[class~="home"][class~="title-6"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  font-size: 35px;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  margin-top: 15pt;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-left: 0;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  transform: translateX(-50%);
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-left-style: solid;
}

[class~="block-post-tags"] ul li {
  border-bottom-color: #e5e5e5;
}

.block-lookbook .item-lookbook:after {
  border-radius: 50%;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li a {
  display: flex;
}

[class~="block-products"] ul[class~="products-list"] li:last-child {
  border-bottom-style: none;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"],
[class~="block-post-archives"] ul {
  padding-top: 0;
}

[class~="block-products"] ul[class~="products-list"] li:last-child {
  border-bottom-color: currentColor;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  transition: opacity 0.3s ease;
}

.block-lookbook .item-lookbook:after {
  border-radius: 50%;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  transform: translateX(-50%);
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a:hover {
  border-right-color: #cb8161;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  transition: opacity 0.3s ease;
}

[class~="block-banners"][class~="layout-7"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="banner-image-description"] {
  margin-bottom: 0.208333333in;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a {
  font-size: 12pt;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-title"] {
  margin-bottom: 11.25pt;
}

body[class~="home"][class~="title-6"]
  [class~="block-newsletter"]
  [class~="newsletter-title"] {
  text-transform: none;
}

.block-product-cats.layout-4 .text-wrap .title {
  font-size: 33.75pt;
}

.block-newsletter .newsletter-form input[type="email"].bg-white {
  background: #fff;
}

.block-product-cats.layout-4 .text-wrap .title {
  line-height: 0.520833333in;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-bottom-style: solid;
}
.popupTitle {
  /* position: block;
  position: top;
  position: absolute; */
  bottom: 10px;
  left: 40px;
  color: #fff;
  background: transparent;
}
.block-lookbook .item-lookbook:after {
  border-radius: 50%;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-text"] {
  font-family: Cormorant Garamond, serif;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-right-style: solid;
}

.block-product-cats.layout-4 .text-wrap .title {
  margin-top: 0;
}

[class~="block-intro"] [class~="row"] {
  align-items: center;
}

.block-lookbook .item-lookbook:after {
  z-index: -1;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  content: "";
}

[class~="block-instagram"] a[class~="instagram"]:before {
  top: 0;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="small-text"]
  [class~="nav-item"]
  [class~="nav-link"] {
  font-size: 13.5pt;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-text"] {
  font-size: 35px;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-text"] {
  font-weight: 600;
}

.block-lookbook .item-lookbook:after {
  animation-name: n;
}

.block-lookbook .item-lookbook:after {
  animation-name: n;
}

[class~="block-intro"][class~="layout-2"] [class~="intro-text"] {
  line-height: 45px;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li a {
  justify-content: space-between;
}

[class~="block-intro"][class~="layout-3"] [class~="row"] {
  margin-left: -0.9375pc;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"],
[class~="block-intro"][class~="layout-3"] [class~="row"] {
  margin-bottom: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-top-style: solid;
}

.block-lookbook .item-lookbook:after {
  animation-duration: 2s;
}

[class~="block-intro"][class~="layout-3"] [class~="row"] {
  margin-right: -0.9375pc;
}

.block-product-cats.layout-4 .text-wrap .title {
  margin-bottom: 18.75pt;
}

[class~="block-intro"][class~="layout-3"] [class~="row"] {
  margin-top: 0;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"]
  a {
  line-height: 1.5625pc;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-left-color: #e8e8e8;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  font-size: 0.6875pc;
}

[class~="block-intro"] [class~="intro-wrap"] {
  max-width: 40.625pc;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"] {
  padding-left: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-bottom-color: #e8e8e8;
}

.block-lookbook .item-lookbook:after {
  animation-duration: 2s;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"] {
  padding-bottom: 0;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-left: auto;
}

.block-newsletter .newsletter-form input[type="email"].bg-white {
  border-left-color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-right-color: #e8e8e8;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  opacity: 0;
}

[class~="block-intro"] [class~="intro-wrap"] {
  margin-right: auto;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  padding-bottom: 0.0625in;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-top-color: currentColor;
}

[class~="block-post-archives"] ul {
  list-style: none;
}

.block-lookbook .item-lookbook:after {
  animation-timing-function: ease-in-out;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  display: flex;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  letter-spacing: 3px;
}

[class~="block-intro"] [class~="intro-wrap"] {
  margin-left: auto;
}

.block-newsletter .newsletter-form input[type="email"].bg-white {
  border-bottom-color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-top-color: #e8e8e8;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"],
[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  margin-left: 0;
}

.block-lookbook .item-lookbook:after {
  animation-timing-function: ease-in-out;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"] {
  padding-right: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"],
[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"] {
  padding-top: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-left-style: solid;
}

[class~="block-products"] ul[class~="products-list"] li:last-child,
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  border-image: none;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  height: 100%;
}

.block-newsletter .newsletter-form input[type="email"].bg-white {
  border-right-color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  line-height: 0.5in;
}

[class~="block-intro"] [class~="intro-wrap"] {
  padding-left: 11.25pt;
}

.block-lookbook .item-lookbook:after {
  animation-iteration-count: infinite;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  margin-bottom: 1.25pc;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  font-size: 13.5pt;
}

.block-newsletter .newsletter-form input[type="email"].bg-white {
  border-top-color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  height: 0.5in;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li a {
  align-items: center;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"],
[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  margin-right: 0;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-bottom: 0.208333333in;
}

[class~="block-intro"] [class~="intro-wrap"] {
  padding-bottom: 0.520833333in;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-left-width: 0;
}

[class~="block-intro"] [class~="intro-wrap"] {
  padding-right: 11.25pt;
}

.block-lookbook .item-lookbook:after {
  animation-iteration-count: infinite;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  padding-left: 15pt;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  visibility: hidden;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"],
[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  text-transform: uppercase;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="sub-title"] {
  margin-top: 0.416666667in;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  font-weight: 500;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  background-color: rgba(37, 37, 37, 0.45);
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="text-wrap"]
  [class~="button"] {
  line-height: 0.416666667in;
}

.block-lookbook .item-lookbook:before {
  width: 2.5pc;
}

.block-lookbook .item-lookbook:before {
  height: 2.5pc;
}

[class~="block-post-tags"] ul,
[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"] {
  padding-left: 0;
}

.block-lookbook .item-lookbook:before,
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  position: absolute;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  padding-right: 0pc;
}

[class~="block-post-tags"] ul,
[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"],
[class~="block-product-cats"][class~="layout-4"]
  [class~="slick-list"]
  [class~="slick-slide"],
[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"] {
  padding-bottom: 0;
}

[class~="block-banners"][class~="layout-5"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="button"] {
  padding-top: 0pt;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  letter-spacing: 0.09375pc;
}

[class~="block-products"]
  [class~="nav-tabs"][class~="layout-2"]
  [class~="nav-item"]
  [class~="nav-link"] {
  padding-bottom: 0.104166667in;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-wrap"] {
  margin-top: 0;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-title"] {
  /* color: #cb8161; */
  color: #3c2147;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-left: 7.5pt;
}

.block-products .nav-tabs .nav-item:hover .nav-link,
[class~="block-products"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  [class~="nav-link"][class~="active"] {
  color: #000;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-title"] {
  font-size: 48px;
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  padding-right: 15pt;
}

.block-lookbook .item-lookbook:before {
  content: "";
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  top: 50%;
}

[class~="block-instagram"] a[class~="instagram"]:before {
  z-index: 1;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-right: 7.5pt;
}

[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"],
[class~="block-product-cats"][class~="layout-4"]
  [class~="slick-list"]
  [class~="slick-slide"] {
  padding-top: 0;
}

.block-lookbook .item-lookbook:before {
  left: calc(50% - 20px);
}

[class~="block-intro"][class~="layout-3"] [class~="intro-title"] {
  font-weight: 600;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="section-column"][class~="left"] {
  width: 30.725%;
}

[class~="block-parallax"] {
  background-repeat: no-repeat;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-title"] {
  line-height: 55px;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-bottom-width: 2px;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  display: inline-block;
}

.block-lookbook .item-lookbook:before {
  top: calc(50% - 20px);
}

[class~="block-newsletter"] [class~="newsletter-form"] input[type="email"] {
  width: 100%;
}

.block-instagram a.instagram:after {
  content: "•";
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-right-width: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  background: #fff;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-title"] {
  text-transform: none;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="section-column"][class~="center"] {
  width: 37.933%;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-title"] {
  margin-bottom: 16.5pt;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="section-column"][class~="right"] {
  width: 30.674%;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-item"] {
  margin-bottom: 1.25pc;
}

[class~="block-intro"][class~="layout-3"] [class~="intro-item"] p {
  font-size: 18px;
  color: #3c2147;
  font-weight: 600;
  font-style: italic;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before,
[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"],
.block-instagram a.instagram:after {
  color: #fff;
}

.block-products ul.products-list li .product-image {
  flex: 0 0 0.833333333in;
}
.product-image {
  padding: 10px;
}
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  padding-bottom: 20px;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-image: none;
}

[class~="block-intro"] [class~="intro-wrap"] {
  padding-top: 0.520833333in;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-family: "Lato", Sans-serif;
}

[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"] {
  padding-right: 0;
}

[class~="block-intro"] [class~="intro-title"] {
  font-size: 0.4375in;
}

[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"] {
  text-align: center;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-bottom-style: solid;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  justify-content: center;
}

.block-lookbook .item-lookbook:before {
  border-left-width: 0.010416667in;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-size: 11px;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-top-width: 0;
}

.block-lookbook .item-lookbook:before {
  border-bottom-width: 0.010416667in;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-right: auto;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  content: "";
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  display: flex;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  bottom: 0;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  font-family: icomoon;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="product-content"] {
  padding-left: 15px;
}

.block-lookbook .item-lookbook:before {
  border-right-width: 0.010416667in;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  transform: translateY(0);
}

.block-lookbook .item-lookbook:before {
  border-top-width: 0.010416667in;
}

.block-instagram a.instagram:after {
  font-family: FontAwesome;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-left-width: medium;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  font-size: 20px;
}

[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"] {
  list-style: none;
}

[class~="block-intro"] [class~="intro-title"],
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  text-transform: uppercase;
}

[class~="block-post-tags"] ul li {
  border-right-color: #e5e5e5;
}

.block-lookbook .item-lookbook:before {
  border-left-style: solid;
}

[class~="block-feature"] [class~="box"] [class~="box-icon"] span svg {
  width: 1em;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  top: calc(50% - 10px);
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  margin-top: auto;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  left: calc(50% - 10px);
}

[class~="block-intro"] [class~="intro-title"] {
  line-height: 50px;
}

[class~="block-post-tags"] ul li {
  border-top-color: #e5e5e5;
}

[class~="block-intro"] [class~="intro-title"] {
  margin-top: 0;
}

[class~="block-intro"][class~="layout-4"] [class~="nav-tabs"] {
  margin-bottom: 12px;
  justify-content: center;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  line-height: 1;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  top: auto;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  z-index: 2;
}

[class~="block-intro"] [class~="intro-title"] {
  margin-bottom: 2.5pc;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  line-height: 30pt;
}

[class~="block-intro"][class~="layout-4"] [class~="tab-content"] {
  padding-left: 1.25pc;
}

[class~="block-intro"][class~="layout-4"]
  [class~="tab-content"]
  [class~="button-primary"] {
  margin-top: 50px;
  cursor: pointer;
  color: white;
}
.block-image.slider .item-image img {
  max-height: 1.822916667in;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  position: absolute;
}

[class~="block-intro"] [class~="intro-item"] {
  display: box;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  width: 100%;
}

[class~="block-parallax"] {
  background-size: cover;
}

[class~="block-intro"] [class~="intro-item"] {
  display: flexbox;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-bottom-width: medium;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  left: 0;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-left: 0in;
}

[class~="block-intro"] [class~="intro-item"] {
  display: flex;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-left-style: none;
}

[class~="block-intro"][class~="layout-4"] [class~="tab-content"] {
  padding-bottom: 1.25pc;
}

.block-instagram a.instagram:after {
  font-size: 0.3125in;
}

[class~="block-intro"][class~="layout-4"] [class~="tab-content"] {
  padding-right: 1.25pc;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"],
.block-lookbook .item-lookbook:before {
  border-right-style: solid;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  width: 110px;
}

[class~="block-intro"][class~="layout-4"] [class~="tab-content"] {
  padding-top: 1.25pc;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  letter-spacing: 3px;
}

.block-instagram a.instagram:after {
  font-weight: 400;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-bottom: 1.875pc;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-right: 0in;
}

[class~="block-intro"][class~="layout-4"] [class~="section-column"] {
  width: 100%;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"],
[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"],
.block-intro.layout-4 .intro-wrap,
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-left: 0;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-radius: 50%;
}

[class~="block-intro"] [class~="intro-item"] {
  box-align: center;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"] {
  padding-top: 0px;
}

.block-image.slider .item-image img {
  width: auto;
}

.block-lookbook .item-lookbook:before,
[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-bottom-style: solid;
}

[class~="block-intro"] [class~="intro-item"] {
  flex-align: center;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"] {
  font-size: 30pt;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"] {
  line-height: 3.4375pc;
}

[class~="block-post-cats"] ul li a,
[class~="block-intro"] [class~="intro-item"] {
  align-items: center;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  font-family: "Lato", Sans-serif;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  font-size: 60px;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  font-size: 12px;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"] {
  font-weight: 600;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  font-weight: 700;
}

[class~="block-intro"] [class~="intro-item"] {
  margin-bottom: 45px;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"] {
  margin-bottom: 0.625pc;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-radius: 50%;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"],
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-bottom: 0;
}

[class~="block-intro"] [class~="intro-item"] {
  font-size: 10.5pt;
}

.block-image.slider .item-image img {
  transition-duration: 0.3s;
}

.block-image.slider .item-image img {
  transition-property: transform;
}

[class~="block-post-tags"] ul li {
  border-image: none;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  text-transform: uppercase;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-left: 0;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  line-height: 24px;
}

[class~="block-intro"][class~="layout-2"] {
  max-width: 1000px;
}

.block-lookbook .item-lookbook:before {
  border-top-style: solid;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"],
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"],
.block-intro.layout-4 .intro-wrap {
  margin-right: 0;
}

[class~="block-feature"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"],
[class~="block-intro"][class~="layout-4"] [class~="intro-title"],
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
.block-intro.layout-4 .intro-wrap {
  margin-top: 0;
}

.block-instagram a.instagram:after {
  top: calc(50% - 13px);
}

.block-lookbook .item-lookbook:before {
  border-left-color: #fcfbfe;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  line-height: 48.75pt;
}

[class~="block-intro"][class~="layout-4"] [class~="intro-title"] {
  text-transform: none;
}

.block-intro.layout-4 .intro-item {
  margin-bottom: 0.3125in;
}

.block-instagram a.instagram:after {
  text-align: center;
}

.block-lookbook .item-lookbook:before {
  border-bottom-color: #fcfbfe;
}

[class~="block-intro"][class~="layout-2"] {
  margin-right: auto;
}

.block-instagram a.instagram:after {
  line-height: 100%;
}

.block-intro.layout-4 .intro-wrap {
  max-width: none;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-bottom: 0.208333333in;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  min-width: 11.875pc;
}

[class~="block-post-tags"] ul li {
  overflow: hidden;
}

[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-description"] {
  margin-bottom: 0;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-radius: 50%;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before,
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  background: #fff;
}

[class~="block-banners"][class~="layout-6"]
  [class~="block-widget-banner"]
  [class~="banner-wrapper-infor"]
  [class~="content"]
  [class~="title-banner"] {
  margin-top: 0;
}

[class~="block-parallax"] {
  background-attachment: fixed;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-title"] {
  font-size: 12pt;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  position: absolute;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  border-radius: 20px;
}

.block-intro.layout-4 .intro-wrap {
  margin-bottom: 0;
}

.block-instagram a.instagram:after {
  z-index: 2;
}

[class~="block-intro"][class~="layout-2"] {
  margin-left: auto;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"],
[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  display: inline-block;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-family: "Lato", Sans-serif;
}

[class~="block-instagram"] a[class~="instagram"] img {
  transition: transform 0.3s ease;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  content: "";
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-size: 0.15625in;
}

.block-intro.layout-4 .intro-wrap,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-bottom: 0;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  line-height: 2.375pc;
}

[class~="block-feature"] [class~="box"] [class~="box-icon"] span svg {
  height: 1em;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-right-style: none;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="f"] {
  border-radius: 20px;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  width: 2.25pt;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  height: 6px;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img,
.block-lookbook .item-lookbook:before {
  border-radius: 50%;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-left: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"]:hover {
  border-left-color: #cb8161;
}

.block-lookbook .item-lookbook:before {
  border-right-color: #fcfbfe;
}

[class~="block-newsletter"] [class~="sub-title"] {
  margin-bottom: 6pt;
}

.block-lookbook .item-lookbook:before {
  border-top-color: #fcfbfe;
}

.block-intro.layout-4 .intro-wrap,
[class~="block-intro"][class~="layout-5"] [class~="intro-wrap"] {
  padding-left: 0;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-top-style: none;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-bottom: 0.0625pc;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-right: 0;
}

[class~="block-instagram"] a[class~="instagram"] img {
  transition: transform 0.3s ease;
}

.block-lookbook .item-lookbook:before {
  border-image: none;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-left-color: currentColor;
}

.block-newsletter .title {
  font-size: 0.3125in;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  transition: all 0.3s ease;
}

.block-lookbook .item-lookbook:before {
  border-radius: 50%;
}

[class~="block-post-tags"] ul,
[class~="block-intro"][class~="layout-5"] [class~="intro-wrap"],
.block-intro.layout-4 .intro-wrap,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-right: 0;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-top: 0;
}

.block-newsletter .title {
  line-height: 1;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-right-style: solid;
}

[class~="block-feature"] [class~="box"] [class~="box-icon"] span svg,
[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"] {
  position: relative;
}

[class~="block-feature"] [class~="box"] [class~="box-icon"] span svg {
  display: block;
}

.block-lookbook .item-lookbook:before {
  border-radius: 50%;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  border-radius: 25px;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
[class~="block-parallax"] {
  margin-left: 0px;
}

.block-lookbook .item-lookbook:before,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  border-radius: 50%;
}

[class~="block-post-tags"] ul,
[class~="block-intro"][class~="layout-5"] [class~="intro-wrap"],
.block-intro.layout-4 .intro-wrap,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  padding-top: 0;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-left-width: 0.75pt;
}

.block-lookbook .item-lookbook:before {
  border-radius: 50%;
}

[class~="block-intro"][class~="layout-5"] [class~="intro-wrap"] {
  max-width: 58.125pc;
}

.block-newsletter .title {
  margin-bottom: 18.75pt;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-bottom-color: #000;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-bottom-width: 0.75pt;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-bottom: 0px;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a,
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-top-style: solid;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-right-width: 0.75pt;
}

[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"] {
  font-size: 0.8125pc;
}

[class~="block-parallax"] {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-right-color: currentColor;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  top: 4.5pt;
}

.block-post-archives ul li:before {
  content: "5";
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  left: 50%;
}

.block-product-filter ul.filter-items li,
[class~="block-posts"]
  [class~="post-item"]
  [class~="post-content"]
  [class~="post-time"] {
  color: #868686;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"],
[class~="block-intro"][class~="layout-5"] [class~="intro-wrap"] {
  padding-bottom: 0;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-top-color: currentColor;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-right: 0in;
}

.block-product-filter ul.filter-items li {
  margin-right: 0.104166667in;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-top-width: 0.75pt;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  border-image: none;
}

.block-lookbook .item-lookbook:before {
  z-index: -1;
}

.block-lookbook .item-lookbook:before {
  animation-name: n;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  transform: translateY(-50%);
}

.block-intro.layout-4 .nav-tabs .nav-item a.active,
[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"]
  a:hover,
.block-newsletter .title {
  color: #000;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-top: 0px;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-left-style: solid;
}

.block-intro.layout-4 .nav-tabs .nav-item a:before {
  transition: all 0.3s ease;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  text-transform: none;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  padding-left: 0;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  transform: translateX(-50%);
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-left-color: #ddd;
}

[class~="block-product-cats"][class~="layout-3"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  letter-spacing: normal;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  transform: translateX(-50%);
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  padding-right: 0;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  border-radius: 0.260416667in;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  border-radius: 1.5625pc;
}

.block-intro.layout-5 .intro-icon,
.block-instagram a.instagram,
.block-post-archives ul li:before,
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-image"]
  [class~="thumbnail"]
  img {
  display: inline-block;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  padding-top: 0;
}

[class~="block-posts"][class~="slider"]
  [class~="post-date-wrap"]
  [class~="post-date"] {
  top: -0.020833333in;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-left-width: 0.75pt;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  border-radius: 18.75pt;
}

[class~="block-post-tags"] ul {
  list-style: none;
}

[class~="block-parallax"]
  [class~="go-to-section"]
  [class~="go-to-button"]:before {
  border-radius: 1.5625pc;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  width: 100%;
}

.block-lookbook .item-lookbook:before {
  animation-name: n;
}

.block-intro.layout-5 .intro-icon {
  line-height: 1;
}

.block-intro.layout-5 .intro-icon {
  transition: all 0.3s;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  max-width: 580px;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="read-more"],
.block-posts.slider .post-content .post-categories {
  color: #868686;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] a {
  color: inherit;
}

.block-posts.slider .post-content .post-categories {
  font-size: 11px;
}

.block-posts.slider .post-content .post-categories {
  text-transform: uppercase;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"]:hover {
  color: #cb8161;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] a {
  font-size: inherit;
}

[class~="block-contact-map"] iframe {
  max-width: 100%;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-left-color: #e6e6e6;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-left: 0;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li:last-child {
  margin-bottom: 0;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  height: 0.416666667in;
}

[class~="block-post-tags"] ul li {
  background: transparent;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"] {
  line-height: 40px;
}

.block-intro.layout-5 .intro-icon {
  transition: all 0.3s;
}

.block-posts.slider .post-content .post-categories,
[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  letter-spacing: 0.1875pc;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color-wrap"] {
  display: flex;
}

.block-posts.slider .post-content .post-categories {
  margin-bottom: 3.75pt;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="post-title"] {
  font-size: 19.5pt;
}

.block-lookbook .item-lookbook:before {
  animation-duration: 2s;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="post-title"] {
  font-weight: 600;
}

.block-lookbook .item-lookbook:before {
  animation-duration: 2s;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-left-width: 0;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="post-title"] {
  line-height: 35px;
}

.block-lookbook .item-lookbook:before {
  animation-timing-function: ease-in-out;
}

.block-intro.layout-5 .intro-icon,
.block-product-filter ul.filter-items li {
  text-align: center;
}

.block-intro.layout-5 .intro-icon {
  transition: all 0.3s;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-bottom: 0.083333333in;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-bottom-style: solid;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] a {
  line-height: inherit;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  font-size: 8.25pt;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  input[type="email"]::placeholder {
  color: #999;
}

.block-lookbook .item-lookbook:before {
  animation-timing-function: ease-in-out;
}

.block-product-filter ul.filter-items li {
  float: left;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="post-title"] {
  margin-top: 0;
}

.block-lookbook .item-lookbook:before {
  animation-iteration-count: infinite;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:before {
  content: none;
}

.block-lookbook .item-lookbook:before {
  animation-iteration-count: infinite;
}

.block-lookbook .item-lookbook:after {
  animation-delay: 1.5s;
}

.block-product-filter ul.filter-items li {
  font-size: 14px;
}

.block-intro.layout-5 .intro-icon {
  color: #818a91;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  background-color: #020101;
}

.block-post-archives ul li:before {
  font-family: eleganticons;
}

.block-intro.layout-5 .intro-title,
.block-intro.layout-5 .intro-icon {
  font-size: 37.5pt;
}

.block-intro.layout-5 .intro-text {
  padding: 0 0 10px 0;
}
.block-product-filter ul.filter-items li {
  cursor: pointer;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-bottom: 0;
}

[class~="block-newsletter"] [class~="newsletter-content"],
.block-posts ul.posts-list li:last-child {
  border-bottom-width: 0;
}

[class~="block-parallax"] {
  margin-bottom: 0in;
}

[class~="block-post-cats"] ul li [class~="count"] {
  width: 1.5625pc;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  border-radius: 0px;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-right-width: 0;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  padding-left: 0pc;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-width: 0.75pt;
}

.block-instagram a.instagram {
  position: relative;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"] {
  width: 1.375pc;
}

.block-posts ul.posts-list li:last-child {
  padding-bottom: 0;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-top-width: 0;
}

.block-instagram a.instagram {
  vertical-align: top;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"] {
  height: 16.5pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  padding-bottom: 0pc;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  padding-right: 0pc;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:hover {
  background: #cb8161;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-left-style: none;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-right: 0;
}

.block-intro.layout-5 .intro-icon {
  margin-bottom: 15px;
}

.block-posts ul.posts-list li:last-child,
[class~="block-newsletter"] [class~="newsletter-content"] {
  border-bottom-style: none;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="number-lookbook"]:hover,
[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  color: #fff;
}

[class~="block-post-tags"] ul li,
.block-testimonial.layout-2 .testimonial-content .item {
  padding-left: 15px;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a {
  padding-top: 0pc;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  height: 3pc;
}

.block-instagram a.instagram {
  overflow: hidden;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]:hover
  [class~="content-lookbook"] {
  display: block;
}

.block-intro.layout-5 .intro-title {
  text-transform: none;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"] {
  margin-top: 10px;
}

[class~="block-parallax"] {
  margin-right: 0in;
}

[class~="block-product-filter"] ul[class~="filter-items"] {
  list-style-type: none;
}

.block-intro.layout-5 .intro-title {
  margin-bottom: 25px;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-left-width: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul,
[class~="block-product-filter"] ul[class~="filter-items"] {
  padding-left: 0;
}

[class~="block-post-tags"] ul li {
  padding-bottom: 0.0625in;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"],
[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-right-style: solid;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"] {
  width: 345pt;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-right-style: none;
}

[class~="block-contact-map"] iframe,
[class~="block-product-filter"] ul[class~="filter-items"] {
  width: 100%;
}

[class~="block-product-filter"] ul[class~="filter-items"],
.block-posts ul.posts-list li:last-child {
  margin-bottom: 0;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"],
[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-top-style: solid;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"] {
  margin-right: 7.5pt;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-right-width: 0.75pt;
}

[class~="block-intro"][class~="layout-6"] [class~="intro-title"],
[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-text"] a,
[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  text-transform: uppercase;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-top: 0;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-top-width: 0.75pt;
}

[class~="block-intro"][class~="layout-6"] [class~="intro-title"] {
  font-size: 1.875pc;
}

[class~="block-product-filter"] ul[class~="filter-items"] {
  float: left;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-left-style: solid;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-top-style: none;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-width: 0;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-right-width: medium;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul {
  overflow-y: scroll;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-right-width: 0;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-left-color: currentColor;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul {
  max-height: 1.3125in;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"] {
  text-align: right;
}

.block-contact-info .info-icon svg {
  width: 1em;
}

.block-testimonial.layout-2 .testimonial-content .item {
  text-align: center;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-bottom-color: currentColor;
}

[class~="block-intro"][class~="layout-6"] [class~="intro-title"] {
  line-height: 40px;
}

[class~="block-post-cats"] ul li [class~="count"] {
  height: 1.5625pc;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-left-color: #e5e5e5;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-top-width: medium;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-size: 11px;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-top-width: 0;
}

.block-testimonial.layout-2 .testimonial-content .item,
.block-intro.layout-6 .intro-wrap {
  padding-bottom: 0;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-style: solid;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span,
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-left-style: none;
}

[class~="block-intro"][class~="layout-6"] [class~="intro-title"] {
  margin-bottom: 1.25pc;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  width: 24px;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-bottom-color: #e5e5e5;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span,
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-bottom-style: none;
}

.block-testimonial.layout-2 .testimonial-content .item {
  padding-right: 11.25pt;
}

.block-intro.layout-6 .intro-wrap {
  max-width: 442.5pt;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-left-color: #fff;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"],
.block-intro.layout-6 .intro-wrap,
[class~="block-contact-map"] iframe {
  margin-left: 0;
}

.block-testimonial.layout-2 .testimonial-content .item,
.block-intro.layout-6 .intro-wrap {
  padding-top: 0;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-right-color: currentColor;
}

[class~="block-product-cats"][class~="layout-4"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  letter-spacing: 0.03125in;
}

.block-intro.layout-6 .intro-wrap,
[class~="block-intro"][class~="layout-7"] [class~="row"] {
  margin-bottom: 0;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-bottom: 0.104166667in;
}

.block-post-archives ul li:before {
  font-size: 11.25pt;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"],
.block-intro.layout-6 .intro-wrap {
  margin-right: 0;
}

[class~="block-intro"][class~="layout-7"] [class~="row"],
.block-intro.layout-6 .intro-wrap,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="rating"] {
  margin-top: 0;
}

.block-intro.layout-6 .intro-wrap,
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-left: 0;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-top-color: currentColor;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  height: 24px;
}

.block-contact-info .info-icon svg {
  height: 1em;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-color: #fff;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-right-color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  border-image: none;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="section-column"][class~="center"]
  [class~="section-column"] {
  width: 50%;
}

.block-contact-info .info-icon svg {
  position: relative;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul {
  list-style: none;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"],
[class~="block-post-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

.block-intro.layout-6 .intro-wrap,
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-right: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  width: 0.104166667in;
}

[class~="block-post-cats"] ul li [class~="count"] {
  font-weight: 400;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-right-style: none;
}

.block-contact-info .info-icon svg {
  display: block;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-top-style: none;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  background-color: #eaeaea;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-top-color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-left: auto;
}

.block-post-archives ul li:before {
  margin-right: 3px;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  color: #434343;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  border-radius: 0.052083333in;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  border-radius: 5px;
}

[class~="block-contact-info"] [class~="info-title"] h2 {
  font-size: 35px;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  font-family: "Cormorant Garamond", Sans-serif;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-family: Cormorant Garamond, serif;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  font-size: 27pt;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  line-height: 44px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  border-radius: 0.3125pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  border-radius: 0.052083333in;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  font-weight: 500;
}

[class~="block-intro"][class~="layout-7"] [class~="row"] {
  margin-left: -11.25pt;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  font-style: italic;
}

.dark .block-newsletter .newsletter-form .btn-submit input[type="submit"] {
  border-image: none;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-size: 0.208333333in;
}

[class~="block-intro"][class~="layout-7"] [class~="row"] {
  margin-right: -11.25pt;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar {
  border-radius: 0.3125pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-thumb {
  border-radius: 5px;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"],
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-bottom: 0;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  font-weight: 600;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-thumb {
  border-radius: 3.75pt;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"] {
  max-width: 600px;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  text-transform: uppercase;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-bottom: auto;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"] {
  margin-right: 0;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  margin-bottom: 0pc;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"] {
  margin-left: auto;
}

[class~="block-post-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"][class~="jslider-value-to"] {
  float: right;
}

.block-testimonial .testimonial-content .item .testimonial-excerpt {
  margin-bottom: 0.208333333in;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"] {
  width: 100%;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-right: auto;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  background-color: #ffad76;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-thumb {
  border-radius: 3.75pt;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  font-size: 0.125in;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"],
[class~="block-contact-info"] [class~="info-title"] h2 {
  padding-top: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-thumb {
  border-radius: 5px;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-left-color: currentColor;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  fill: #fff;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-thumb {
  border-radius: 0.3125pc;
}

[class~="block-post-tags"] ul li {
  padding-right: 15px;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"],
.block-posts ul.posts-list li:last-child {
  border-bottom-color: currentColor;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  color: #000;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  margin-top: auto;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"] {
  padding-left: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-thumb {
  background-color: #000;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  color: #fff;
}

[class~="block-contact-info"] [class~="info-title"] h2 {
  margin-left: 0;
}

[class~="block-contact-info"] [class~="info-title"] h2,
.block-product-filter ul.filter-items.image {
  margin-bottom: 0;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  color: #818a91;
}

[class~="block-contact-info"] [class~="info-title"] h2 {
  margin-right: 0;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-wrap"] {
  padding-right: 0;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"],
[class~="block-newsletter"] [class~="newsletter-title"] {
  margin-top: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-track {
  background-color: #eaeaea;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-track {
  border-radius: 5px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-track {
  border-radius: 0.3125pc;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-right-color: currentColor;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  font-weight: 400;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-top-color: currentColor;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-right-color: #e5e5e5;
}

[class~="block-newsletter"] [class~="newsletter-content"] {
  display: flex;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"]:hover {
  border-bottom-color: #cb8161;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-track {
  border-radius: 5px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-track {
  border-radius: 0.052083333in;
}

.block-posts ul.posts-list li:last-child,
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  border-image: none;
}

[class~="block-post-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="filter-content"]
  ul::scrollbar-track {
  border-radius: 5px;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-title"],
[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"],
[class~="block-newsletter"] [class~="newsletter-title"] {
  font-size: 30px;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  font-size: 0.520833333in;
}

[class~="block-contact-info"] [class~="info-title"] h2 {
  margin-top: 0;
}

[class~="block-product-cats"][class~="layout-5"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"] {
  letter-spacing: normal;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="check"] a {
  font-weight: normal;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  font-size: 0;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-top-color: #e5e5e5;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  padding-left: 0.197916667in;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  padding-bottom: 0.197916667in;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"],
[class~="block-contact-info"] [class~="info-title"] h2,
[class~="block-intro"][class~="layout-7"] [class~="intro-title"] {
  line-height: 1;
}

[class~="block-post-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-parallax"] {
  margin-top: 0in;
}

[class~="block-newsletter"] [class~="newsletter-text"] {
  margin-bottom: 0.229166667in;
}

[class~="block-products"]
  ul[class~="products-list"]
  li
  [class~="rating"]
  [class~="star"]:before {
  letter-spacing: 2.25pt;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-title"] {
  font-weight: 500;
}

.block-product-filter ul.filter-items.image {
  margin-left: -0.052083333in;
}

[class~="block-post-cats"] ul li [class~="count"] {
  border-radius: 50%;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  padding-right: 0.197916667in;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  padding-top: 0.197916667in;
}

[class~="block-newsletter"] [class~="newsletter-title"] {
  margin-bottom: 7.5pt;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  border-radius: 50%;
}

[class~="block-intro"][class~="layout-7"] [class~="intro-title"] {
  margin-bottom: 15pt;
}

[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-newsletter"]
  [class~="newsletter-content"]
  [class~="your-email"] {
  width: 100%;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-left: 0.3125in;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  border-radius: 50%;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  display: inline-block;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-bottom: 0;
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="content"]
  [class~="title"],
[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  font-size: 0.145833333in;
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="content"]
  [class~="title"],
.block-contact-form .block-title h2 {
  margin-top: 0;
}

[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  margin-left: 0;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  bottom: auto;
}

[class~="block-newsletter"] [class~="newsletter-title"] {
  text-transform: uppercase;
}

[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-text"] {
  margin-top: 8px;
}

[class~="block-post-cats"] ul li [class~="count"] {
  background: #f1f1f1;
}

[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  margin-bottom: 0.052083333in;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-right-style: none;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-top-style: none;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  transition: all 0.3s;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-bottom-color: #e6e6e6;
}

[class~="block-contact-map"] iframe {
  margin-bottom: 0;
}

.block-contact-form .block-title h2 {
  font-size: 35px;
}

[class~="block-contact-form"] [class~="contact-us-form"] label,
[class~="block-intro"]
  [class~="intro-item"]
  [class~="content"]
  [class~="title"],
[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-text"] {
  margin-bottom: 0.104166667in;
}

.block-product-filter ul.filter-items.image {
  margin-right: -0.052083333in;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  transition: all 0.3s;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"] {
  transition: all 0.3s;
}

[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-left: auto;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-left-color: currentColor;
}

[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-bottom: auto;
}

[class~="block-intro"] [class~="intro-item"] [class~="icon"] [class~="wrap"],
[class~="block-post-cats"] ul li [class~="count"] {
  text-align: center;
}

[class~="block-post-cats"] ul li [class~="count"] {
  line-height: 18.75pt;
}

.block-product-filter ul.filter-items.image {
  margin-top: 0;
}

[class~="block-posts"] ul[class~="posts-list"] li [class~="post-image"] {
  flex: 0 0 80px;
}

[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-right: 0;
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="content"]
  [class~="title"] {
  font-family: "Lato", Sans-serif;
}

[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  margin-right: 0;
}

.block-post-search .search-from input[type="text"] {
  line-height: 2.8125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] label {
  color: #000;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  max-width: 36.25pc;
}

[class~="block-newsletter"][class~="layout-2"] [class~="newsletter-form"] {
  margin-top: auto;
}

[class~="block-feature"][class~="layout-2"]
  [class~="box"]
  [class~="box-title-wrap"]
  [class~="box-title"] {
  margin-top: 0.052083333in;
}

[class~="block-post-cats"] ul li [class~="count"] {
  font-size: 0.8125pc;
}

[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  margin-left: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  transition: all 0.3s ease;
}

[class~="block-post-tags"] ul li {
  padding-top: 0.0625in;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-width: 0.125pc;
}

[class~="block-intro"]
  [class~="intro-item"]
  [class~="content"]
  [class~="title"] {
  letter-spacing: 0.03125in;
  font-weight: 600;
}

[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  transition: all 0.3s ease;
}

[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"],
.block-post-archives ul li:before,
[class~="block-contact-form"] [class~="contact-us-form"] label {
  position: relative;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"][class~="jslider-value-to"] {
  margin-right: -0.625pc;
}

[class~="block-posts"][class~="slider"]
  [class~="post-content"]
  [class~="read-more"]:hover {
  color: #cb8161;
}

.block-post-search .search-from input[type="text"] {
  height: 2.8125pc;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-left-width: 0;
}

[class~="block-posts"] ul[class~="posts-list"] li [class~="post-content"] {
  padding-left: 0.9375pc;
}

.block-post-search .search-from input[type="text"],
.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-style: solid;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-bottom-width: 0;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-right-width: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"],
[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  background: #000;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-top-width: 0;
}

.block-post-search .search-from input[type="text"] {
  border-left-width: 0.010416667in;
}

[class~="block-post-cats"] ul li [class~="count"] {
  display: block;
}

[class~="block-products"][class~="layout-2"][class~="border-item"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"] {
  border-image: none;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-left-style: none;
}

[class~="block-newsletter"] [class~="newsletter-form"] [class~="btn-submit"] {
  cursor: pointer;
}

[class~="block-instagram"] a[class~="instagram"]:hover:before,
[class~="block-instagram"] a[class~="instagram"]:hover:after {
  opacity: 1;
}

.block-post-search .search-from input[type="text"] {
  border-bottom-width: 0.010416667in;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  border-radius: 50%;
}

.block-image.slider .item-image {
  display: flex;
}

.block-intro.layout-3 .section-column.left,
.block-intro.layout-3 .section-column.right {
  width: 50%;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-right: 0.3125in;
}

.block-image.slider .item-image {
  align-items: center;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"] {
  margin-bottom: 0;
}

.block-intro.layout-3 .section-column.left,
[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  padding-top: 0;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-bottom-color: currentColor;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  background: transparent;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-bottom-style: none;
}

.block-image.slider .item-image {
  justify-content: center;
}

.block-post-search .search-from input[type="text"] {
  border-right-width: 0.010416667in;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  border-bottom-color: #000;
}

[class~="block-contact-form"] [class~="contact-us-form"] label {
  font-size: 1pc;
}

[class~="block-parallax"],
.block-post-search .search-from input[type="text"],
.block-intro.layout-3 .section-column.right,
.block-intro.layout-3 .section-column.left {
  padding-left: 0.9375pc;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-right-style: none;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-top-style: none;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"] {
  line-height: 0.1875in;
}

[class~="block-parallax"] [class~="content"] [class~="title"],
.block-parallax .content .description {
  color: #fff;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="item-title"] {
  text-transform: capitalize;
}

.block-intro.layout-3 .section-column.right,
.block-intro.layout-3 .section-column.left {
  padding-bottom: 0;
}

[class~="block-parallax"] [class~="content"] [class~="title"] {
  font-size: 0.833333333in;
}

[class~="block-parallax"] [class~="content"] [class~="title"] {
  font-weight: 600;
}

[class~="block-lookbook"]
  [class~="item-lookbook"]
  [class~="content-lookbook"]
  [class~="content-lookbook-bottom"]
  [class~="price"] {
  font-weight: 500;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  color: transparent;
}

.block-post-search .search-from input[type="text"] {
  border-top-width: 0.010416667in;
}

.block-intro.layout-3 .section-column.right,
.block-intro.layout-3 .section-column.left {
  padding-right: 0.9375pc;
}

[class~="block-instagram"] a[class~="instagram"]:hover:after {
  visibility: visible;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  position: absolute;
}

.block-post-search .search-from input[type="text"] {
  border-left-style: solid;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-left-color: currentColor;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  border-image: none;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="sub-title"] {
  font-size: 0.6875pc;
}

[class~="block-parallax"] [class~="content"] [class~="title"] {
  line-height: 0.989583333in;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="sub-title"] {
  text-transform: uppercase;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  width: 100%;
}

[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-post-tags"] ul li {
  margin-left: 0;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"] {
  z-index: 2;
}

[class~="block-parallax"] [class~="content"] [class~="title"] {
  margin-top: 0;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-bottom-color: #ddd;
}

[class~="block-contact-map"] iframe {
  margin-right: 0;
}

.block-post-search .search-from input[type="text"] {
  border-right-style: solid;
}

[class~="block-contact-map"] iframe {
  margin-top: 0;
}

.block-post-search .search-from input[type="text"] {
  border-top-style: solid;
}

[class~="block-parallax"] [class~="content"] [class~="title"] {
  margin-bottom: 0.3125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  color: #000;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="sub-title"] {
  letter-spacing: 0.03125in;
}

.block-parallax .content .description {
  font-size: 1pc;
}

.block-parallax .content .description {
  margin-bottom: 1.875pc;
}

.block-parallax .content .button {
  line-height: 33pt;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"],
.block-parallax.right-text .content {
  padding-left: 0;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]:hover,
[class~="block-post-tags"] ul li:hover {
  background: #cb8161;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="sub-title"] {
  margin-bottom: 0.104166667in;
}

[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-right-color: currentColor;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="title"] {
  font-size: 33.75pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  max-height: 2.708333333in;
}

.block-parallax.right-text .content {
  max-width: 28.125pc;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="title"] {
  line-height: 3.4375pc;
}

.block-parallax.right-text .content {
  margin-left: auto;
}

.block-parallax.right-text .content {
  margin-right: 0;
}

.block-post-search .search-from input[type="text"] {
  border-left-color: #e6e6e6;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]
  input[type="submit"],
.block-product-filter .filter-item .custom-checkbox input[type="checkbox"] {
  cursor: pointer;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-bottom-color: currentColor;
}

[class~="block-post-tags"] ul li:hover {
  border-left-color: #cb8161;
}

.block-post-search .search-from input[type="text"] {
  border-bottom-color: #e6e6e6;
}

[class~="block-parallax"] {
  padding-bottom: 337.5pt;
}

.block-post-search .search-from input[type="text"],
.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"],
.block-parallax.right-text .content,
[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"] {
  padding-bottom: 0;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="title"] {
  margin-bottom: 1.25pc;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-top-color: currentColor;
}

.block-product-filter .filter-item .custom-checkbox input[type="checkbox"] {
  opacity: 0;
}

.block-product-filter .filter-item .custom-checkbox input[type="checkbox"],
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  position: absolute;
}

[class~="block-contact-map"] iframe {
  line-height: 1;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  border-image: none;
}

.block-parallax.right-text .content,
.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  padding-right: 0;
}

[class~="block-lookbook"][class~="layout-2"]
  [class~="lookbook-intro-wrap"]
  [class~="lookbook-intro"]
  [class~="button"] {
  line-height: 0.4375in;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  content: "";
}

[class~="block-post-tags"] ul li:hover {
  border-bottom-color: #cb8161;
}

.block-post-search .search-from input[type="text"],
.block-parallax.right-text .content,
.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"],
.block-intro.layout-3 .section-column.right {
  padding-top: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  padding-left: 0.208333333in;
}

.block-post-search .search-from input[type="text"] {
  border-right-color: #e6e6e6;
}

.block-post-search .search-from input[type="text"] {
  border-top-color: #e6e6e6;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  width: 1.1875pc;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  height: 0.302083333in;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  height: 3px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  display: none;
}

.block-post-search .search-from input[type="text"] {
  border-image: none;
}

[class~="block-instagram"] a[class~="instagram"]:hover:before {
  visibility: visible;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  left: 4.5pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-left-width: 1.5pt;
}

.block-post-search .search-from .btn i,
[class~="block-intro"][class~="layout-3"]
  [class~="intro-title"][class~="black"] {
  color: #000;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  padding-bottom: 7.5pt;
}

[class~="block-intro"][class~="layout-3"]
  [class~="intro-btn"]
  [class~="button"] {
  line-height: 0.416666667in;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"],
.block-post-search .search-from input[type="text"] {
  width: 100%;
}

[class~="block-post-tags"] ul li {
  margin-bottom: 4px;
}

[class~="block-post-tags"] ul li {
  margin-right: 4.5pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-bottom-width: 1.5pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-right-width: 1.5pt;
}

[class~="block-newsletter"] [class~="newsletter-form"],
[class~="block-post-tags"] ul li {
  margin-top: 0;
}

.block-post-search .search-from input[type="text"] {
  padding-right: 52.5pt;
}

[class~="block-newsletter"] [class~="newsletter-form"],
.block-testimonial.layout-2 .testimonial-content .item .rating {
  margin-bottom: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  padding-right: 0.208333333in;
}

[class~="block-post-tags"] ul li {
  text-transform: capitalize;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  padding-top: 7.5pt;
}

[class~="block-product-cats"][class~="layout-2"]
  [class~="item-product-cat-content"]
  [class~="product-cat-content-info"]
  [class~="item-title"]:hover {
  color: #fff;
}

[class~="block-newsletter"] [class~="newsletter-form"],
.block-testimonial.layout-2 .testimonial-content .item .rating {
  margin-right: 0;
}

[class~="block-intro"][class~="layout-3"]
  [class~="intro-btn"]
  [class~="button"] {
  margin-top: 7.5pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-top-width: 1.5pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  font-weight: 400;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"],
[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-left-style: solid;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"] {
  display: inline-block;
}

.block-testimonial.layout-2 .testimonial-content .item .rating {
  margin-left: 0;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"] {
  padding-left: 18.75pt;
}

[class~="block-post-tags"] ul li:hover {
  border-right-color: #cb8161;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-bottom-style: solid;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  background: #fff;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-left-width: 0.0625pc;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  border-radius: 50%;
}

[class~="block-post-tags"] ul li:hover {
  border-top-color: #cb8161;
}

.block-post-search .search-from .btn i {
  font-size: 18px;
}

.block-post-search .search-from .btn i {
  line-height: 45px;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-right-style: solid;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"],
.block-contact-info .info-item .item-tilte h2 {
  font-family: Lato, sans-serif;
}

.block-contact-info .info-item .item-tilte h2 {
  font-size: 13px;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-bottom-width: 0.0625pc;
}

.block-contact-info .info-item .item-tilte h2 {
  font-weight: 500;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-top-style: solid;
}

.block-contact-info .info-item .item-tilte h2 {
  text-transform: uppercase;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-right-width: 0.0625pc;
}

[class~="block-newsletter"] [class~="newsletter-form"] {
  display: flex;
}

[class~="block-post-tags"] ul li {
  position: relative;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-left-color: #fff;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-bottom-color: #fff;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-top-width: 0.0625pc;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-right-color: #fff;
}

.block-contact-info .info-item .item-tilte h2 {
  letter-spacing: 2px;
}

.block-parallax.bg-top-center {
  background-position: top center;
}

.block-contact-info .info-item .item-tilte h2 {
  margin-bottom: 12px;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"] {
  padding-right: 18.75pt;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  left: 0;
}

[class~="block-parallax"][class~="bg-img-1"] {
  background-image: url("../../media/banner/parallax-bg-1.jpg");
}

.block-contact-info .info-item .item-tilte h2 {
  margin-top: 0;
}

[class~="block-contact-info"] [class~="info-item"] [class~="item-content"] p {
  margin-bottom: 2.25pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-top-color: #fff;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  height: 3.125pc;
}

[class~="block-instagram"] a[class~="instagram"]:hover img {
  transform: scale(1.2);
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  top: 2px;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  font-size: 0.145833333in;
}

[class~="block-contact-map"] iframe {
  border-left-width: medium;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  width: 3.75pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-image: none;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  line-height: 37.5pt;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  font-weight: 600;
}

[class~="block-intro"][class~="layout-4"]
  [class~="nav-tabs"]
  [class~="nav-item"] {
  padding-top: 0;
}

[class~="block-intro"][class~="layout-4"]
  [class~="section-column"][class~="right"] {
  padding-top: 60px;
}

.block-testimonial.layout-2 .testimonial-content .item .rating {
  margin-top: 0;
}

/* [class~=block-intro][class~=layout-4] [class~=section-column][class~=right] {
  padding-left: 1.875pc;
} */

.block-parallax.bg-img-2 {
  background-image: url("../../media/banner/parallax-bg-2.jpg");
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  height: 9pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-radius: 18.75pt;
}

.block-post-archives ul li:before {
  top: 0.75pt;
}

/* [class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  margin-bottom: 15pt;
} */

[class~="block-product-cats"][class~="layout-5"]
  [class~="section-column"][class~="center"]
  [class~="section-column"][class~="full"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  width: 100%;
}

[class~="block-parallax"][class~="bg-img-3"] {
  background-image: url("../../media/banner/parallax-bg-3.jpg");
}

[class~="block-instagram"] a[class~="instagram"]:hover img {
  transform: scale(1.2);
}

[class~="block-contact-map"] iframe {
  border-bottom-width: medium;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-left: 0.208333333in;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-bottom-style: solid;
}

[class~="block-contact-map"] iframe {
  border-right-width: medium;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-bottom: 7.5pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-right-style: solid;
}

[class~="block-product-filter"] ul[class~="filter-items"] li:last-child {
  margin-right: 0;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  display: inline-block;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-left-width: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-right: 0.208333333in;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  position: relative;
}

.block-parallax.bg-img-4 {
  background-image: url("../../media/banner/parallax-bg-4.jpg");
}

[class~="block-info"] [class~="info-wrap"] ul li {
  margin-bottom: 0.3125pc;
}

[class~="block-contact-map"] iframe {
  border-top-width: medium;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-size: 1rem;
}

[class~="block-parallax"] [class~="content"] {
  padding-left: 11.25pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-radius: 0.260416667in;
}

[class~="block-product-filter"] ul[class~="filter-items"] li:hover,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  color: #000;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li {
  width: 2.5pc;
}

[class~="block-intro"][class~="layout-4"]
  [class~="intro-btn"]
  [class~="button"],
.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  line-height: 0.416666667in;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  height: 40px;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  color: #868686;
}

[class~="block-parallax"] [class~="content"] {
  padding-bottom: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  padding-top: 7.5pt;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-bottom-width: 0.1875pc;
}

/* [class~=block-intro][class~=layout-4] [class~=section-column][class~=right] {
  justify-content: end;
} */

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-top-style: solid;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-radius: 18.75pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-left-color: #000;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"] {
  letter-spacing: 0.125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  font-weight: 400;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-left-width: 0.010416667in;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-bottom-width: 0.010416667in;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-right-width: 0.1875pc;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-radius: 0.260416667in;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  font-style: normal;
}

[class~="block-intro"][class~="layout-6"]
  [class~="section-column"][class~="left"],
.block-intro.layout-6 .section-column.right {
  width: 50%;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-top-width: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-left-color: #e6e6e6;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  border-radius: 1.5625pc;
}

.block-newsletter.layout-2 .newsletter-form .btn-submit input[type="submit"],
[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  text-transform: uppercase;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  color: #020101;
}

[class~="block-parallax"] [class~="go-to-section"],
.block-intro.layout-6 .section-column .row {
  width: 100%;
}

.block-intro.layout-6 .section-column .row {
  align-items: normal;
}

[class~="block-parallax"] [class~="content"],
[class~="block-parallax"] {
  padding-right: 11.25pt;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-left-style: solid;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  border-radius: 50%;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-right-width: 0.010416667in;
}

[class~="block-parallax"] [class~="content"] {
  padding-top: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-top-width: 0.010416667in;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-bottom-color: #000;
}

.block-intro.layout-6 .section-column .row {
  margin-left: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  background: #000;
}

.block-intro.layout-6 .section-column .row {
  margin-bottom: 0;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  font-size: 7.5pt;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  width: 13px;
}

[class~="block-parallax"] [class~="go-to-section"] {
  position: absolute;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  animation: m 1s infinite alternate;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"],
[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-right-color: #000;
}

[class~="block-parallax"] [class~="go-to-section"] {
  bottom: 0;
}

[class~="block-parallax"] [class~="go-to-section"] {
  margin-bottom: 37.5pt;
}

[class~="block-parallax"] [class~="go-to-section"] [class~="go-to-button"] {
  animation: m 1s infinite alternate;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  color: #444;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-top-color: #000;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"],
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  font-size: 9pt;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  margin-bottom: 15pt;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  padding-bottom: 0.5pc;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  height: 0.8125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] textarea {
  border-image: none;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-bottom-style: solid;
}

.block-info .info-wrap ul {
  list-style: none;
}

[class~="block-parallax"] {
  padding-top: 320px;
}

[class~="block-contact-map"] iframe {
  border-left-style: none;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  top: -0.3125pc;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  border-radius: 0;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-bottom-style: dashed;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-excerpt"] {
  line-height: 1.75;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-right-style: solid;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-bottom-color: #e7e7e7;
}

.block-post-archives ul li:before,
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  color: #000;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"],
[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  border-radius: 50%;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"],
[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  font-family: "Lato", Sans-serif;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  border-radius: 50%;
}

.block-info .info-wrap ul {
  padding-left: 0;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  border-image: none;
}

.block-intro.layout-6 .section-column .row {
  margin-right: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after,
[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-top-style: solid;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  text-transform: uppercase;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  padding-left: 0pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-left-color: #000;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-bottom-color: #000;
}

[class~="block-contact-map"] iframe {
  border-bottom-style: none;
}

.block-intro.layout-6 .section-column .row {
  margin-top: 0;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"],
[class~="block-parallax"] [class~="go-to-section"] {
  text-align: center;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  border-radius: 50%;
}

[class~="block-product-filter"] [class~="layout-slider"],
[class~="block-intro"][class~="layout-7"]
  [class~="section-column"][class~="left"],
.block-info .info-wrap ul,
.block-intro.layout-7 .section-column.right {
  padding-bottom: 0;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="filter-title"] {
  font-weight: 700;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  letter-spacing: 0.03125in;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  border-radius: 50%;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-left-color: #666;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  border-image: none;
}

[class~="block-intro"][class~="layout-7"]
  [class~="section-column"][class~="left"],
.block-intro.layout-7 .section-column.right {
  width: 50%;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-bottom-color: #666;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li {
  height: 0.416666667in;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="email"] {
  background: #fff;
}

[class~="block-product-filter"] [class~="filter-item"] [class~="check"] {
  cursor: pointer;
}

[class~="block-intro"][class~="layout-7"]
  [class~="section-column"][class~="left"],
.block-intro.layout-7 .section-column.right {
  padding-left: 0.9375pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"],
[class~="block-parallax"] {
  position: relative;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"] {
  display: inline-block;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-right-color: #666;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"] {
  vertical-align: middle;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  background: #f1f1f1;
}

[class~="block-intro"][class~="layout-7"]
  [class~="section-column"][class~="left"],
.block-intro.layout-7 .section-column.right {
  padding-right: 0.9375pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  height: 3.125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  font-size: 0.145833333in;
}

[class~="block-intro"][class~="layout-7"]
  [class~="section-column"][class~="left"],
.block-info .info-wrap ul,
[class~="block-product-filter"] [class~="layout-slider"],
.block-intro.layout-7 .section-column.right {
  padding-top: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-pointer"] {
  border-radius: 50%;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-right-color: #e6e6e6;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  margin-top: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-top-color: #666;
}

[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label"] {
  font-size: 13px;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"] {
  float: left;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  padding-bottom: 0pc;
}

.block-info .info-wrap ul {
  padding-right: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  line-height: 37.5pt;
}

[class~="block-testimonial"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  margin-bottom: 7.5pt;
}

[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label"] {
  left: -0.25pc;
}

[class~="block-product-filter"] [class~="layout-slider"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  width: 100%;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-bottom-color: #e6e6e6;
}

/* [class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-product-filter"] [class~="layout-slider"],
[class~="block-newsletter"][class~="layout-2"][class~="one-col"]
  [class~="newsletter-title-wrap"] {
  margin-bottom: 15pt;
} */

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  border-image: none;
}

[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label-to"] {
  left: auto;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-left: 0;
}

[class~="block-product-filter"] [class~="layout-slider"] {
  padding-left: 0.3125pc;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  line-height: 18pt;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-right-color: #ddd;
}

.block-info .info-wrap ul {
  margin-left: auto;
}

.block-info .info-wrap ul {
  margin-bottom: auto;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  transform: rotate(45deg);
}

[class~="block-product-filter"] [class~="jslider"] [class~="jslider-label-to"] {
  right: -0.1875pc;
}

[class~="block-newsletter"][class~="layout-2"][class~="one-col"]
  [class~="newsletter-form"] {
  margin-right: auto;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  position: absolute;
}

[class~="block-contact-map"] iframe {
  border-right-style: none;
}

.block-post-archives ul li:before {
  line-height: 1;
}

.block-info .info-wrap ul {
  margin-right: auto;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  top: 0;
}

.block-info .info-wrap ul {
  margin-top: auto;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-bottom: 0.375pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="form-button"],
[class~="block-newsletter"][class~="layout-2"][class~="one-col"]
  [class~="newsletter-title-wrap"] {
  text-align: center;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span {
  border-top-color: currentColor;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  right: 0;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  font-size: 0;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"],
[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-left: 0.208333333in;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  height: 45px;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-right: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  transform: rotate(45deg);
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"] {
  margin-left: 7.5pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-bottom: 7.5pt;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  transform: rotateY(180deg);
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  transform: rotateY(180deg);
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  background: transparent;
}

[class~="block-contact-map"] iframe {
  border-top-style: none;
}

[class~="block-contact-map"] iframe {
  border-left-color: currentColor;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  padding-bottom: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  padding-right: 0.208333333in;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"]:after {
  transform: rotate(45deg);
}

[class~="block-product-filter"] [class~="layout-slider"] {
  padding-right: 0.3125pc;
}

[class~="block-newsletter"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:hover {
  background: #cb8161;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  padding-right: 0.677083333in;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"] {
  margin-top: 0;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  padding-top: 0;
}

[class~="block-post-search"] [class~="search-from"] [class~="btn"] {
  border-radius: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-top-color: #e6e6e6;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"] {
  margin-left: -3.75pt !important;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"] {
  margin-top: 27pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  padding-top: 7.5pt;
}

[class~="block-post-search"] [class~="search-from"] {
  position: relative;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  font-size: 12px;
}

[class~="block-post-search"] [class~="search-from"] {
  overflow: hidden;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  color: #000;
}

[class~="block-contact-form"] [class~="block-title"] [class~="sub-title"] {
  font-size: 12pt;
}

.block-contact-info .info-icon {
  font-size: 0.520833333in;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"],
[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  font-size: 14px;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  font-weight: 400;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-left-width: 0.010416667in;
}

[class~="block-contact-form"]
  [class~="contact-us-form"]
  [class~="form-button"] {
  margin-top: 30px;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  height: 42px;
}

[class~="block-instagram"] [class~="right"] [class~="row"]:first-child {
  margin-bottom: 30px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  margin-right: 12px;
}

.block-contact-info .info-icon,
[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  display: inline-block;
}

.block-testimonial.layout-2 .testimonial-content .item .testimonial-title {
  font-size: 1.5pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  line-height: 42px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  width: 16px;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-bottom-width: 0.010416667in;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-right-width: 0.010416667in;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"][class~="crimson"] {
  background: #d9bf7a;
}

[class~="block-contact-map"] iframe {
  border-bottom-color: currentColor;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-top-width: 0.010416667in;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  height: 12pt;
}

.block-testimonial.layout-2 .testimonial-content .item .testimonial-title {
  margin-left: 0;
}

[class~="block-contact-map"] iframe {
  border-right-color: currentColor;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"],
[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  text-transform: uppercase;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"] {
  color: black;
}

.block-testimonial.layout-2 .testimonial-content .item .testimonial-title {
  margin-bottom: 12px;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-left-style: solid;
}

.block-contact-info .info-icon {
  margin-bottom: 10px;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-bottom-style: solid;
}

[class~="block-contact-form"] [class~="block-title"],
[class~="block-contact-info"] [class~="info-title"] {
  margin-bottom: 40px;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  span,
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  border-image: none;
}

[class~="block-post-archives"] ul li a {
  color: #868686;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  cursor: pointer;
}

.block-testimonial.layout-2 .testimonial-content .item .testimonial-title {
  margin-right: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-right-style: solid;
}

[class~="block-contact-form"] [class~="block-title"] {
  text-align: center;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-top-style: solid;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-radius: 0.020833333in;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-left-color: #000;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"],
[class~="block-info"] [class~="info-wrap"] {
  background: #fff;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-value"] {
  background: transparent;
}

[class~="block-contact-map"] iframe {
  border-top-color: currentColor;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  letter-spacing: 0.1875pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-radius: 1.5pt;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-radius: 2px;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-radius: 2px;
}

[class~="block-contact-map"] iframe {
  border-image: none;
}

.block-testimonial.layout-2 .testimonial-content .item .testimonial-title {
  margin-top: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  font-weight: 500;
}

[class~="block-testimonial"][class~="layout-1"]:hover
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 5px;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-left: 3.125pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-radius: 0.125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-bottom-color: #000;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"],
.block-intro.layout-6 .section-column .intro-image.left {
  padding-bottom: 0;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-right-color: #000;
}

[class~="block-testimonial"][class~="layout-1"]:hover
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 0.3125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-right: 3.125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-top-color: #000;
}

[class~="block-info"] [class~="info-wrap"] {
  padding-left: 15px;
}

[class~="block-info"] [class~="info-wrap"] {
  padding-bottom: 48.75pt;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="text"] li {
  line-height: 0.416666667in;
}

[class~="block-contact-form"] [class~="contact-us-form"] input[type="text"] {
  border-image: none;
}

[class~="block-info"] [class~="info-wrap"] {
  padding-right: 11.25pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  padding-top: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-left-width: 0.75pt;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"] {
  font-size: 0.145833333in;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  letter-spacing: 1.5pt;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-bottom-width: 0.75pt;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-right-width: 0.75pt;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  color: #fff;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  background: #3c2147;
}

[class~="block-info"] [class~="info-wrap"] {
  padding-top: 3.125pc;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  transition: all 0.3s ease;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-top-width: 0.75pt;
}

[class~="block-info"] [class~="info-wrap"] {
  max-width: 5.3125in;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  transition: all 0.3s ease;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  fill: #cb8161;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-left-style: solid;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  transform: rotate(0deg);
}

[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"],
[class~="block-info"] [class~="info-wrap"] {
  margin-left: auto;
}

.block-intro.layout-6 .section-column .intro-image.left {
  padding-left: 1.25pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-bottom-style: solid;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"] {
  box-shadow: none;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-right-style: solid;
}

[class~="block-contact-form"] [class~="contact-us-form"] [class~="button"] {
  cursor: pointer;
}

.block-intro.layout-6 .section-column .intro-image.left {
  padding-right: 1.25pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-top-style: solid;
}

[class~="block-info"] [class~="info-wrap"],
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  margin-bottom: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-left-color: #dbdbdb;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  color: #cb8161;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"][class~="eggshell"] {
  background: #f1ead7;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-right-color: #e6e6e6;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="count"],
[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  display: block;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  color: #818a91;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  border-left-color: #cb8161;
}

[class~="block-info"] [class~="info-wrap"],
[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  margin-right: auto;
}

[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"],
[class~="block-info"] [class~="info-wrap"] {
  margin-top: 0;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  border-bottom-color: #cb8161;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  border-right-color: #cb8161;
}

.block-intro.layout-6 .section-column .intro-image.left {
  padding-top: 2.083333333in;
}

[class~="block-info"] [class~="info-wrap"],
.block-intro.layout-6 .section-column .intro-image.left,
[class~="block-info"] [class~="info-wrap"] [class~="icon"],
[class~="block-contact-info"] {
  text-align: center;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-top-color: #e6e6e6;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  width: 1em;
}

.block-product-filter ul.filter-items.image li {
  width: 33.33%;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"],
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a:hover {
  border-top-color: #cb8161;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  height: 1em;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  display: inline-block;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  line-height: 1;
}

.block-product-filter ul.filter-items.image li {
  padding-left: 0.3125pc;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-bottom-color: #dbdbdb;
}

.block-product-filter ul.filter-items.image li {
  padding-bottom: 0;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-right-color: #dbdbdb;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  position: relative;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  transition: all 0.3s;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  transition: all 0.3s;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] svg {
  margin-bottom: 10px;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  transition: all 0.3s;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  border-top-color: #dbdbdb;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"],
[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  border-image: none;
}

[class~="block-product-filter"]
  [class~="filter-item"]
  [class~="custom-checkbox"]
  [class~="checkmark"] {
  background-color: #fff;
}

[class~="block-contact-info"]
  [class~="info-item"]
  [class~="item-content"][class~="small-width"] {
  max-width: 3.020833333in;
}

[class~="block-info"] [class~="info-wrap"] [class~="icon"] {
  font-size: 3.125pc;
}

[class~="block-post-tags"] ul li a {
  color: #868686;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  margin-bottom: 0px;
}

[class~="block-intro"][class~="layout-6"]
  [class~="section-column"]
  [class~="intro-image"][class~="right"] {
  text-align: right;
}

[class~="block-contact-map"] iframe {
  height: 4.583333333in;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  margin-left: 0in;
}

[class~="block-newsletter"][class~="layout-2"][class~="one-col"][class~="align-left"]
  [class~="newsletter-form"] {
  margin-left: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  border-radius: 0;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  margin-bottom: 0.208333333in;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  margin-right: 0px;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  margin-top: 0pc;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  color: #d29379;
}

[class~="block-contact-form"]
  [class~="contact-us-form"]
  [class~="button"]:hover {
  background: #cb8161;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-info"]
  [class~="testimonial-customer-name"] {
  margin-top: 0;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  font-size: 2.5pc;
}

[class~="block-info"] [class~="info-wrap"] [class~="title"] {
  font-weight: 600;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"]
  [class~="icon-quote"] {
  color: #dedede;
}

[class~="block-newsletter"][class~="layout-2"][class~="one-col"][class~="align-left"]
  [class~="newsletter-form"] {
  margin-right: 0;
}

[class~="block-newsletter"][class~="layout-2"][class~="one-col"][class~="align-left"]
  [class~="newsletter-title-wrap"],
[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li {
  text-align: left;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  border-radius: 0;
}

.block-product-filter ul.filter-items.image li {
  padding-right: 0.3125pc;
}

.block-product-filter ul.filter-items.image li {
  padding-top: 0;
}

.block-post-cats ul li.current a:hover {
  color: #cb8161;
}

[class~="block-testimonial"][class~="layout-2"]
  [class~="testimonial-content"]
  [class~="item"]
  [class~="testimonial-icon"]
  [class~="icon-quote"] {
  font-size: 22.5pt;
}

[class~="block-newsletter"][class~="layout-2"]
  [class~="newsletter-form"]
  [class~="btn-submit"]:hover {
  background: transparent;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  border-radius: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="v"] {
  border-radius: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"]:hover {
  border-right-color: #cb8161;
}

.block-product-filter ul.filter-items.image li {
  margin-left: 0;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-top-color: #ddd;
}

[class~="block-banners"][class~="layout-3"]
  [class~="section-column"][class~="right"]
  [class~="block-widget-banner"]:last-child
  [class~="banner-wrapper-infor"] {
  padding-top: 0in;
}

[class~="block-product-filter"]
  ul[class~="filter-items"][class~="color"]
  li
  [class~="color"][class~="grullo"] {
  background: #a19d80;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span {
  border-image: none;
}

.block-product-filter ul.filter-items.image li {
  margin-bottom: 0.625pc;
}

[class~="block-product-filter"] ul[class~="filter-items"] li span,
[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li {
  display: block;
}

.block-product-filter ul.filter-items.image li {
  margin-right: 0;
}

.block-product-filter ul.filter-items.image li {
  margin-top: 0;
}

[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="l"],
[class~="block-product-filter"]
  [class~="layout-slider"]
  span[class~="jslider"][class~="jslider_plastic"]
  [class~="jslider-bg"]
  i[class~="r"] {
  display: none;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-quickview"]
  > a {
  box-shadow: none;
}

[class~="block-post-cats"] ul li[class~="current"] a,
[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  color: #000;
}

[class~="block-post-archives"] ul li a:hover,
[class~="block-post-cats"] ul li a:hover {
  color: #cb8161;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li {
  float: none;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li {
  margin-right: 0;
}

[class~="block-products"][class~="layout-2"]
  [class~="products-list"][class~="grid"]
  [class~="product-wapper"]
  [class~="products-thumb"]
  [class~="product-button"]
  [class~="product-btn"]:hover {
  border-top-color: #cb8161;
}

[class~="block-product-filter"] ul[class~="filter-items"][class~="color"] li {
  margin-bottom: 7.5pt;
}

[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  content: "*";
}

[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  position: absolute;
}

[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  top: -1px;
}

[class~="block-contact-form"]
  [class~="contact-us-form"]
  label[class~="required"]:before {
  right: -0.083333333in;
}

[class~="block-info"] [class~="info-wrap"] ul li:last-child {
  margin-bottom: 0;
}

[class~="block-post-tags"] ul li:hover a {
  color: #fff;
}

/*===================================================================
6. Product Functions
====================================================================*/
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span,
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  display: inline-block;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"] {
  vertical-align: middle;
}

[class~="wishlist-popup"][class~="show"] {
  opacity: 1;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"]:hover,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue:hover,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  a:hover {
  background: #cb8161;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-left-width: medium;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span:hover:before {
  color: #cc6055;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span,
[class~="wishlist-empty"] {
  text-align: center;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue:hover {
  border-left-color: #cb8161;
}

[class~="wishlist-popup"][class~="show"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  top: 50% !important;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"] {
  width: 0.166666667in;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  cursor: pointer;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span {
  height: 12pt;
}

[class~="wishlist-items"] tr td:first-child,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th:first-child {
  padding-left: 1.25pc;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  content: "";
}

[class~="wishlist-popup"][class~="show"] {
  visibility: visible;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-remove"]
  span {
  line-height: 0.166666667in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  position: absolute;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added"] {
  display: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-count-wrapper"]:before {
  content: "(";
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-name"]
  a,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-name"] {
  font-weight: 400;
}

.wishlist-items .wishlist-item .wishlist-item-remove span.removing:before {
  animation: remove-spinner 1s linear infinite;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-name"]
  a,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-name"],
.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-size: 12pt;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  font-size: 22px;
}

.wishlist-items .wishlist-item .wishlist-item-remove span.removing:before {
  animation: remove-spinner 1s linear infinite;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  background: #fff;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  z-index: 9999;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  position: relative;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-count-wrapper"]:after {
  content: ")";
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  border-collapse: collapse;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"] {
  width: 100px;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  z-index: 8 !important;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  color: #000;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  padding-left: 0.208333333in;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  padding-bottom: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-time"] {
  font-size: 0.875pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  padding-right: 0.208333333in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  line-height: 26.25pt;
}

[class~="wishlist-items"] tr {
  border-bottom-width: 0.75pt;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  padding-top: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  transition: all 0.1s ease;
}

.wishlist-items .wishlist-item .wishlist-item-actions {
  text-align: right;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  width: 95px !important;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th
  [class~="remove"] {
  text-transform: none;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  text-transform: uppercase;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  transition: all 0.1s ease;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  font-size: 0.208333333in;
}

[class~="wishlist-items"] tr td {
  border-left-width: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  height: auto;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  padding-left: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  content: "";
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  font-family: feather;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-family: "feather";
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  font-weight: 500;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  padding-bottom: 11.25pt;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
[class~="wishlist-items"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  margin-left: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after,
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  content: "";
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  max-width: 670px !important;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before,
[class~="wishlist-items"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  margin-bottom: 0;
}

[class~="wishlist-items"] tr td {
  border-bottom-width: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  max-width: 360pt;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  padding-right: 0.9375pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  text-transform: capitalize;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  margin-right: 3.75pt;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  position: absolute;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  margin-top: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  padding-right: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue:hover {
  border-bottom-color: #cb8161;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  padding-top: 15px;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  height: 0.020833333in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0 !important;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0.041666667in;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  display: inline-block;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0 !important;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-bottom-width: medium;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th
  [class~="remove"] {
  font-weight: 400;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0 !important;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  speak: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before,
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  width: 100%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0 !important;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  width: 7.5pt;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  border-radius: 0 !important;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  height: 0.625in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue:hover {
  border-right-color: #cb8161;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  background: #fff;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  width: 0.260416667in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  line-height: 60px;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  position: relative;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  width: 90%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  height: 0.260416667in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-bottom-width: 0.0625pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  padding-left: 0.0625in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-bottom-style: solid;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  height: auto;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-bottom-color: #e5e5e5;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue:hover {
  border-top-color: #cb8161;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue:hover {
  color: #fff !important;
}

[class~="wishlist-items"] tr td {
  border-right-width: 0;
}

[class~="wishlist-items"] tr td {
  border-top-width: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top:before {
  top: 3px;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  max-height: 90%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"][class~="wishlist-notice-show"] {
  top: 0;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
[class~="wishlist-items"] {
  margin-right: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  content: "";
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  padding-bottom: 0.0625in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  padding-right: 0.0625in;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  height: 100%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  top: 50%;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  border-image: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th
  [class~="remove"] {
  text-decoration: underline;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  padding-top: 0.0625in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  flex: 0 0 auto;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  margin-left: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  margin-bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"],
[class~="wishlist-popup"] [class~="wishlist-popup-inner"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  display: block;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  position: absolute;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="wishlist-items"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top {
  margin-top: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  top: 40%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  top: 12pt;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  right: 16px;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  left: 50%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  left: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  background-color: rgba(255, 255, 255, 0.7);
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  left: calc(50% - 5px);
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  position: relative;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th
  [class~="remove"] {
  cursor: pointer;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  margin-top: -0.010416667in;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  opacity: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transform: translate3d(-50%, -50%, 0);
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transform: translate3d(-50%, -50%, 0);
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  min-height: 60pt;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  padding-left: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-radius: 100%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-radius: 100%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  visibility: hidden;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-right-width: medium;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-image"]
  img {
  box-shadow: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  padding-bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr
  th
  [class~="remove"] {
  margin-left: 5px;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-style: normal;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-radius: 100%;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-price"]
  ins {
  text-decoration: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  padding-right: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  padding-top: 0;
}

[class~="wishlist-popup"] {
  position: fixed;
}

[class~="wishlist-items"] tr td {
  border-left-style: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-radius: 100%;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-weight: 400;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  display: flex;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-radius: 100%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td:first-child,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]:before {
  z-index: 7;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  flex: 1 1 auto;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  flex-direction: column;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  font-variant: normal;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transition: all 0.5s;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:before,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:hover:after,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:after {
  transform: rotate(0deg);
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transition: all 0.5s;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:hover:after,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:after,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:hover:before {
  transform: rotate(0deg);
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  content: "";
}

.wishlist-items .wishlist-item .wishlist-item-price span {
  color: #cb8161;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  margin-left: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-left-width: 0.125pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  background-color: hsla(0, 0%, 53%, 0.35);
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  margin-bottom: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  transition: all 0.3s ease;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  transition: all 0.3s ease;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"]
  > p:last-child,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="description"]
  > p:last-child {
  margin-bottom: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transition: all 0.5s;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  position: relative;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-bottom-width: 0.125pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-left-width: 0.0625pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  margin-right: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  margin-top: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  width: 100%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-bottom-width: 0.0625pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  background-color: #fff;
}

.wishlist-items .wishlist-item .wishlist-item-price span {
  font-size: 11.25pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-top-width: medium;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transition: all 0.5s;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-right-width: 0.0625pc;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-right-width: 0.125pc;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-left-width: medium;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-bottom-width: medium;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  transition: all 0.5s;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"] {
  overflow: hidden;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  position: absolute;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-right-width: medium;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  width: auto;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  min-width: 2.083333333in;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  text-transform: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="wishlist-popup"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"],
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  height: 100%;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  overflow-x: hidden;
}

[class~="wishlist-popup"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  top: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  right: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-top-width: 0.125pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-top-width: 0.0625pc;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"] tr td {
  border-bottom-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-left-style: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-mid {
  overflow-y: auto;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  max-width: 300px;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  background: #fff;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-left: 0.15625in;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-bottom: 0.15625in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  color: #000;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"] tr td,
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-right-style: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-right: 0.15625in;
}

[class~="wishlist-popup"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  left: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  padding-top: 0.15625in;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  margin-left: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  background: transparent;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  padding-left: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  padding-bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  margin-bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  margin-right: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  min-width: 3.25pc;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  margin-top: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="wishlist-popup"] {
  width: 100%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td:first-child {
  padding-left: 0.15625in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-left-style: solid;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="wishlist-items"] tr,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-bottom-style: solid;
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  font-smoothing: antialiased;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-right-style: solid;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  padding-right: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  padding-top: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-width: medium;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  *,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  box-sizing: border-box;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  background-color: #292a30;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  text-rendering: optimizeLegibility;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  font-size: 14px;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  vertical-align: middle;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-style: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  position: fixed;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  top: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-top-style: solid;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  color: #43454b;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-color: currentColor;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  z-index: 99999997;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-bottom-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  border-top-color: #e5e5e5;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  height: 0.020833333in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  border-image: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  width: 7.5pt;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  height: 0.625in;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  font-weight: 700;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  min-height: 39pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-color: #eee;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"] {
  pointer-events: auto;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-image: url("../img/remove-dark.svg");
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  line-height: 1;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  display: flex;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  flex-wrap: wrap;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-rating"]
  td
  [class~="star-rating"] {
  float: left;
}

[class~="wishlist-popup"] {
  z-index: 99999989;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-right-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  top: 50%;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  text-align: left;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  align-items: center;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  flex: 0 0 auto;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  text-align: center;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-top-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  position: relative;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  text-transform: uppercase;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  position: absolute;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  background-color: #f7f7f7;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  border-image: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  cursor: pointer;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  width: 0.395833333in;
}

.compare-popup .compare-popup-inner .compare-table .compare-table-inner,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"] {
  overflow: hidden;
}

.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  background-color: #fff;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-left-color: hsla(0, 0%, 53%, 0.35);
}

.wishlist-items .wishlist-item .wishlist-item-remove span:before {
  osx-font-smoothing: grayscale;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  top: 7.5pt;
}

.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  border-radius: 0.125pc;
}

.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  border-radius: 1.5pt;
}

.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  border-radius: 1.5pt;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-bottom-color: hsla(0, 0%, 53%, 0.35);
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  right: 10px;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  left: calc(50% - 5px);
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  color: #000;
}

[class~="wishlist-popup"] [class~="wishlist-popup-inner"],
.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  width: 100%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  height: 42px;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  z-index: 10000;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  margin-top: -0.010416667in;
}

[class~="wishlist-popup"] [class~="wishlist-popup-inner"],
.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  height: 100%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  top: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  width: 2.25pc;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-repeat: no-repeat;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  height: 27pt;
}

.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  max-height: 100%;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-price"]
  del
  span {
  color: #9b9b9b;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  background-color: hsla(0, 0%, 53%, 0.35);
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  right: 0;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody
  tr:hover
  td {
  background-color: #f8f8f8;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-left-width: 0.09375in;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  background: transparent;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-width: medium;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-right-color: hsla(0, 0%, 53%, 0.35);
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-bottom-width: medium;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-left: 0;
}

[class~="wishlist-popup"] {
  background: rgba(0, 0, 0, 0.7);
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-bottom-width: 0.09375in;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-bottom: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-right-width: medium;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-width: medium;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  padding-right: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-top-color: hsla(0, 0%, 53%, 0.35);
}

[class~="wishlist-popup"] [class~="wishlist-popup-inner"],
.compare-popup .compare-popup-inner .compare-table .compare-table-inner {
  position: relative;
}

[class~="wishlist-items"] tr td,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-style: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-top-style: solid;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  background-position: center;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  border-image: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-right-width: 0.09375in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-top-width: 0.09375in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  line-height: 48px;
}

[class~="wishlist-popup"] [class~="wishlist-empty"] {
  margin-top: 18.75pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  background-image: url("../img/checkbox-dark.svg");
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  background-size: 0.166666667in 16px;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-left-style: solid;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-left-style: none;
}

[class~="compare-popup"][class~="active"] {
  opacity: 1;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-bottom-style: solid;
}

[class~="wishlist-items"] tr td,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-left-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  text-transform: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  background-position: center left;
}

[class~="wishlist-popup"] {
  opacity: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  transition: all 0.3s ease;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"]
  [class~="compare-table-close-icon"] {
  cursor: pointer;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  padding-top: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-settings"] {
  padding-left: 20px;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-bottom-style: none;
}

[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  margin-left: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-right-style: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner,
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  display: flex;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  color: #999;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  margin-bottom: 0;
}

[class~="compare-popup"][class~="active"] {
  visibility: visible;
}

[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  flex: 1;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"] {
  font-weight: 400;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-right-style: solid;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  padding-left: 15pt;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-left-color: transparent;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-price"]
  del
  span,
[class~="wishlist-popup"] {
  font-size: 0.875pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  padding-bottom: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-bottom-color: transparent;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  padding-right: 15pt;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-left-color: #a9a8a8;
}

[class~="wishlist-items"] tr td,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-bottom-color: currentColor;
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before,
.compare-popup .compare-popup-inner {
  display: block;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"] {
  margin-right: 0;
}

.quickview-popup .quickview-container .quickview-close:hover {
  background: #e5e5e5;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"],
[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-add"]
  p {
  margin-top: 0;
}

[class~="wishlist-popup"] {
  visibility: hidden;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-right-color: transparent;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  flex-wrap: wrap;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:hover:before {
  transform: rotate(0deg);
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-top-color: transparent;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-image: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:before {
  transition: all 0.3s ease;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  border-right-width: medium;
}

[class~="wishlist-items"] tr td,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-right-color: currentColor;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-radius: 100%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-top-color: currentColor;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-radius: 100%;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-bottom-color: #a9a8a8;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after,
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:before {
  transform-origin: 50% 50%;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-radius: 100%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  a {
  text-decoration: none;
}

[class~="wishlist-popup"] {
  transition: opacity 0.3s;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  flex-direction: row;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"]
  > [class~="row"],
.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  align-items: center;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:before {
  transform: rotate(-45deg);
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  color: #fff;
}

[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-bottom: 0.052083333in;
}

[class~="wishlist-popup"] {
  transition: opacity 0.3s;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  border-top-width: medium;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  display: inline-block;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-radius: 100%;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  top: calc(50% - 10px);
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  line-height: 0.3125in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  font-size: 0.208333333in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-left-width: 0;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-left-style: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:before {
  transform: rotate(-45deg);
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"] {
  border-radius: 100%;
}

.wishlist-items .wishlist-item .wishlist-item-add a,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  background: #000;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner,
.compare-popup .compare-popup-inner,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  width: 100%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  a {
  outline: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner {
  justify-content: space-between;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  border-image: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  width: 0.395833333in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"] {
  overflow: hidden;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  a,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  display: block;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="wishlist-item-price"]
  del
  span {
  margin-right: 0.020833333in;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-bottom-style: none;
}

[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-right: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-bottom-width: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-right-color: #a9a8a8;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  font-size: 9.75pt;
}

[class~="wishlist-popup"] *,
[class~="wishlist-popup"] {
  box-sizing: border-box;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-right-width: 0;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  padding-left: 0.875pc;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-right-style: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  transform: rotate(45deg);
}

.compare-popup .compare-popup-inner {
  height: 100%;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  padding-bottom: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-top-width: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  line-height: 2.1875pc;
}

.compare-popup .compare-popup-inner {
  position: relative;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"]
  span:before {
  letter-spacing: 0.3pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"] {
  background: #fff;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  padding-right: 0.875pc;
}

[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-top: 0.052083333in;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  padding-top: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  transform: rotate(45deg);
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"] {
  height: 42px;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-top-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  position: absolute;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"] {
  color: #868686;
}

[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  margin-left: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-detail"] {
  max-width: 10.416666667in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-track"],
.quickview-container .entry-summary .price-single,
[class~="quickview-popup"] [class~="quickview-container"] [class~="slick-list"],
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  display: flex;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-left-color: currentColor;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"] {
  width: 55%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  display: inline-block;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-bottom-color: currentColor;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-right-color: currentColor;
}

[class~="wishlist-items"] tr td,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-top-color: currentColor;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-top-color: #000;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  font-size: 0.875pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  font-weight: 500;
}

[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  flex: 1;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  padding-left: 18.75pt;
}

.wishlist-items .wishlist-item .wishlist-item-add a {
  margin-top: 0.9375pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  float: right;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  width: 45%;
}

[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-bottom: 0.052083333in;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  border-image: none;
}

.wishlist-popup .wishlist-popup-inner .wishlist-popup-content > div {
  align-self: stretch;
}

[class~="quickview-popup"][class~="active"] {
  opacity: 1;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  width: 11.25pt;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  left: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  padding-left: 0.46875in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-images-slider"]
  [class~="img-thumbnail"] {
  float: unset;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  padding-bottom: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"] {
  padding-right: 0.46875in;
}

.compare-popup {
  position: fixed;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  content: "";
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="product-content-detail"] {
  max-height: 30.3125pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  padding-right: 18.75pt;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  padding-top: 0;
}

[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"],
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  margin-right: 0;
}

.quickview-container .entry-summary .price-single,
[class~="single-product"] [class~="entry-summary"] [class~="price-single"],
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after,
.compare-popup {
  width: 100%;
}

.single-product .quickview-single-info .star-rating span:before {
  letter-spacing: 0.3pc;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  margin-bottom: 0;
}

.compare-popup {
  top: 0;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  height: 15px;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="product-content-detail"] {
  padding-right: 0.083333333in;
}

[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity-button"] {
  margin-top: 0.052083333in;
}

.quickview-container .entry-summary .price-single,
[class~="single-product"] [class~="entry-summary"] [class~="price-single"] {
  line-height: 100%;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-radius: 50%;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  height: 1.5pt;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  border-right-color: currentColor;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  border-top-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  text-transform: uppercase;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-radius: 50%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-page"] {
  text-decoration: unset !important;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  top: 50%;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  display: inline-block;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  bottom: 0;
}

[class~="quickview-popup"][class~="active"] {
  visibility: visible;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-left-width: medium;
}

.quickview-container .entry-summary .price-single,
[class~="single-product"] [class~="entry-summary"] [class~="price-single"] {
  margin-bottom: 1.25pc;
}

[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="single-add-to-cart-button"],
[class~="quickview-container"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"],
[class~="quickview-container"]
  [class~="product-type-variable"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="single-add-to-cart-button"],
[class~="quickview-container"]
  [class~="product-type-variable"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"] {
  margin-bottom: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  margin-top: -0.010416667in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="product-content-detail"] {
  overflow-x: hidden;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  line-height: 23.25pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  margin-top: 0;
}

[class~="single-product"] [class~="entry-summary"] [class~="price-single"] {
  display: flex;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  line-height: 0.5in;
}

[class~="single-product"] [class~="entry-summary"] [class~="price"],
[class~="quickview-container"] [class~="entry-summary"] [class~="price"] {
  color: #cb8161;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th,
[class~="wishlist-items"] tr td {
  border-image: none;
}

[class~="quickview-container"] [class~="entry-summary"] [class~="price"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after,
[class~="single-product"] [class~="entry-summary"] [class~="price"] {
  font-size: 20px;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  transition: all 0.3s ease;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  padding-left: 25px;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="description"] {
  margin-bottom: 15px;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  text-align: center;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-bottom-width: medium;
}

[class~="quickview-popup"] [class~="quickview-container"] {
  position: fixed;
}

.compare-popup {
  left: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  padding-bottom: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-right-width: medium;
}

[class~="quickview-container"] [class~="entry-summary"] [class~="price"],
[class~="single-product"] [class~="entry-summary"] [class~="price"] {
  display: inline-block;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-top-width: medium;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-radius: 50%;
}

[class~="single-product"] [class~="entry-summary"] [class~="price"],
[class~="quickview-container"] [class~="entry-summary"] [class~="price"] {
  vertical-align: middle;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-family: "feather";
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-radius: 50%;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th {
  border-image-width: 0;
}

[class~="wishlist-items"] tr td {
  background: transparent;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-family: eleganticons;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"],
.quickview-container .quickview-single-info .star-rating {
  margin-right: 0.625pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  display: block !important;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  visibility: visible !important;
}

[class~="quickview-container"] [class~="entry-summary"] [class~="price"],
[class~="single-product"] [class~="entry-summary"] [class~="price"] {
  font-weight: 400;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  transition: all 0.3s ease;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-style: normal;
}

.compare-popup {
  height: 100%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  transition: none;
}

.compare-popup {
  z-index: 99999979;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  padding-right: 25px;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  padding-left: 0;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  padding-top: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-left-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  transition: none;
}

[class~="quickview-popup"] [class~="quickview-container"] {
  z-index: 10;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  position: relative;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  border-radius: 50%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-size: 0.145833333in;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-collapse: collapse;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-weight: 500;
}

[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="single-add-to-cart-button"],
.single-product
  .product-type-variable
  .quickview-single-info
  .entry-summary
  > .cart
  .single-add-to-cart-button,
[class~="single-product"]
  [class~="product-type-simple"]
  [class~="quickview-single-info"]
  [class~="entry-summary"]
  > [class~="cart"]
  [class~="quantity"],
.single-product
  .product-type-variable
  .quickview-single-info
  .entry-summary
  > .cart
  .quantity {
  margin-bottom: 0;
}

[class~="wishlist-items"] tr td:last-child {
  padding-right: 20px;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-left-width: 0.125pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  content: "";
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  background-color: #fff;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-bottom-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-right-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-top-style: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="product-rating"] {
  display: flex;
}

.quickview-container .quickview-single-info .star-rating,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  display: inline-block;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  background: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before,
[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  position: absolute;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  letter-spacing: 1.125pt;
}

.compare-popup {
  box-sizing: border-box;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  animation: 2s linear 0s normal none infinite running o;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-bottom-width: 0.125pc;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-transform: uppercase;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-right-width: 0.125pc;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  animation: 2s linear 0s normal none infinite running o;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-left-color: currentColor;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-top
  .wishlist-popup-close:hover:before {
  transform: rotate(0deg);
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-bottom-color: currentColor;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  line-height: 0.458333333in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-top-width: 0.125pc;
}

.compare-popup {
  pointer-events: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
.quickview-container .quickview-single-info .star-rating {
  width: 63.75pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-right-color: currentColor;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-top-color: currentColor;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  height: 1.5pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  border-image: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  width: 100%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  background: rgba(0, 0, 0, 0.75);
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  padding-right: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-size: 1.125pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-left-style: solid;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-bottom-style: solid;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-weight: 400;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-weight: 900;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  width: 0.395833333in;
}

.quickview-container .quickview-single-info .star-rating,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  font-size: 9.75pt;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"]:hover:not(
    [class~="disabled"]
  ),
.quickview-container
  .quickview-single-info
  .single-add-to-cart-button.button:hover:not(.disabled) {
  background: #cb8161;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"],
.quickview-container .quickview-single-info .star-rating {
  margin-bottom: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  top: 50%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a,
.quickview-container
  .quickview-single-info
  .single-add-to-cart-button.button:hover:not(.disabled),
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"]:hover:not(
    [class~="disabled"]
  ) {
  color: #fff;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  left: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-variant: normal;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  text-transform: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"] {
  margin-right: 7.5pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-align: center;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  padding-left: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-right: 0;
}

.wishlist-items .wishlist-item-remove span:before {
  content: "" !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-left: 0.15625in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-right-style: solid;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  margin-top: -0.010416667in;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-bottom: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-right: 0.15625in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  background-color: rgba(0, 0, 0, 0.5);
}

[class~="quickview-popup"] [class~="quickview-container"] {
  left: 50%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  height: 31.5pt;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  font-smoothing: antialiased;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  transition: all 0.3s ease;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  text-indent: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  padding-bottom: 11.25pt;
}

[class~="wishlist-items"] tr td {
  padding-left: 7.5pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"],
.single-product .quickview-single-info .review-link {
  position: relative;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:before {
  transition: all 0.3s ease;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-top: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  cursor: pointer;
}

.single-product .quickview-single-info .review-link,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"] {
  top: -0.052083333in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after,
.quickview-popup .quickview-container .quickview-close:before {
  transform-origin: 50% 50%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"],
.single-product .quickview-single-info .review-link {
  color: #868686;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-top"]
  [class~="wishlist-popup-close"]:after {
  osx-font-smoothing: grayscale;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue,
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-style: solid;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="review-link"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
.single-product .quickview-single-info .review-link {
  font-size: 10.5pt;
}

.quickview-popup .quickview-container .quickview-close:before {
  transform: rotate(-45deg);
}

.quickview-container
  .quickview-single-info
  .button.quick-buy:hover:not(.disabled) {
  background: #bb643e;
}

.compare-popup {
  opacity: 0;
}

.compare-popup {
  visibility: hidden;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"] {
  background: transparent;
}

.quickview-popup .quickview-container .quickview-close:before {
  transform: rotate(-45deg);
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-left-color: #000;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  vertical-align: top;
}

[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  margin-bottom: 13px;
}

.single-product .quickview-single-info .quantity button.minus {
  position: absolute;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  transform: rotate(45deg);
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  flex: 1;
}

.compare-popup {
  transition: all 0.8s ease-in-out;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-left: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  text-decoration: underline;
}

[class~="quickview-popup"] [class~="quickview-container"] {
  top: 50%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-bottom: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  padding-top: 1.875pc;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  top: 0.1875pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="quickview-close"]:after {
  transform: rotate(45deg);
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-top: 0;
}

[class~="quickview-container"] [class~="entry-summary"] [class~="price"] del {
  font-size: 1.125pc;
}

[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  margin-top: 18.75pt;
}

.compare-popup {
  transition: all 0.8s ease-in-out;
}

[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  padding-bottom: 0.15625in;
}

.single-product .quickview-single-info .quantity button.minus {
  left: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  white-space: nowrap;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  transition: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  transition: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-right-color: currentColor;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-bottom-color: #000;
}

.single-product .quickview-single-info .quantity button.minus {
  bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
.single-product .quickview-single-info .quantity button.minus {
  border-left-width: medium;
}

[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-width: 0.010416667in;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="loading"]:before {
  margin-right: 4.5pt;
}

[class~="quickview-popup"] {
  content: "";
}

[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-color: #efefef;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  font-weight: 500;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-family: eleganticons;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  letter-spacing: 1.125pt;
}

[class~="quickview-popup"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-image: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-transform: uppercase;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  padding-right: 0.15625in;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  line-height: 0.458333333in;
}

[class~="quickview-popup"] {
  position: fixed;
}

.single-product .quickview-single-info .quantity button.minus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-bottom-width: medium;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  content: "N";
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  position: relative;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"]:hover:not([class~="disabled"]) {
  background: #bb643e;
}

[class~="quickview-popup"] [class~="quickview-container"] {
  transform: translate(-50%, -50%);
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-right-color: #000;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  padding-top: 15px;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  vertical-align: top;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  text-align: left;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  background: rgba(0, 0, 0, 0.75);
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  color: #fff;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  display: inline-block;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-width: 0.010416667in;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  text-align: center;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-right: 0;
}

[class~="quickview-container"] [class~="entry-summary"] [class~="price"] del,
[class~="single-product"] [class~="entry-summary"] [class~="price"] del {
  margin-right: 0.1875pc;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-style: solid;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  width: auto;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-left: 0.15625in;
}

[class~="quickview-popup"] {
  top: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-top-color: #efefef;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span,
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  outline: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="description"] {
  border-image: none;
}

[class~="single-product"] [class~="entry-summary"] [class~="price"] del {
  font-size: 1.125pc;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-top-color: #000;
}

[class~="wishlist-items"] tr td {
  padding-bottom: 0.9375pc;
}

.single-product .quickview-single-info .quantity button.minus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-right-width: medium;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-top-color: currentColor;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  border-image: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  min-width: 12.5pc;
}

[class~="single-product"] [class~="quickview-single-info"] [class~="cart"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="cart"] {
  padding-left: 0;
}

[class~="quickview-container"] [class~="quickview-single-info"] [class~="cart"],
[class~="single-product"] [class~="quickview-single-info"] [class~="cart"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-bottom: 0;
}

[class~="quickview-popup"] {
  left: 0;
}

[class~="quickview-container"] [class~="quickview-single-info"] [class~="cart"],
[class~="single-product"] [class~="quickview-single-info"] [class~="cart"] {
  padding-right: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-popup-content-bot-inner"]
  a {
  cursor: pointer;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  font-size: 0.8125pc;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  max-width: 3.125in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span {
  text-decoration: underline;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  font-weight: 500;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  padding-right: 0.15625in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  text-transform: uppercase;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="star-rating"]:before,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="star-rating"]:before {
  letter-spacing: 0.3pc;
}

[class~="quickview-popup"] {
  bottom: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="quickview-container"] [class~="quickview-single-info"] [class~="cart"],
[class~="single-product"] [class~="quickview-single-info"] [class~="cart"] {
  padding-top: 0;
}

[class~="quickview-popup"] [class~="quickview-container"] {
  transform: translate(-50%, -50%);
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
.single-product .quickview-single-info .quantity button.minus {
  border-top-width: medium;
}

.single-product .quickview-single-info .quantity button.minus {
  border-left-style: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button {
  outline-color: transparent;
}

[class~="quickview-popup"] {
  width: 100%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  vertical-align: top;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  color: #000 !important;
}

.quickview-container .product-title {
  margin-top: 0;
}

.single-product .quickview-single-info .quantity button.minus {
  border-bottom-style: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  flex: 1;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-left: 0;
}

[class~="quickview-popup"] {
  height: 100%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-bottom: 0;
}

.quickview-container .product-title {
  margin-bottom: 0.125in;
}

[class~="quickview-popup"] {
  z-index: 9999;
}

.single-product .quickview-single-info .quantity button.minus {
  border-right-style: none;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-continue {
  text-decoration: unset !important;
}

.single-product .quickview-single-info .quantity button.minus {
  border-top-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  margin-top: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button {
  color: #868686;
}

.single-product .quickview-single-info .quantity button.minus {
  border-left-color: currentColor;
}

.single-product .quickview-single-info .quantity button.minus {
  border-bottom-color: currentColor;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  font-family: ElegantIcons;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  color: #43454b;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button {
  font-size: 0;
}

[class~="wishlist-items"] tr td {
  padding-right: 7.5pt;
}

[class~="wishlist-items"] tr td {
  padding-top: 0.9375pc;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  margin-right: 0.3125pc;
}

.single-product .quickview-single-info .quantity button.minus {
  border-right-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  background-color: #000;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  margin-right: 0.072916667in;
}

.quickview-container .product-title {
  font-size: 0.333333333in;
}

.single-product .quickview-single-info .quantity button.minus {
  border-top-color: currentColor;
}

.quickview-container .product-title {
  line-height: 2.5pc;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  height: 3.75pc;
}

.single-product .quickview-single-info .quantity button.minus {
  border-image: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="single-add-to-cart-button"][class~="button"] {
  white-space: nowrap;
}

.single-product .quickview-single-info .quantity button.minus {
  width: 0.395833333in;
}

.wishlist-popup
  .wishlist-popup-inner
  .wishlist-popup-content
  .wishlist-popup-content-bot
  .wishlist-popup-content-bot-inner
  span {
  cursor: pointer;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  line-height: 0.625in;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-left-width: 0.75pt !important;
}

.single-product .quickview-single-info .quantity button.minus {
  height: 31.5pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  font-weight: 400;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  transition: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-bottom-width: 0.75pt !important;
}

.single-product .quickview-single-info .quantity button.minus {
  background: transparent;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  top: 0.625in;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-left-width: medium;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-bottom-width: medium;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-right-width: 0.75pt !important;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-size: 1.125pc;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-top-width: 0.75pt !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-left-style: solid !important;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-right-width: medium;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  transition: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-top-width: medium;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after,
[class~="quickview-popup"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  display: inline-block;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-bottom-style: solid !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-right-style: solid !important;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  font-size: 0.875pc;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  font-weight: 500;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  display: block;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  width: 1.041666667in;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-top-style: solid !important;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  text-align: center;
}

[class~="wishlist-items"] tr {
  border-bottom-color: #e5e5e5;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  letter-spacing: 1.125pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-left-style: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  font-family: eleganticons;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-left-color: #b5b5b5 !important;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  content: "$";
}

.quickview-container .product-title {
  color: #000;
}

[class~="quickview-popup"] {
  background: rgba(0, 0, 0, 0.5);
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-bottom-color: #b5b5b5 !important;
}

.quickview-container .product-title {
  word-break: break-word;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  width: 100%;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  padding-left: 0.208333333in;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-right-color: #b5b5b5 !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-top-color: #b5b5b5 !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  text-transform: uppercase;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  line-height: 33pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-bottom-style: none;
}

[class~="wishlist-items"]
  [class~="wishlist-item"]
  [class~="btn-add-to-cart"]
  a[class~="added-to-cart"]:before {
  vertical-align: bottom;
}

[class~="wishlist-items"] tr td {
  vertical-align: middle;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  padding-bottom: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  padding-right: 0.208333333in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  padding-top: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  color: #fff;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"],
[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  position: relative;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  border-image: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:before {
  font-weight: 900;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  border-image: none !important;
}

[class~="quickview-popup"] {
  opacity: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"] {
  height: 33pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
.single-product .quickview-single-info .quantity {
  margin-left: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-width: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-bottom-width: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  font-weight: 400;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-style: none;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  position: absolute;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-width: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before {
  content: "L";
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  left: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead {
  z-index: 8;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-top-style: none;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-width: 0;
}

[class~="quickview-popup"] {
  visibility: hidden;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  background: #cb8161;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  transition: all 0.5s;
}

.single-product .quickview-single-info .quantity,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  margin-bottom: 0;
}

.single-product .quickview-single-info .quantity {
  margin-right: 0.072916667in;
}

[class~="quickview-popup"] {
  transition: all 0.8s ease-in-out;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
.single-product .quickview-single-info .quantity {
  margin-top: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  text-align: center;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-left-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  top: 0.03125in;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-continue"]:after {
  line-height: 1;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  transition: all 0.5s;
}

[class~="quickview-popup"] {
  transition: all 0.8s ease-in-out;
}

[class~="quickview-notices-wrapper"] {
  max-width: 1410px;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  padding-left: 11.25pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  margin-right: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-bottom-color: currentColor;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  transition: all 0.5s;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-color: currentColor;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  border-spacing: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before {
  top: 0.25pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  width: 0.5pc;
}

.quickview-container .product-title {
  display: block;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  transition: all 0.5s;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  padding-bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-color: currentColor;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  padding-right: 11.25pt;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  padding-top: 0;
}

[class~="wishlist-popup"]
  [class~="wishlist-popup-inner"]
  [class~="wishlist-popup-content"]
  [class~="wishlist-popup-content-bot"]
  [class~="wishlist-notice"] {
  transition: all 0.5s;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-image: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  z-index: 6;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  cursor: pointer;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  display: inline-block;
}

[class~="single-product"] [class~="product-title"] {
  margin-top: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before {
  content: "L";
}

.single-product .quickview-single-info .quantity {
  border-left-width: 0.75pt !important;
}

.single-product .quickview-single-info .quantity {
  border-bottom-width: 0.75pt !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  vertical-align: top;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  border-collapse: collapse;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  height: 0.5pc;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="plus"]:before,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  position: relative;
}

[class~="single-product"] [class~="product-title"] {
  margin-bottom: 0.125in;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  height: 2.625pc;
}

.single-product .quickview-single-info .quantity {
  border-right-width: 0.75pt !important;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  box-sizing: border-box;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  position: absolute;
}

.single-product .quickview-single-info .quantity {
  border-top-width: 0.75pt !important;
}

.single-product .quickview-single-info .quantity {
  border-left-style: solid !important;
}

.single-product .quickview-single-info .quantity {
  border-bottom-style: solid !important;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  width: 100%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-left: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-bottom: 3.75pt;
}

.single-product .quickview-single-info .quantity {
  border-right-style: solid !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  content: "K";
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-right: 0;
}

[class~="quickview-notices-wrapper"] {
  margin-left: auto;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  top: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  margin-top: 3.75pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  right: 0;
}

.single-product .quickview-single-info .quantity {
  border-top-style: solid !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  color: #000;
}

.single-product .quickview-single-info .quantity {
  border-left-color: #b5b5b5 !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  font-size: 12pt;
}

.single-product .quickview-single-info .quantity {
  border-bottom-color: #b5b5b5 !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="button"][class~="quick-buy"] {
  white-space: nowrap;
}

.single-product .quickview-single-info .button.quick-buy {
  transition: none;
}

.single-product .quickview-single-info .quantity {
  border-right-color: #b5b5b5 !important;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-close"] {
  cursor: pointer;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-style: none;
}

.single-product .quickview-single-info .button.quick-buy {
  transition: none;
}

.single-product .quickview-single-info .button.quick-buy,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  font-weight: 500;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-width: medium;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  margin-bottom: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"] {
  z-index: 5;
}

.single-product .quickview-single-info .button.quick-buy {
  font-size: 0.875pc;
}

[class~="quickview-notices-wrapper"] {
  margin-bottom: auto;
}

.single-product .quickview-single-info .quantity {
  border-top-color: #b5b5b5 !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-width: medium;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  width: 1.041666667in;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-width: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-width: medium;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  border-radius: 50%;
}

[class~="quickview-notices-wrapper"] {
  margin-right: auto;
}

.single-product .quickview-single-info .quantity {
  border-image: none !important;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-width: medium;
}

.single-product .quickview-single-info .quantity {
  height: 33pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"] {
  height: 100%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-style: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  z-index: 6;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  top: 4px;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-bottom-width: 0;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  margin-top: 0;
}

[class~="single-product"] [class~="product-title"] {
  font-size: 0.333333333in;
}

[class~="single-product"] [class~="product-title"] {
  line-height: 2.5pc;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr
  td {
  border-image-width: 0;
}

.single-product .quickview-single-info .button.quick-buy {
  letter-spacing: 1.125pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  padding-left: 0;
}

.single-product .quickview-single-info .quantity {
  display: inline-block;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table,
.single-product .quickview-single-info .button.quick-buy {
  padding-bottom: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  content: "K";
}

.single-product .quickview-single-info .quantity,
.single-product .quickview-single-info .button.quick-buy,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"],
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button[class~="minus"]:before {
  position: relative;
}

.quickview-popup .quickview-container .slick-dots {
  position: absolute;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-width: 0;
}

.single-product .quickview-single-info .button.quick-buy {
  text-transform: uppercase;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  padding-right: 0;
}

.single-product .quickview-single-info .button.quick-buy {
  line-height: 33pt;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  border-radius: 50%;
}

[class~="single-product"] [class~="product-title"],
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  color: #000;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-bottom-style: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-width: 0;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-top-style: none;
}

.quickview-popup .quickview-container .slick-dots {
  bottom: 0.364583333in;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-track"],
[class~="quickview-popup"] [class~="quickview-container"] [class~="slick-list"],
.single-product
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart,
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart {
  display: flex;
}

.single-product .quickview-single-info .button.quick-buy,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table {
  padding-top: 0;
}

.single-product .quickview-single-info .button.quick-buy {
  background: #cb8161;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-color: currentColor;
}

.single-product .quickview-single-info .button.quick-buy {
  color: #fff;
}

.single-product .quickview-single-info .button.quick-buy {
  text-align: center;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img,
[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-color: currentColor;
}

[class~="quickview-notices-wrapper"] {
  margin-top: auto;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-right-color: currentColor;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-top-color: currentColor;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  border-radius: 50%;
}

[class~="quickview-container"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-image: none;
}

[class~="quickview-notices-wrapper"],
.single-product
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart,
.single-product .quickview-single-info .button.quick-buy,
.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart {
  width: 100%;
}

.single-product .quickview-single-info .button.quick-buy {
  padding-left: 11.25pt;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"] {
  overflow: auto;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-style: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 0.9375pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 0.9375pc;
}

.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart,
.single-product
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart {
  margin-top: 0;
}

[class~="single-product"] [class~="product-title"] {
  word-break: break-word;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-left-width: medium;
}

.single-product .quickview-single-info .button.quick-buy {
  padding-right: 11.25pt;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-right-style: none;
}

.single-product .quickview-single-info .button.quick-buy {
  cursor: pointer;
}

[class~="single-product"] [class~="product-title"] {
  display: block;
}

.single-product .quickview-single-info .button.quick-buy {
  display: inline-block;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-style: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-bottom-width: medium;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-right-width: medium;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-left-color: currentColor;
}

.single-product .quickview-single-info .button.quick-buy {
  vertical-align: top;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-bottom-color: currentColor;
}

.single-product .quickview-single-info .button.quick-buy {
  margin-left: 0;
}

.single-product .quickview-single-info .button.quick-buy {
  margin-bottom: 3.75pt;
}

.single-product .quickview-single-info .button.quick-buy {
  margin-right: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-right-color: currentColor;
}

.single-product .quickview-single-info .button.quick-buy {
  margin-top: 3.75pt;
}

.single-product .quickview-single-info .button.quick-buy {
  white-space: nowrap;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-top-color: currentColor;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-top-width: medium;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  padding-right: 0;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  border-image: none;
}

.quickview-container
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart,
.single-product
  .product-type-simple
  .quickview-single-info
  .entry-summary
  > .cart {
  flex-wrap: wrap;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-bottom-style: none;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  top: calc(50% - 10px);
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  height: 2.625pc;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"] {
  font-size: 15pt;
}

.quickview-popup .quickview-container .slick-dots li,
[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button {
  border-radius: 50%;
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before,
.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  tbody {
  z-index: 7;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  height: auto;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  text-align: center;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  font-size: 12pt;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button,
.quickview-popup .quickview-container .slick-dots li {
  border-radius: 50%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  input {
  font-weight: 500;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-left"] {
  left: 11.25pt;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="img-quickview"]
  [class~="slick-arrow"][class~="fa-angle-right"] {
  right: 0.9375pc;
}

.quickview-popup .quickview-container .slick-dots li {
  width: 0.5pc;
}

.quickview-popup .quickview-container .slick-dots li {
  height: 0.5pc;
}

[class~="wishlist-items"] tr,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  border-image: none;
}

.quickview-popup .quickview-container .slick-dots li {
  border-radius: 50%;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-radius: 0.125pc;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover,
[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:focus {
  color: #000;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-width: medium;
}

.quickview-popup .quickview-container .slick-dots li {
  border-radius: 50%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-width: medium;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-width: medium;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-width: medium;
}

.quickview-popup .quickview-container .slick-dots li {
  border-radius: 50%;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-style: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-style: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-radius: 0.125pc;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-style: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  border-radius: 0.125pc;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-style: none;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-left-color: currentColor;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-bottom-color: currentColor;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-right-color: currentColor;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-top-color: currentColor;
}

[class~="single-product"]
  [class~="quickview-single-info"]
  [class~="quantity"]
  button:hover {
  border-image: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr[class~="tr-image"]
  img {
  box-shadow: none;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  tbody
  tr:nth-child(2n)
  td {
  background-color: #fbfbfb;
}

[class~="compare-popup"]
  [class~="compare-popup-inner"]
  [class~="compare-table"]
  [class~="compare-table-inner"]
  [class~="compare-table-items"]
  table
  thead
  tr {
  padding-top: 0;
}

[class~="quickview-popup"]
  [class~="quickview-container"]
  [class~="slick-dots"]
  li
  button:before,
.quickview-popup .quickview-container .slick-dots li button:after {
  content: none;
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  top: 0;
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  left: 0;
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  background-color: rgba(255, 255, 255, 0.7);
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  opacity: 0;
}

[class~="wishlist-popup"] [class~="wishlist-popup-content-mid"]:before {
  visibility: hidden;
}

.compare-popup
  .compare-popup-inner
  .compare-table
  .compare-table-inner
  .compare-table-items
  table
  thead
  tr
  th
  a {
  text-decoration: none;
}

/*===================================================================
7. Shop
====================================================================*/
/**
 * 7.1. Shop - Top Bar
 */
.products-topbar {
  margin-bottom: 30px;
}

.products-topbar .products-topbar-left {
  float: left;
}

.products-topbar .products-topbar-right {
  float: right;
}

.products-topbar .products-count {
  margin-top: 5px;
  font-size: 16px;
}

.products-topbar .products-sort {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  text-transform: capitalize;
  cursor: pointer;
  float: right;
}

.products-topbar .products-sort .sort-toggle {
  position: relative;
  border: 1px solid #000;
  display: inline-block;
  line-height: 34px;
  padding: 0 20px;
}

.products-topbar .products-sort .sort-toggle:after {
  content: "\43";
  font-family: eleganticons;
  font-size: 13px;
  padding-left: 5px;
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0;
  vertical-align: 0;
  border: 0;
  line-height: 1;
  position: relative;
  top: 1px;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform-origin: 65% 50%;
  color: #000;
}

.products-topbar .products-sort .sort-list {
  min-width: 210px;
  z-index: 9999;
  right: 0;
  left: auto !important;
  padding: 10px 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  border-radius: 0;
}

.products-topbar .products-sort .sort-list li {
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 400;
}

.products-topbar .products-sort .sort-list li a {
  color: #868686;
}

.products-topbar .products-sort .sort-list li a:hover {
  color: #000;
}

.products-topbar .layout-toggle {
  float: right;
  list-style: none;
  padding: 9px 10px;
  margin-right: 10px;
  border: 1px solid #e1e1e1;
  background: #fff;
  line-height: 1;
}

.products-topbar .layout-toggle li {
  float: left;
  padding-right: 10px;
  position: relative;
}

.products-topbar .layout-toggle li:last-child {
  padding-right: 0;
}

.products-topbar .layout-toggle li a {
  display: inline-block;
  text-align: center;
  height: 17px;
  transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 17px;
  padding: 0;
  border: none;
}

.products-topbar .layout-toggle li a.active,
.products-topbar .layout-toggle li a:hover {
  background: #fff;
  border-color: #000;
}

.products-topbar .layout-toggle li a .icon-column {
  vertical-align: middle;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 0;
  display: inline-block;
}

.products-topbar .layout-toggle li a .icon-column .layer {
  position: absolute;
  left: 0;
  height: 5px;
}

.products-topbar .layout-toggle li a .icon-column .layer.first {
  top: 0;
}

.products-topbar .layout-toggle li a .icon-column .layer.middle {
  top: 6px;
}

.products-topbar .layout-toggle li a .icon-column .layer.last {
  top: 12px;
}

.products-topbar .layout-toggle li a .icon-column .layer > span {
  position: absolute;
  display: block;
  width: 5px;
  height: 100%;
  background: #cecece;
  border-radius: 1px;
  border-radius: 1px;
  border-radius: 1px;
  border-radius: 1px;
  border-radius: 1px;
}

.products-topbar .layout-toggle li a.active .icon-column .layer > span,
.products-topbar .layout-toggle li a:hover .icon-column .layer > span {
  background: #000;
}

.products-topbar .layout-toggle li a .icon-column .layer > span:first-child {
  left: 0;
}

.products-topbar .layout-toggle li a .icon-column .layer > span:nth-child(2) {
  left: 6px;
}

.products-topbar .layout-toggle li a .icon-column .layer > span:last-child {
  left: 12px;
}

.products-topbar .layout-toggle li a.layout-list {
  width: 25px;
}

.products-topbar
  .layout-toggle
  li
  a.layout-list
  .icon-column
  .layer
  > span:first-child {
  left: 0;
}

.products-topbar
  .layout-toggle
  li
  a.layout-list
  .icon-column
  .layer
  > span:last-child {
  left: 6px;
  width: 17px;
  height: 1px;
  top: 2px;
}

/**
 * 7.2. Shop - Products
 */
.products-list.grid .product-wapper {
  position: relative;
  padding: 0;
  margin: 0 0 40px;
  text-align: center;
}

.products-list.grid .slick-list .product-wapper {
  margin-bottom: 0;
}

.products-list.grid .product-wapper {
  position: relative;
  padding: 0;
  margin: 0 0 40px;
  text-align: center;
}

.products-list.grid .product-wapper .products-thumb {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.products-list.grid .product-wapper .products-thumb:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}

.products-list.grid .product-wapper .products-thumb .product-lable > div {
  overflow: hidden;
  display: inline-block;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-lable
  > div:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}

.products-list.grid .product-wapper .products-thumb .product-stock {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: #fff;
  color: #000;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

.products-list.grid .product-wapper .products-thumb .product-thumb-hover {
  position: relative;
  display: block;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-thumb-hover
  .post-image {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: auto;
}

.products-list.grid
  .product-wapper:hover
  .products-thumb
  .product-thumb-hover
  .post-image {
  opacity: 0;
  visibility: hidden;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-thumb-hover
  .hover-image {
  opacity: 0;
  visibility: hidden;
  max-width: 100%;
  height: auto;
}

.products-list.grid
  .product-wapper:hover
  .products-thumb
  .product-thumb-hover
  .hover-image {
  opacity: 1;
  visibility: visible;
}

.products-list.grid .product-wapper .products-thumb .product-button {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  z-index: 9;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  > :nth-child(odd) {
  transform: translateY(-15px);
  transform: translateY(-15px);
}

.products-list.grid .product-wapper .products-thumb .product-button > * {
  margin: 0 5px;
  transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transform: translateY(15px);
  position: relative;
}

.products-list.grid .product-wapper:hover .products-thumb .product-button > * {
  opacity: 1;
  visibility: visible;
  transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.products-list.grid .product-wapper .products-thumb .product-button > :before {
  content: attr(data-title);
  position: absolute;
  padding: 0 10px;
  background: #000;
  color: #fff;
  top: -30px;
  line-height: 23px;
  white-space: nowrap;
  left: 50%;
  font-size: 12px;
  z-index: 10;
  transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  pointer-events: none;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  > :hover:after,
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  > :hover:before {
  opacity: 1;
  visibility: visible;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn {
  font-size: 0;
  background: #fff;
  text-align: center;
  position: relative;
  white-space: nowrap;
  margin-top: 0;
  border: 0;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  letter-spacing: normal;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn:hover {
  background: #cb8161;
  border-color: #cb8161;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn:before {
  content: "\e901";
  font-family: icomoon;
  font-size: 16px;
  line-height: 40px;
  color: #000;
  transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn:hover:before {
  color: #fff;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.adding:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 16px;
  height: 16px;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 7px;
  left: 0;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.adding:hover:before {
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-top-color: #fff;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.added:before {
  color: #cb8161;
  transition: all 0.1s ease;
  transition: all 0.1s ease;
  position: relative;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-btn.added:hover:before {
  color: #fff;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .product-btn:before {
  content: "\e902";
  width: 16px;
  top: 0;
  margin: 0;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .product-btn.loading:before {
  content: "";
  margin-top: 11px;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .added-to-cart:before {
  content: "\4e";
  color: #cb8161;
  font-family: ElegantIcons;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-add-to-cart
  .added-to-cart:hover:before {
  color: #fff;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-compare
  .product-btn:before {
  content: "\e913";
  font-size: 20px;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .btn-compare
  .product-btn.adding:before {
  content: "";
}

.products-list.grid .product-wapper .products-thumb .product-button > :after {
  top: -5px;
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview {
  text-align: center;
  background: #fff;
  font-size: 0;
  display: block;
  white-space: nowrap;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview:hover
  > a {
  background: #cb8161;
  border-color: #cb8161;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  > a {
  color: #000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 0;
  display: inline-block;
  position: relative;
  outline: unset;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  > a
  > i {
  font-size: 16px;
  color: #000;
  line-height: 40px;
  transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  .loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 18px;
  height: 18px;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 8px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview:hover
  .loading:before,
.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview:hover
  > a
  > i {
  color: #fff;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  .loading:hover:before {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-top-color: #fff;
}

.products-list.grid
  .product-wapper
  .products-thumb
  .product-button
  .product-quickview
  .loading
  i {
  display: none;
}

.products-list.grid .product-wapper .products-content {
  text-align: center;
  margin-top: 15px;
}

.products-list.grid .product-wapper .products-content h3.product-title {
  font-size: 14px;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  margin-top: 2px;
  margin-bottom: 6px;
  letter-spacing: 1.5px;
  font-weight: 400;
  line-height: 18px;
}

.products-list.grid .product-wapper .products-content h3.product-title a {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.products-list.grid
  .product-wapper
  .products-content
  h3.product-title
  a:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}

.products-list.grid .product-wapper .products-content .rating {
  margin-bottom: 7px;
}

.products-list.grid .product-wapper .products-content .price {
  line-height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.products-list.grid .product-wapper .products-content .price:before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent 0, #e8e8e8 50%, transparent);
  animation: h 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  opacity: 0;
  visibility: hidden;
}

.products-list.grid .product-wapper .products-content .price del {
  line-height: 100%;
  margin-right: 2px;
}

.products-list.grid .product-wapper .btn-add-to-cart {
  margin-top: 15px;
  text-align: center;
}

.products-list.grid .product-wapper .btn-add-to-cart a {
  display: inline-block;
  border: 1px solid #e8e8e8;
  color: #a9a8a8;
  font-size: 15px;
  line-height: 48px;
  width: 100%;
  padding: 0 15px;
  transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-align: center;
  position: relative;
  text-transform: lowercase;
}

.products-list.grid .product-wapper .btn-add-to-cart a.loading {
  transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.products-list.grid .product-wapper .btn-add-to-cart a:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}

.products-list.grid .product-wapper .btn-add-to-cart a:before {
  content: "\e901";
  font-family: icomoon;
  font-size: 20px;
  line-height: 30px;
  color: #a9a8a8;
  transition: all 0.1s ease;
  transition: all 0.1s ease;
  margin: 0;
  display: inline-block;
  position: relative;
  left: 0;
  top: 4px;
  transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 10px;
}

.products-list.grid .product-wapper .btn-add-to-cart a.loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid #a9a8a8;
  border-top-color: #000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 4px;
}

.products-list.grid .product-wapper .btn-add-to-cart a:hover:before {
  color: #fff;
}

.products-list.grid .product-wapper .btn-add-to-cart a.added {
  display: none;
}

.products-list.grid .product-wapper .btn-add-to-cart a.added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  text-indent: 0;
  text-transform: none;
}

.products-list.grid .product-wapper .rating {
  margin-bottom: 8px;
}

.products-list.grid .product-wapper .rating .star:before,
.products-list.grid .product-wapper .rating .star:after {
  font-size: 11px;
}

.products-list.grid .product-wapper .rating .count {
  font-size: 12px;
  margin-left: 5px;
  color: #9b9b9b;
}

.products-list.list .product-wapper {
  margin-bottom: 40px;
  transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.products-list.list .product-wapper .products-thumb {
  position: relative;
  overflow: hidden;
}

.products-list.list .product-wapper .products-thumb .product-thumb-hover {
  position: relative;
  display: block;
  overflow: hidden;
}

.products-list.list .product-wapper .products-thumb .post-image {
  transition: all 0.3s;
  transition: all 0.3s;
}

.products-list.list .product-wapper .products-thumb:hover .post-image {
  transform: translate(100%);
  transform: translate(100%);
}

.products-list.list .product-wapper .products-thumb .hover-image {
  top: 0;
  position: absolute;
  overflow: hidden;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  transform: translate(-100%);
  transform: translate(-100%);
  transition: all 0.3s;
  transition: all 0.3s;
}

.products-list.list .product-wapper .products-thumb:hover .hover-image {
  transform: translate(0);
  transform: translate(0);
}

.products-list.list .product-wapper .products-thumb .product-quickview {
  position: absolute;
  top: calc(50% - 21px);
  left: calc(50% - 21px);
  width: 42px;
  height: 42px;
  background: #fff;
  text-align: center;
  line-height: 42px;
  z-index: 9999;
  transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transform: scale(0);
}

.products-list.list .product-wapper:hover .products-thumb .product-quickview {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transform: scale(1);
}

.products-list.list .product-wapper .products-thumb .product-quickview:hover {
  background: #000;
}

.products-list.list .product-wapper .products-thumb .product-quickview a {
  font-size: 0;
  width: 42px;
  height: 42px;
  display: inline-block;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.products-list.list .product-wapper .products-thumb .product-quickview a i {
  font-size: 16px;
  color: #000;
  line-height: 42px;
}

.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview
  .loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 18px;
  height: 18px;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 3px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview:hover
  .loading:before,
.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview:hover
  > a
  > i {
  color: #fff;
}

.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview
  .loading:hover:before {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-top-color: #fff;
}

.products-list.list
  .product-wapper
  .products-thumb
  .product-quickview
  .loading
  i {
  display: none;
}

.products-list.list .product-wapper .products-content .product-title {
  font-size: 28px;
  margin-top: -2px;
  margin-bottom: 12px;
  font-weight: 500;
}

.products-list.list .product-wapper .products-content .price {
  font-size: 18px;
  color: #cb8161;
  margin-bottom: 10px;
  display: block;
}

.products-list.list .product-wapper .products-content .rating {
  margin-bottom: 24px;
}

.products-list.list .product-wapper .products-content .product-button {
  display: inline-flex;
  flex-wrap: wrap;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  .product-btn {
  display: inline-block;
  text-align: center;
  color: #fff;
  height: 42px;
  position: relative;
  clear: both;
  padding: 0 30px;
  background: #000;
  border: 1px solid #000;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  .product-btn:hover {
  background: transparent;
  color: #000;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  a.loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid #a9a8a8;
  border-top-color: #000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 3px;
  margin-right: 10px;
}

.products-list.list .product-wapper .products-content .btn-add-to-cart a.added {
  display: none;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-add-to-cart
  a.added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  margin-right: 5px;
  vertical-align: bottom;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn {
  margin-top: 0;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 500;
  padding: 0;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  float: left;
  font-size: 0;
  background: transparent;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn:hover {
  cursor: pointer;
  background: #000;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn:before {
  content: "\f08a";
  position: absolute;
  left: 0;
  width: 100%;
  color: #000;
  font-family: FontAwesome;
  font-size: 17px;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-wishlist
  .product-btn:hover:before {
  color: #fff;
}

.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.adding:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-top-color: #000;
  width: 16px;
  height: 16px;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 7px;
  left: 0;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.adding:hover:before {
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-top-color: #fff;
}

.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.added:before {
  color: #cb8161;
}

.products-list.list
  .product-wapper
  .products-content
  .product-button
  .product-btn.added:hover:before {
  color: #fff;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn {
  text-align: center;
  background: #fff;
  font-size: 0;
  display: block;
  white-space: nowrap;
  color: #000;
  width: 42px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  border: 0;
  cursor: pointer;
  border: 1px solid #000;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn:before {
  font-size: 20px;
  color: #000;
  line-height: 38px;
  transition: all 0.1s ease;
  transition: all 0.1s ease;
  content: "\e913";
  font-family: icomoon;
  margin: 0;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn:hover {
  background: #000;
  border-color: #000;
}

.products-list.list
  .product-wapper
  .products-content
  .btn-compare
  .product-btn:hover:before {
  color: #fff;
}

.products-list.list .product-wapper .products-content .product-description {
  margin-top: 30px;
  display: inline-block;
  width: 100%;
  padding-top: 30px;
  border-top: 1px solid #e5e5e5;
}

/**
 * 7.3. Shop - Details
 */
.shop-details .product-images > .row {
  margin: 0 -7.5px;
}

.shop-details .product-images > .row > div {
  padding: 0 7.5px;
}

.shop-details .product-images .content-thumbnail-scroll {
  position: relative;
}

.shop-details .product-images .content-thumbnail-scroll .slick-list {
  padding: 0 !important;
}

.shop-details .product-images .content-thumbnail-scroll .img-item {
  padding-bottom: 15px;
  vertical-align: top;
  border: 0;
  cursor: pointer;
}

.shop-details
  .product-images
  .content-thumbnail-scroll
  .img-item
  .img-thumbnail-scroll {
  vertical-align: top;
  display: inline-block;
  transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid hsla(0, 0%, 67%, 0.25);
  overflow: hidden;
  margin: 1px;
}

.shop-details
  .product-images
  .content-thumbnail-scroll
  .img-item.slick-current
  .img-thumbnail-scroll {
  border-color: #000;
}

.shop-details .product-images .content-thumbnail-scroll .slick-arrow {
  right: calc(50% - 15px);
  left: auto;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 0;
  transform: none;
  transform: none;
  background: #000;
  color: #fff;
  font-family: FontAwesome;
  text-align: center;
}

.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-left {
  top: 0;
}

.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-left:before {
  content: "\f106";
}

.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-right {
  bottom: 10px;
  top: auto;
}

.shop-details
  .product-images
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-right:before {
  content: "\f107";
}
.block-instagram .slick-wrap .container-fluid {
  padding: 0 !important;
}
.col-5-new {
  width: 20%;
  flex: 0 0 auto;
}
.colectionTitle {
  text-align: center;
  color: #3c2147;
  font-weight: 700;
}
.colectionRow {
  justify-content: center;
}
.shop-details
  .product-images
  div.col-md-2:hover
  .content-thumbnail-scroll
  .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.shop-details
  .product-images
  div.col-md-2:hover
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-left {
  top: -10px;
}

.shop-details
  .product-images
  div.col-md-2:hover
  .content-thumbnail-scroll
  .slick-arrow.fa-angle-right {
  bottom: 0;
}

.shop-details
  .product-images
  .image-thumbnail
  .slick-slide.slick-current
  .img-thumbnail-scroll,
.shop-details
  .product-images
  .image-thumbnail
  .slick-slide:hover
  .img-thumbnail-scroll,
.shop-details .product-images .image-thumbnail .slick-slide:hover a,
.shop-details .product-images .image-thumbnail .slick-slide a.active {
  border-color: #000;
}

.shop-details .product-images .img-item img {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.shop-details .product-images .main-image .slick-arrow {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: hsla(0, 0%, 100%, 0.5);
  color: #000;
  font-family: FontAwesome;
  text-align: center;
  top: calc(50% - 15px);
  opacity: 1;
  visibility: visible;
  z-index: 9998;
  font-size: 18px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.shop-details .product-images .main-image .slick-arrow:hover {
  background: #000;
  color: #fff;
}

.shop-details .product-images .main-image .slick-arrow.fa-angle-left {
  left: 20px;
}

.shop-details .product-images .main-image .slick-arrow.fa-angle-left:before {
  content: "\f104";
}

.shop-details .product-images .main-image .slick-arrow.fa-angle-right {
  right: 20px;
}

.shop-details .product-images .main-image .slick-arrow.fa-angle-right:before {
  content: "\f105";
}

.shop-details .product-info {
  padding-left: 30px;
}

.shop-details .product-info .title {
  font-size: 30px;
  line-height: 38px;
  margin-top: 0;
  margin-bottom: 12px;
}

.shop-details .product-info .price {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 15px;
  display: block;
}

.shop-details .product-info .price del {
  font-size: 18px;
  margin-right: 2px;
}

.shop-details .product-info .description {
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid #efefef;
  margin-bottom: 20px;
}

.shop-details .variations table,
.shop-details .variations tr td {
  border: none;
  padding: 0;
}

.shop-details .variations .label {
  font-size: 12px;
  color: #000;
  margin-bottom: 0;
  margin-right: 15px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  width: 70px;
}

.shop-details .variations .label:after {
  position: relative;
  content: ":";
  top: 0;
  right: 0;
  display: inline-block;
  padding-left: 5px;
}

.shop-details .variations tr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.shop-details .variations .attributes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.shop-details .variations .attributes ul li {
  display: inline-block;
  margin-right: 10px;
}

.shop-details .variations .attributes ul.text li span {
  width: 28px;
  height: 28px;
  line-height: 26px;
  display: inline-block;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  font-size: 13px;
  text-align: center;
  border: 1px solid #d5d5d5;
  cursor: pointer;
}

.shop-details .variations .attributes ul.text li span:hover {
  border: 1px solid #000;
  color: #000;
}

.shop-details .variations .attributes ul.colors li span {
  width: 28px;
  height: 28px;
  display: inline-block;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  background: #e5d4c2;
  cursor: pointer;
  position: relative;
}

.shop-details .variations .attributes ul.colors li span.color-2 {
  background: #d9bf7a;
}

.shop-details .variations .attributes ul.colors li span.color-3 {
  background: #d4937d;
}

.shop-details .variations .attributes ul.colors li span:before {
  content: "";
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
  width: 36px;
  height: 36px;
  border: 1px solid #000;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  transform: unset;
  transform: unset;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

.shop-details .variations .attributes ul.colors li span:hover:before {
  opacity: 1;
  visibility: visible;
}

.shop-details .buttons {
  display: flex;
  vertical-align: top;
  flex-wrap: wrap;
}

.shop-details .buttons .add-to-cart-wrap {
  display: flex;
  margin: 5px 0;
  flex-wrap: wrap;
  flex: 1;
}

.shop-details .buttons .add-to-cart-wrap .quantity {
  margin: 0;
  border: 1px solid #b5b5b5 !important;
  height: 50px;
  display: inline-block;
  margin-right: 7px;
  position: relative;
}

.shop-details .buttons .add-to-cart-wrap .quantity button {
  outline-color: transparent;
  color: #868686;
  font-size: 0;
}

.shop-details .buttons .add-to-cart-wrap .quantity button:before {
  font-family: eleganticons;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
}

.shop-details .buttons .add-to-cart-wrap .quantity button:hover {
  border: none;
  color: #000;
}

.shop-details .buttons .add-to-cart-wrap .quantity button.plus {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  line-height: 24px;
  border: none;
  width: 38px;
  height: 46px;
}

.shop-details .buttons .add-to-cart-wrap .quantity button.plus:before {
  content: "\4c";
  position: relative;
  top: 4px;
}

.shop-details .buttons .add-to-cart-wrap .quantity button.minus {
  position: absolute;
  left: 0;
  bottom: 0;
  border: none;
  width: 38px;
  height: 46px;
  background: transparent;
}

.shop-details .buttons .add-to-cart-wrap .quantity button.minus:before {
  content: "\4b";
  position: relative;
  top: 4px;
}

.shop-details .buttons .add-to-cart-wrap .quantity input {
  width: 125px;
  border: 0;
  height: 46px;
  text-align: center;
  padding: 0 38px;
  font-size: 17px;
  font-weight: 500;
}

.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart {
  transition: none;
  transition: none;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 50px;
  position: relative;
  background: #000;
  color: #fff;
  text-align: center;
  margin-right: 20px;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  margin: 0;
  white-space: nowrap;
}

.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a {
  color: #fff;
  display: block;
}

.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart:hover {
  background: #cb8161;
}

.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a.loading:before {
  position: relative;
  display: inline-block;
  content: "";
  border: 2px solid #a9a8a8;
  border-top-color: #000;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  background: none;
  animation: 2s linear 0s normal none infinite running o;
  animation: 2s linear 0s normal none infinite running o;
  text-indent: 0;
  top: 3px;
  margin-right: 10px;
}

.shop-details .buttons .add-to-cart-wrap .btn-add-to-cart a.added {
  display: none;
}

.shop-details
  .buttons
  .add-to-cart-wrap
  .btn-add-to-cart
  a.added-to-cart:before {
  content: "\4e";
  font-family: ElegantIcons;
  margin-right: 5px;
  vertical-align: bottom;
}

.shop-details .buttons .btn-quick-buy {
  width: 100%;
}

.shop-details .buttons .btn-quick-buy .product-btn {
  transition: none;
  transition: none;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 50px;
  position: relative;
  background: #cb8161;
  color: #fff;
  text-align: center;
  margin-right: 20px;
  padding: 0 15px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 5px 0;
  white-space: nowrap;
}

.shop-details .buttons .btn-quick-buy .product-btn:hover {
  background: #bb643e;
}

.shop-details .buttons .btn-wishlist .product-btn {
  margin-top: 20px;
  text-align: center;
  color: #000;
  display: inline-flex;
  cursor: pointer;
  vertical-align: top;
  margin-right: 25px;
  align-items: center;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0;
}

.shop-details .buttons .btn-compare .product-btn {
  margin-top: 20px;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0;
}

.shop-details .buttons .btn-wishlist .product-btn:before,
.shop-details .buttons .btn-compare .product-btn:before {
  content: "\e901";
  font-family: icomoon;
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #cecece;
  margin-right: 10px;
  color: #000;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
}

.shop-details .buttons .btn-wishlist .product-btn.added:before {
  color: #cb8161;
}

.shop-details .buttons .btn-wishlist .product-btn.added:hover:before {
  color: #fff;
}

.shop-details .buttons .btn-compare .product-btn:before {
  content: "\e913";
  font-size: 20px;
}

.shop-details .buttons .btn-wishlist .product-btn:hover:before,
.shop-details .buttons .btn-compare .product-btn:hover:before {
  color: #fff;
  background: #000;
  border-color: #000;
}

.shop-details .product-info .product-meta {
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  border-color: #efefef;
  border-width: 1px 0;
  border-style: solid;
  padding: 20px 0;
  margin: 30px 0;
}

.shop-details .product-info .product-meta > span {
  display: inline-flex;
  color: #868686;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-right: 25px;
}

.shop-details .product-info .product-meta > span span,
.shop-details .product-info .product-meta > span a {
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  margin-left: 5px;
}

.shop-details .product-info .social-share > a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #868686;
  font-weight: 500;
  margin-right: 40px;
}

.shop-details .product-info .social-share > a i {
  color: #000;
  font-size: 15px;
  margin-right: 7px;
}

.shop-details .product-tabs {
  margin-top: 60px;
  padding-bottom: 50px;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
}

.shop-details .product-tabs .product-tabs-wrap {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
}

.shop-details .product-tabs .nav-tabs {
  text-align: center;
  padding: 35px 0 0;
  margin-bottom: 25px;
  border-bottom: none;
  display: block;
}

.shop-details .product-tabs .nav-tabs .nav-item {
  display: inline-block;
  margin: 5px 20px;
}

.shop-details .product-tabs .nav-tabs .nav-item a {
  font-family: Cormorant Garamond, serif;
  border: none;
  font-size: 24px;
  font-weight: 600;
  color: #868686;
  padding: 0 0 8px 0;
  position: relative;
}

.shop-details .product-tabs .nav-tabs .nav-item a:before {
  position: absolute;
  bottom: 0;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 0;
  height: 2px;
  background: #000;
}

.shop-details .product-tabs .nav-tabs .nav-item a:hover,
.shop-details .product-tabs .nav-tabs .nav-item a.active {
  color: #000;
}

.shop-details .product-tabs .nav-tabs .nav-item a:hover:before,
.shop-details .product-tabs .nav-tabs .nav-item a.active:before {
  width: 100%;
}

.shop-details .product-tabs .tab-content .tab-pane p {
  line-height: 2em;
}

.shop-details .product-tabs .product-attributes th,
.shop-details .product-tabs .product-attributes td {
  border: 1px solid #e5e5e5;
  padding: 16px 30px;
  vertical-align: top;
}

.shop-details .product-tabs .product-attributes th {
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
}

.shop-details .product-tabs .product-reviews .reviews-title {
  font-size: 25px;
  margin-bottom: 30px;
}

.shop-details .product-tabs .product-reviews .comment-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  margin-bottom: 30px;
}

.shop-details .product-tabs .product-reviews .comment-list li {
  padding: 0 15px;
  flex: 1;
  min-width: 50%;
  margin-bottom: 25px;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .content-comment-container {
  border: 1px solid #d9d9d9;
  padding: 30px;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 10px;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  > img {
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  margin: 0;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .rating {
  margin-bottom: 4px;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .comment-text {
  margin: 0;
  padding-left: 15px;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .review-author {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .comment-container
  .review-time {
  font-size: 13px;
}

.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .content-comment-container
  .description,
.shop-details
  .product-tabs
  .product-reviews
  .comment-list
  li
  .content-comment-container
  .description
  p {
  color: #000;
  line-height: 22px;
  margin-bottom: 0;
}

.shop-details .product-tabs #review-form .comment-reply-title {
  color: #000;
  font-size: 13px;
  margin-bottom: 20px;
  display: inline-block;
  border: 2px solid #000;
  line-height: 44px;
  padding: 0 35px;
  text-transform: uppercase;
  font-weight: 500;
}

.shop-details .product-tabs #review-form .comment-reply-title:before {
  content: "\e918";
  font-family: wpbingofont;
  display: inline-block;
  font-size: 20px;
  margin-right: 15px;
  font-weight: 400;
}

.shop-details .product-tabs #review-form .required {
  color: red;
}

.shop-details .product-tabs #review-form .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.shop-details .product-tabs #review-form .comment-form .comment-notes {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 6px;
}

.shop-details .product-tabs #review-form .comment-form-rating {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
}

.shop-details .product-tabs #review-form .comment-form-rating label {
  font-weight: 500;
  margin-right: 0;
  margin-bottom: 10px;
  color: #000;
}

.shop-details .product-tabs #review-form .comment-form-rating .stars {
  padding-top: 0;
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 7px;
  padding: 5px 0;
  width: 160px;
  position: relative;
  top: 2px;
}

.shop-details .product-tabs #review-form .comment-form-rating .stars span {
  display: inline-block;
}

.shop-details .product-tabs #review-form .comment-form-rating .stars span a {
  display: inline-block;
  vertical-align: middle;
  line-height: 100%;
  position: relative;
  font-size: 0;
  width: auto;
  margin-right: 3px;
}

.shop-details
  .product-tabs
  #review-form
  .comment-form-rating
  .stars
  span
  a:after {
  content: "\f005";
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: unset;
  text-indent: 0;
  color: #cecece;
}

.shop-details .product-tabs #review-form .comment-form-comment {
  flex: 1;
  padding: 0 10px;
  margin: 0;
  order: 2;
}

.shop-details .product-tabs #review-form .content-info-reviews {
  flex: 1;
  padding: 0 10px;
  order: 1;
  margin: 0;
}

.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .comment-form-author,
.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .comment-form-email {
  margin-bottom: 25px;
}

.shop-details .product-tabs #review-form .content-info-reviews input,
.shop-details .product-tabs #review-form .comment-form-comment textarea {
  background: #fff;
  padding: 14px 20px;
  border: 1px solid #d6d6d6;
  height: 60px;
  font-weight: 300;
  width: 100%;
}

.shop-details .product-tabs #review-form .comment-form-comment textarea {
  height: 230px;
}

.shop-details .product-tabs #review-form .content-info-reviews .form-submit {
  margin: 0;
}

.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .form-submit
  input[type="submit"] {
  background: #000;
  font-weight: 500;
  padding: 0 50px;
  height: 60px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 0.1em;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.shop-details
  .product-tabs
  #review-form
  .content-info-reviews
  .form-submit
  input[type="submit"]:hover {
  background: #cb8161;
}

.shop-details .product-related {
  margin-top: 50px;
}

.shop-details .product-related .block-title {
  text-align: center;
  margin-bottom: 50px;
}

.shop-details .product-related .block-title h2 {
  font-size: 28px;
  color: #000;
  margin: 0;
  display: inline-block;
  position: relative;
  padding-bottom: 15px;
}

.shop-details .product-related .block-title h2:before {
  content: "";
  width: 30%;
  height: 2px;
  background: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}

/**
 * 7.4. Shop - Cart
 */
.shop-cart .cart-items thead {
  border: 1px solid #e8e8e8;
}

.shop-cart .cart-items thead tr th {
  font-size: 16px;
  color: #000;
  padding: 15px;
  font-weight: 500;
  border-bottom-width: 1px;
}

.shop-cart .cart-items tbody {
  border: 1px solid #e8e8e8;
  border-top: 0;
}

.shop-cart .cart-items tbody tr td {
  padding: 15px;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  vertical-align: middle;
}

.shop-cart .cart-items tbody tr td.product-thumbnail {
  display: flex;
  align-items: center;
}

.shop-cart .cart-items tbody tr td.product-thumbnail img {
  width: 80px;
}

.shop-cart .cart-items tbody tr td.product-thumbnail .product-name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.shop-cart .cart-items tbody tr td.product-thumbnail .product-name a {
  font-size: 16px;
  color: #868686;
}

.shop-cart .cart-items tbody tr td.product-thumbnail .product-name a:hover {
  color: #000;
}

.shop-cart .cart-items tbody tr td.product-price span,
.shop-cart .cart-items tbody tr td.product-subtotal span {
  color: #000;
}

.shop-cart .cart-items tbody tr td.product-quantity .quantity input.qty {
  border-left: 0;
  border-right: 0;
  width: 30px;
}

.shop-cart .cart-items tbody tr td.product-quantity .quantity .minus {
  border-right: 0;
  width: 30px;
}

.shop-cart .cart-items tbody tr td.product-quantity .quantity .plus {
  border-left: 0;
  width: 30px;
}

.shop-cart .cart-items tbody tr td.product-remove a {
  font-size: 0;
  text-align: center;
}

.shop-cart .cart-items tbody tr td.product-remove a:before {
  display: inline-block;
  content: "\ea02";
  font-family: feather;
  font-size: 10px;
  color: #000 !important;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  border-radius: 50%;
  line-height: 15px;
}

.shop-cart .cart-items tbody tr td.product-remove a:hover:before {
  color: #fff !important;
  background: #000;
  border-color: #000;
}

.shop-cart .cart-items .actions .bottom-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.shop-cart .cart-items .actions .coupon {
  flex: 0 0 50%;
  display: flex;
}

.shop-cart .cart-items .actions .coupon input {
  line-height: 40px;
  padding: 0 10px;
  flex: 1;
  margin-right: 5px;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
}

.shop-cart .cart-items .actions .bottom-cart .button {
  padding: 0 35px;
  background: #000;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 40px;
  cursor: pointer;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.shop-cart .cart-items .actions .bottom-cart .button:hover {
  background: #cb8161;
}

.shop-cart .cart-items .actions .bottom-cart h2 {
  margin: 0;
  font-size: 18px;
}

.shop-cart .cart-totals {
  background: #f6f6f6;
  padding-bottom: 35px;
  max-width: 400px;
  margin-left: auto;
}

.shop-cart .cart-totals h2 {
  font-size: 20px;
  color: #000;
  padding: 11px 30px;
  background: #e9e9e9;
  margin: 0;
  font-weight: 600;
  line-height: 1.8;
  border-bottom: 0;
}

.shop-cart .cart-totals .cart-subtotal {
  display: flex;
  padding: 10px 30px;
  align-items: center;
  margin-top: 10px;
}

.shop-cart .cart-totals .cart-subtotal > * {
  flex: 1;
}

.shop-cart .cart-totals .cart-subtotal .title {
  color: #000;
  font-size: 16px;
}

.shop-cart .cart-totals .cart-subtotal span {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.shop-cart .cart-totals .shipping-totals {
  display: flex;
  padding: 10px 30px;
  align-items: center;
}

.shop-cart .cart-totals .shipping-totals > * {
  flex: 1;
}

.shop-cart .cart-totals .shipping-totals .title {
  color: #000;
  font-size: 16px;
}

.shop-cart .cart-totals .shipping-totals .shipping-methods {
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
}

.shop-cart .cart-totals .shipping-totals .shipping-desc {
  margin-bottom: 0;
}

.shop-cart .cart-totals .order-total {
  display: flex;
  padding: 10px 30px;
  align-items: center;
}

.shop-cart .cart-totals .order-total > * {
  flex: 1;
}

.shop-cart .cart-totals .order-total .title {
  color: #000;
  font-size: 16px;
}

.shop-cart .cart-totals .order-total span {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.shop-cart .cart-totals .proceed-to-checkout {
  padding: 0 30px;
  margin-top: 15px;
}

.shop-cart .cart-totals .proceed-to-checkout .button {
  background: #000;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  cursor: pointer;
}

.shop-cart .cart-totals .proceed-to-checkout .button:hover {
  background: #cb8161;
}

.shop-cart-empty .cart-empty {
  border-top: 3px solid #000;
  text-transform: capitalize;
  padding: 12px 22px;
  margin: 0 0 24px;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
  width: 100%;
}

.shop-cart-empty .return-to-shop .button {
  line-height: 34px;
  background: #000;
  color: #fff;
  padding: 0 20px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
}

.shop-cart-empty .return-to-shop .button:before {
  content: "\23";
  font-size: 18px;
  font-family: ElegantIcons;
  margin: 0 5px 0 0;
  position: relative;
  top: 3px;
}

.shop-cart-empty .return-to-shop .button:hover {
  background: rgba(0, 0, 0, 0.75);
}

/**
 * 7.5. Shop - Checkout
 */
.shop-checkout .checkout h3 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 600;
}

.shop-checkout .checkout .form-row {
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}

.shop-checkout .checkout .form-row label {
  color: #000;
  font-weight: 500;
  float: left;
  margin-bottom: 0;
  display: block;
}

.shop-checkout .checkout .form-row .required {
  color: #e42234;
  font-weight: 400;
  border: 0;
}

.shop-checkout .checkout .form-row .input-wrapper {
  width: 100%;
  float: left;
}

.shop-checkout .checkout .form-row .input-wrapper .input-text {
  width: 100%;
  border-width: 0 0 2px;
  border-color: #e5e5e5;
  padding: 0;
  height: 32px;
  line-height: 32px;
  color: #000;
}

.shop-checkout .checkout .form-row .selection .select2-selection {
  height: 32px;
  line-height: 32px;
  border-width: 0 0 2px;
  border-color: #e5e5e5;
  border-radius: 0;
}

.shop-checkout .checkout .form-row .selection .select2-selection span {
  height: 32px;
  line-height: 32px;
  padding: 0;
}

.shop-checkout .account-fields {
  margin-bottom: 20px;
}

.shop-checkout .checkout .account-fields .form-row {
  margin-bottom: 15px;
}

.shop-checkout .account-fields .checkbox span {
  margin-left: 10px;
}

.shop-checkout .ship-to-different-address .checkbox span {
  margin-left: 10px;
}

.shop-checkout .checkout .form-row .input-wrapper textarea.input-text {
  height: 100px;
  line-height: 24px;
}

.shop-checkout .checkout-review-order {
  padding: 30px 40px;
  border: 1px solid #000;
}

.shop-checkout .checkout-review-order h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.shop-checkout .checkout-review-order .review-order-title {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin-bottom: 22px;
}

.shop-checkout .checkout-review-order .cart-item {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

.shop-checkout .checkout-review-order .cart-item .info-product {
  display: flex;
}

.shop-checkout
  .checkout-review-order
  .cart-item
  .info-product
  .product-thumbnail {
  max-width: 55px;
}

.shop-checkout
  .checkout-review-order
  .cart-item
  .info-product
  .product-thumbnail
  img {
  border: 1px solid #e8e8e8;
}

.shop-checkout .checkout-review-order .cart-item .info-product .product-name {
  padding-left: 20px;
  color: #000;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 14px;
}

.shop-checkout
  .checkout-review-order
  .cart-item
  .info-product
  .product-name
  .product-quantity {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #666;
}

.shop-checkout .checkout-review-order .cart-item .product-total span {
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.shop-checkout .checkout-review-order .cart-subtotal,
.shop-checkout .checkout-review-order .order-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dedede;
  padding: 20px 0;
}

.shop-checkout .checkout-review-order .cart-subtotal {
  margin-top: 30px;
}

.shop-checkout .checkout-review-order .cart-subtotal span,
.shop-checkout .checkout-review-order .order-total span {
  color: #000;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.shop-checkout .checkout-review-order .shipping-totals {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dedede;
  padding: 20px 0;
}

.shop-checkout .checkout-review-order .shipping-methods {
  text-align: right;
  padding: 0;
  list-style: none;
  margin: 0;
}

.shop-checkout .checkout-review-order .order-total .total-price span {
  font-size: 18px;
  color: #000;
  letter-spacing: 1.1px;
}

.shop-checkout .checkout-review-order .checkout-payment .payment-methods {
  padding: 20px;
  margin-bottom: 30px;
  list-style: none;
  border: 1px solid #d9d9d9;
}

.shop-checkout .checkout-review-order .checkout-payment .custom-radio li {
  margin-bottom: 10px;
}

.shop-checkout
  .checkout-review-order
  .checkout-payment
  .custom-radio
  li:last-child {
  margin-bottom: 0;
}

.shop-checkout .checkout-review-order .checkout-payment .custom-radio li label {
  color: #000;
  font-weight: 500;
}

.shop-checkout .checkout-review-order .checkout-payment .button {
  background: #000;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  border-radius: 0;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
  cursor: pointer;
}

.shop-checkout .checkout-review-order .checkout-payment .button:hover {
  background: #cb8161;
}

/**
 * 7.6. Shop - Wishlist
 */
.shop-wishlist .wishlist-items {
  border: 1px solid #e5e5e5;
}

/*===================================================================
8. Blog
====================================================================*/
.posts-list.grid .post-entry .post-meta .post-time,
.posts-list.list .post-entry .post-meta .post-comment,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"],
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"] {
  display: flex;
}

[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  font-size: 0.114583333in;
}

[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  color: #666;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a
  i,
.post-details.no-sidebar .post-title {
  text-align: center;
}

[class~="post-details"] [class~="post-meta"] a:hover,
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a:hover {
  color: #cb8161;
}

.post-details .post-content blockquote i {
  display: block;
}

.posts-list.grid .post-entry {
  margin-bottom: 2.5pc;
}

[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"] {
  margin-top: 10px;
}

[class~="posts-list"][class~="list"] [class~="post-entry"] {
  margin-bottom: 55px;
}

.posts-list.grid .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after,
.posts-list.list .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  content: "";
}

[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after {
  width: 0.010416667in;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-time"],
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"],
.posts-list.list .post-entry .post-meta .post-comment,
.posts-list.grid .post-entry .post-meta .post-time {
  align-items: center;
}

[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after {
  height: 13.5pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"]:hover {
  color: #000;
}

[class~="post-details"] [class~="post-meta"] a,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  a {
  color: #ababab;
}

[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a,
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-btn"]
  a {
  text-transform: uppercase;
}

.posts-list.grid .post-entry .post-title {
  font-size: 0.208333333in;
}
.posts-list.grid .post-entry .post-title a {
  font-size: 16px;
}
.post-details.no-sidebar .post-title {
  font-size: 0.375in;
}

.posts-list.grid .post-entry .post-title {
  line-height: 1.1;
}

.posts-list.grid .post-entry .post-title {
  width: 100%;
}

[class~="post-details"] [class~="post-content"] blockquote,
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  font-weight: 500;
}

[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-categories"]
  a {
  letter-spacing: 0.008333333in;
}

.post-details .post-title {
  margin-top: 20px;
}

[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  margin-left: 11.25pt;
}

.posts-list.grid .post-entry .post-meta .post-time:after {
  margin-left: 0.625pc;
}

.post-details .post-content blockquote i {
  font-size: 0.239583333in;
}

[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after,
.posts-list.grid .post-entry .post-meta .post-time:after {
  margin-bottom: 0;
}

[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after {
  margin-right: 11.25pt;
}

.post-details .post-content blockquote i {
  color: #dadada;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  i,
[class~="post-details"] [class~="post-meta"] i {
  margin-right: 0.0625in;
}

.posts-list.grid .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after {
  margin-top: 0;
}

.posts-list.grid .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after {
  display: inline-block;
}

.post-details.no-sidebar .post-meta {
  align-content: center;
}

.posts-list.grid .post-entry .post-title,
[class~="post-details"] [class~="post-content"] blockquote {
  margin-left: 0;
}

.posts-list.grid .post-entry .post-meta .post-time:after {
  margin-right: 0.625pc;
}

.posts-list.grid .post-entry .post-title {
  margin-bottom: 6pt;
}

.post-details.no-sidebar .post-meta {
  justify-content: center;
}

[class~="post-details"] [class~="post-meta"] [class~="post-time"]:after {
  background: #dedede;
}

[class~="posts-list"][class~="grid"] [class~="post-entry"] [class~="post-meta"],
[class~="post-details"] [class~="post-meta"] [class~="post-comment"],
[class~="post-details"] [class~="post-meta"] [class~="post-time"],
[class~="post-details"] [class~="post-meta"] [class~="post-categories"] {
  display: flex;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-btn"]
  a {
  font-size: 12px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]:last-child {
  margin-bottom: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-avatar"] {
  float: left;
}

.post-details .post-content blockquote i {
  margin-bottom: 1pc;
}

.posts-list.grid .post-entry .post-meta .post-time:after {
  height: 1px;
}

[class~="post-details"] [class~="post-meta"] [class~="post-categories"],
[class~="posts-list"][class~="grid"] [class~="post-entry"] [class~="post-meta"],
[class~="post-details"] [class~="post-meta"] [class~="post-comment"],
[class~="post-details"] [class~="post-meta"] [class~="post-time"] {
  align-items: center;
}

.posts-list.grid .post-entry .post-meta .post-time:after {
  width: 0.625pc;
}

.posts-list.grid .post-entry .post-title,
[class~="post-details"] [class~="post-content"] blockquote {
  margin-right: 0;
}

.post-details
  .comments-area
  .comments-list
  .comment-item
  .comment-content-wrap {
  overflow: hidden;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  line-height: 1.4;
}

.posts-list.grid .post-entry .post-meta .post-time:after {
  background: #d5d5d5;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-time"],
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a
  i,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"],
[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"] {
  font-size: 0.135416667in;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  margin-bottom: 1.5pt;
}

[class~="post-details"] [class~="post-content"] blockquote {
  margin-bottom: 0.3125in;
}

.post-details .post-title {
  margin-bottom: 7.5pt;
}

.posts-list.list .post-entry .post-meta .post-time:after {
  width: 0.010416667in;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label,
.posts-list.list .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  margin-bottom: 0;
}

.posts-list.grid .post-entry .post-title {
  margin-top: 2.25pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  font-weight: 600;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  margin-top: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  text-transform: uppercase;
}

.posts-list.list .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  margin-right: 11.25pt;
}

.posts-list.list .post-entry .post-meta .post-time:after {
  height: 0.1875in;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:last-child {
  margin-right: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"],
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label {
  color: #000;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-author"] {
  letter-spacing: 0.75pt;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
.posts-list.list .post-entry .post-meta .post-time:after {
  margin-top: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-time"] {
  margin-bottom: 3pt;
}

[class~="post-details"] [class~="post-content"] blockquote {
  margin-top: 0.3125in;
}

.posts-list.list .post-entry .post-meta .post-time:after {
  margin-left: 11.25pt;
}

[class~="posts-list"][class~="grid"] [class~="post-entry"] [class~="post-meta"],
[class~="post-details"] [class~="post-meta"] {
  flex-wrap: wrap;
}

.posts-list.list .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  display: inline-block;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  background: #cb8161;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-time"] {
  color: #999;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="posts-list"][class~="grid"] [class~="post-entry"] [class~="post-meta"],
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"] {
  color: #868686;
}

.posts-list.list .post-entry .post-meta .post-time:after,
[class~="post-details"] [class~="post-meta"] [class~="post-categories"]:after {
  background: #dedede;
}

[class~="post-details"] [class~="post-meta"] {
  display: flex;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"],
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  font-weight: 400;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label,
.posts-list.list .post-entry .post-title {
  margin-left: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  position: absolute;
}

[class~="post-details"] [class~="post-meta"],
[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-image"] {
  margin-bottom: 1.25pc;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  label {
  margin-right: 0.3125pc;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  top: 0.3125pc;
}

[class~="post-details"] [class~="post-content"] blockquote {
  padding-left: 35px;
}

[class~="post-details"] [class~="post-content"] blockquote {
  padding-bottom: 0;
}

[class~="posts-list"][class~="grid"]
  [class~="post-entry"]
  [class~="post-meta"] {
  letter-spacing: 0.03125pc;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  right: 0;
}

.posts-list.list .post-entry .post-title {
  font-size: 34px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  color: #c2c2c2;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  color: #fff;
}

.posts-list.list .post-entry .post-title {
  line-height: 1.3;
}

[class~="post-details"] [class~="post-meta"] {
  align-items: center;
}

[class~="post-details"] [class~="post-content"] [class~="content-img"] {
  margin-top: 1.875pc;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-left-color: #cb8161;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"],
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] {
  margin-top: 1.25pc;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-bottom-color: #cb8161;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  font-size: 10.5pt;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-right-color: #cb8161;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  font-weight: 500;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  line-height: 2.375pc;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"] {
  margin-bottom: 0;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="tags-links"]
  a:hover {
  border-top-color: #cb8161;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"] {
  font-size: 0;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  content: "";
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  width: 0.010416667in;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  width: 0.416666667in;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  height: 47px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  padding-left: 0.208333333in;
}

[class~="post-details"] [class~="post-content"] blockquote {
  padding-right: 0;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  height: 30pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  padding-bottom: 0.625pc;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  height: 0.1875in;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  margin-right: 5px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  padding-right: 0.208333333in;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-left: 11.25pt;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  font-family: Lato, sans-serif;
}

.post-details .prev-next-post .next-post,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] {
  width: 50%;
}

[class~="post-details"] [class~="post-content"] blockquote {
  padding-top: 0;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-left-width: 0.0625pc;
}

[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"],
.post-details .prev-next-post .next-post {
  float: left;
}

[class~="post-details"] [class~="post-content"] blockquote {
  color: #666;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-bottom: 0;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  font-size: 0.8125pc;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  padding-top: 0.625pc;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  font-weight: 700;
}

[class~="post-details"] [class~="post-content"] blockquote {
  border-left-width: 2px;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-bottom-width: 0.0625pc;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-right-width: 0.0625pc;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-top-width: 0.0625pc;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  text-align: center;
}

[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] {
  padding-right: 11.25pt;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  line-height: 3.125pc;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content"] blockquote {
  border-left-style: solid;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-bottom-style: solid;
}

.posts-list.list .post-entry .post-title {
  margin-bottom: 7.5pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-left-width: medium;
}

[class~="post-details"] [class~="post-meta"] {
  font-size: 10.5pt;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  text-transform: uppercase;
}

[class~="post-details"] [class~="post-content"] blockquote {
  border-left-color: #000;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-right-style: solid;
}

[class~="post-details"] [class~="post-content"] blockquote {
  border-image: none;
}

[class~="post-details"] [class~="post-content"] blockquote h2,
.posts-list.list .post-entry .post-title {
  margin-right: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-bottom-width: medium;
}

[class~="post-details"] [class~="post-content"] blockquote {
  position: relative;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-right-width: medium;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-top-style: solid;
}

.posts-list.list .post-entry .post-meta,
[class~="post-details"] [class~="post-meta"] {
  color: #ababab;
}

[class~="post-details"] [class~="post-meta"],
.posts-list.list .post-entry .post-meta {
  letter-spacing: 0.375pt;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] {
  text-align: right;
}

.posts-list.list .post-entry .post-meta,
[class~="post-details"] [class~="post-content"] {
  font-size: 0.15625in;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] {
  float: right;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after,
.posts-list.list .post-entry .post-title {
  margin-top: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-top-width: medium;
}

.posts-list.list .post-entry .post-meta,
.post-details .post-content-entry {
  display: flex;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  margin-left: 0;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-left-color: #e5e5e5;
}

[class~="post-details"] [class~="post-content"] blockquote {
  font-size: 12pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-left-style: none;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  margin-right: 11.25pt;
}

.posts-list.list .post-entry .post-meta {
  margin-bottom: 15px;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-bottom-color: #e5e5e5;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-bottom-style: none;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  display: inline-block;
}

.posts-list.list .post-entry .post-meta,
.post-details .post-content-entry {
  flex-wrap: wrap;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-right-style: none;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-meta"]
  [class~="post-categories"]:after {
  background: #dedede;
}

.posts-list.list .post-entry .post-meta,
.post-details .post-content-entry {
  align-items: center;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  margin-bottom: 0;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] {
  padding-left: 0.15625in;
}

[class~="post-details"] [class~="prev-next-post"] [class~="hover-extend"] {
  font-size: 9.75pt;
}

.post-details .post-content-entry .entry-social-share .social-share a:hover i {
  color: #fff;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-top-style: none;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:last-child {
  margin-right: 0;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a,
[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-right-color: #e5e5e5;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  content: "";
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a,
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-top-color: #e5e5e5;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-bottom-style: solid;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-left-color: currentColor;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  border-image: none;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-bottom-color: currentColor;
}

.post-details .post-content-entry {
  justify-content: space-between;
}

[class~="post-details"] [class~="prev-next-post"] [class~="hover-extend"] {
  text-transform: uppercase;
}

[class~="post-details"] [class~="prev-next-post"] [class~="hover-extend"] {
  margin-bottom: 0.3125pc;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a
  i,
[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  color: #000;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  padding-left: 0.15625in;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  font-size: 2pc;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-right-color: currentColor;
}

.post-details .post-content-entry {
  margin-top: 0.9375pc;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-excerpt"] {
  margin-bottom: 25px;
}

[class~="posts-list"][class~="list"]
  [class~="post-entry"]
  [class~="post-excerpt"] {
  font-size: 15px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  border-top-color: currentColor;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  background: #cb8161;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  padding-bottom: 0;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  padding-right: 0.15625in;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-left-color: #cb8161;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-bottom-color: #cb8161;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"],
.post-details .post-content-entry {
  padding-bottom: 0.364583333in;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  position: absolute;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  left: 50%;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  padding-top: 0;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-right-color: #cb8161;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  background: transparent;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"] {
  display: inline-block;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"],
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-bottom-color: #e5e5e5;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  margin-bottom: 0;
}

[class~="post-details"] [class~="post-content-entry"] [class~="tags-links"] a {
  white-space: nowrap;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"] {
  position: relative;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p,
.post-details .post-content-entry .entry-social-share label,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"],
.post-details .comments-area .comments-list .comment-item .comment-avatar img {
  margin-left: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"] {
  margin-bottom: 2.5pc;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  margin-top: 11.25pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"],
[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  border-image: none;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"] {
  background: #f5f5f5;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a:hover {
  border-top-color: #cb8161;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="form-control"]::placeholder {
  color: #999;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="button"] {
  line-height: 44px;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a:hover
  [class~="hover-extend"],
.post-details .prev-next-post .next-post a:hover .title,
.post-details .prev-next-post .previous-post a:hover .hover-extend,
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a:hover
  [class~="title"] {
  color: #cb8161;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"],
.post-details .comments-area .comments-list .comment-item .comment-avatar img,
.post-details .post-content-entry .entry-social-share label,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p {
  margin-bottom: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="button"] {
  font-size: 0.135416667in;
}

.post-details .post-content-entry {
  width: 100%;
}

.post-details .post-content-entry,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"] {
  border-bottom-width: 1px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"],
.post-details .post-content-entry {
  border-bottom-style: solid;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  top: calc(50% - 39px);
}

.post-details .post-content-entry .entry-social-share label {
  margin-right: 0.3125pc;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  margin-top: 0.416666667in;
}

.post-details .comments-area .comments-list .comment-item .comment-avatar img {
  margin-right: 0.208333333in;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  [class~="button"] {
  cursor: pointer;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"] {
  border-image: none;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-title"] {
  text-align: center;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"],
.post-details .comments-area .comments-list .comment-item .comment-avatar img,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p,
.post-details .post-content-entry .entry-social-share label {
  margin-top: 0;
}

.post-details .post-content-entry .entry-social-share label {
  color: #000;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a,
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"] {
  display: block;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  list-style: none;
}

[class~="post-details"] [class~="post-content"] blockquote h2 {
  letter-spacing: 1px;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a {
  color: #ababab;
}

[class~="post-details"]
  [class~="post-content-entry"]
  [class~="entry-social-share"]
  [class~="social-share"]
  a {
  font-size: 0;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a {
  font-size: 15px;
}

[class~="post-details"] [class~="prev-next-post"] [class~="next-post"] a,
[class~="post-details"] [class~="prev-next-post"] [class~="previous-post"] a {
  text-transform: capitalize;
}

.post-details .comments-area .comments-list .comment-item .comment-avatar img {
  min-width: 4.375pc;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-list"],
[class~="post-details"] [class~="prev-next-post"] {
  padding-left: 0;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  overflow-wrap: break-word;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="comment-notes"] {
  margin-bottom: 15pt;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p {
  margin-right: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"] {
  position: relative;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  padding-bottom: 0;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  width: 0.0625pc;
}

.post-details .post-content-entry {
  border-bottom-color: #e5e5e5;
}

.post-details .post-content-entry {
  border-image: none;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"] {
  margin-bottom: 1.875pc;
}

[class~="post-details"] [class~="prev-next-post"],
[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  padding-right: 0;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  font-size: 15pt;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  height: 0.8125in;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-content"]
  p {
  line-height: 1.6;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  overflow: hidden;
}

[class~="post-details"] [class~="prev-next-post"]:before {
  background: #e5e5e5;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"]:before {
  content: "";
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  text-overflow: ellipsis;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  padding-top: 0;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"],
[class~="post-details"]
  [class~="prev-next-post"]
  [class~="next-post"]
  a
  [class~="title"] {
  white-space: nowrap;
}

[class~="post-details"] [class~="comments-area"] [class~="comments-list"] {
  margin-top: 30px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"]:before,
[class~="post-details"] [class~="prev-next-post"] {
  display: inline-block;
}

[class~="post-details"] [class~="comments-area"] [class~="comment-form"] {
  margin-top: 50px;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"]:before {
  font-size: 0.875pc;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comments-list"]
  [class~="comment-item"]
  [class~="comment-reply-link"]:before {
  font-family: FontAwesome;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  textarea[class~="form-control"] {
  height: auto;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-group"]
  textarea[class~="form-control"] {
  box-shadow: none;
}

[class~="post-details"] [class~="prev-next-post"] {
  padding-bottom: 0.3125in;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  margin-left: 0;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  margin-bottom: 0;
}

[class~="post-details"]
  [class~="prev-next-post"]
  [class~="previous-post"]
  a
  [class~="title"] {
  margin-top: 0;
}

[class~="post-details"] [class~="comments-area"] [class~="comment-form"] {
  text-align: center;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-header"]
  h3 {
  font-size: 24pt;
}

[class~="post-details"] [class~="prev-next-post"] {
  padding-top: 0.3125in;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-header"]
  h3 {
  margin-top: 0;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-header"]
  h3 {
  margin-bottom: 11.25pt;
}

[class~="post-details"]
  [class~="comments-area"]
  [class~="comment-form"]
  [class~="form-header"]
  h3 {
  color: #000;
}

[class~="post-details"] [class~="prev-next-post"] {
  width: 100%;
}

[class~="post-details"] [class~="prev-next-post"] {
  border-bottom-width: 0.010416667in;
}

[class~="post-details"] [class~="prev-next-post"] {
  border-bottom-style: solid;
}

[class~="post-details"] [class~="prev-next-post"] {
  border-bottom-color: #e5e5e5;
}

[class~="post-details"] [class~="prev-next-post"] {
  border-image: none;
}

[class~="post-details"] [class~="prev-next-post"] {
  position: relative;
}

/*===================================================================
9. Pages
====================================================================*/
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  input {
  width: 100%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  bottom: -3pt;
}

.page-login-register .box-form-login .box-content {
  padding-left: 67.5pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-login"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"],
[class~="page-login-register"] [class~="box-form-login"] h2 {
  text-align: center;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  transition: all 0.2s ease 0s;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input {
  position: absolute;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="required"] {
  color: red;
}

.page-login-register .box-form-login .box-content,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="password"]
  input,
[class~="page-my-account"] [class~="my-account-navigation"] ul li a,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input {
  padding-bottom: 0;
}

.page-login-register .box-form-login .box-content {
  padding-right: 67.5pt;
}

.page-login-register .box-form-login {
  border-left-width: 0.020833333in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input {
  opacity: 0;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  position: relative;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  input {
  height: 2.8125pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  h2[class~="register"]:after {
  width: 9.375pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"] {
  display: flex;
}

[class~="page-login-register"] [class~="box-form-login"] h2:before {
  font-size: 18px;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  transition: all 0.2s ease 0s;
}

[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"],
[class~="page-login-register"] [class~="box-form-login"] h2 {
  margin-left: 0;
}

.page-login-register .box-form-login .box-content,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input {
  padding-top: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input {
  height: 1.375pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"] {
  justify-content: space-between;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input {
  cursor: pointer;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  width: 12pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"] {
  flex-wrap: wrap;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  margin-bottom: 40px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  input {
  line-height: 2.8125pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="password"]
  input {
  padding-left: 0.166666667in;
}

.page-login-register .box-form-login {
  border-bottom-width: 0.020833333in;
}

[class~="page-login-register"] [class~="box-form-login"] h2:before {
  content: "";
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"]:hover {
  background: #cb8161;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  font-weight: 500;
}

.page-login-register .box-form-login {
  border-right-width: 0.020833333in;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  padding-left: 0;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  margin-right: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="password"]
  input,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input {
  padding-right: 0.166666667in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-left-color: #cb8161;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  h2[class~="register"]:after {
  left: calc(50% - 75px);
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  height: 12pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-login"] {
  margin-top: 20px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  content: "";
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
[class~="page-login-register"] [class~="box-form-login"] h2:before {
  display: inline-block;
}

.page-login-register .box-form-login {
  border-top-width: 0.020833333in;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  margin-top: 0;
}

[class~="page-login-register"] [class~="box-form-login"] h2:before {
  font-family: wpbingofont;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  font-size: 20px;
}

.page-login-register .box-form-login {
  border-left-style: solid;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-bottom-color: #cb8161;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-right-color: #cb8161;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  font-weight: 600;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="input-text"] {
  width: 100%;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  color: #fff;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="email"]
  input,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="password"]
  input,
[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"] {
  margin-bottom: 0.15625in;
}

.page-login-register .box-form-login,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-style: solid;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  padding-right: 0;
}

.page-login-register .box-form-login h2.register:before {
  content: "";
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  left: 50%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="remember-me"]
  input:checked
  + label:before {
  border-top-color: #cb8161;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  background: #000;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="password"]
  input,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-top: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-left-width: medium !important;
}

.page-login-register .box-form-login,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-style: solid;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  font-size: 9pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-radius: 50%;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  text-transform: uppercase;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  label {
  display: block;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-left: 18.75pt;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-bottom-width: medium !important;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-bottom: 0;
}

[class~="page-login-register"] [class~="box-form-login"] h2 {
  padding-bottom: 0.9375pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-width: 0.75pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-width: 0.75pt;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a:hover,
[class~="page-my-account"] [class~="my-account-navigation"] ul li a:focus {
  background: transparent;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul {
  padding-left: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-width: 0.75pt;
}

.page-login-register .box-form-login .box-content .rememberme-lost .remember-me,
[class~="page-login-register"] [class~="box-form-login"] h2:before {
  margin-right: 0.104166667in;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  padding-right: 18.75pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before,
.page-login-register .box-form-login {
  border-top-style: solid;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  line-height: 31.5pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  transform: translateX(-50%);
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-width: 0.75pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-style: solid;
}

.page-login-register .box-form-login {
  border-left-color: #eaeaea;
}

.page-login-register .box-form-login {
  border-bottom-color: #eaeaea;
}

[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"] {
  margin-right: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-right-width: medium !important;
}

.page-my-account .my-account-navigation ul li:last-child {
  border-bottom-width: medium;
}

.page-login-register .box-form-login {
  border-right-color: #eaeaea;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  label {
  width: 100%;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-top-width: medium !important;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-left-style: none !important;
}

.page-login-register .box-form-login {
  border-top-color: #eaeaea;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="username"]
  input {
  padding-left: 0.166666667in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="username"]
  input,
[class~="page-my-account"] [class~="my-account-navigation"] ul {
  padding-bottom: 0;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  content: "";
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-left-color: #000;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-bottom-color: #000;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after {
  width: 8.125pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-right-color: #000;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-bottom-style: none !important;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after {
  height: 2px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"],
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  cursor: pointer;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-top-color: #000;
}

.page-login-register .box-form-login,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  border-image: none;
}

[class~="page-forget-password"] [class~="reset-password"] [class~="form-row"],
.page-my-account .my-account-addresses .addresses {
  margin-top: 0;
}

.page-login-register .box-form-login .box-content .rememberme-lost .remember-me,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  position: relative;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  margin-right: 0.3125pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"] {
  color: #000;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  top: 2.25pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:before {
  z-index: 2;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-right-style: none !important;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-top-style: none !important;
}

[class~="page-my-account"] [class~="my-account-content"] a:hover {
  opacity: 0.7;
}

.page-login-register .box-form-login,
[class~="page-my-account"] [class~="my-account-navigation"] ul {
  padding-right: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  width: 0.166666667in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"]:hover {
  background: #cb8161;
}

.page-my-account .my-account-addresses .addresses {
  display: flex;
}

.page-my-account .my-account-addresses .addresses {
  flex-wrap: wrap;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  height: 16px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"] {
  font-weight: 500;
}

.page-my-account .my-account-addresses .addresses {
  margin-left: -0.15625in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="username"]
  input {
  padding-right: 0.166666667in;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  label,
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  em {
  display: block;
}

.page-login-register .box-form-login {
  padding-left: 0;
}

.page-my-account .my-account-addresses .addresses {
  margin-bottom: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="username"]
  input,
[class~="page-my-account"] [class~="my-account-navigation"] ul {
  padding-top: 0;
}

.page-my-account .my-account-addresses .addresses {
  margin-right: -0.15625in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  display: inline-block;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-left-width: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-left-color: currentColor !important;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  label {
  width: 100%;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-radius: 50%;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-bottom-color: currentColor !important;
}

.page-login-register .box-form-login {
  padding-bottom: 30pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  position: absolute;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  top: 3pt;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul {
  list-style: none;
}

.page-login-register .box-form-login {
  padding-top: 37.5pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-width: 1.875pt;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul {
  border-bottom-width: medium;
}

.page-login-register .box-form-login {
  height: 100%;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-width: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul,
.page-my-account .my-account-navigation ul li:last-child,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-style: none;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-right-width: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  background: #f5f5f5;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-top-width: 0;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-left: 0;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-left-style: none;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-width: 1.875pt;
}

.page-login-register
  .box-form-login
  .box-content
  .rememberme-lost
  .remember-me
  input:checked
  + label:after {
  opacity: 1;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-right-color: currentColor !important;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="username"]
  input {
  margin-bottom: 0.15625in;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-top-color: currentColor !important;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-width: 1.875pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-width: 1.875pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-style: solid;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  border-left-width: 0.0625pc;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li a {
  border-image: none !important;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  em {
  margin-top: 0.3125pc;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  padding-left: 0.208333333in;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"],
[class~="page-my-account"] [class~="my-account-navigation"] ul {
  border-bottom-color: currentColor;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-right-style: none;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  transition: all 0.2s ease 0s;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-style: solid;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul {
  border-image: none;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-top-style: none;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  transition: all 0.2s ease 0s;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-right: 0;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"] {
  margin-top: 0;
}

[class~="page-my-account"] [class~="my-account-content"] strong,
[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  font-weight: 500;
}

[class~="page-about-us"] [class~="block"] [class~="block-title"] {
  text-align: center;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-left-color: currentColor;
}

[class~="page-404"] {
  position: relative;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  padding-bottom: 11.25pt;
}

[class~="page-about-us"] [class~="block"] [class~="block-title"] {
  margin-bottom: 3.125pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-style: solid;
}

[class~="page-about-us"] [class~="block"] [class~="block-title"] h2 {
  font-size: 3.125pc;
}

[class~="page-my-account"] [class~="my-account-orders"] table {
  border-left-width: 0.0625pc;
}

[class~="page-faq"] [class~="faq-section"] [class~="section-title"] h2 {
  font-size: 22.5pt;
}

[class~="page-faq"] [class~="faq-section"] [class~="section-title"] h2 {
  margin-top: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  padding-right: 0.208333333in;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  padding-left: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  padding-bottom: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-style: solid;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-right-color: currentColor;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-left-color: #fff;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-bottom-color: #fff;
}

[class~="page-my-account"] [class~="my-account-orders"] table,
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-bottom-width: 0.0625pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-right-color: #fff;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  transform: translateX(-50%);
}

[class~="page-faq"] [class~="faq-section"] [class~="section-title"] h2 {
  margin-bottom: 12pt;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  padding-right: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  padding-top: 0;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-top-color: #fff;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after,
.page-404 .content-page-404 .sub-title:before {
  position: absolute;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  border-top-color: currentColor;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after,
[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"]:hover {
  background: #cb8161;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after,
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  background: #000;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-left-width: medium !important;
}

[class~="page-faq"] [class~="faq-section"] [class~="faq-item"] {
  margin-bottom: 1.25pc;
}

[class~="page-my-account"] [class~="my-account-orders"] table,
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-right-width: 0.0625pc;
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  border-image: none;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  padding-top: 11.25pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  left: 0;
}

[class~="page-404"] [class~="content-page-404"] [class~="title-error"] {
  font-size: 2.916666667in;
}

[class~="page-404"] [class~="content-page-404"] [class~="title-error"] {
  line-height: 1;
}

[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-top-width: 0.0625pc;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-bottom-width: medium !important;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-right-width: medium !important;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-top-width: medium !important;
}

[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-left-style: solid;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after {
  bottom: -0.125pc;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-title"],
[class~="page-404"] [class~="content-page-404"] [class~="title-error"] {
  color: #000;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-left-style: none !important;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  z-index: 1;
}

.page-404 .content-page-404 .sub-title:before {
  content: "";
}

.page-404 .content-page-404 .sub-title:before {
  width: 140px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="inline"]:after {
  opacity: 0;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  font-size: 0.416666667in;
}

[class~="page-login-register"] [class~="box-form-login"] h2:after {
  left: calc(50% - 65px);
}

[class~="page-forget-password"]
  [class~="reset-password"]
  [class~="form-row"]
  [class~="button"] {
  margin-top: 7.5pt;
}

[class~="page-my-account"] [class~="my-account-orders"] table,
[class~="page-my-account"] [class~="my-account-navigation"] ul li,
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-bottom-style: solid;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a {
  display: inline-block;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-left-width: 0.0625pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a,
[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  position: relative;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  flex: 0 0 50%;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-left-width: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-bottom-style: none !important;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-bottom-width: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  max-width: 50%;
}

[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-my-account"] [class~="my-account-orders"] table {
  border-right-style: solid;
}

[class~="page-my-account"] [class~="my-account-orders"] table,
[class~="page-my-account"] [class~="my-account-navigation"] {
  border-top-style: solid;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-right-style: none !important;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-top-style: none !important;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  padding-right: 15px;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-right-width: 0;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  font-weight: 500;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  padding-left: 11.25pt;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li,
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-bottom-width: 1px;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  border-left-color: #f5f5f5;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  border-bottom-color: #f5f5f5;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-left: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-left-color: currentColor !important;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  margin-bottom: 25px;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  display: flex;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-top-width: 0;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-left-style: none;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-bottom-style: none;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-right-style: none;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-bottom-color: currentColor !important;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-title"] {
  padding-bottom: 7.5pt;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  justify-content: space-between;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-bottom: 11.25pt;
}

.page-404 .content-page-404 .sub-title:before {
  height: 0.020833333in;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-right-color: currentColor !important;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  border-right-color: #f5f5f5;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-top-style: none;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  content: "";
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-left-color: currentColor;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  border-bottom-color: hsla(0, 0%, 90%, 0.5);
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  max-width: 243.75pt;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-bottom-color: currentColor;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-left: auto;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-bottom: auto;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-right: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-top-color: currentColor !important;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  position: absolute;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-right-width: 0.0625pc;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"] {
  margin-top: 11.25pt;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-right-color: currentColor;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  border-top-color: currentColor;
}

.page-404 .content-page-404 .sub-title:before {
  bottom: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  border-top-color: #f5f5f5;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-top-width: 0.0625pc;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-navigation"],
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  border-image: none;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li span {
  border-image: none !important;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="input-text"] {
  width: 100%;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  flex-wrap: wrap;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  font-size: 9.75pt;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-left: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  margin-bottom: 40px;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-404"] [class~="content-page-404"] [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  padding-bottom: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li,
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  padding-right: 0;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  color: #fff;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  background: #000;
}

[class~="page-my-account"] [class~="my-account-orders"] table {
  border-left-color: #e6e6e6;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-top: 0;
}

[class~="page-my-account"] [class~="my-account-orders"] table {
  border-bottom-color: #e6e6e6;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-right: auto;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  list-style-type: none;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  margin-top: auto;
}

[class~="page-404"] [class~="content-page-404"] [class~="sub-error"] {
  font-size: 16px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-left-width: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  align-items: center;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  cursor: pointer;
}

.page-404 .content-page-404 .sub-title:before {
  left: calc(50% - 70px);
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after,
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  display: inline-block;
}

[class~="page-my-account"] [class~="my-account-account-details"] fieldset,
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  margin-top: 0.3125in;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-left: 0.3125in;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-bottom: 1.25pc;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  text-transform: uppercase;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  font-size: 0.75pc;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-bottom: 0.104166667in;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  padding-left: 1.25pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-width: 0;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-right: 0.3125in;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  font-weight: 500;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  padding-top: 1.25pc;
}

.page-login-register
  .box-form-login
  .box-content
  .button-login
  input[type="submit"] {
  letter-spacing: 0.75pt;
}

[class~="page-my-account"] [class~="my-account-orders"] table {
  border-right-color: #e6e6e6;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  width: 30%;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  padding-left: 0.260416667in;
}

.page-my-account .my-account-navigation ul li:last-child,
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-bottom-color: currentColor;
}

[class~="page-my-account"] [class~="my-account-navigation"] {
  float: left;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-right-width: 0;
}

.page-404 .content-page-404 .sub-title:before {
  background: #e1e1e1;
}

.page-my-account .my-account-navigation ul li:last-child {
  border-image: none;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-top-width: 0;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-left: 0.416666667in;
}

[class~="page-my-account"] [class~="my-account-orders"] table {
  border-top-color: #e6e6e6;
}

.page-my-account .my-account-content {
  width: 68%;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  padding-right: 0.260416667in;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  padding-right: 0.416666667in;
}

.page-my-account .my-account-content {
  float: right;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-left-style: none;
}

.page-my-account .my-account-content {
  font-size: 15px;
}

[class~="page-my-account"] [class~="my-account-orders"] table {
  border-image: none;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  padding-top: 0;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-style: none;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  line-height: 2.625pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-right-style: none;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-style: none;
}

.page-my-account .my-account-content {
  line-height: 1.4;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-left-width: 0;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-left-color: currentColor;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-left-style: solid;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-right-color: currentColor;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  border-top-color: currentColor;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  height: 0.520833333in;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-bottom: 0.125in;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before,
[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  width: 100%;
}

[class~="page-my-account"] [class~="my-account-account-details"] legend {
  color: #000;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  line-height: 0.520833333in;
}

[class~="page-404"] {
  text-align: center;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-bottom-style: solid;
}

[class~="page-my-account"] [class~="my-account-account-details"] legend {
  font-weight: 400;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  border-bottom-color: currentColor;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-right-style: solid;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  padding-top: 0.104166667in;
}

[class~="page-my-account"] [class~="my-account-navigation"] ul li {
  vertical-align: middle;
}

[class~="page-faq"] [class~="faq-section"] {
  margin-bottom: 0.625in;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-top-style: solid;
}

[class~="page-my-account"] [class~="my-account-account-details"] legend {
  margin-top: 0.3125in;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  border-image: none;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"],
.page-faq .faq-section .faq-item .faq-question {
  font-weight: 500;
}

.page-404 .content-page-404,
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-left: 0;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-left: 0.208333333in;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after {
  content: "$";
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  font-size: 18px;
}

.page-404 .content-page-404 {
  padding-bottom: 3.75pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  font-size: 9.75pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
.page-404 .content-page-404 {
  padding-right: 0;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th,
[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  padding-right: 0.208333333in;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"] {
  margin-top: 0.625pc;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after {
  margin-left: 5px;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-bottom: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  padding-top: 12px;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after {
  font-family: eleganticons;
}

.page-404 .content-page-404 {
  padding-top: 6.25pc;
}

.page-about-us .block .block-title .sub-title {
  font-size: 16.5pt;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  font-size: 10.5pt;
}

.page-faq .faq-section .faq-item .faq-question {
  color: #333;
}

.page-faq .faq-section .faq-item .faq-question,
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  font-size: 16px;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3,
[class~="page-404"] [class~="content-page-404"] [class~="button"]:after {
  font-size: 1.25pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  height: 0.75pt;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  padding-top: 0;
}

[class~="page-my-account"]
  [class~="my-account-navigation"]
  ul
  li
  a[class~="active"] {
  background: transparent;
}

.page-faq .faq-section .faq-item .faq-question {
  line-height: 1.5;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  color: #fff;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"],
[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  background: #000;
}

.page-faq .faq-section .faq-item .faq-question {
  margin-bottom: 4.5pt;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr td,
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  line-height: 1.8;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr td,
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  vertical-align: top;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  border-radius: 0;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after,
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  position: relative;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-left-color: #f5f5f5;
}

[class~="page-my-account"] [class~="my-account-account-details"] legend {
  margin-bottom: 0.9375pc;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:hover:before,
[class~="page-404"] [class~="content-page-404"] [class~="button"]:hover {
  background: #cb8161;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  color: #000;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  cursor: pointer;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after {
  line-height: 1;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  text-transform: uppercase;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  z-index: 1;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"],
[class~="page-my-account"] [class~="my-account-orders"] table tr th {
  font-weight: 500;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="button-register"]
  input[type="submit"] {
  letter-spacing: 0.75pt;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"]:after {
  top: 3.75pt;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-bottom-color: #f5f5f5;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-right-color: #f5f5f5;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-top-color: #f5f5f5;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3 {
  margin-left: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3 {
  margin-bottom: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3 {
  margin-right: 0;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"]
  h3 {
  margin-top: 0;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr td {
  padding-left: 0.208333333in;
}

[class~="page-404"] [class~="content-page-404"] [class~="button"] {
  letter-spacing: 0.1em;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  [class~="col-title"] {
  background: #f5f5f5;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  border-image: none;
}

[class~="page-my-account"]
  [class~="my-account-navigation"]
  ul
  li
  a[class~="active"] {
  color: #cb8161;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  transition: all 0.3s ease;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr td {
  padding-bottom: 7.5pt;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr td {
  padding-right: 0.208333333in;
}

[class~="page-my-account"] [class~="my-account-orders"] table tr td {
  padding-top: 7.5pt;
}

[class~="page-my-account"]
  [class~="my-account-addresses"]
  [class~="addresses"]
  [class~="addresses-col"]
  address {
  margin-bottom: 0;
}

[class~="page-my-account"]
  [class~="my-account-account-details"]
  [class~="form-row"]
  [class~="button"]:hover {
  background: #cb8161;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:before {
  transition: all 0.3s ease;
}

[class~="page-login-register"]
  [class~="box-form-login"]
  [class~="box-content"]
  [class~="rememberme-lost"]
  [class~="lost-password"]
  a:hover:before {
  width: 50%;
}

/*===================================================================
10. Footer
====================================================================*/
#site-footer[class~="small-space"] [class~="footer-top"] {
  padding-bottom: 50px;
}

#site-footer [class~="social-link"] li,
#site-footer [class~="social-link"] li a {
  display: inline-block;
}

#site-footer[class~="no-border-top"] {
  border-top-width: medium;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  color: #000;
}

#site-footer[class~="small-space"] [class~="row"] > div {
  padding-left: 11.25pt;
}

#site-footer[class~="no-border-top"] {
  border-top-style: none;
}

#site-footer,
#site-footer.dark .footer-bottom {
  border-top-width: 0.75pt;
}

#site-footer ul {
  list-style: none;
}

#site-footer [class~="footer-bottom"] p,
#site-footer[class~="three-columns"]
  [class~="column-center"]
  [class~="block"]:last-child,
#site-footer [class~="footer-bottom"] ul {
  margin-bottom: 0;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  font-size: 12px;
}

#site-footer ul {
  padding-left: 0;
}

#site-footer[class~="no-border-top"] {
  border-top-color: currentColor;
}

#site-footer[class~="small-space"] [class~="footer-top"] {
  margin-bottom: 0.520833333in;
}

#site-footer[class~="no-border-top"] {
  border-image: none;
}

#site-footer [class~="social-link"] li a {
  width: 0.395833333in;
}

#site-footer[class~="small-space"] [class~="row"] {
  margin-left: -0.15625in;
}

#site-footer[class~="small-space"] [class~="row"] {
  margin-bottom: 0;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li:first-child {
  margin-left: 0;
}

#site-footer ul,
#site-footer[class~="small-space"] [class~="row"] > div {
  padding-bottom: 0;
}

#site-footer ul li a:hover {
  color: #cb8161;
}

[class~="home"] #site-footer,
#site-footer[class~="m-t-0"] {
  margin-top: 0;
}

#site-footer[class~="small-space"] [class~="row"] {
  margin-right: -0.15625in;
}

#site-footer,
#site-footer.dark .footer-bottom {
  border-top-style: solid;
}

#site-footer[class~="small-space"] [class~="row"] {
  margin-top: 0;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:hover:before {
  width: 100%;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li:last-child {
  margin-right: 0;
}

#site-footer .footer .block {
  margin-bottom: 40px;
}

#site-footer .footer .block p {
  text-align: left;
  /* width: 400px; */
  margin: 0 auto;
}
#site-footer .footer .block .footer-logo {
  /* margin-bottom: 40px; */
  height: auto;
  width: 30%;
  margin: 0 auto;
  padding-bottom: 20px;
}
#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  letter-spacing: 0.125pc;
}

#site-footer [class~="social-link"] li a i,
#site-footer .block .block-title {
  font-size: 1pc;
}

#site-footer .block .block-title {
  font-weight: 600;
}

#site-footer ul li {
  margin-bottom: 6pt;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a,
#site-footer .block .block-title {
  text-transform: uppercase;
}

#site-footer .block .block-title {
  margin-top: 0;
}

#site-footer[class~="m-t-30"] {
  margin-top: 22.5pt;
}

#site-footer [class~="social-link"] li a:hover {
  background: #cb8161;
}

#site-footer .footer .block.m-b-15 {
  margin-bottom: 11.25pt;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  line-height: 1.8;
}

#site-footer ul {
  padding-right: 0;
}

#site-footer[class~="small-space"] [class~="row"] > div {
  padding-right: 11.25pt;
}

#site-footer[class~="small-space"] {
  padding-top: 3.125pc;
}

#site-footer .block .block-title,
#site-footer .footer .block.m-b-20 {
  margin-bottom: 20px;
}

#site-footer.background {
  background: #f6f6f6;
}

#site-footer [class~="social-link"] li a {
  height: 2.375pc;
}

#site-footer.background {
  border-top-width: medium;
}

#site-footer [class~="social-link"] li a {
  line-height: 28.5pt;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  position: absolute;
}

#site-footer [class~="social-link"] li a {
  border-left-width: 0.75pt;
}

#site-footer.background {
  border-top-style: none;
}

#site-footer.background {
  border-top-color: currentColor;
}

#site-footer [class~="social-link"] li a {
  border-bottom-width: 0.75pt;
}

#site-footer.background {
  border-image: none;
}

#site-footer.dark .footer-bottom {
  border-top-color: #2d2d2d;
}

#site-footer[class~="dark"] {
  background: #161616;
}

#site-footer .footer-bottom .footer-right .block-menu ul li {
  display: inline-block;
}

#site-footer.dark .footer-bottom {
  border-image: none;
}

#site-footer[class~="small-space"] [class~="row"] > div,
#site-footer ul {
  padding-top: 0;
}

#site-footer [class~="social-link"] li a:hover {
  border-left-color: #cb8161;
}

#site-footer [class~="social-link"] li a i {
  color: #000;
}

#site-footer [class~="social-link"] li a:hover {
  border-bottom-color: #cb8161;
}

#site-footer[class~="dark"] ul li a:hover,
#site-footer[class~="dark"] [class~="social-link"] li a i,
#site-footer[class~="dark"] [class~="block"] [class~="block-title"],
#site-footer [class~="social-link"] li a:hover i {
  color: #fff;
}

#site-footer [class~="footer-bottom"][class~="background-white"] {
  background: #fff;
}

#site-footer [class~="social-link"] li:first-child {
  margin-left: 0;
}

#site-footer [class~="social-link"] li a {
  border-right-width: 0.75pt;
}

#site-footer .footer-bottom.no-border-top {
  border-top-width: medium;
}

#site-footer [class~="social-link"] li a {
  border-top-width: 0.75pt;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  content: "";
}

#site-footer .footer-bottom.no-border-top {
  border-top-style: none;
}

#site-footer [class~="social-link"] li:last-child {
  margin-right: 0;
}

#site-footer .footer-bottom.no-border-top {
  border-top-color: currentColor;
}

#site-footer [class~="footer-top"] {
  border-bottom-width: 1px;
}

#site-footer [class~="footer"] [class~="block"][class~="m-b-30"] {
  margin-bottom: 22.5pt;
}

#site-footer [class~="social-link"] li {
  margin-left: 0.1875pc;
}

#site-footer [class~="social-link"] li a {
  border-left-style: solid;
}

#site-footer [class~="footer-top"],
#site-footer [class~="social-link"] li a {
  border-bottom-style: solid;
}

#site-footer [class~="social-link"] li a {
  border-right-style: solid;
}

#site-footer [class~="social-link"] li a:hover {
  border-right-color: #cb8161;
}

#site-footer .footer-bottom.no-border-top {
  border-image: none;
}

#site-footer .footer-bottom .footer-right .block-menu ul li {
  margin-left: 15px;
}

#site-footer ul {
  margin-bottom: 0;
}

#site-footer [class~="footer-top"] {
  border-bottom-color: #e5e5e5;
}

#site-footer [class~="social-link"] li a {
  border-top-style: solid;
}

#site-footer [class~="social-link"] li a {
  border-left-color: #d3d3d3;
}

#site-footer [class~="social-link"] li a {
  border-bottom-color: #d3d3d3;
}

#site-footer {
  border-top-color: #e5e5e5;
}

#site-footer[class~="small-space"] [class~="footer-bottom"] {
  margin-top: 10px;
}

#site-footer [class~="footer-bottom"] [class~="footer-right"] {
  text-align: right;
}

#site-footer [class~="social-link"] li a:hover {
  border-top-color: #cb8161;
}

#site-footer,
#site-footer [class~="footer-top"] {
  border-image: none;
}

#site-footer[class~="three-columns"] [class~="footer"] {
  padding-left: 0;
}

#site-footer [class~="social-link"] li a {
  border-right-color: #d3d3d3;
}

#site-footer[class~="three-columns"] [class~="footer"] {
  padding-bottom: 0.3125in;
}

#site-footer p {
  margin-bottom: 6pt;
}

#site-footer [class~="social-link"] li a {
  border-top-color: #d3d3d3;
}

#site-footer[class~="three-columns"] [class~="footer"],
#site-footer [class~="footer-bottom"] {
  padding-right: 0;
}

#site-footer [class~="social-link"] li a {
  border-image: none;
}

#site-footer [class~="social-link"] li {
  margin-bottom: 0;
}

#site-footer[class~="three-columns"] [class~="footer"] {
  padding-top: 0.3125in;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  background: #000;
}

#site-footer [class~="footer-top"] {
  padding-bottom: 4.375pc;
}

#site-footer [class~="social-link"] li,
#site-footer[class~="three-columns"] [class~="column-center"] {
  text-align: center;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  bottom: 0;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-left-width: 0.0625pc;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  left: 0;
}

#site-footer [class~="footer-top"] {
  margin-bottom: 0.729166667in;
}

#site-footer [class~="social-link"] li {
  margin-right: 0.1875pc;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-left-style: solid;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  width: 0;
}

#site-footer [class~="footer-bottom"] {
  border-top-width: 0.010416667in;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  display: inline-block;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-left-color: #e8e8e8;
}

#site-footer [class~="social-link"] li a {
  border-radius: 50%;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  height: 1px;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-image: none;
}

#site-footer .footer-bottom .footer-right .block-menu ul li {
  margin-right: 11.25pt;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-right-width: 0.0625pc;
}

#site-footer {
  padding-top: 52.5pt;
}

#site-footer [class~="social-link"] li a {
  border-radius: 50%;
}

#site-footer [class~="footer-bottom"] {
  border-top-style: solid;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-right-style: solid;
}

#site-footer {
  margin-top: 4.375pc;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  border-right-color: #e8e8e8;
}

#site-footer[class~="three-columns"] [class~="column-center"],
#site-footer
  [class~="footer-bottom"]
  [class~="block-widget-wrap"]
  > [class~="row"] {
  align-items: center;
}

#site-footer [class~="footer-bottom"] {
  border-top-color: #e0e0e0;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  flex: 0 0 28%;
}

#site-footer [class~="footer-bottom"] {
  border-image: none;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  flex: 0 0 28%;
}

#site-footer [class~="social-link"] li a {
  border-radius: 50%;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  max-width: 28%;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  transition: all 0.3s ease;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  padding-top: 60px;
}

#site-footer [class~="social-link"] li {
  margin-top: 0;
}

#site-footer [class~="footer-bottom"] {
  margin-top: 0px;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  padding-bottom: 60px;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  display: flex;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  align-content: center;
}

#site-footer.three-columns,
#site-footer [class~="footer-bottom"] {
  padding-left: 0;
}

#site-footer [class~="footer-bottom"] {
  padding-bottom: 15pt;
}

#site-footer[class~="three-columns"] [class~="column-center"] {
  justify-content: center;
}

#site-footer [class~="footer-bottom"] {
  padding-top: 15pt;
}

#site-footer [class~="social-link"] li a {
  border-radius: 50%;
}

#site-footer [class~="social-link"] li a {
  border-radius: 50%;
}

#site-footer[class~="three-columns"] [class~="column-left"],
#site-footer[class~="three-columns"] [class~="column-right"] {
  flex: 0 0 36%;
}

#site-footer[class~="three-columns"] [class~="column-right"],
#site-footer[class~="three-columns"] [class~="column-left"] {
  flex: 0 0 36%;
}

#site-footer[class~="three-columns"] [class~="column-left"],
#site-footer[class~="three-columns"] [class~="column-right"] {
  max-width: 36%;
}

#site-footer[class~="three-columns"] [class~="column-left"],
#site-footer[class~="three-columns"] [class~="column-right"] {
  padding-top: 22.5pt;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a {
  position: relative;
}

#site-footer[class~="three-columns"] [class~="column-left"],
#site-footer[class~="three-columns"] [class~="column-right"] {
  padding-bottom: 22.5pt;
}

#site-footer .block-newsletter {
  max-width: 3.4375in;
}

#site-footer
  [class~="footer-bottom"]
  [class~="footer-right"]
  [class~="block-menu"]
  ul
  li
  a:before {
  transition: all 0.3s ease;
}

#site-footer.three-columns {
  padding-bottom: 0;
}

#site-footer[class~="three-columns"] [class~="column-right"] {
  padding-left: 0.833333333in;
}

#site-footer {
  color: #868686;
}

#site-footer[class~="three-columns"] [class~="footer-bottom"] {
  margin-top: 0;
}

#site-footer.three-columns {
  padding-right: 0;
}

#site-footer.three-columns {
  padding-top: 0;
}

#site-footer ul li span {
  color: #000;
}

#site-footer[class~="three-columns"]
  [class~="footer"]
  [class~="block"]:last-child {
  margin-bottom: 0;
}

#site-footer ul li a {
  color: #868686;
}

/* =======Gallery====== */
#gallery-section.container-fluid {
  /* padding: 0 !important; */
}

#myModal .gallery-scroll {
  padding: 30px;
  margin: 0 auto;
  max-width: 50%;
  justify-content: center;
}
.slick-track {
  /* width: 100% !important; */
}
/* .row > .column {
  padding: 0;
} */
.whoWeAreImg {
  width: 262px;
  heigth: 333px;
}

.row-gal {
  width: 100.01% !important;
  margin: 0 auto !important;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 19%;
}
.columnSlider {
  float: left;
  width: 17% !important;
}
/* The Modal (background) */
.modal-gal {
  position: fixed;
  z-index: 1;
  padding-top: 30px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000000e0;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fff !important ;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  user-select: none;
  background: transparent;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  /* background-color: rgba(0, 0, 0, 0.8); */
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

img {
  /* margin-bottom: -4px; */
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.blink {
  animation: blink-animation 1.5s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.demo {
  opacity: 0.6;
  width: 100%;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
  width: 265px;
  height: auto;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.store-loacte-select {
  width: 100%;
  /* background: transparent; */
  color: #3c2147;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 500;
  border: 1px solid #3c2147;
}
.form-select:focus {
  border-color: #3c2147 !important;
  box-shadow: none !important;
}
.form-control-wrap i {
  position: relative;
  left: 95%;
  bottom: 30%;
  font-size: 14px;
}
.tryAtBtn {
  padding: 10px 0px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.tryAtBtn a.button {
  color: white; /* Set the text color to inherit from its parent */
}
.cameraIcon {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.productImgDiv {
  padding: 10px;
  margin: 15px 0px;
}
.productCard {
  padding: 15px;
}
.wishlistDiv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  align-items: center;
}
.trayVideoSection {
  width: 540px;
  height: 400px;
}
.wightTitle {
  margin-bottom: 0px;
}
.productCardSection {
  display: flex;
  justify-content: center;
  box-shadow: rgb(95 72 111) 2px 1px 11px;
  padding: 10px 20px;
  margin-top: 10px;
}
.videoLocatore {
  border: 2px solid #3c2147;
  border-radius: 7px;
  font-size: 10px;
  color: #3c2147;
}
.tryAtHome {
  border-radius: 9px;
  width: 100%;
  font-size: 15px;
  /* margin: 20px 0px; */
  text-align: center;
  padding: 4px 0px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.backBtn {
  font-size: 22px;

  color: #3c2147;
}
/* .tryAtOn {
 
  border: 1px solid #210832;
  color: #210832;
}
.tryAtOn:hover {
  color: #210832;
} */
.croseIconDiv {
  position: absolute;
  right: -10px;
  top: -28px;
  font-size: 30px;
  color: #210832;
}
