.App {
  text-align: center;
}
body {
  color: #868686;
}
a:hover {
  text-decoration: none !important;
}
.menu-link {
  text-decoration: none;
}
/* Add your global styles here */
.menu-item.active a {
  text-decoration: underline;
}
.menu-link {
  text-decoration: none;
}
.menu-link.active {
  text-decoration: underline;
}

/* Style the link when it's focused (clicked) */
.menu-link:focus {
  text-decoration: underline;
}

/* Style the active link with an underline */
.menu-item.active .menu-link {
  text-decoration: underline;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none !important;
  border: none !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs {
  border-bottom: none !important;
}

/* Slider Css */
/* .slick-slide img {
  display: block;
  height: 600px;
} */
.prev-arrow {
  background: transparent;
  border: 1px solid white;
  font-size: 30px;
  position: absolute;
  left: 2%;
  z-index: 999;
  top: 50%;
  border-radius: 34px;
  width: 40px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.next-arrow {
  background: transparent;
  border: 1px solid white;
  font-size: 30px;
  position: absolute;
  left: 95%;
  z-index: 999;
  top: 50%;
  border-radius: 34px;
  width: 40px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
ul.slick-dots {
  position: absolute !important;
}

/* Trending Product Css */
.slick-img {
  width: 350px;
}
.trend-prev-arrow {
  font-size: 30px;
  position: absolute;
  left: -0.5%;
  z-index: 999;
  top: 43%;
  border-radius: 34px;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}
.trend-next-arrow {
  font-size: 30px;
  position: absolute;
  left: 93%;
  z-index: 999;
  top: 43%;
  border-radius: 34px;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}
.quickview-text {
  background: black;
  position: absolute;
  top: 70%;
  width: 92px;
  left: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.quickview-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.quickview {
  background: white;
  padding: 0px 10px 5px 12px;
  position: absolute;
  top: 78%;
  font-size: 20px;
  left: 170px;
}

/* Who we are */
.logo-div {
  max-width: 20% !important;
  flex: 0 0 20% !important;
}

/* HEADER */
.menu-link:hover {
  text-decoration: none;
}

.wishlist-message-container {
  visibility: visible;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #805195;
  border-radius: 30px;
  padding: 10px;
  z-index: 999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);

  animation: fadeInMessCont 0.8s ease-in-out forwards;
}

.wishlist-message {
  color: white; /* Text color */
  margin: 0; /* Remove any default margin */
  padding: 10px 10px 0 10px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInMess 0.5s ease-in-out forwards;
}

@keyframes fadeInMessCont {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move the box down by 20px at the start */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move the box up to its normal position */
  }
}

@keyframes fadeInMess {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move the box down by 20px at the start */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move the box up to its normal position */
  }
}

.wishlist-remove-container {
  visibility: visible;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #805195; /* Soft green background color */

  border-radius: 30px; /* Rounded corners */
  padding: 10px;
  z-index: 999; /* Ensure the message is above other elements */
  text-align: center; /* Center text */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);

  animation: fadeInMessCont 0.8s ease-in-out forwards;
}

.wishlist-remove-message {
  color: white; /* Text color */
  margin: 0; /* Remove any default margin */
  padding: 10px 10px 0 10px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInMess 0.5s ease-in-out forwards;
}

.emptyWishlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.emptyWishlistBox {
  background-color: #e6e6ff; /* Light purple background color */
  padding: 10px 30px;
  border-radius: 40px;
  text-align: center;
  opacity: 0; /* Set initial opacity to 0 for fade-in effect */
  transform: translateY(20px); /* Move the box down by 20px initially */

  animation: fadeIn 0.8s ease-in-out forwards; /* Animation properties */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move the box down by 20px at the start */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move the box up to its normal position */
  }
}

/* ALERT IN CATEGORY PRODUCTS */
.alert-container {
  visibility: visible;
  /* position: fixed; */
  /* top: 50%;
  left: 50%; */
  transform: translate(-50%, -50%);
  background-color: #805195;
  border-radius: 50px;
  padding: 10px;
  padding-top: 5px;
  /* z-index: 999;  */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(20px);

  animation: fadeInAlert 0.8s ease-in-out forwards;
}

.alert-display {
  color: white;
  margin: 0; /* Remove any default margin */
  padding: 10px 10px 0 10px;
  background-color: #805195;
  border-color: #805195;
  opacity: 0;
  transform: translateX(20px);
  animation: fadeIndisplay 0.5s ease-in-out forwards;
}

@keyframes fadeInAlert {
  from {
    opacity: 0;
    transform: translateX(20px); /* Move the box down by 20px at the start */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* Move the box up to its normal position */
  }
}

@keyframes fadeIndisplay {
  from {
    opacity: 0;
    transform: translateX(20px); /* Move the box down by 20px at the start */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* Move the box up to its normal position */
  }
}

.modal-content {
  width: 700px;
}
/* Loader Css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* PRDUCT DETAILS PAGE */
.metalDetailContainer {
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  flex-direction: column;
}

/* .slide{
  display: flex;
justify-content: center;
align-items: center;
padding: 20px;
padding-bottom: 25px;
padding-right: 25px;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), #edf1f4);
border-radius: 40px;
box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1), -15px -15px 20px #fff;

  
} */
.metalName {
  float: left;
  font-size: 20px;
  margin: 0;
}

.metalWeight {
  float: right;
  font-size: 20px;
  margin: 0px;
  line-height: 22px;
}

.ReactModal__Overlay {
  background: none !important;
}
.ReactModal__Content {
  background: none !important;
  border: none !important;
}

/* Mobile menu css */
/* MobileSidebar.css */
.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -100%; /* Initially hidden off the screen */
  width: 80%;
  max-width: 300px; /* Adjust to your preference */
  height: 100%;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #15021e;
  color: white;
}

.mobile-sidebar.open {
  left: 0; /* Slide in when open */
}

.sidebar-content {
  padding: 20px;
}

.close-btn {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #555;
}

.sidebar-content ul {
  list-style: none;
}
.sidebar-content ul li {
  padding: 12px;
}
.hamberge-btn {
  background: none;
  font-size: 25px;
}
/* glod price css ---> */
.goldPriceDiv {
  font-size: 16px;
  font-family: Lato, sans-serif;
  background-color: #3c2147;
  color: white;
  text-shadow: 1px 1px 3px #707471;
  font-weight: 300;
  padding: 5px 20px;
  text-align: end;
}
.goldPriceTitle {
  font-weight: 600;
  color: #ffd283;
  font-size: 15px;
}
/* BlinkingText.css */

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
/* /// store locater ---> */
.sticky-donate {
  position: fixed;
  bottom: 20%;
  z-index: 99;
  left: 20px;
}
.right-align-text {
  text-align: right;
}
.sticky-donate .sticky-donate-text span {
  font-size: 12px;
  background-color: #fb8c51;
  color: #ffffff;
  text-shadow: 1px 1px 3px #f98d51;
  font-weight: 700;
  border-radius: 15px;
  bottom: 25%;
  /* left: -13px; */
  right: -10px;
  position: fixed;
  padding: 10px 15px;
  display: block;
  width: 38px;
  transition: all 0.1s ease;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  align-items: start;
  letter-spacing: -1.5px;
}
.containerOne {
  position: relative;
  font-family: Arial;
}

.text-block {
  position: absolute;
  background-color: #0000008f;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconTitle {
  color: rgb(255, 255, 255);
  padding: 90px 0;
  text-align: center;
}
.iconDiv {
  display: flex;
  justify-content: center;
}
.iconAdress {
  height: 80px;
  width: auto;
}
.caresolContainer {
  padding-top: 70px;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.addressTitle {
  text-align: center;
}
/* address card ---> */
.addreshCard {
  padding: 20px;
  height: 250px;
}
.react-multi-carousel-track {
  gap: 15px;
}

/* blod detials css ---> */
.producDetialsCol {
  border: 1px solid;
  padding: 4px;
  text-align: center;
  color: gray;
  cursor: pointer;
}
.categoryUl li.activeCategory,
[class~="block-post-tags"] ul li:hover {
  background-color: #3c2147 !important;
  border: 1px solid #3c2147;
}
.categroyTitle {
  text-align: center;
  color: #3c2147;
}
.categoryUl {
  display: flex;
  flex-wrap: wrap;
}
.categoryUl li.activeCategory a {
  color: #fff;
}
.categoryUl li {
  flex-basis: 100%;
  color: #000;
}
/* .col-5-new {
  max-width: 20%;
} */
.productDetailsTitle {
  font-size: 40px;
  color: #3c2147;
  font-weight: 600;
  text-align: center;
}
.productDesription {
  text-align: center;
  line-height: 30px;
  /* padding: 20px; */
}

/* Media Query for responsive */
@media (max-width: 321px) {
  .footer-p {
    width: 308px !important;
  }
}
@media (min-width: 0px) and (max-width: 481px) {
  .next-arrow {
    left: 90% !important;
  }
  #site-footer .footer .block p {
    width: 281px !important;
  }
  .next-arrow {
    width: 30px !important;
    height: 30px !important;
    top: 37% !important;
    font-size: 20px !important;
  }
  .prev-arrow {
    width: 30px !important;
    height: 30px !important;
    top: 37% !important;
    font-size: 20px !important;
  }
  /* .slick-img {
    width: 168px !important;
  } */
  img.hover-shadow {
    width: 83px !important;
  }
  .iconTitle {
    padding: 171px 0px !important;
  }
  .col-5-new {
    width: 100% !important;
  }
}

@media (max-width: 961px) {
  .header-desktop {
    display: none;
  }
  .prev-arrow {
    left: 1%;
  }
  .next-arrow {
    left: 94%;
  }
  img.hover-shadow {
    width: 150px !important;
  }
  .slick-img {
    width: 240px;
  }
  .trend-prev-arrow {
    left: -1% !important;
  }
  .trend-next-arrow {
    left: 94% !important;
  }
}
@media (min-width: 961px) and (max-width: 1025px) {
  .slick-img {
    width: 240px !important;
  }
  .trend-next-arrow {
    left: 95% !important;
  }
  img.hover-shadow {
    width: 200px !important;
  }
  .iconTitle {
    padding: 100px 0;
  }
  .col-5-new {
    width: 26%;
  }
}
@media (max-width: 768px) {
  .col-5-new {
    width: 50%;
  }
  .iconTitle {
    padding: 157px 0;
  }
  img.hover-shadow {
    width: 100% !important;
  }
}

.appvide {
  display: flex;
  align-items: center;
}

.appcanvas {
  position: absolute;
  width: 540px;
  height: 400px;

  /* top: 100px */
}

.appvide {
  position: relative;
}
.overLapCard {
  width: 106%;
  background: #80808059;
  border: none;
  padding: 111px;
}
.foucsIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 75px;
  color: #fff;
}
.findFaceTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.overLapDiv {
  width: 300px;
}
.descriptionOverLap {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
}
.trayOnOverLap {
  position: absolute;
  top: 0px;
  left: 14px;
}
.myapp {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
