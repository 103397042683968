@media (max-width: 1510px) and (min-width: 1200px) {
  .quickview-notices-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1600px) {
  .slick-wrap:hover .slick-arrow.fa-angle-left {
    left: -40px;
  }
  .slick-wrap:hover .slick-arrow.fa-angle-right {
    right: -40px;
  }
  .product-cats-list.slick-wrap:hover .slick-arrow.fa-angle-left {
    left: -15px;
  }
  .product-cats-list.slick-wrap:hover .slick-arrow.fa-angle-right {
    right: -15px;
  }
}
@media (max-width: 1600px) {
  .block-sliders.nav-vertical .slick-dots {
    right: 30px;
  }
}
@media (max-width: 1350px) {
  .site-navigation ul > li.level-0 {
    padding: 0 10px;
  }
  .header-left .site-navigation ul > li.level-0 {
    padding: 0 8px;
  }
  .header-left .site-navigation ul > li.level-0:first-child {
    padding-left: 0;
  }
  .header-left .site-navigation ul > li.level-0:last-child {
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .lg-m-lr {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .lg-p-lr {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .section-mb-l {
    margin-bottom: 70px;
  }
  .block-instagram .row > div,
  .block-product-cats.layout-2 .row > div,
  .block-products .products-list > .row > div,
  .large-space .row > div,
  .section-padding {
    padding: 0 7.5px;
  }
  .block-instagram .row,
  .block-product-cats.layout-2 .row,
  .block-products .products-list > .row,
  .large-space .row {
    margin: 0 -7.5px;
  }
  .column-background-wrap {
    padding: 70px 0 40px;
  }
  .site-header.header-v2 .site-logo {
    margin: 0 30px;
  }
  .site-navigation ul > li.level-0.mega-menu.mega-menu-fullwidth > .sub-menu {
    width: 600px;
  }
  .block-banners.layout-2
    .block-widget-banner
    .banner-wrapper-infor
    .title-banner,
  .block-newsletter .newsletter-title,
  body.home .section .block-title h2 {
    font-size: 25px;
  }
  .block-banners.layout-3
    .block-widget-banner
    .banner-wrapper-infor
    .title-banner,
  .block-intro.layout-2 .intro-text,
  body.home.title-8 .section .block-title h2 {
    font-size: 35px;
  }
  .block-sliders .item-content .item-info,
  .block-sliders.layout-3 .item-content .item-info {
    padding: 0 0 0 100px;
  }
  .block-sliders i.slick-arrow.fa-angle-left {
    left: 30px;
  }
  .block-sliders i.slick-arrow.fa-angle-right {
    right: 30px;
  }
  .block-sliders .item-content .item-info .title-slider {
    font-size: 60px;
  }
  .block-sliders.layout-2 .item-content .item-info .button-slider {
    line-height: 40px;
    padding: 0 22px;
  }
  .block-sliders.layout-2 .item-content .item-info .title-slider {
    font-size: 55px;
    margin-bottom: 16px;
  }
  .block-sliders.layout-2 .item-content .item-info .subtitle-slider {
    font-size: 12px;
  }
  .block-sliders.layout-3 .item-content .item-info .title-slider {
    font-size: 45px;
  }
  .block-intro .intro-item,
  .block-sliders.layout-3 .item-content .item-info .description-slider {
    margin-bottom: 25px;
  }
  .block-sliders.layout-4 .item-content .item-info .subtitle-slider {
    font-size: 70px;
  }
  .block-sliders.layout-4 .item-content .item-info .title-slider {
    font-size: 40px;
    margin-bottom: 35px;
  }
  .block-product-cats.round-border .slick-sliders .slick-list {
    margin: 0;
  }
  .block-product-cats i.slick-arrow.fa-angle-left {
    left: 0;
  }
  .block-product-cats i.slick-arrow.fa-angle-right {
    right: 0;
  }
  .block-parallax .content .title {
    font-size: 65px;
    line-height: 75px;
  }
  .block-sliders.nav-left .slick-dots {
    padding: 0 0 30px 15px;
  }
  .slick-sliders .slick-list {
    margin: 0 -15px;
  }
  .slick-sliders .slick-list .slick-slide {
    padding: 0 15px;
  }
  .block-product-cats.layout-3 .slick-sliders .slick-list {
    margin: 0 -5px;
  }
  .block-product-cats.layout-3 .item-product-cat {
    padding: 0 5px;
  }
  .block-product-cats.layout-4 .text-wrap .title {
    font-size: 35px;
    line-height: 42px;
  }
  .block-intro .intro-title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 25px;
  }
  .block-intro.layout-3 .intro-title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 15px;
  }
  .block-instagram .right .row:first-child,
  .block-intro.layout-3 .intro-item {
    margin-bottom: 15px;
  }
  .block-intro.layout-4 .nav-tabs .nav-item {
    padding: 0 20px;
  }
  .block-intro.layout-4 .nav-tabs .nav-item a {
    font-size: 18px;
  }
  .block-intro.layout-5 .intro-title {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .block-intro.layout-6 .intro-title {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  .block-intro.layout-7 .intro-title {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  .block-banners.layout-1 .block-widget-banner .banner-wrapper-infor {
    padding: 0 15px 35px 30px;
  }
  .block-banners.layout-1
    .block-widget-banner
    .banner-wrapper-infor
    .title-banner,
  .block-banners.layout-7
    .block-widget-banner
    .banner-wrapper-infor
    .title-banner,
  .block-posts.layout-2 .post-content .post-title {
    font-size: 20px;
  }
  .block-banners.layout-3 .block-widget-banner .banner-wrapper-infor,
  .block-banners.layout-3
    .section-column.right
    .block-widget-banner:first-child
    .banner-wrapper-infor,
  .block-banners.layout-3
    .section-column.right
    .block-widget-banner:last-child
    .banner-wrapper-infor {
    justify-content: center;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 15px;
  }
  .block-banners.layout-6
    .block-widget-banner
    .banner-wrapper-infor
    .content
    .title-banner {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .block-banners.layout-7 .block-widget-banner .banner-image {
    margin-bottom: 20px;
  }
  .md-content-full-height * {
    height: 100%;
  }
  .md-content-full-height .banner-wrapper-infor {
    height: auto;
  }
  .block-product-cats.layout-2 .row > div:nth-child(2n) .cat-item {
    margin-top: 0;
  }
  .block-product-cats.layout-2 .cat-item .cat-title {
    margin-top: 20px;
  }
  .block-product-cats.layout-5
    .item-product-cat-content
    .product-cat-content-info {
    padding-bottom: 20px;
  }
  .block-product-cats.layout-5
    .item-product-cat-content
    .product-cat-content-info
    .item-title {
    font-size: 17px;
  }
  .block-lookbook.layout-2 .lookbook-intro-wrap .lookbook-intro .title {
    font-size: 35px;
    line-height: 42px;
    margin-bottom: 16px;
  }
  .block-lookbook.layout-2 .lookbook-intro-wrap .lookbook-intro .sub-title {
    margin-bottom: 6px;
  }
  .block-posts.layout-3 .post-content {
    padding-left: 30px;
  }
  .block-posts.layout-3 .post-content .post-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .left-sidebar {
    padding-right: 15px;
  }
  #site-footer.three-columns .column-right,
  .right-sidebar,
  .shop-details .product-info {
    padding-left: 15px;
  }
  .page-login-register .box-form-login .box-content {
    padding: 0 50px;
  }
  .shop-cart .cart-totals {
    max-width: 100%;
    margin-top: 20px;
  }
  .page-about-us .block .block-title h2 {
    font-size: 40px;
  }
  #site-footer .footer-bottom .footer-right .block-menu ul li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 992px) {
  body i.slick-arrow {
    opacity: 0;
    visibility: hidden;
  }
  body i.slick-arrow:hover {
    cursor: pointer;
    color: #000;
  }
  .site-header .header-mobile {
    display: none;
  }
  .home .site-header.header-v1 {
    position: absolute;
    width: 100%;
  }
  .home .site-header.header-v1.relative {
    position: relative;
  }
}
@media (min-width: 991px) {
  .wishlist-popup .wishlist-popup-inner .wishlist-popup-content {
    min-height: 600px;
  }
}
@media (max-width: 991px) {
  .modal-content {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 72%;
    object-position: center;
  }
  #myModal .gallery-scroll {
    padding: 30px;
    margin: 0 auto;
    max-width: 100%;
  }
  .next,
  .prev {
    top: 38%;
  }
  .site-header.color-white {
    background: #000;
  }
  .newsletter-popup .newsletter-container .newsletter-img,
  .products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .btn-compare,
  .products-list.grid
    .product-wapper
    .products-thumb
    .product-button
    .product-quickview,
  .site-header .header-desktop {
    display: none;
  }
  .block-posts.layout-3 .post-image,
  .md-b-10 {
    margin-bottom: 10px;
  }
  .block-product-cats.layout-2 .item-product-cat,
  .md-b-15 {
    margin-bottom: 15px;
  }
  .md-b-20 {
    margin-bottom: 20px;
  }
  .md-b-30 {
    margin-bottom: 30px;
  }
  .md-b-50 {
    margin-bottom: 50px;
  }
  .mm-btn {
    height: 50px;
  }
  .newsletter-popup {
    width: 300px;
  }
  .newsletter-popup .newsletter-container .newsletter-form {
    width: 100%;
    padding: 30px 0;
  }
  .section-container.small {
    max-width: none;
  }
  .section.contact-background {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .site-mobile-navigation,
  .site-mobile-vertical {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    width: 280px;
    z-index: 999999;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: hidden;
    border-width: 1px 0 0 1px;
    -webkit-transform: translate3d(-280px, 0, 0);
    transform: translate3d(-280px, 0, 0);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .site-mobile-navigation.active,
  .site-mobile-vertical.active {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 1px 12px 2px hsl(0deg 0% 56% / 30%);
  }
  .site-mobile-navigation .remove-megamenu,
  .site-mobile-vertical .remove-megamenu {
    padding: 10px 20px 20px;
    z-index: 10;
    cursor: pointer;
    text-align: right;
    display: block;
    background: #cb8161;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    font-family: Lato, sans-serif;
  }
  .site-mobile-navigation .remove-megamenu:after,
  .site-mobile-vertical .remove-megamenu:after {
    content: "\4d";
    font-family: eleganticons;
    color: #fff;
    font-size: 25px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    margin-left: 5px;
    position: relative;
    top: 5px;
  }
  .site-mobile-navigation .mm-menu,
  .site-mobile-vertical .mm-menu {
    background: #fff;
    z-index: 999;
    top: 68px;
  }
  .site-mobile-navigation .mm-menu .mm-panels > .mm-panel:before {
    height: 10px;
  }
  .site-mobile-navigation .mm-menu .mm-panels > .mm-panel > .mm-listview,
  .site-mobile-vertical .mm-menu .mm-panels > .mm-panel > .mm-listview {
    margin: -8px 0 0;
  }
  .site-mobile-navigation .mm-menu .mm-listview,
  .site-mobile-vertical .mm-menu .mm-listview {
    margin: 0;
    padding: 0;
  }
  .site-mobile-navigation .mm-menu ul > li,
  .site-mobile-navigation .mm-menu ul > li.level-0,
  .site-mobile-vertical .mm-menu ul > li,
  .site-mobile-vertical .mm-menu ul > li.level-0 {
    display: inline-block;
    width: 100%;
    position: relative !important;
    padding-top: 8px;
    padding-bottom: 6px;
    border-bottom: 1px solid hsla(0, 0%, 90%, 0.5);
  }
  .site-mobile-navigation
    .mm-menu
    .mm-panels
    > .mm-panel
    > .mm-listview
    > li.level-0
    > a,
  .site-mobile-navigation
    .mm-menu
    .mm-panels
    > .mm-panel
    > .mm-listview
    > li.level-0
    > span,
  .site-mobile-vertical
    .mm-menu
    .mm-panels
    > .mm-panel
    > .mm-listview
    > li.level-0
    > a,
  .site-mobile-vertical
    .mm-menu
    .mm-panels
    > .mm-panel
    > .mm-listview
    > li.level-0
    > span {
    padding: 0;
    text-align: left;
  }
  .site-mobile-navigation .mm-menu ul > li span.title,
  .site-mobile-navigation .mm-menu ul > li.level-0 span.title,
  .site-mobile-navigation .mm-menu ul > li.level-0 > a:not(.mm-next),
  .site-mobile-navigation .mm-menu ul > li > a:not(.mm-next),
  .site-mobile-vertical .mm-menu ul > li span.title,
  .site-mobile-vertical .mm-menu ul > li.level-0 span.title,
  .site-mobile-vertical .mm-menu ul > li.level-0 > a:not(.mm-next),
  .site-mobile-vertical .mm-menu ul > li > a:not(.mm-next) {
    color: #000;
    padding: 0;
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .site-mobile-navigation .mm-menu ul > li .mm-next,
  .site-mobile-navigation .mm-menu ul > li.level-0 .mm-next,
  .site-mobile-vertical .mm-menu ul > li .mm-next,
  .site-mobile-vertical .mm-menu ul > li.level-0 .mm-next {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 43px;
    text-align: center;
  }
  .block-products .nav-tabs.small-text .nav-item:before,
  .site-mobile-navigation .mm-menu ul li:after,
  .site-mobile-navigation .mm-menu ul > li .mm-next:before,
  .site-mobile-navigation .mm-menu ul > li.level-0 .mm-next:before,
  .site-mobile-vertical .mm-menu ul > li .mm-next:before,
  .site-mobile-vertical .mm-menu ul > li.level-0 .mm-next:before {
    content: none;
  }
  .block-testimonial.layout-1 i.slick-arrow.fa-angle-right,
  .site-mobile-navigation .mm-menu ul > li .mm-next:after,
  .site-mobile-navigation .mm-menu ul > li.level-0 .mm-next:after,
  .site-mobile-vertical .mm-menu ul > li .mm-next:after,
  .site-mobile-vertical .mm-menu ul > li.level-0 .mm-next:after {
    right: 5px;
  }
  .site-mobile-navigation
    .mm-menu
    ul
    > li.current_page_item
    > a
    > span.menu-item-text,
  .site-mobile-navigation
    .mm-menu
    ul
    > li.level-0.current_page_item
    > a
    > span.menu-item-text,
  .site-mobile-vertical
    .mm-menu
    ul
    > li.current_page_item
    > a
    > span.menu-item-text,
  .site-mobile-vertical
    .mm-menu
    ul
    > li.level-0.current_page_item
    > a
    > span.menu-item-text {
    font-weight: 500;
    color: #000;
  }
  .site-mobile-navigation .mm-menu .mm-navbar,
  .site-mobile-vertical .mm-menu .mm-navbar {
    height: 50px;
    line-height: 30px;
    border: none;
  }
  .site-mobile-navigation .mm-menu .mm-navbar a,
  .site-mobile-vertical .mm-menu .mm-navbar a {
    color: #cb8161;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }
  .block-posts.layout-3 .post .post-inner,
  .site-mobile-navigation .mm-menu .sub-menu .row,
  .site-mobile-vertical .mm-menu .sub-menu .row {
    display: block;
  }
  .site-mobile-navigation .mm-menu .sub-menu .row > div,
  .site-mobile-vertical .mm-menu .sub-menu .row > div {
    max-width: 100% !important;
  }
  .site-mobile-navigation .mm-menu .sub-menu .sub-menu-title,
  .site-mobile-vertical .mm-menu .sub-menu .sub-menu-title {
    font-size: 14px;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  .site-mobile-navigation .mm-menu .sub-menu .sub-menu-title:before,
  .site-mobile-vertical .mm-menu .sub-menu .sub-menu-title:before {
    position: absolute;
    bottom: -1px;
    width: 40px;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    content: "";
    left: 0;
  }
  .site-mobile-navigation .mm-menu .sub-menu .menu-section,
  .site-mobile-vertical .mm-menu .sub-menu .menu-section {
    margin-bottom: 40px;
  }
  .site-mobile-navigation .mm-menu .sub-menu .menu-list,
  .site-mobile-vertical .mm-menu .sub-menu .menu-list {
    padding: 0;
  }
  .site-mobile-navigation .mm-menu .sub-menu .menu-list li a,
  .site-mobile-vertical .mm-menu .sub-menu .menu-list li a {
    text-transform: none;
  }
  .site-mobile-navigation .mm-menu .sub-menu .block-posts .post-item,
  .site-mobile-vertical .mm-menu .sub-menu .block-posts .post-item {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .site-mobile-navigation
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-content,
  .site-mobile-vertical
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-content {
    padding-left: 12px;
    margin-top: -3px;
  }
  .site-mobile-navigation
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-image,
  .site-mobile-vertical .mm-menu .sub-menu .block-posts .post-item .post-image {
    max-width: 65px;
    flex: 0 0 65px;
    margin-bottom: 6px;
  }
  .site-mobile-navigation
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-image
    img,
  .site-mobile-vertical
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-image
    img {
    max-width: 65px;
  }
  .site-mobile-navigation
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-content
    .post-title,
  .site-mobile-vertical
    .mm-menu
    .sub-menu
    .block-posts
    .post-item
    .post-content
    .post-title {
    font-size: 15px;
    margin-bottom: 0;
    line-height: 1.2;
  }
  .site-mobile-vertical {
    right: 0;
    left: auto;
    -webkit-transform: translate3d(280px, 0, 0);
    transform: translate3d(280px, 0, 0);
  }
  .site-mobile-vertical .remove-verticalmenu {
    display: block;
    padding: 10px 20px 20px;
    background: #000;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    font-family: Barlow Semi Condensed;
  }
  .site-mobile-vertical .remove-verticalmenu:after {
    content: "\4d";
    font-family: eleganticons;
    color: #fff;
    font-size: 25px;
    line-height: 25px;
    margin-left: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    display: inline-block;
    position: relative;
    top: 5px;
  }
  .mini-cart .cart-popup {
    right: -10px !important;
    top: 48px !important;
    margin-top: 2px !important;
  }
  .block-sliders.layout-3 .item-content .item-info .title-slider {
    font-size: 35px;
    margin-bottom: 6px;
  }
  .block-sliders.layout-3 .item-content .item-info .description-slider {
    margin-bottom: 18px;
  }
  .block-sliders.layout-5 .item-content .item-info .title-slider {
    font-size: 40px;
  }
  .block-sliders.layout-5 .item-content .item-info .button-slider {
    line-height: 40px;
  }
  .block-sliders i.slick-arrow {
    width: 34px;
    height: 34px;
    line-height: 26px;
  }
  .block-sliders i.slick-arrow.fa-angle-left:before,
  .block-sliders i.slick-arrow.fa-angle-right:before {
    font-size: 12px;
  }
  .block-products .nav-tabs.small-text .nav-item {
    padding: 0 10px;
  }
  .block-posts.layout-3 .post-content,
  .block-products .nav-tabs.small-text .nav-item:first-child {
    padding-left: 0;
  }
  .block-products .nav-tabs.small-text .nav-item:last-child {
    padding-right: 0;
  }
  .block-products.slider .content-product-list i.slick-arrow {
    font-size: 16px;
  }
  .block-testimonial .slick-arrow {
    font-size: 20px;
  }
  .block-testimonial.layout-1 i.slick-arrow.fa-angle-left {
    left: 5px;
  }
  .block-newsletter .newsletter-wrap {
    padding: 70px 0;
  }
  .block-lookbook.layout-2 .lookbook-intro-wrap .lookbook-intro {
    width: 100%;
    padding: 0 15px;
  }
  .block-posts.layout-3 .post:first-child .post-image {
    margin-bottom: 0;
  }
  .blog-details-content,
  .right-sidebar {
    order: 1;
  }
  .blog-details-sidebar,
  .posts-column,
  .products-column {
    order: 2;
  }
  .shop-checkout .checkout-review-order,
  .shop-details .product-info {
    margin-top: 30px;
  }
  .page-login-register .box-form-login .box-content {
    padding: 0 30px;
  }
  .page-my-account .my-account-addresses .addresses .addresses-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  #site-footer,
  #site-footer.three-columns {
    padding-bottom: 52px;
  }
  #site-footer.four-columns .column-1,
  #site-footer.four-columns .column-2,
  #site-footer.four-columns .column-3,
  #site-footer.four-columns .column-4 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .back-top {
    bottom: 65px;
    right: 15px;
    z-index: 1;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .column,
  .trendingImgRow {
    margin-right: 0 !important;
  }
  .appcanvas {
    width: 329px;
    height: 233px;
  }
  .overLapCard {
    padding: 27px 4px;
  }
  .column {
    width: 50% !important;
    padding: 10px !important;
  }
  .trendingImgRow {
    justify-content: center !important;
  }
  [class~="block-contact-form"]
    [class~="contact-us-form"]
    [class~="form-button"] {
    margin: 20px 0 !important;
  }
}
@media (max-width: 767px) {
  .block-sliders i.slick-arrow.fa-angle-left,
  .block-sliders i.slick-arrow.fa-angle-right,
  .hidden-xs {
    display: none !important;
  }
  #site-footer,
  #site-footer.three-columns {
    padding-bottom: 10px;
  }
  #site-footer [class~="footer-bottom"] {
    padding-bottom: 5px;
  }
  #header-topbar {
    height: auto;
    line-height: normal;
    padding: 15px 0;
    text-align: center;
  }
  #header-topbar .topbar-right {
    text-align: center;
    margin-top: 15px;
  }
  .sm-m-0 {
    margin: 0;
  }
  .sm-p-0 {
    padding: 0;
  }
  .block-banners.layout-3 .row .section-column.left,
  .post-details .post-content blockquote i,
  .post-details .post-meta,
  .posts-list.list .post-entry .post-image,
  .sm-m-b,
  .sm-m-b-15 {
    margin-bottom: 15px;
  }
  .block-product-cats.layout-4 .text-wrap .title,
  .posts-list.list .post-entry .post-excerpt,
  .products-list.list .product-wapper .products-content .rating,
  .sm-m-b-20 {
    margin-bottom: 20px;
  }
  .sm-m-b-30,
  body.home .section .block-title {
    margin-bottom: 30px;
  }
  .page-about-us .block .block-title,
  .sm-m-b-40 {
    margin-bottom: 40px;
  }
  .products-list.list .product-wapper,
  .sm-m-b-50 {
    margin-bottom: 50px;
  }
  .block-intro .section-column.left,
  .block-intro .section-column.right,
  .block-intro.layout-6 .section-column.left,
  .block-intro.layout-6 .section-column.right,
  .block-posts.layout-3 .post-image img,
  .column-50,
  .page-my-account .my-account-content,
  .page-my-account .my-account-navigation,
  .section-column.left,
  .section-column.right {
    width: 100%;
  }
  .btn-underline:hover:before {
    left: 25%;
    right: 25%;
  }
  .block-intro .intro-item,
  .section-row {
    display: block;
  }
  .site-main .page-title .content-title-heading .text-title-heading {
    font-size: 40px;
  }
  .block-newsletter .newsletter-title,
  body.home .section .block-title h2 {
    font-size: 22px;
  }
  .block-banners.layout-3
    .block-widget-banner
    .banner-wrapper-infor
    .title-banner,
  .block-lookbook .lookbook-intro-wrap .title,
  .page-404 .content-page-404 .sub-title,
  .page-about-us .block .block-title h2,
  body.home.title-3 .block-newsletter .newsletter-title,
  body.home.title-3 .section .block-title h2,
  body.home.title-8 .section .block-title h2 {
    font-size: 30px;
  }
  body.home.title-4 .section .block-title h2 {
    font-size: 32px;
  }
  body.home.title-4 .section .block-title .sub-title {
    font-size: 11px;
  }
  .block-newsletter .title,
  body.home.title-7 .section .block-title h2 {
    font-size: 25px;
  }
  .block-info .info-wrap .title,
  .block-intro.layout-5 .intro-title {
    font-size: 30px;
    margin-bottom: 12px;
  }
  .block-info .info-wrap .icon svg {
    margin-bottom: 5px;
  }
  .block-info .info-wrap .icon,
  body.home.title-3 .section .block-title .title-icon {
    font-size: 36px;
  }
  .section.content-outside {
    padding-top: 0;
    margin-bottom: 70px;
    background: 0 0;
  }
  #site-footer .footer-bottom .footer-right,
  .block-intro.layout-4 .nav-tabs .nav-item:last-child,
  .section.content-outside .block-widget-wrap > div {
    margin-bottom: 0;
  }
  .block-sliders .item-content .item-info {
    padding: 0 0 0 25px;
  }
  .block-sliders.auto-height .item-content .content-image img {
    min-height: 350px;
    object-fit: cover;
  }
  .block-sliders .item-content .item-info .title-slider {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .block-sliders .item-content .item-info .button-slider {
    line-height: 40px;
  }
  .block-sliders.layout-2 .item-content .item-info .title-slider {
    font-size: 45px;
  }
  .block-newsletter .newsletter-wrap > div,
  .block-sliders.layout-3 .item-content .item-info {
    padding: 0 15px;
  }
  .block-intro.layout-6 .intro-wrap,
  .block-sliders.layout-3 .item-content .item-info .content {
    max-width: none;
  }
  .block-sliders.layout-3 .item-content .item-info .title-slider {
    font-size: 30px;
    line-height: 38px;
  }
  .block-sliders.layout-3 .item-content .item-info .button-slider {
    line-height: 40px;
    padding: 0 22px;
  }
  .block-sliders.layout-4 .item-content .item-info .subtitle-slider {
    font-size: 50px;
    line-height: 56px;
  }
  .block-sliders.layout-4 .item-content .item-info .title-slider {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .block-sliders.layout-5 .item-content .item-info .title-slider {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .block-banners.layout-7 .block-widget-banner .banner-wrapper-infor .content,
  .block-intro.layout-6 .section-column .intro-image.right,
  .block-lookbook.layout-2 .lookbook-intro-wrap .lookbook-intro,
  .block-newsletter .newsletter-title,
  .block-newsletter .newsletter-wrap,
  .block-newsletter.layout-2 .newsletter-text,
  .block-parallax .content,
  .block-products .nav-tabs.align-left {
    text-align: center;
  }
  .block-parallax.right-text .content {
    margin-left: 0;
    max-width: 100%;
  }
  .block-parallax .content .title {
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 8px;
  }
  .block-parallax .content .button {
    line-height: 40px;
    padding: 0 20px;
  }
  .block-sliders .slick-dots {
    padding: 0 0 40px;
    text-align: center;
  }
  .block-sliders.nav-center .slick-dots {
    padding: 0 0 40px;
  }
  .block-banners.layout-1 .section-column,
  .block-banners.layout-1 .section-column.center {
    max-width: 100%;
    flex: 0 0 100%;
    justify-content: center;
  }
  .block-banners.layout-1 .block-widget-banner .banner-wrapper-infor {
    justify-content: center;
    text-align: center;
  }
  .block-banners.layout-3 .row .section-column {
    width: 100%;
    text-align: center;
  }
  .block-banners.layout-3
    .row
    .section-column.right
    .section-column-inner
    .block-widget-banner:first-child {
    text-align: center;
    margin-bottom: 15px;
  }
  .block-banners.layout-3 .block-widget-banner .banner-wrapper-infor,
  .block-banners.layout-3
    .section-column.right
    .block-widget-banner:first-child
    .banner-wrapper-infor,
  .block-banners.layout-3
    .section-column.right
    .block-widget-banner:last-child
    .banner-wrapper-infor {
    bottom: 0;
    transform: translateY(0);
    top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 15px 50px;
    text-align: center;
  }
  .block-banners.layout-4
    .block-widget-banner
    .banner-wrapper-infor
    .content
    .title-banner {
    font-size: 20px;
    margin-top: 20px;
  }
  .block-banners.layout-4
    .block-widget-banner
    .banner-wrapper-infor
    .content
    .banner-image-description {
    margin-bottom: 16px;
  }
  .block-banners.layout-6
    .block-widget-banner
    .banner-wrapper-infor
    .content
    .title-banner {
    font-size: 40px;
    line-height: 45px;
  }
  .block-products .nav-tabs .nav-item {
    padding: 0 20px;
  }
  .block-products .nav-tabs .nav-item .nav-link {
    font-size: 18px;
  }
  .block-products .nav-tabs.layout-2 .nav-item .nav-link {
    font-size: 16px;
  }
  .block-product-cats.layout-4 .text-wrap {
    margin-bottom: 30px;
    text-align: center;
  }
  .block-product-cats.layout-4 .text-wrap .sub-title {
    margin: 0 0 10px;
  }
  .block-product-cats.layout-5 .section-column.center,
  .block-product-cats.layout-5 .section-column.center .section-column,
  .block-product-cats.layout-5 .section-column.left,
  .block-product-cats.layout-5 .section-column.right {
    width: 100%;
    justify-content: center;
  }
  .block-lookbook .lookbook-intro-wrap {
    padding: 20px 15px;
  }
  .block-lookbook.layout-2 .lookbook-intro-wrap {
    padding: 30px 15px;
    text-align: center;
  }
  .block-lookbook .lookbook-item .col-image {
    order: 1;
  }
  .block-lookbook .lookbook-item .col-text {
    order: 2;
  }
  .block-intro.layout-3 .intro-title,
  .block-intro.layout-4 .intro-title {
    font-size: 30px;
    line-height: 36px;
  }
  .block-testimonial.layout-1 .testimonial-content .item .testimonial-excerpt {
    font-size: 24px;
    line-height: 32px;
    padding: 0 15px;
  }
  .block-newsletter.align-left .newsletter-title,
  .block-newsletter.align-left.layout-2 .newsletter-text {
    text-align: left;
  }
  .block-newsletter .newsletter-form {
    max-width: 360px;
  }
  .block-newsletter.layout-2 .newsletter-form {
    margin-right: auto;
    margin-top: 20px;
  }
  .block-posts.slider .post-content .post-title {
    font-size: 24px;
    line-height: 30px;
  }
  .block-contact-info .info-item .item-tilte h2,
  .post-details .post-content blockquote p,
  .posts-list.list .post-entry .post-meta {
    margin-bottom: 10px;
  }
  .block-intro .intro-wrap {
    text-align: center;
    padding: 70px 15px;
  }
  .block-intro .intro-title {
    font-size: 25px;
    line-height: 32px;
  }
  .block-intro .intro-item .icon {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .block-intro.layout-2 .intro-text {
    font-size: 30px;
    line-height: 35px;
  }
  .block-intro.layout-3 .section-column.left,
  .block-intro.layout-3 .section-column.right,
  .block-intro.layout-7 .section-column.left,
  .block-intro.layout-7 .section-column.right {
    width: 100%;
    display: block;
    text-align: center;
  }
  .block-intro.layout-3 .intro-wrap {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .block-intro.layout-4 .section-column.right {
    justify-content: center;
    padding-left: 0;
    padding-top: 40px;
  }
  .block-intro.layout-4 .nav-tabs .nav-item {
    width: 100%;
    padding: 0;
    margin-bottom: 6px;
  }
  .block-intro.layout-4 .nav-tabs .nav-item a {
    font-size: 15px;
  }
  .block-intro.layout-4 .nav-tabs .nav-item a:before {
    content: none;
  }
  .block-intro.layout-4 .intro-item {
    margin-bottom: 22px;
  }
  .block-intro.layout-5 .intro-icon {
    font-size: 40px;
    margin-bottom: 5px;
  }
  .block-intro.layout-6 .intro-title,
  .block-intro.layout-7 .intro-title {
    font-size: 22px;
    line-height: 30px;
  }
  .block-intro.layout-6 .section-column .intro-image.left {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .block-intro.layout-7 .intro-wrap {
    max-width: none;
    margin-top: 20px;
  }
  .block-video {
    padding: 150px 0 180px;
  }
  .block-video .video-caption .caption-title {
    font-size: 50px;
  }
  .products-list.list .product-wapper .products-content .product-title {
    margin-top: 16px;
  }
  .products-list.list .product-wapper .products-content .product-description {
    padding-top: 0;
    margin-top: 10px;
    border-top: none;
  }
  .wishlist-items tr {
    display: flex;
    flex-wrap: wrap;
  }
  .wishlist-items .wishlist-item-remove {
    flex: 0 0 30px;
    padding-left: 12px !important;
  }
  .wishlist-items .wishlist-item-image {
    flex: 0 0 70px;
  }
  .wishlist-items .wishlist-item-image img {
    width: 65px !important;
  }
  .wishlist-items .wishlist-item-price {
    line-height: 1.1;
  }
  .wishlist-items .wishlist-item-add a {
    margin: 0 !important;
  }
  .wishlist-items tr td.wishlist-item-info {
    padding: 10px 5px;
  }
  .wishlist-items tr td.wishlist-item-actions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 0;
    border-top: 1px dashed #e5e5e5;
    padding: 15px !important;
  }
  .wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top {
    padding: 0 10px;
  }
  .wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-top:before {
    font-size: 20px;
  }
  .wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot {
    height: 80px;
  }
  .wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-page {
    line-height: 30px;
    padding: 0 15px;
    margin: 2.5px 0;
    font-size: 10px;
    width: 100%;
    text-align: center;
  }
  .wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-continue {
    line-height: 26px;
    padding: 0 15px;
    margin: 2.5px 0;
    font-size: 10px;
    width: 100%;
    text-align: center;
  }
  .wishlist-popup
    .wishlist-popup-inner
    .wishlist-popup-content
    .wishlist-popup-content-bot
    .wishlist-notice {
    height: 80px;
    line-height: 80px;
    top: 80px;
  }
  .shop-details .product-images > .row > div:first-child {
    margin-top: 10px;
    order: 1;
  }
  .shop-details .product-images .content-thumbnail-scroll .img-item {
    padding: 0 5px;
  }
  .shop-details .product-images .content-thumbnail-scroll .slick-arrow {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
  }
  .shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-left,
  .shop-details
    .product-images
    div.col-md-2:hover
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-left {
    top: calc(50% - 15px);
    left: -10px;
  }
  .shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-left:before {
    content: "\f104";
  }
  .shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-right,
  .shop-details
    .product-images
    div.col-md-2:hover
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-right {
    bottom: calc(50% - 10px);
    right: -10px;
  }
  .shop-details
    .product-images
    .content-thumbnail-scroll
    .slick-arrow.fa-angle-right:before {
    content: "\f105";
  }
  .shop-details .product-images .content-thumbnail-scroll .slick-list {
    margin: 0 -5px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .shop-details .product-tabs #review-form .comment-form-comment {
    flex: 0 0 100%;
    order: 1;
    margin-bottom: 15px;
  }
  .shop-cart .cart-items {
    width: 730px;
    max-width: 730px;
  }
  .posts-list.list .post-entry .post-title {
    font-size: 26px;
  }
  .post-details .post-title {
    margin-top: 14px;
    font-size: 26px;
    margin-bottom: 10px;
  }
  .post-details .post-content blockquote {
    padding-left: 20px;
  }
  .page-about-us .block .block-title .sub-title {
    font-size: 18px;
    line-height: 1.4;
  }
  .page-404 .content-page-404 .title-error {
    font-size: 170px;
  }
  #site-footer .footer-bottom .footer-left,
  #site-footer .footer-bottom .footer-right {
    text-align: center;
    margin-bottom: 12px;
  }
  #site-footer .footer-bottom .footer-right .block-menu ul li:first-child {
    margin-left: 10px;
  }
  #site-footer .footer-bottom .footer-right .block-menu ul li:last-child {
    margin-right: 10px;
  }
  #site-footer.four-columns .column-1,
  #site-footer.four-columns .column-2,
  #site-footer.four-columns .column-3,
  #site-footer.four-columns .column-4,
  #site-footer.three-columns .column-center,
  #site-footer.three-columns .column-left,
  #site-footer.three-columns .column-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  #site-footer.three-columns .column-center {
    border-left: none;
    border-right: none;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: left;
  }
  #site-footer.three-columns .column-right {
    justify-content: flex-start;
  }
}
@media (max-width: 480px) {
  body.home .section .block-title.title-underline {
    display: block;
    border-bottom: none;
    padding-top: 20px;
    margin-bottom: 50px;
  }
  .wishListCard {
    margin-bottom: 20px;
  }
  .overLapCard {
    padding: 31px 8px;
  }
  .cart-product-added {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    max-width: 280px;
  }
  .trend-next-arrow {
    left: 85% !important;
    color: #000;
  }
  .trend-prev-arrow {
    color: #000;
  }
  .whoWeAreImg {
    width: 326px !important;
  }
  .mini-cart .cart-popup {
    width: 290px;
    right: -10px !important;
    padding: 15px;
  }
  .mini-cart .cart-popup a.product-name {
    width: 100%;
  }
  .mini-cart .cart-popup .remove {
    right: 0;
  }
  .button-outline {
    padding: 0 20px;
    line-height: 30px;
  }
  .search-overlay .search-from .search-close {
    display: block;
  }
  .block-sliders.layout-2 .item-content .item-info .subtitle-slider {
    font-size: 11px;
  }
  .block-sliders.layout-2 .item-content .item-info .title-slider {
    font-size: 35px;
  }
  .block-sliders.layout-4 .item-content .item-info .subtitle-slider {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 5px;
  }
  .block-sliders.layout-4 .item-content .item-info .title-slider {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .block-banners.layout-2
    .block-widget-banner
    .banner-wrapper-infor
    .title-banner {
    font-size: 22px;
    margin-bottom: 4px;
  }
  .block-banners.layout-2
    .block-widget-banner
    .banner-wrapper-infor
    .banner-image-description {
    font-size: 0px;
    margin-bottom: 10px;
  }
  .block-products .nav-tabs .nav-item {
    display: block;
    margin-bottom: 10px;
    padding: 0;
  }
  .block-products .nav-tabs .nav-item .nav-link {
    display: block;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    font-size: 17px;
    padding: 6px 0;
    border-radius: 0;
  }
  .block-products .nav-tabs.layout-2 .nav-item .nav-link {
    font-size: 14px;
    padding: 6px 0;
  }
  .block-products .nav-tabs .nav-item .nav-link.active,
  .block-products .nav-tabs .nav-item:hover .nav-link {
    border: 1px solid #000;
    background: 0 0;
  }
  .block-products .nav-tabs .nav-item .nav-link:before,
  .block-products .nav-tabs .nav-item:before,
  .shop-details .product-tabs .nav-tabs .nav-item a:before {
    content: none;
  }
  .block-products .nav-tabs.small-text .nav-item {
    padding: 0;
  }
  .block-contact-info .info-title {
    margin-bottom: 40px;
  }
  .block-contact-info .info-title h2 {
    font-size: 28px;
  }
  .block-contact-form .block-title {
    margin-bottom: 30px;
  }
  .block-contact-form .block-title h2,
  .post-details .comments-area .comment-form .form-header h3,
  .post-details .comments-area .comments-title {
    font-size: 26px;
  }
  .block-video .video-caption .caption-title {
    font-size: 36px;
  }
  .site-main .page-title .content-title-heading .text-title-heading {
    font-size: 30px;
  }
  .products-topbar .products-topbar-left,
  .products-topbar .products-topbar-right {
    float: none;
  }
  .products-topbar .layout-toggle,
  .products-topbar .products-sort {
    float: left;
  }
  .products-topbar .products-sort {
    margin-right: 10px;
  }
  .products-topbar .products-topbar-left {
    margin-bottom: 10px;
  }
  .shop-details .buttons .add-to-cart-wrap .quantity input {
    width: 100px;
  }
  .shop-details .product-info .social-share > a {
    display: inline-flex;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .shop-details .product-tabs .nav-tabs .nav-item {
    width: 100%;
    margin: 0;
  }
  .shop-details .product-tabs .nav-tabs .nav-item a {
    font-size: 20px;
    padding: 0;
  }
  .shop-details .product-tabs .nav-tabs {
    margin-bottom: 25px;
  }
  .page-my-account .my-account-navigation,
  .shop-details
    .product-tabs
    .product-reviews
    .comment-list
    li
    .content-comment-container {
    padding: 20px;
  }
  .shop-checkout .checkout-review-order {
    padding: 10px 20px;
  }
  .shop-checkout .checkout-review-order .cart-item .info-product .product-name {
    padding-left: 15px;
  }
  .posts-list.list .post-entry .post-title {
    font-size: 22px;
  }
  .posts-list.list .post-entry .post-meta .post-categories:after,
  .posts-list.list .post-entry .post-meta .post-time:after {
    margin: 0 10px;
  }
  .post-details
    .comments-area
    .comments-list
    .comment-item
    .comment-content-wrap {
    overflow: unset;
  }
  .post-details
    .comments-area
    .comments-list
    .comment-item
    .comment-avatar
    img {
    width: 45px;
    min-width: 45px;
    margin-right: 15px;
  }
  .appcanvas {
    width: 340px;
    height: 270px;
  }
  .appvide {
    margin: 10px 0px;
  }
  .modal-content {
    width: 90%;
  }
  .trayVideoSection {
    width: 340px;
    height: 270px;
  }
  .post-details .comments-area .comments-list .comment-item .comment-author {
    padding-top: 4px;
  }
  .post-details .comments-area .comments-list .comment-item .comment-time {
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .page-login-register .box-form-login .box-content {
    padding: 0 15px;
  }
  .page-404 .content-page-404 .title-error {
    font-size: 120px;
  }
  .page-404 .content-page-404 .sub-title {
    font-size: 20px;
    margin-top: 10px;
    padding-bottom: 20px;
  }
  .page-404 .content-page-404 .button {
    font-size: 12px;
  }
}
