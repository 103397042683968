#pbOverlay,
#pbOverlay.pbLoading .imageWrap {
  width: 100%;
}
#pbOverlay.show {
  opacity: 1;
  pointer-events: auto;
  z-index: 99999999;
}
#pbOverlay,
#pbOverlay .pbLoader {
  pointer-events: none;
  opacity: 0;
  text-align: center;
}
#pbOverlay {
  overflow: hidden;
  user-select: none;
  background: rgba(0, 0, 0, 0.85);
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
#pbOverlay.msie {
  background-color: rgba(0, 0, 0, 0.6);
}
#pbOverlay.msie.pbLoading .imageWrap {
  background: url(../img/checkbox-dark.svg) center center no-repeat;
}
@keyframes pbLoaderFrames {
  50% {
    height: 5px;
  }
}
@-webkit-keyframes pbLoaderFrames {
  50% {
    height: 5px;
  }
}
#pbOverlay .pbLoader {
  visibility: hidden;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  border-radius: 100%;
  box-shadow: 15px 32px 60px -20px #fff inset, 1px 1px 3px 1px #fff inset,
    0 0 20px;
  width: 100px;
  height: 100px;
  transition: 0.3s;
  -webkit-transition: 0.2s;
}
#pbOverlay.thumbs .pbLoader {
  margin-top: -100px;
}
#pbOverlay.pbLoading:not(.msie):not(.error) .pbLoader {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
#pbOverlay .pbLoader b {
  display: inline-block;
  vertical-align: middle;
  margin: 0 2px;
  width: 8px;
  height: 60px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -webkit-animation: 0.9s linear infinite pbLoaderFrames;
  animation: 0.9s linear infinite pbLoaderFrames;
}
#pbOverlay .pbLoader b:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
#pbOverlay .pbLoader b:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
#pbCaption .pbThumbs ul:after,
#pbOverlay .imageWrap:after,
#pbOverlay .pbLoader:before,
#pbOverlay .prevNext:after {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -0.25em;
  vertical-align: middle;
}
@keyframes deadImage {
  50% {
    text-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
    transform: scale(0.85);
  }
}
@-webkit-keyframes deadImage {
  50% {
    text-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
    -webkit-transform: scale(0.85);
  }
}
#pbOverlay .imageWrap {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  height: 100%;
  perspective: 1200px;
  -webkit-perspective: 1200px;
  position: relative;
}
#pbOverlay .imageWrap:before {
  content: "\2716";
  position: absolute;
  top: -999px;
  left: 0;
  width: 100%;
  color: transparent;
  text-shadow: 0 2px 35px rgba(255, 255, 255, 0);
  font-size: 22em;
  cursor: default;
  -webkit-transition: text-shadow 0.7s ease-out;
  transition: text-shadow 0.7s ease-out;
}
#pbOverlay.error .imageWrap:before {
  top: 46%;
  text-shadow: 0 0 0 #fff;
  -webkit-animation: 2s linear 1s infinite deadImage;
  animation: 2s linear 1s infinite deadImage;
}
#pbOverlay.thumbs .imageWrap {
  margin-top: -50px;
  padding: 50px 0;
}
#pbOverlay.error .imageWrap img {
  width: 0;
}
#pbOverlay.show .imageWrap img {
  transition: 0.8s cubic-bezier(0.1, 0.87, 0.48, 1) 0.5s;
  -webkit-transition: 0.8s cubic-bezier(0.1, 0.8, 0.5, 1.03) 0.5s;
  -ms-transition: 0.3s ease-out 0.5s;
  transform: scale(1) rotateX(0);
  -webkit-transform: scale(1) rotateX(0);
  -ms-transform: scale(1);
  visibility: hidden;
  box-shadow: 0 0 20px #000;
  background-color: #fff;
}
#pbOverlay .imageWrap img,
#pbOverlay.hide .imageWrap img.prepare {
  display: inline-block;
  opacity: 1;
  vertical-align: middle;
  transform: scale(0) rotateX(80deg);
  -webkit-transform: scale(0) rotateX(80deg);
  -ms-transform: scale(0) rotateX(80deg);
  border-radius: 6px;
  border: none;
  max-height: 95%;
  max-width: 100%;
}
#pbOverlay.on .imageWrap img {
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -ms-transition-delay: 0s;
}
#pbOverlay .imageWrap img.zoomable {
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -ms-transition: 0.1ms;
  position: relative;
  z-index: 9;
}
#pbOverlay.hide .imageWrap img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  opacity: 0;
  transition: 0.4s ease-in;
  -webkit-transition: 0.4s ease-in;
  -ms-transition: 0.4s ease-in;
}
#pbAutoplayBtn,
#pbCloseBtn {
  position: absolute;
  top: -50px;
  right: -50px;
  z-index: 999;
  display: block;
  padding: 0 0 20px 20px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-out 0.3s;
  -webkit-transition: 0.2s ease-out 0.3s;
}
.hide #pbCloseBtn {
  top: -50px;
  right: -50px;
}
.on #pbCloseBtn {
  top: -2px;
  right: -2px;
}
#pbCloseBtn:before {
  content: "\00D7";
  font-weight: 700;
}
#pbAutoplayBtn:before,
#pbCloseBtn:before {
  display: inline-block;
  height: 35px;
  width: 35px;
  padding: 8px 8px 12px 12px;
  font-size: 2em;
  opacity: 0.8;
  line-height: 0.7;
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0 0 0 70px;
  transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
}
#pbCloseBtn:hover:before {
  padding: 15px 10px 24px 24px;
  background: rgba(255, 100, 100, 0.4);
}
#pbAutoplayBtn {
  display: none;
  right: auto;
  left: -50px;
  padding: 0;
  width: 50px;
  height: 50px;
  font-size: 13px;
}
#pbOverlay.hasArrows .prevNext,
.hasAutoplay #pbAutoplayBtn {
  display: block;
}
#pbAutoplayBtn:hover {
  width: 60px;
  height: 60px;
}
.on #pbAutoplayBtn {
  top: 0;
  left: 0;
  transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
}
#pbAutoplayBtn:before {
  content: "\2016";
  width: 100%;
  height: 100%;
  border-radius: 0 0 70px;
  font-weight: 700;
  padding: 0;
  text-indent: -6px;
  line-height: 1.6;
}
#pbAutoplayBtn:active:before {
  text-shadow: 0 0 3px #fff, 0 0 6px #fff;
}
#pbAutoplayBtn.play:before {
  content: "\25BA";
}
#pbAutoplayBtn .pbProgress {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  padding: 6px;
  top: 0;
  left: 0;
  opacity: 0.2;
  transform: rotateZ(0);
  -webkit-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
#pbAutoplayBtn.playing .pbProgress {
  -webkit-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
#pbAutoplayBtn .pbProgress:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  box-shadow: 0 0 0 8px #fff inset;
}
#pbCaption,
.hide #pbCaption {
  position: absolute;
  z-index: 999;
  bottom: -110px;
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.show.on #pbCaption {
  bottom: 5px;
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
}
#pbCaption .counter,
#pbCaption .title {
  display: inline-block;
  color: #fff;
  margin: 0 6px;
}
#pbCaption .counter {
  opacity: 0.55;
}
.pbCaptionText {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.9;
  font-weight: 700;
  font-size: 0.9em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.pbCaptionText.change {
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
  opacity: 0;
}
#pbOverlay .pbThumbs {
  display: none;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
#pbOverlay.thumbs .pbThumbs {
  display: block;
  width: 100%;
  padding: 5px 0 2px;
  overflow: hidden;
}
#pbCaption .pbThumbs:hover {
  clear: both;
}
#pbCaption .pbThumbs ul {
  display: inline-block;
  position: relative;
  list-style: none;
  height: 80px;
  padding: 0 5px;
  margin: 0;
  white-space: pre;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}
#pbCaption .pbThumbs ul:after {
  vertical-align: bottom;
}
#pbCaption .pbThumbs li {
  display: inline-block;
  vertical-align: bottom;
  height: 70%;
  opacity: 0.6;
  transition: 0.15s;
  -webkit-transition: 0.15s;
}
#pbCaption .pbThumbs li.active,
#pbCaption .pbThumbs li:hover {
  height: 100%;
  opacity: 1;
}
#pbCaption .pbThumbs a {
  height: 100%;
  padding: 0 2px;
  display: block;
  user-select: none;
}
#pbCaption .pbThumbs img {
  height: 96%;
  min-height: 95.9%;
  border: 2px solid #fff;
  max-width: none;
  border-radius: 0;
  transition: 0.15s;
  -webkit-transition: 0.15s;
}
#pbCaption .pbThumbs li:hover img {
  min-height: 96%;
}
#pbCaption .pbThumbs li.fast a img {
  transition: none;
  -webkit-transition: none;
}
#pbCaption .pbThumbs li.active a img {
  border-color: #005bab;
  min-height: 96%;
}
#pbOverlay .prevNext {
  display: none;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 35%;
  padding: 80px 0;
  opacity: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  top: 0;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  text-shadow: 0 0 12px #000, 0 0 10px #fff;
  cursor: pointer;
}
#pbOverlay.on .prevNext:hover {
  opacity: 0.5;
}
#pbOverlay.on .prevNext:active {
  transition: 80ms;
  opacity: 1;
  text-shadow: 0 0 16px #fff, 0 0 10px #000;
}
.prevNext b {
  display: inline-block;
  vertical-align: middle;
  transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}
.prevNext:hover b {
  transition: 0.2s cubic-bezier(0.095, 0.87, 0.485, 0.985);
  -webkit-transition: 0.2s cubic-bezier(0.095, 0.87, 0.485, 0.985);
}
#pbPrevBtn b {
  transform: scale(0.4) translateX(350px);
  -webkit-transform: scale(0.4) translateX(350px);
}
#pbNextBtn b {
  transform: scale(0.4) translateX(-350px);
  -webkit-transform: scale(0.4) translateX(-350px);
}
#pbNextBtn b:after,
#pbPrevBtn b:before {
  display: inline;
  line-height: 0.3;
  font-size: 18em;
  font-weight: 400;
  color: #fff;
  font-family: Arial;
}
#pbPrevBtn b:before {
  content: "\2039";
}
#pbNextBtn b:after {
  content: "\203A";
}
#pbOverlay.on #pbPrevBtn:hover b {
  transform: scale(1) translateX(20px);
  -webkit-transform: scale(1) translateX(20px);
}
#pbOverlay.on #pbNextBtn:hover b {
  transform: scale(1) translateX(-20px);
  -webkit-transform: scale(1) translateX(-20px);
}
#pbOverlay.show #pbPrevBtn {
  left: 0;
  text-align: left;
}
#pbOverlay.show #pbNextBtn {
  right: 0;
  text-align: right;
}
@media all and (max-width: 700px) {
  #pbOverlay .imageWrap img,
  #pbOverlay.hide .imageWrap img.prepare {
    max-height: 100%;
  }
  #pbOverlay.thumbs .imageWrap {
    padding: 0;
    margin: 0;
  }
  #pbOverlay .pbThumbs {
    margin-bottom: -60px;
  }
  #pbOverlay .pbThumbs.show {
    margin: 0;
  }
  #pbCaption .pbThumbs ul {
    height: 50px;
  }
  #pbCaption .pbThumbs li {
    height: 100%;
    opacity: 1;
  }
  #pbCaption .pbThumbs li img {
    min-height: 96.5%;
  }
}
@media all and (max-width: 710px) {
  #pbCaption .pbThumbs li.active a img {
    min-height: 96%;
  }
}
