.myAcoountTitle {
  border-bottom: 1px solid #2108325e;
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.myAccountLink {
  font-size: 22px;
  padding: 10px;
  border-bottom: 1px solid #2108325e;
  border-right: 1px solid #2108325e;
}
.myAccountLink:hover {
  background-color: #210832;
}
.detailsDiv {
  border: 1px solid #2108325e;
}
.informationDiv {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #2108325e;
  background-color: #ada4b3;
}
.informationTitle {
  color: #210832;
}
.editBtn {
  color: #210832;
  border: 1px solid;
  background: #fff;
  padding: 6px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 7px;
}
.myAccountRow {
  padding: 20px;
}
