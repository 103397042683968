@font-face {
  font-family: icomoon;
  src: url(../fonts/icomoon6dbe.eot?fzv6fp);
  src: url(../fonts/icomoon6dbe.eot?fzv6fp#iefix) format("embedded-opentype"), url(../fonts/icomoon6dbe.ttf?fzv6fp) format("truetype"), url(../fonts/icomoon6dbe.woff?fzv6fp) format("woff"), url(../fonts/icomoon6dbe.svg?fzv6fp#icomoon) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

[class*=" icon-"], 
[class^=icon-] {
    font-family: icomoon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing:grayscale
}

.icon-compare:before {
    content: "\e913"
}

.icon-email:before {
    content: "\e911"
}

.icon-pin:before {
    content: "\e912"
}

.icon-right-arrow-two:before {
    content: "\e900"
}

.icon-heart:before {
    content: "\e901"
}

.icon-large-paper-bag:before {
    content: "\e902"
}

.icon-left-arrow-angle:before {
    content: "\e903"
}

.icon-right-arrow:before {
    content: "\e904"
}

.icon-right-arrow-angle:before {
    content: "\e905"
}

.icon-search:before {
    content: "\e906"
}

.icon-user:before {
    content: "\e907"
}

.icon-add:before {
    content: "\e908"
}

.icon-envelope:before {
    content: "\e909"
}

.icon-straight-quotes:before {
    content: "\e90a"
}

.icon-wedding-rings:before {
    content: "\e90b"
}

.icon-bracelet:before {
    content: "\e90c"
}

.icon-pendant:before {
    content: "\e90d"
}

.icon-earrings-1:before {
    content: "\e90e"
}

.icon-earrings:before {
    content: "\e90f"
}

.icon-left-arrow:before {
    content: "\e910"
}
